<template>
  <div class="calendar__wrapper">
    <div class="calendar__header">
      <p class="calendar__date">{{ getFormattedDate }}</p>
      <div class="calendar__controllers">
        <img alt="" class="calendar__controller calendar__controller--left" src="../assets/icons/arrow-left.svg"
             @click="onSubMonth()">
        <img alt="" class="calendar__controller calendar__controller--right" src="../assets/icons/arrow-right.svg"
             @click="onAddMonth()">
      </div>
<!--      <v-button fa-icon="calendar" size="yellow w-20 p-1 m-1" :value="$t('добавитьРасписание')"-->
<!--                @click="showModal=true" v-if="role!=='clinic'"/>-->
      <br>
    </div>
    <div class="calendar__main">
      <div class="calendar__weekdays">
        <p v-for="(weekday, index) in weekdays" :key="index" class="calendar__weekday">{{ weekday }}</p>
      </div>
      <div class="calendar__days">
        <div v-for="(day, index) in days" :key="index"
             :class="['calendar__day', day.class, getDayClass(day.count),]">
          <div class="day">
            <div
                :class="events.includes(dateAsArray[0] + '-' + getDay(dateAsArray[1] + 1) + '-' + getDay(day.count))?'day-active':''">
              {{
                getDay(day.count)
              }}
            </div>
          </div>
          <div v-for="(s) in shedule" :key="s.id">
            <div v-if="(dateAsArray[0] + '-' + getDay(dateAsArray[1] + 1) + '-' + getDay(day.count))===s.start"
                 :alt="s.time"  v-bind:class="{ 'offline': s.record_type==$t('оффлайн') }" class="event" >
              <el-tooltip :content="s.time"
                          :open-delay="100"
                          placement="top"
                          popperClass="tooltip">
                <span class="value">{{ createName(s.title) }}</span>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RangeDatepickerModal v-if="showModal" :visible="showModal"
                          @close="showModal=false"></RangeDatepickerModal>
  </div>

</template>

<script>
import VButton from '@/components/btn/v-button'
import * as dateUtil from '../utils/dateUtil.js'
import RangeDatepickerModal from '@/components/modals/range-datepicker-modal.vue'

export default {
  components: {
    VButton,
    RangeDatepickerModal
  },
  props: {
    shedule: undefined,
    events: undefined,
    lang: {
      type: String,
      default: 'ru'
    }
  },
  data() {
    return {
      showModal: false,
      weekdays: [],
      days: [],
      role: localStorage.role ? localStorage.role : null,
      dateAsArray: [],
      countOfDays: 0,
      today: []
    }
  },
  computed: {
    getFormattedDate() {
      const date = dateUtil.formatDate(this.dateAsArray, 'MMMM, YYYY')
      return date
    }
  },
  created() {
    this.weekdays = dateUtil.getWeekdays(this.lang)
    this.monthNames = dateUtil.getMonthNames(this.lang)
    this.dateAsArray = dateUtil.getDateAsArray()
    this.getDays()
    this.getCurrentDate()
  },
  methods: {
    getDay(v) {
      return v < 10 ? '0' + v : v
    },
    onAddMonth() {
      this.dateAsArray = dateUtil.addMonth(this.dateAsArray)
      this.getDays()
    },
    onSubMonth() {
      this.dateAsArray = dateUtil.subMonth(this.dateAsArray)
      this.getDays()
    },
    getDays() {
      this.countOfDays = dateUtil.getDaysCountInMonth(
          this.dateAsArray[1],
          this.dateAsArray[0]
      )
      const calendarDays = []
      for (let i = 1; i <= this.countOfDays; i++) {
        calendarDays.push({
          count: i,
          class: ''
        })
      }

      const currentMonthFirstDayIndex = this.getWeekdayOfMonth('first')
      const currentMonthLastDayIndex = this.getWeekdayOfMonth('last')
      let prevMonthCountOfDays = this.getMonthDayCount('prev')

      for (let i = currentMonthFirstDayIndex; i > 0; i--) {
        calendarDays.unshift({
          count: prevMonthCountOfDays,
          class: 'calendar__day--disabled'
        })
        prevMonthCountOfDays--
      }

      for (let i = 1; i <= 6 - currentMonthLastDayIndex; i++) {
        calendarDays.push({
          count: i,
          class: 'calendar__day--disabled'
        })
      }

      this.days = calendarDays
    },
    getWeekdayOfMonth(type) {
      const day = [...this.dateAsArray]
      // eslint-disable-next-line eqeqeq
      if (type == 'last') {
        day[2] = 0
      } else {
        // eslint-disable-next-line eqeqeq
        if (type == 'first') {
          day[2] = 1
        }
      }
      return dateUtil.getDayOfYear(day)
    },
    getMonthDayCount(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'next') {
        return dateUtil.getDaysCountInMonth(
            this.dateAsArray[1] + 1,
            this.dateAsArray[0]
        )
        // eslint-disable-next-line eqeqeq
      } else if (type == 'prev') {
        return dateUtil.getDaysCountInMonth(
            this.dateAsArray[1] - 1,
            this.dateAsArray[0]
        )
      }
    },
    getCurrentDate() {
      this.today = dateUtil.getDateAsArray()
    },
    getDayClass(day) {
      // eslint-disable-next-line no-mixed-operators,eqeqeq
      return day < this.today[2] && this.dateAsArray[1] == this.today[1] || this.dateAsArray[1] < this.today[1] || this.dateAsArray[0] != this.today[0] ? 'calendar__day--disabled' : ''
    },
    createName(v) {
      return v.substring(0, v.lastIndexOf(' ') + 2) + '.'
    }
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 450px) {
  .calendar {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__main {
      background: #FFFFFF;
      box-shadow: -1px 0px 0.65vw 0.065vw rgba(0, 147, 138, 0.21);
      border-radius: 0.65vw;
    }

    &__weekdays {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    &__weekday {
      text-align: right;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 0.78vw;
      color: #232A3E;
      padding: 0 0.52vw;
    }

    &__days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    &__day {
      background: #FFFFFF;
      border: 0.065vw solid #E9E9E9;
      min-height: 90px;
      padding: 0.52vw;
      text-align: right;
      color: #232A3E;
      font-weight: bold;

      &--disabled {
        color: #C0C3CC;
      }
    }

    &__date {
      font-family: Gotham;
      font-weight: bold;
      font-size: 4vw;
      width: 26%;
      color: #000000;
    }

    &__controller {
      cursor: pointer;

      &--right {
        margin-left: 2.275vw;
      }
    }

    &__controllers {
      margin-right: auto;
      margin-left: 4.225vw;
    }
  }

  .day {
    display: flex;
    flex-direction: row-reverse;
  }

  .day-active {
    float: right;
    padding: 0.5vw;
    margin: -0.5vw;
    color: white;
    border-radius: 0.3vw;
    background-color: #00938A;
  }

  .event {
    border-left: 0.7vw solid #00938A;
    padding: 0.25vw 0.5vw;
    margin: 0.5vw -0.5vw;
    font-size: 2vw;
    text-align: center;
    background-color: rgba(0, 147, 138, 0.14);
  }
  .offline{
    border-left: 0.7vw solid red;
  }
}

@media only screen and (min-width: 451px) {
  .offline{
    border-left: 0.7vw solid red!important;
  }
  .calendar {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__main {
      background: #FFFFFF;
      box-shadow: -1px 0px 0.65vw 0.065vw rgba(0, 147, 138, 0.21);
      border-radius: 0.65vw;
    }

    &__weekdays {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    &__weekday {
      text-align: right;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 0.78vw;
      color: #232A3E;
      padding: 0 0.52vw;
    }

    &__days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    &__day {
      background: #FFFFFF;
      border: 0.065vw solid #E9E9E9;
      min-height: 90px;
      padding: 0.52vw;
      text-align: right;
      color: #232A3E;
      font-weight: bold;

      &--disabled {
        color: #C0C3CC;
      }
    }

    &__date {
      font-family: Gotham;
      font-weight: bold;
      font-size: 31px;
      width: 26%;
      color: #000000;
    }

    &__controller {
      cursor: pointer;

      &--right {
        margin-left: 2.275vw;
      }
    }

    &__controllers {
      margin-right: auto;
      margin-left: 4.225vw;
    }
  }

  .day {
    display: flex;
    flex-direction: row-reverse;
  }

  .day-active {
    float: right;
    padding: 0.5vw;
    margin: -0.5vw;
    color: white;
    border-radius: 0.3vw;
    background-color: #00938A;
  }

  .event {
    border-left: 0.7vw solid #00938A;
    padding: 0.25vw 0.5vw;
    margin: 0.5vw -0.5vw;
    font-size: 0.9vw;
    text-align: center;
    background-color: rgba(0, 147, 138, 0.14);
  }
}

</style>
