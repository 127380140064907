<template>
  <div>
    <v-ans
        :answer="$t('ОткудаСкидкиОтвет')"
        :question="$t('ОткудаСкидки')"/>
    <v-ans
        :answer="$t('чтоТакоеЧекапОтвет')"
        :question="$t('чтоТакоеЧекап')"/>
    <v-ans
        :answer="$t('КомуНужноПроходитьПроверкуОтвет')"
        :question="$t('КомуНужноПроходитьПроверку')"/>
    <v-ans
        :answer="$t('какойКлиникеОтноситесьОтвет')"
        :question="$t('какойКлиникеОтноситесь')"/>
  </div>
</template>

<script>
import vAns from '@/components/answer/answer'

export default {
  name: 'v-answer-component',
  components: {vAns}
}
</script>

<style scoped>

</style>
