<template>
  <div class="profile-title" v-bind:class="padding">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'profile-title',
  props: ['title', 'padding']
}
</script>

<style lang="scss" scoped>
@media only screen and (max-device-width: 450px) {
  .profile-title {
    font-family: Gotham-Medium, sans-serif;
    font-size: 4vw;
margin:1rem 0;
    &.p-2 {
      padding: 2.04vw 0;
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .profile-title {
    font-family: Gotham-Medium, sans-serif;
    font-size: 1.3vw;

    &.p-2 {
      padding: 1.04vw 0;
    }
  }
}
</style>
