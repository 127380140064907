<template>
  <div class="analysis">
    <vue-title :title="name"></vue-title>
    <main-layout>
      <div class="profile__main">
        <left-navigation/>
        <div class="profile__main__content">
          <page-title
              :title="name" weight="normal"
          />
          <analysis-item
              v-for="(analyze) in analysis"
              :key="analyze.id"
              :name="$t('результатыАнализов')+analyze.id"
              :url="analyze.file_url"
          />
          <template>
            <page-title
                :title="$t('загруженныеАнализы')" weight="normal"
            />
            <div class="edt" @click="showPopup=true">{{$t('загрузитьАнализы')}}</div>
            <analysis-item
                v-for="(analyze) in userAnalysis"
                :key="analyze.id"
                :name="analyze.filename"
                :url="analyze.file_url"
                :user="true"
                @delete="deleteAnalyze(analyze.id)"
            />
          </template>
          <preloader v-show="loader"/>
        </div>
      </div>
    </main-layout>
    <v-popup
        v-show="showPopup" :title="$t('загрузитьАнализы')" @closePopup="closeInfoPopup"
    >
      <div class="popup_body">
        <form @submit.prevent="uploadAnalyze">
          <div class="form-group">
            {{$t('выберитеФайл')}}
            <input @change="uploadFile" class="input" type="file" :placeholder="$t('введитеДанные')" required>
          </div>
          <div class="form-group">
           {{$t('введитеНазваниеАнализа')}}
            <input v-model.trim="filename" class="input"
                   :placeholder="$t('введитеНазваниеАнализа')"
                   type="text">
          </div>

          <div class="form-group">
            <button :disabled="disabledBtn || load" class="btn btn-success" type="submit">{{$t('Сохранить')}}</button>
          </div>
        </form>
      </div>
    </v-popup>
  </div>
</template>

<script>
import LeftNavigation from '@/components/user/left-navigation'
import MainLayout from '@/views/MainLayout'
import PageTitle from '@/components/titles/page-title'
import AnalysisItem from '@/components/analysis/analysis-item'
import Preloader from '@/components/preloader'
import VPopup from '@/components/modals/v-popup'

export default {
  name: 'MyAnalysis',
  components: {
    Preloader,
    AnalysisItem,
    PageTitle,
    LeftNavigation,
    VPopup,
    MainLayout
  },
  data: function () {
    return {
      loader: false,
      name: this.$t('моиАнализы'),
      analysis: [],
      analyze: '',
      showPopup: false,
      file: null,
      filename: '',
      userAnalysis: [],
      load: false,
    }
  },
  async created() {
    await this.getAnalysis()
    await this.getMyAnalysis()
  },
  computed: {
    disabledBtn() {
      return this.file === null || this.filename === ''
    }
  },
  methods: {
    async getAnalysis() {
      this.loader = true
      var url = `${process.env.VUE_APP_API_URL}user/analyses/get`
      const result = await this.$http.post(
          url, {}
      )
      const data = result.data.map(item => {
        return item
      })
      if (data) {
        this.analysis = data
        this.loader = false
      }
    },
    async getMyAnalysis() {
      try {
        this.loader = true

        const url = `${process.env.VUE_APP_API_URL}user/analyses/get/my`
        const result = await this.$http.post(
            url, {}
        )
        this.userAnalysis = result.data
      } catch (e) {

      } finally {
        this.loader = false
      }
    },
    async uploadAnalyze() {
      try {
        this.loader = true
        this.load = true;
        const formData = new FormData()
        formData.append('file', this.file)
        formData.append('filename', this.filename)

        const url = `${process.env.VUE_APP_API_URL}user/analyses/my/upload`
        const result = await this.$http.post(
            url, formData
        )

        await this.getMyAnalysis()
        this.closeInfoPopup()
      } catch (e) {

      } finally {
        this.loader = false
        this.load = false
      }
    },
    async deleteAnalyze(id) {
      try {
        this.loader = true
        const conf = confirm(this.$t('удалитьАнализ'))
        if (conf) {
          const url = `${process.env.VUE_APP_API_URL}user/analyses/my/archive`
          await this.$http.post(
              url, {id}
          )
          await this.getMyAnalysis()
        }
      } catch (e) {

      } finally {
        this.loader = false
      }
    },
    uploadFile(e) {
      this.file = e.target.files[0]
    },
    closeInfoPopup() {
      document.querySelector('body').classList.remove('overflow-hidden')
      document.querySelector('#app').classList.remove('overflow-hidden')
      document.querySelector('.main-layout').classList.remove('overflow-hidden')
      this.showPopup = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

.edt {
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: $green;
  width: 100%;
  max-width: 220px;
  //text-align: left !important;
  font-family: Gotham-Medium, sans-serif;
  font-weight: 500;
  line-height: 22px;
  font-size: 16px;
  border: 1px solid $green;
  border-radius: 8px;
  padding: 4px;
  margin-bottom: 16px;
}
</style>
