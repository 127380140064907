<template>
  <div class="feedback card gr-success flex-col">
    <v-popup v-show="message" close-position="text-accent" @closePopup="closeInfoPopup">
      <div class="h2">
        <div class="center text-accent">
          <font-awesome-icon :icon="['fa','check-circle']" class="big-icon"/>
        </div>
        <span class="center" v-html="message"></span>
        <div class="center">
          <v-button size="text-accent p-1" value="OK" @click="closeInfoPopup"/>
        </div>
      </div>
    </v-popup>
    <form v-on:submit.prevent="submitForm">
      <div class="feedback__header h1 bold text-white text-45">
        {{$t('ПодключитьКлинику')}}
      </div>
      <div class="feedback__info page-info secondary-text bold">
       {{$t('ОставьтеНомер')}}
      </div>
      <div class="feedback_input">
        <div class="feedback_input_col">
          <input ref="name" class="input" :placeholder="$t('названиеКлиники')" required>
        </div>
        <div class="feedback_input_col">
          <input id="tel__numbera" ref="tel" class="input" :placeholder="$t('номерТелефона')" required
                 type="tel">
        </div>
        <div class="feedback_input_col">
          <input :disabled="send" class="input btn danger text-white" type="submit" :value="$t('отправитьЗаявку')">
        </div>
      </div>
      <div class="t20 feedback__checkup">
        <label class="container">
          <div class="feedback__checkup__flex">
            <div class="feedback__checkup__flex_col">
              <input required type="checkbox">
            </div>
            <div class="feedback__checkup__flex_col">
              <span class="checkmark">  {{ $t('яПринимаю') }}</span>
            </div>
          </div>
        </label>
      </div>
    </form>
  </div>
</template>
<script>
import Inputmask from 'inputmask'
import VPopup from '@/components/modals/v-popup'
import VButton from '@/components/btn/v-button'
import {successFeedback} from '@/utils/functions'

export default {
  name: 'feedback-clinique',
  components: {
    VButton,
    VPopup
  },
  mounted() {
    var im = new Inputmask('+9 (999) 999 99 99')
    im.mask(document.getElementById('tel__numbera'))
  },
  data() {
    return {
      message: '',
      send: false
    }
  },
  methods: {
    closeInfoPopup() {
      this.message = ''
    },
    async submitForm() {
      this.send = true
      const result = await this.$http.post(
          `${process.env.VUE_APP_API_URL}web/request/partner`,
          {
            phone: `7${this.$refs.tel.value
                .replaceAll(' ', '')
                .replaceAll('(', '')
                .replaceAll(')', '')
                .replaceAll('+7', '')}`,
            name: this.$refs.name.value
          }
      )
      if (result) {
        this.$refs.tel.value = ''
        this.$refs.name.value = ''
        this.message = successFeedback()
        this.send = false
      }
    }
  }
}
</script>
<style scoped>
@media only screen and (max-device-width: 450px) {
  .feedback {
    padding: 3.9vw 2.665vw;
  }

  .feedback_input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .feedback__checkup__flex {
    display: flex;
    font-size: 2.975vw;
    align-items: center;
  }

  .feedback__checkup__flex_col {
    display: table-cell;
    align-items: center;
    justify-content: center;
  }

  .feedback__checkup {
    text-align: left !important;
    line-height: 1.3vw;
  }

  .feedback__header {
    font-size: 22px !important;
    line-height: 1.95vw;
    text-align: center;
  }

  .feedback__info {
    line-height: 1.625vw;
    font-family: Gotham, sans-serif !important;
    font-weight: 400 !important;
  }

  .feedback_input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .feedback_input_col > .btn {
    border-radius: 1.105vw !important;
    /*width: 100% !important;*/
  }
}

@media only screen and (min-width: 451px) {
  .feedback__checkup__flex {
    display: flex;
    flex-direction: row;
  }

  .feedback_input_col {
    width: 100%;
  }

  .feedback_input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .feedback_input_col > .btn {
    border-radius: 1.105vw !important;
  }

  .feedback__checkup__flex_col {
  }
}
</style>
