const handleView = () => {
    return window.innerWidth <= 450
}
const successOrder = () => {
    if (localStorage.getItem('locale') == 'ru') {
        return 'Заявка принята, сейчас вы будете перенаправлены на страницу оплаты'
    } else if (localStorage.getItem('locale') == 'kk') {
        return 'Өтінім қабылданды, енді сіз төлем бетіне қайта бағытталасыз'
    } else {
        return 'The application has been accepted, now you will be redirected to the payment page'
    }
}
const cancelOrder = () => {

    if (localStorage.getItem('locale') == 'ru') {
        return 'Ваша заявка успешно<br> отменена'
    } else if (localStorage.getItem('locale') == 'kk') {
        return 'Сіздің өтінішіңіз сәтті <br> жойылды'
    } else {
        return 'Your application has been <br>successfully canceled'
    }
}
const cancelAlert = (type) => {


    if (localStorage.getItem('locale') == 'ru') {
        return Alert('отменить', type)
    } else if (localStorage.getItem('locale') == 'kk') {
        return Alert('болдырмау', type)
    } else {
        return Alert('cancel', type)
    }
}
const deleteAlert = (type) => {


    if (localStorage.getItem('locale') == 'ru') {
        return Alert('удалить', type)
    } else if (localStorage.getItem('locale') == 'kk') {
        return Alert('өшіру', type)
    } else {
        return Alert('delete', type)
    }

}
const confirmAlert = (type) => {


    if (localStorage.getItem('locale') == 'ru') {
        return Alert('подтвердить', type)
    } else if (localStorage.getItem('locale') == 'kk') {
        return Alert('растау', type)
    } else {
        return Alert('confirm', type)
    }

}
const approveAlert = (type) => {
    if (localStorage.getItem('locale') == 'ru') {
        return Alert('завершить', type)
    } else if (localStorage.getItem('locale') == 'kk') {
        return Alert('аяқтау', type)
    } else {
        return Alert('complete', type)
    }
}
const Alert = (action, type) => {

    if (localStorage.getItem('locale') == 'ru') {
        return 'Вы действительно хотите ' + action + ' ' + type + '?'
    } else if (localStorage.getItem('locale') == 'kk') {
        return 'Сіз шынымен қалайсыз ба ' + action + ' ' + type + '?'
    } else {
        return 'Do you really want to' + action + ' ' + type + '?'
    }

}

const successFeedback = () => {
    if (localStorage.getItem('locale') == 'ru') {
        return 'Ваша заявка успешно<br> отправлена'
    } else if (localStorage.getItem('locale') == 'kk') {
        return 'Сіздің өтінішіңіз сәтті<br> жіберілді'
    } else {
        return 'Your application has been successfully<br> sent'
    }

}

export {handleView, successOrder, successFeedback, cancelOrder, cancelAlert, confirmAlert, deleteAlert, approveAlert}
