<template>
  <div class="sm-service-info">
    <v-popup v-show="message" @closePopup="message = null">
      <div class="popup__body h2">
        {{ message }}
      </div>
    </v-popup>
    <v-popup
        v-show="timeServiceTake"
        close-position="right-close"
        @closePopup="closeTimeServiceTake"
    >
      <div class="service__item__popup">
        <div class="service__item__popup__body1">
          <form v-on:submit.prevent="submitOrderForm">
            <div class="service__item__popup__body1__left">
              <span class="service__item__popup__body1__left__header">{{ $t('ЗаписьВКлинику') }}</span>
              <input
                  ref="s_id"
                  :value="service.id"
                  required
                  type="hidden"
              >
              <div class="form-group">
                <input
                    :value="$i18n.locale=='ru'?service.title:service.title_en"
                    class="input-sm bordered"
                    disabled="disabled"
                    readonly="readonly"
                    required
                    style="background-color: gainsboro!important; border-color: gainsboro"
                    type="text"
                />
              </div>
              <div class="form-group">
                {{ $t('Вашеимя') }}
                <input
                    ref="username"
                    v-model="user.name"
                    class="input-sm bordered"
                    required
                    type="text"
                />
              </div>
              <div class="form-group">
                {{ $t('вашЭлектронныйАдрес') }}
                <input
                    ref="email"
                    v-model="user.email"
                    class="input-sm bordered"
                    required
                    type="email"
                />
              </div>
              <div class="form-group">
                {{ $t('номерТелефона') }}
                <input :id=service.id ref="usercontact" class="input tel__number" :placeholder="$t('введитеДанные')"
                       required
                       :value="user.phone"
                       type="tel">
              </div>

              <v-button
                  :disabled="load || errorMessage"
                  size="btn btn-success text-white"
                  type="submit"
                  :value="$t('перейтиКоплате')"
              />
            </div>
          </form>
          <div class="service__item__popup__body1__right">
            <date-picker
                v-model="dateTime"
                :open="true"
                :attributes="attrs"
                :is-required='true'
                :is24hr='true'
                :disabled-dates="disableDates"
                :available-dates='availableDates'
                :minute-increment="30"
                :timezone="timezone"
                mode="date"
                type="date"
                value="multiple"
                @update:from-page="changeMonth"
            />
            <el-select v-model="selectedTime" class="select_time vc-container" :placeholder="$t('выберитеВремя')">
              <el-option
                  v-for="item in times"
                  :key="item.time_id"
                  :label="item.title_time"
                  :value="item.title_time"
                  :disabled="item.disabled"
              >
              </el-option>
            </el-select>
            <p v-if="errorMessage" class="center text-red-600 text-xs italic mt-1">
              {{ errorMessage }}
            </p>
          </div>
        </div>
      </div>
    </v-popup>
    <div class="sm-service-info-content">
      <span class="sm-service-info-content__title">
        {{ $i18n.locale == 'kk' ? service.title_kz : $i18n.locale == 'ru' ? service.title : service.title_en }}
      </span>
      <span class="bold">
        {{ service.price }}  {{ $t('тенге') }}
      </span>
    </div>
    <button v-if="role=='user'" class="sm-service-info-btn" @click="recordService">
      {{ $t('Записаться') }}
    </button>
    <button v-else class="sm-service-info-btn" @click="loginModal">
      {{ $t('Записаться') }}
    </button>
  </div>
</template>

<script>
import VPopup from '@/components/modals/v-popup'
import VButton from '@/components/btn/v-button'
import {successOrder} from '@/utils/functions'
import moment from "moment"
import Inputmask from "inputmask";

export default {
  name: 'sm-service-info',
  components: {
    VButton,
    VPopup
  },
  props: ['service'],
  recordService() {
    this.timeTake = true
  },
  data: function () {
    return {
      user: localStorage.user ? JSON.parse(localStorage.user) : {name: '', email: '', phone: ''},
      role: localStorage.role ? localStorage.role : null,
      load: false,
      dateTime: moment(),
      timeServiceTake: false,
      message: null,
      eMessage: undefined,
      selectedTime: '',
      availableDates: [],
      dates: [],
      disableDates: [],
      timezone: '',
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          }
        }
      ]
    }
  },
  mounted() {
    var im = new Inputmask('+9 (999) 999 99 99')
    im.mask(document.getElementById(this.service.id))
    // this.getSchedule()
  },
  beforeUpdate() {
    this.timeServiceTake && this.dates.length === 0 && this.getSchedule()
  },
  computed: {
    errorMessage: {
      get() {
        if (this.dateTime === '' || this.selectedTime === '') return this.$t('датаОбязательна')
        // var h = this.dateTime.getHours()
        // var m = this.dateTime.getMinutes()
        //
        // if (h == new Date().getHours() && m == new Date().getMinutes()) {
        //   return 'Поле дата и время обязательны для заполнения'
        // }
        return this.eMessage
      },
      set() {
      }
    },
    times() {
      const date = moment(this.dateTime).format('YYYY-MM-DD')
      return this.dates[date]?.times
    }
  },
  watch: {
    dateTime: function () {
      this.selectedTime = ''
    }
  },
  methods: {
    loginModal(){
      this.timeServiceTake = false
      document.getElementById('login').click()

    },
    changeMonth(objOfDate, ...props) {
      this.selectedTime = ''
      const date = `${objOfDate.month}/01/${objOfDate.year}`
      this.dateTime = date
      this.timeServiceTake && this.getSchedule()

      // if(!this.dateTime){
      //   this.getSchedule()
      // }
      // else if (this.dateTime && moment(this.dateTime).month() !== moment(date).month()) {
      //   this.dateTime = date
      //   this.getSchedule()
      // }
    },
    async getSchedule() {
      this.selectedTime = ''
      try {
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}web/clinic-schedule`, {
              date: this.dateTime !== '' && this.dateTime != 'Invalid date' ? moment(this.dateTime).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
              clinic_info_id: this.service.clinic_info_id,
              clinic_service_id: this.service.id,
            }
        )
        for (let i = 0; i < result.data.disable.length; i++) {
          for (let x = 0; x < result.data.all.length; x++) {
            if (result.data.disable[i].date == result.data.all[x].date) {
              for (let time = 0; time < result.data.all[x].times.length; time++) {
                if (result.data.all[x].times[time].title_time.substr(0, 5) == result.data.disable[i].time.substr(0, 5)) {
                  result.data.all[x].times[time].disabled = true
                }
              }
            }
          }
        }
        var today = new Date();
        var time = new Date();

        let Hour = time.getHours();
        let Minutes = time.getMinutes();

        time = Hour + ':' + Minutes

        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;


        for (let y = 0; y < result.data.all.length; y++) {
          if (result.data.all[y].date == today) {
            for (let x = 0; x < result.data.all[y].times.length; x++) {
              console.log(time)
              console.log(result.data.all[y].times[x].title_time.split('-')[1])
              var a = time;
              var b = result.data.all[y].times[x].title_time.split('-')[1];
              var amin = a.split(':')[0] * 60 + a.split(':')[1] * 1;
              var bmin = b.split(':')[0] * 60 + b.split(':')[1] * 1;
              if (bmin + 60 * 12 < amin) {
                bmin += 60 * 24
              }
              var c = bmin - amin

              if (c <= 0) {
                result.data.all[y].times[x].disabled = true
              }


            }
          }
        }

        this.dates = result.data.all.reduce((acc, item) => {
          acc[item.date] = item;
          return acc;
        }, {})
        this.availableDates = result.data.all.filter(item => {
          return moment(item.date).isSameOrAfter(moment().format('YYYY-MM-DD'))
        }).map(item => ({
          start: moment(item.date).toDate(),
          end: moment(item.date).toDate(),
        }))

        this.disableDates = this.availableDates.length === 0 ? [{start: new Date(1970)}] : []
        this.dateTime = ''
      } catch (e) {
      }
    },
    async submitOrderForm() {
      if (this.dateTime) {
        var h = this.dateTime.getHours()
        var m = this.dateTime.getMinutes()
        const time = this.selectedTime.split('-')[0]
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}web/clinics/request-appointment`, {
              clinic_service_id: this.$refs.s_id.value,
              date: moment(this.dateTime).format('YYYY-MM-DD'),
              time,
              name: this.user.name,
              email: this.user.email,
              phone: this.$refs.usercontact.value.replace(/\D/g, '')
            }
        ).catch(function (error) {
          if (localStorage.getItem('locale') == 'ru') {
            alert('На это время уже есть запись')
          }
          else if(localStorage.getItem('locale') == 'kk')
          {
            alert('Бұл жолғы рекорд қазірдің өзінде бар')
          }

          else
          {
            alert('There is already a record for this time')
          }
        })
        if (result) {
          this.message = successOrder()
          setTimeout(() => {
            window.location.href = result.data.redirect_url
          }, 2000)
          this.user.name = ''
          this.user.email = ''
          this.user.phone = ''
          this.$refs.s_id.value = ''
          this.load = false
          this.closeTimeServiceTake()
        }
      }
    },
    recordService() {
      this.timeServiceTake = true
    },
    closeTimeServiceTake() {
      this.timeServiceTake = false
      document.querySelector('body').classList.remove('overflow-hidden')
      document.querySelector('#app').classList.remove('overflow-hidden')
      document.querySelector('.main-layout').classList.remove('overflow-hidden')
    },
    pad(n) {
      return n < 10 ? '0' + n : n
    }
  }
}
</script>

<style lang="scss" scoped>
.is-disabled {
  background-color: red !important;
  color: white !important;
}

.is-disabled:hover {
  background-color: red !important;
  color: white !important;
}

.select_time {
  width: 100%;
  max-width: 250px;
  margin-top: 20px;
}

.sm-service-info {
  font-size: 13px;
  margin: 1rem;
  font-size: 13px;
  font-style: normal;
  font-family: Gotham, sans-serif;
}

.sm-service-info-content {
  display: flex;
  width: 200px;
  min-height: 150px;
  color: black;
  flex-direction: column;
  background-color: #F2F2F2;
  border-radius: 0.78vw 0.78vw 0 0;
  padding: 1.04vw;

  &__title {
    padding-bottom: 1.3vw;
  }
}

.sm-service-info-btn {
  border: none;
  width: 100%;
  cursor: pointer;
  border-radius: 0 0 0.78vw 0.78vw;
  background-color: #0b7d75;
  padding: 0.65vw;
  text-align: center;
  color: white;
}

.service__item__popup__body1 {
  display: flex;
  flex-direction: row;
  max-width: 700px;
  //margin: 0 0.65vw;
  margin: 12px 0px;

  &__left {
    margin: 0 1.3vw;
    display: flex;
    flex-direction: column;
    width: 400px;
    justify-content: space-around;
    height: 100%;

    &__header {
      font-family: Gotham-Pro, sans-serif;
      font-size: 1.56vw;
    }
  }

  &__right {
    padding: 1.04vw 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-align: left;

    &__logo {
      margin: 0 1.95vw 1.3vw 0;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 100%;

      &__profession {
        display: flex;
        margin: 0.65vw 0;
        flex-direction: row;
      }

      &__action {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  &__body {
    line-height: 1.755vw;

    &__info {
      width: 90%;
      border-top: 0.065vw solid gainsboro;
      flex-direction: row;
      display: flex;
      font-size: 1.04vw;
      font-family: Gotham-Pro, sans-serif;
      padding-top: 1.04vw;
      margin-bottom: 2.08vw;
      justify-content: space-between;
    }

    &__bio {
      font-family: PlumbKaz, sans-serif !important;
      overflow: hidden;
      font-size: 0.975vw !important;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
}

@media only screen and (max-device-width: 450px) {
  .service__item__popup {
    width: 100%;

    .service__item__popup__body1__right, form, .service__item__popup__body1__left {
      width: 100%;
    }

    .service__item__popup__body1__right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .service__item__popup__body1__left {
      width: 90%;
      padding: 0 12px;
    }

    .service__item__popup__body1__left__header {
      margin: 12px 0;
      font-size: 4vw;
    }

  }
  .service__item__popup__body1 {
    flex-wrap: wrap-reverse;
  }
}
</style>
