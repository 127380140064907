import axios from 'axios'

export const reference = {
  state: {
    professions: [],
    scheduleTime: [],
    cities: [],
    operations: [],
    fileTypes: []
  },
  mutations: {
    setProfessions (state, modules) {
      state.professions = modules
    },
    setCities (state, cities) {
      state.cities = cities
    },
    setTime (state, time) {
      state.scheduleTime = time
    }
  },
  actions: {
    async getProfessions (context) {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}web/dictionaries/professions`, {})
        context.commit('setProfessions', result.data)
      } catch (e) {
        throw e
      }
    },
    async getTime (context) {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}web/dictionaries/schedule-time`, {})
        context.commit('setTime', result.data)
      } catch (e) {
        throw e
      }
    },
    async getCities (context) {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}web/dictionaries/cities`, {})
        context.commit('setCities', result.data)
      } catch (e) {
        throw e
      }
    }
  }
}
