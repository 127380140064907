<template>
  <div class="service">
    <div class="row">
      <v-service-title v-if="!mobile" :checkup_id="checkup_id" size="sm" @change="sortBy" @click="changeType(3)"/>
    </div>
    <v-popup v-show="filter"  :title="$t('Фильтр')" @closePopup="filter=false">
      <div class="mob_filter">
        <div v-if="mobile" class="col-12 top">
          <v-service-filter :mobile="true" selected="3" @changeCity="changeCity" @changeSum="changeSum" @changeType="changeType"/>
        </div>
      </div>
    </v-popup>
    <div class="service__filter">
      <div v-if="!mobile" class="col-2 top">
        <v-service-filter :title="true" selected="3" @changeCity="changeCity" @changeSum="changeSum"
                          @changeType="changeType"/>
      </div>
      <div class="service__content">
        <v-button v-if="mobileView" fa-icon="sliders-h" size="success long"  :value="$t('Фильтр')" @click="filter=true"/>
        <div v-if="type==1||!type||type==null">
          <service-item v-for="(service) in services" v-show="type==1 ||type==null" :key="service.id"
                        :mobile="mobileView"
                        :service="service"/>
        </div>
        <div v-if="type==2 && !checkup_id">
          <clinic-item v-for="(service) in services" v-show="type==2 " :key="service.id" :clinic="service"
                       :mobile="mobileView"/>
        </div>
        <div v-if="type==2&&checkup_id">
          <clinic-item2 v-for="(service) in services" v-show="type==2" :key="service.id" :checkupid="checkup_id"
                        :clinic="service" :mobile="mobileView"/>
        </div>
        <div v-if="type==3">
          <checkup-item v-for="(service) in services" v-show="type==3" :key="service.id" :checkup="service"
                        :mobile="mobileView"
                        @selectClinic="selectClinic"/>
        </div>
        <preloader v-show="load"/>
      </div>
    </div>
  </div>
</template>
<script>
import ServiceItem from '@/components/services/service-item'
import vServiceFilter from '@/components/checkups/service-filter'
import vServiceTitle from '@/components/titles/service-title'
import Preloader from '@/components/preloader'
import ClinicItem from '@/components/clinic/clinic-item'
import CheckupItem from '@/components/checkups/checkup-item'
import ClinicItem2 from '@/components/clinic/clinic-item-2'
import VButton from '@/components/btn/v-button'
import VPopup from '@/components/modals/v-popup'
import {handleView} from '@/utils/functions'

export default {
  name: 'service',
  components: {
    VPopup,
    VButton,
    ClinicItem2,
    CheckupItem,
    ClinicItem,
    Preloader,
    ServiceItem,
    vServiceFilter,
    vServiceTitle
  },
  props: ['viewIndex', 'mobile', 'search'],
  data() {
    return {
      filter: false,
      services: [],
      checkup_id: undefined,
      dataService: '',
      data: {},
      mobileView: false,
      load: false,
      filterQuery: {
        search_query: ''
      },
      startTimer: false,
      type: null
    }
  },
  async created() {
    await this.getService()
    this.mobileView = handleView()
  },
  computed: {
    searchValue() {
    }
  },
  methods: {
    selectClinic(val) {
      this.checkup_id = val
      this.services = []
      this.getService()
    },
    changeType(even) {
      this.checkup_id = null
      this.type = even
      this.getService()
    },
    changeSum(min, max) {
      this.filterQuery.price_from = min
      this.filterQuery.price_to = max
      this.callAfterSort()
    },
    changeCity(even) {
      this.filterQuery.city_id = even
      this.callAfterSort()
    },
    sortBy(even) {
      this.filterQuery.sort_by = even
      this.getService()
    },
    callAfterSort() {
      if (!this.startTimer) {
        this.startTimer = true
        setTimeout(() => {
          this.getService()
          this.startTimer = false
        }, 3000)
      }
    },
    async getService(url) {
      // this.services = []
      // if (!url) {
      //   this.services = []
      // }
      var textType = 'services'
      // eslint-disable-next-line eqeqeq
      if (this.type == 1 || this.type == null) {
        textType = 'services'
        // eslint-disable-next-line eqeqeq
      } else if (this.type == 2) {
        textType = 'clinics'
        // eslint-disable-next-line eqeqeq
      } else if (this.type == 3) {
        textType = 'checkups'
        if (this.checkup_id) {
          textType = 'checkups/get-clinics'
          this.filterQuery.id_checkup = this.checkup_id
          this.type = 2
        }
      }

      this.load = true
      this.filterQuery.search_query = this.search
      const result = await this.$http.post(
          url != null ? url : `${process.env.VUE_APP_API_URL}web/` + textType,
          this.filterQuery
      )
      this.data = result.data
      var data = null
      if (this.checkup_id) {
        data = result.data.map(item => {
          this.services.push(item)
          return item
        })
      } else {
        data = result.data.data.map(item => {
          this.services.push(item)
          return item
        })
        if (result.data.next_page_url != null) {
          this.getService(result.data.next_page_url)
        }
      }
      if (data) {
        this.load = false
      }
    },
    scroll() {
      window.onscroll = () => {
        var bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 400 > document.documentElement.offsetHeight
        if (bottomOfWindow && !this.load) {
          if (this.data.next_page_url) {
            this.getService(this.data.next_page_url)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .mob_filter {
    height: 40vh;

    & {
      .form-control {
        padding: 2vw 0;
      }
    }

    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .service {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    &__filter{
      width:100%;
    }
  }
  .service-item-cell_center {
    width: 100%;
  }
}

@media only screen and (min-device-width: 451px) {
  .service {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    &__content {
      width: 100% !important;
    }

    &__filter {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }
}

.top {
  vertical-align: top;
}
</style>
