<template>
  <div class="profile">
    <vue-title :title="$t('чекапы')"/>
    <main-layout>
      <div class="profile__main">
        <left-navigation type="doctor"/>
        <div class="profile__main__content">
          <page-title :title="$t('Запись')+'№'+JSON.parse(this.$route.query.checkup).id" weight="normal"/>
          <div class="consultation__analyze">
            <div class="consultation__analyze__title h2">
              <div>{{ $t('Заключение') }}<img src="../../assets/icons/ant-design_edit-filled.svg" @click="edit"/>
                <v-button v-if="editTo" size="sm btn-success text-white" value="Изменить" @click="update"/>
              </div>
            </div>
            <p v-if="!editTo">
              <!--              {{ this.$route.query.checkup.conclusion ? this.$route.query.checkup.conclusion : 'Заключение отсутствует' }}-->
            </p>
            <textarea v-if="editTo" ref="conc" v-model="JSON.parse(this.$route.query.checkup).conclusion"></textarea>
          </div>
          <div class="consultation__analyze">
            <div class="consultation__analyze__title h2">{{ $t('результатыАнализов') }}</div>
            <analyzes-download
                v-for="(analyze) in JSON.parse(this.$route.query.checkup).analyses"
                :id="analyze.id"
                :key="analyze.id"
                :name="$t('результатыАнализов') + analyze.id"
                :url="analyze.file_url"
                show-upload="true"
                @uploaded="uploaded"
            />
            <analyzes-download
                :id="JSON.parse(this.$route.query.checkup).id"
                :name="$t('новыйАнализ')"
                url=""
                @uploaded="uploaded"
            />
          </div>
          <div class="consultation__analyze" v-if="JSON.parse(this.$route.query.checkup).user">
            <div class="consultation__analyze__title h2">
              <div>{{ $t('карточкаПациента') }}</div>
            </div>
            <analyzes-download
                :userAnalyze="true"
                v-for="(analyze, index) in JSON.parse(this.$route.query.checkup).user.analyses_from_user"
                :id="analyze.id"
                :key="analyze.id"
                :name="analyze.filename"
                :url="analyze.file_url"
                @uploaded="uploaded"
            />
          </div>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import moment from 'moment'
import LeftNavigation from '@/components/user/left-navigation'
import PageTitle from '@/components/titles/page-title'
import AnalyzesDownload from '@/components/analysis/analyzes-download'
import VButton from '@/components/btn/v-button'
import {cancelAlert, confirmAlert, handleView} from '@/utils/functions'

export default {
  name: 'ClinicCheckupById',
  props: ['checkup'],
  components: {
    VButton,
    AnalyzesDownload,
    PageTitle,
    LeftNavigation,
    MainLayout
  },
  data: function () {
    return {
      editTo: false,
      role: localStorage.role,
      action: '',
      message: '',
      id: '',
      index: '',
      removed: false,
      name: this.$t('чекапы'),
      mobileView: true,
      orders: [],
      loader: false,
      isTokenIsEmpty: null,
      showNav: false,
      type: 1
    }
  },
  async created() {
    this.mobileView = handleView()
  },
  methods: {
    edit() {
      this.editTo = !this.editTo
    },
    closeInfoPopup() {
      this.message = ''
      this.id = ''
      this.action = ''
      this.index = undefined
    },
    confirm(id, index) {
      this.message = confirmAlert('чекап')
      this.action = 'confirm'
      this.index = index
      this.id = id
    },
    cancel(id, index) {
      this.message = cancelAlert('чекап')
      this.action = 'cancel'
      this.index = index
      this.id = id
    },
    async postAction() {
      this.removed = true
      var url = `${process.env.VUE_APP_API_URL}${this.role}/services/` + this.action
      const result = await this.$http.post(
          url, {id: this.id}
      )
      if (result) {
        this.removed = false
        this.orders.splice(this.index, this.index + 1)
        this.closeInfoPopup()
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD HH:mm')
      }
    },
    changeToggle(item) {
      this.type = item
      this.orders = []
      this.getOrders()
    },
    uploaded(result) {
      this.$route.query.checkup.analyses.push({
        name: result.file_url,
        id: 'new'
      })
    },
    scroll() {
      const element = document.getElementById('feedback-partner')
      element.scrollIntoView({behavior: 'smooth'})
    },
    async getOrders() {
      this.loader = true
      var url = `${process.env.VUE_APP_API_URL}clinic/checkups/get`

      const result = await this.$http.post(url, {
        status: this.type
      })
      const data = result.data.data.map(item => {
        return item
      })
      if (data) {
        this.orders = data
      }
      this.loader = false
    }
  },

  async mounted() {
    await this.getOrders()
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@import "../../assets/css/profile";

.table {
  width: 100%;
  box-shadow: 14px 23px 64px 53px rgb(212 217 232 / 20%);
  border-radius: 3px;
  background: white;
  padding: 1rem;
  border: 1px solid gainsboro;

  & {
    tr {
      width: 100%;
      line-height: 5vh;
    }
  }
}

p {
  font-size: 20px;
  padding-bottom: 20px;
}

.text-center {
  text-align: center;
}

</style>
