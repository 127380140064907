<template>
  <div :class="getClass('service-title')" class="service-title">
    <div :class="size">
      {{$t('cортировка')}}
    </div>
    <div class="service-select">
      <div :class="getClass('btn')" class="btn btn-success">
        <select :class="getClass('select')" @change="select">
          <option hidden>{{ $t('поЦене') }}</option>
          <option value="asc">{{ $t('поВозрастанию') }}</option>
          <option value="desc">{{ $t('поУбыванию') }}</option>
        </select>
      </div>
    </div>
    <div>
      <v-button @click="click" v-if="checkup_id" size="select btn-success text-white p-1" :value="$t('Назад')"/>
    </div>
  </div>
</template>

<script>

import VButton from '@/components/btn/v-button'

export default {
  components: {VButton},
  props: ['size', 'checkup_id'],
  name: 'service-title',
  methods: {
    select(event) {
      this.$emit('change', event.target.value)
    },
    click() {
      this.$emit('click')
    },
    getClass(element) {
      if (this.size) {
        // eslint-disable-next-line eqeqeq
        if (element == 'service-title') {
          return 'divsm'
        }
        // eslint-disable-next-line eqeqeq
        if (element == 'select') {
          return ['sm', 'smc']
        }
        // eslint-disable-next-line eqeqeq
        if (element == 'btn') {
          return ['smpd', 'smc']
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .service-select {
    width: auto;
    text-align: center;
    border-radius: 2.04vw !important;

    .btn {
      border-radius: 2.04vw !important;
    }

    select {
      outline: none;
      border: none;
      color: white;
      font-size: 4vw;
      background-color: $green;

      option {
        color: $green;
        padding: 1.69vw;
        font-size: 4vw;
        background-color: white;
      }
    }
  }

  .sm {
    border-radius: 2vw;
    padding: 1.26vw 1.26vw !important;
    font-size: 4vw !important;
  }

  .success {
    background-color: $green;
  }

  .smpd {
    padding: 0.065vw 0.78vw !important;
  }

  .smc {
    background-color: darkgrey !important;
  }

  .divsm {
    width: 24rem !important;
  }

  .col-1 {
    width: 18%;
  }

  .service-title {
    font-family: Gotham-Medium, sans-serif;
    font-size: 1.69vw;
    font-weight: 400;
    display: flex;
    margin: 1.04vw 0;
    width: 30rem;
    flex-direction: row;
    color: $black_secondary;
    justify-content: flex-end;
  }
}

@media only screen and (min-device-width: 451px) {
  .service-select {
    width: auto;
    text-align: center;
    border-radius: 1.04vw !important;

    .btn {
      border-radius: 1.04vw !important;
    }

    select {
      outline: none;
      border: none;
      color: white;
      font-size: 1.56vw;
      background-color: $green;

      option {
        color: $green;
        padding: 1.69vw;
        font-size: 1.3vw;
        background-color: white;
      }
    }
  }

  .sm {
    border-radius: 0.52vw;
    padding: 0.26vw 0.26vw !important;
    font-size: 1.3vw !important;
  }

  .success {
    background-color: $green;
  }

  .smpd {
    padding: 0.065vw 0.78vw !important;
  }

  .smc {
    background-color: darkgrey !important;
  }

  .divsm {
    width: 24rem !important;
  }

  .col-1 {
    width: 18%;
  }

  .service-title {
    font-family: Gotham-Medium, sans-serif;
    font-size: 1.69vw;
    font-weight: 400;
    display: flex;
    margin: 1.04vw 0;
    width: 30rem;
    flex-direction: row;
    color: $black_secondary;
    justify-content: space-between;
    align-items: center;
    align-content: flex-end;
  }
}
</style>
