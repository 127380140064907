import axios from 'axios'

export const appAuth = {
    state: {
        // user: null,
        logInDialog: false,
        user: JSON.parse(localStorage.getItem('user')) || null,
        token: localStorage.getItem('accessToken') || '',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
        role: localStorage.getItem('role') || ''
    },
    getters: {
        islogInDialog: (state) => state.logInDialog,
        isLoggedIn: (state) => !!state.token,
        user: (state) => state.user,
        // isAdmin: (state) => (state.user.is_admin != null ? state.user.is_admin : false),
        role: (state) => state.role || 'user'
    },
    mutations: {
        authSuccess(state, data, role) {
            state.token = data
            state.Authorization = 'Bearer ' + data
            state.role = role
        },
        setUser(state, data) {
            state.user = data
        },
        logout(state) {
            state.token = ''
        },
        openLogInDialog(state) {
            state.logInDialog = !state.logInDialog
        }
    },
    actions: {
        async login(context, payload) {
            axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
            const url = process.env.VUE_APP_API_URL
            try {
                const result = await axios.post(`${url}user/login`, {
                    email: payload.email,
                    password: payload.password,
                    device_name: 'web'
                })
                const accessToken = result.data.token
                const role = result.data.role
                localStorage.setItem('accessToken', accessToken)
                localStorage.setItem('role', role)
                axios.defaults.headers.common.Token = 'Bearer ' + accessToken
                axios.defaults.headers.common.xAccessToken = 'Bearer ' + accessToken
                axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken
                context.commit('authSuccess', accessToken, role)

                var urlProfile = `${url}user/profile`

                if (role == 'clinic') {
                    urlProfile = `${url}clinic/profile`
                } else if (role == 'doctor') {
                    urlProfile = `${url}doctor/profile`
                }
                const user = (await axios(urlProfile, {
                    method: 'POST',
                    mode: 'no-cors',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    },
                    credentials: 'same-origin'
                })).data
                localStorage.setItem('user', JSON.stringify(user))
                context.commit('setUser', user)

            } catch (e) {
                this.$message.error('Неверный логин или пароль')
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                throw e
            }
        },
        async logout(context) {
            context.commit('logout')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('user')
            localStorage.removeItem('role')
            delete axios.defaults.headers.common.Token
        },
        async registration(context, payload) {
            const url = process.env.VUE_APP_API_URL
            try {
                axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
                const result = await axios.post(
                    `${url}user/register`, {
                        name: payload.name,
                        surname: payload.surname,
                        patronymic: payload.patronymic,
                        email: payload.email,
                        phone: payload.phone,
                        date_birthdate: payload.birth,
                        password: payload.pass,
                        sex: payload.sex,
                        city_id: payload.city_id,
                        device_name: 'web'
                    }
                )
                const accessToken = result.data.token
                const role = 'user'
                axios.defaults.headers.common.Token = 'Bearer ' + accessToken
                axios.defaults.headers.common.xAccessToken = 'Bearer ' + accessToken
                axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken
                context.commit('authSuccess', accessToken, role)
                try {
                    const user = (await axios(`${url}user/profile`, {
                        method: 'POST',
                        mode: 'no-cors',
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json'
                        },
                        credentials: 'same-origin'
                    })).data
                    localStorage.setItem('user', JSON.stringify(user))
                    context.commit('setUser', user)
                } catch (e) {
                    context.dispatch('logout')
                    throw e
                }
            } catch (e) {
                this.$message.error('Неверный логин или пароль')
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                throw e
            }
        },
        async reset(context, payload) {
            const url = process.env.VUE_APP_API_URL
            try {
                axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
                const result = await axios.post(
                    `${url}user/update-password`, {
                        password: payload.passwordNew,
                        old_password: payload.password,
                        password_confirmation: payload.passwordConfirm,
                    }
                )
                try {
                    const user = (await axios(`${url}user/profile`, {
                        method: 'POST',
                        mode: 'no-cors',
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json'
                        },
                        credentials: 'same-origin'
                    })).data
                    localStorage.setItem('user', JSON.stringify(user))
                    context.commit('setUser', user)
                } catch (e) {
                    context.dispatch('logout')
                    throw e
                }
            } catch (e) {
                this.$message.error('Неверный логин или пароль')
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                throw e
            }
        },
        async update(context, payload) {
            const url = process.env.VUE_APP_API_URL
            try {
                axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
                // eslint-disable-next-line no-unused-vars
                const result = await axios.post(
                    `${url}user/profile/update`, {
                        name: payload.name,
                        surname: payload.surname,
                        patronymic: payload.patronymic,
                        phone: payload.phone,
                        date_birthdate: payload.date_birthdate,
                        sex: payload.sex,
                        city_id: payload.city_id
                    }
                )
                try {
                    const user = (await axios(`${url}user/profile`, {
                        method: 'POST',
                        mode: 'no-cors',
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json'
                        },
                        credentials: 'same-origin'
                    })).data
                    localStorage.setItem('user', JSON.stringify(user))
                    context.commit('setUser', user)
                } catch (e) {
                    context.dispatch('logout')
                    throw e
                }
            } catch (e) {
                this.$message.error('Неверный логин или пароль')
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                throw e
            }
        },
        async updateDoctor(context, payload) {
            const url = process.env.VUE_APP_API_URL
            try {
                axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
                // eslint-disable-next-line no-unused-vars
                const result = await axios.post(
                    `${url}doctor/profile/update`, {
                        name: payload.name,
                        email: payload.email,
                        bio: payload.regbio,
                        profile_image: JSON.parse(localStorage.getItem('user')).profile_image,
                        date_birthdate: payload.date_birthdate,
                        sex: 1,
                        profession_id: payload.profession_id,
                        city_id: payload.city_id
                    }
                )
                try {
                    const user = (await axios(`${url}doctor/profile`, {
                        method: 'POST',
                        mode: 'no-cors',
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json'
                        },
                        credentials: 'same-origin'
                    })).data
                    localStorage.setItem('user', JSON.stringify(user))
                    context.commit('setUser', user)
                } catch (e) {
                    context.dispatch('logout')
                    throw e
                }
            } catch (e) {
                this.$message.error('Неверный логин или пароль')
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                throw e
            }
        },
        async updateClinic(context, payload) {
            const url = process.env.VUE_APP_API_URL
            try {
                axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
                // eslint-disable-next-line no-unused-vars
                const result = await axios.post(
                    `${url}clinic/profile/update`, {
                        name: payload.name,
                        description: payload.description,
                        address: payload.address,
                        profile_photo: JSON.parse(localStorage.getItem('user')).profile_photo,
                        work_time: payload.work_time,
                        phone: payload.phone
                    }
                )
                try {
                    const user = (await axios(`${url}clinic/profile`, {
                        method: 'POST',
                        mode: 'no-cors',
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json'
                        },
                        credentials: 'same-origin'
                    })).data
                    localStorage.setItem('user', JSON.stringify(user))
                    context.commit('setUser', user)
                } catch (e) {
                    context.dispatch('logout')
                    throw e
                }
            } catch (e) {
                this.$message.error('Неверный логин или пароль')
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                throw e
            }
        }
    }
}
