<template>
  <div class="how-it-work">
    <div class="how_cards" v-on:scroll.passive='handleScroll'>
      <v-card-with-text
          :content="$t('выберитеИнтересующуюВасПрограммуОбследований')"
          header="1"/>
      <v-card-with-text :content="$t('подберитеМедицинскоеУчреждение')" header="2"/>
      <v-card-with-text :content="$t('подберитеДату')" header="3"/>
      <v-card-with-text :content="$t('пройдитеПрограмму')" header="4"/>
    </div>
    <div v-if="mobileView">
      <ul id="carouselDots" class="dots">
        <li option="0" selected></li>
        <li option="1"></li>
        <li option="2"></li>
        <li option="3"></li>
      </ul>
    </div>
  </div>
</template>

<script>
import vCardWithText from '@/components/card-with-text'

export default {
  name: 'v-how-text',
  components: {
    vCardWithText
  },
  data: function () {
    return {scrollPosition: null}
  },
  props: ['mobileView'],
  methods: {
    handleScroll() {
      this.isUserScrolling = window.top.scrollX
    }
  }
}

</script>

<style scoped>
@media only screen and (max-device-width: 450px) {
  .how_cards {
    box-shadow: 0.91vw 1.495vw 4.16vw 3.445vw rgba(212, 217, 232, 0.2);
    border-radius: 0.78vw;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch !important;
    scroll-snap-type: x mandatory !important;
  }
}

.how_cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

</style>
