<template>
  <main-layout>
    <div class="container wrapper-404">

      <div class="welcome-text  text-center px-4">
        <h1>{{ $t('404.not-found') }}
          <router-link style="    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;" to="/">{{ $t('404.back-home') }}</router-link>
        </h1>
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from '@/views/MainLayout'
import vBlockTitle from '@/components/titles/block-title'
import vPageTitle from '@/components/titles/page-title'
import vNavigationHistory from '@/components/navigation-history'
import VHowText from '@/components/v-how-text'
import Advantage from '@/components/adv/advantage'
import vFeedback from '@/components/feedbacks/feedback'
import {handleView} from '@/utils/functions'

export default {
  name: 'About',
  components: {
    Advantage,
    VHowText,
    MainLayout,
    vBlockTitle,
    vPageTitle,
    vNavigationHistory,
    vFeedback
  },
  data: function () {
    return {
      nav_title: this.$t('оНас'),
      mobileView: true,
      showNav: false
    }
  },
  created() {
    this.mobileView = handleView()
  }
}
</script>
<style>
.wrapper-404 {
  display: flex;
  min-height: 70vh;
}

.welcome-text {
  margin: auto;
}
</style>
