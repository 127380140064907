<template>
  <div class="main-layout">
    <v-navigation :mobileView="this.mobileView"/>
    <slot></slot>
    <footer class="footer">
      <div class="footer__logo-div">
        <img alt="" class="footer__logo" src="../assets/img/logo.svg"/>
      </div>
      <div class="footer__links t16">
        <a href="/privacy-policy.pdf" target="_blank">{{$t('Политика')}}</a>
        <a href="/terms-and-conditions.pdf" target="_blank">{{$t('Правила')}}</a>
        <a href="/agreement.pdf" target="_blank">{{$t('ДоговорОферты')}}</a>
      </div>
      <hr class="footer__line"/>
      <div class="footer__info">
        <div class="footer__info_text t14">
         {{$t('выСоглашаетесь')}}
        </div>
        <div class="footer__info_text t14 footer_payments">
         {{$t('ПринимаемОплату')}}
          <font-awesome-icon :icon="['fab', 'cc-mastercard']" class="text-accent p-1 m-1 icon"/>
          <font-awesome-icon :icon="['fab', 'cc-visa']" class="text-accent p-1 m-1 icon"/>
        </div>
        <div class="footer__social-link footer_links">
          <span class="footer__info_text t14">{{$t('мыВСоцСетях')}} </span>
          <v-social-links social="instagram" url="https://instagram.com/texme.kz"/>
        </div>
      </div>
    </footer>
    <alarm/>
  </div>
</template>

<script>
import vSocialLinks from '@/components/footer/social-links'
import vNavigation from '@/components/navigation'
import Alarm from '@/components/modals/Alarm'
import { handleView } from '@/utils/functions'

export default {
  name: 'MainLayout',
  components: {
    Alarm,
    vSocialLinks,
    vNavigation
  },
  data: function () {
    return {
      nav_title: 'Личный кабинет',
      mobileView: true,
      showNav: false
    }
  },
  created () {
    this.mobileView = handleView()
  }
}
</script>

<style lang="scss" scoped>
.footer_payments{
  margin-top: 8px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.footer_links{
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top:8px;
}
@import "../assets/css/colors";
.icon{
  font-size: 20px;
}
.footer__logo-div {
  position: relative;
  width: 100%;
  text-align: center;
}

.p-1 {
  padding: 0 0.2vw;
}

@media only screen and (max-device-width: 450px) {
  .p-1 {
    padding: 0 0.5vw !important;
  }
  .footer__logo {
    height: 6vw;
    padding: 0 !important;
  }
  .footer {
    padding: 4vw !important;
    margin-top: 20vw !important;
    background-color: white;
  }
  .footer__links {
    background-color: white;
    font-size: 3vw !important;
    padding-bottom: 3vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    line-height: 6vw;
  }
  .footer__line {
    margin-top: 0.65vw !important;
  }
  .footer__info {
    background-color: white;
    padding: 1.625vw 0;
    display: flex;
    flex-direction: column;
  }
  .footer__info_text {
    background-color: white;
    font-size: 2.5vw !important;
  }
  .footer__social-links {
    background-color: white;
    padding-top: 1vw;
  }

}

.footer {
  font-family: Manrope-Regular, sans-serif;
  margin-top: 0.65vw;
  text-align: center;
  /*border-top: 0.2px solid ;*/
  /*box-shadow: 0 0 0.65vw #00938A14;*/
  box-shadow: 0.13vw  -0.325vw 0.325vw  -0.325vw rgba(77, 77, 77, 0.13);
  //height: 3.25vw;
  background-color: white;
  padding: 1.95vw 9.3125rem;
}

.footer__logo {
  padding: 0.78vw;
}

.footer__links {
  padding-bottom: 1.625vw;
}

.footer__links > a {
  color: $gray1 !important;
  padding-left: 1.56vw;
}

.footer__line {
  margin-top: 2.275vw;
  border: 0.065vw solid #007D75;
  background: #007D75;
  opacity: 0.2;
}

.footer__info {
  padding: 1.625vw 0;
  display: flex;
  justify-content: space-between;
}

.footer__info_text {

}

.overflow-hidden {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.right {
  float: right;
}

.footer__social-links {
  display: flex;
  justify-content: center;
}
</style>
