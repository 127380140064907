<template>
  <div class="profile">
    <vue-title :title="$t('расписание')"/>
    <main-layout>
      <RangeDatepickerModal :work="true" v-if="showPopup" :visible="showPopup"
                            @close="showPopup=false" @load-work="getSchedule"></RangeDatepickerModal>
      <div class="profile__main">
        <left-navigation type="doctor"/>
        <div class="profile__main__content">
          <page-title title="" weight="normal"/>
          <div class="profile_content">
            <v-button fa-icon="calendar" size="yellow w-20 p-1 m-1" :value="$t('добавитьРасписание')"
                      @click="showPopup=true" v-if="role!=='clinic'"/>
            <calendar-view
                :items="items"
                :show-date="showDate"
                :time-format-options="{ hour: 'numeric', minute: '2-digit' }"
                :enable-drag-drop="true"
                :disable-past="disablePast"
                :disable-future="disableFuture"
                :show-times="showTimes"
                :display-period-uom="displayPeriodUom"
                :display-period-count="displayPeriodCount"
                :starting-day-of-week="startingDayOfWeek"
                class="calendar_view theme-default"
                :period-changed-callback="periodChanged"
                :current-period-label="useTodayIcons ? 'icons' : ''"
                :displayWeekNumbers="displayWeekNumbers"
                :enable-date-selection="true"
                :selection-start="selectionStart"
                :selection-end="selectionEnd"
                @date-selection-start="setSelection"
                @date-selection="setSelection"
                @date-selection-finish="finishSelection"
                @drop-on-date="onDrop"
                @click-date="onClickDay"
                @click-item="onClickItem"
                @contextmenu="console.log('test')"
            >
              <template #header="{ headerProps }">
                <calendar-view-header slot="header" :header-props="headerProps" @input="setShowDate"/>
              </template>
              <template #item="{value, top}">
                <div @click="onClickItem(value)" v-if=" value.originalItem.requestType==1" draggable="true" title=" "
                     class="cv-item" :class="value.classes" :style="{top: top}">
                  <span
                      class="startTime">{{ value.originalItem.title_time }}</span>
                </div>
                <div @click="onClickItem(value)" v-if=" value.originalItem.requestType==2" draggable="true" title=" "
                     class="cv-item cv-item-offline" :class="value.classes" :style="{top: top}">
                  <span
                      class="startTime">{{ value.originalItem.title_time }}</span>
                </div>
              </template>
            </calendar-view>
          </div>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import LeftNavigation from '@/components/user/left-navigation'
import PageTitle from '@/components/titles/page-title'
import VPopup from '@/components/modals/v-popup'
import Calendar from '@/components/Calendar.vue'
import * as dateUtil from '@/utils/dateUtil'
import {handleView} from '@/utils/functions'
import {CalendarView, CalendarViewHeader, CalendarMathMixin} from "vue-simple-calendar/src/components/bundle";
import 'vue-simple-calendar/static/css/default.css'
import 'vue-simple-calendar/static/css/holidays-us.css'
import 'vue-simple-calendar/static/css/gcal.css'
import moment from "moment";
import VButton from '@/components/btn/v-button'
import RangeDatepickerModal from '@/components/modals/range-datepicker-modal.vue'

export default {
  name: 'DoctorScheduleWork',
  components: {
    VPopup,
    VButton,
    PageTitle,
    LeftNavigation,
    MainLayout,
    Calendar,
    CalendarView,
    CalendarViewHeader,
    RangeDatepickerModal
  },
  data: function () {
    return {
      role: localStorage.role ? localStorage.role : null,
      name: 'Расписание',
      mobileView: true,
      isTokenIsEmpty: null,
      showNav: false,
      showPopup: false,
      range: {
        start: new Date(2021, 2, 1),
        end: new Date(2021, 2, 3)
      },
      fcEvents: [],
      events: [],
      showDate: this.thisMonth(1),
      message: "",
      startingDayOfWeek: 1,
      disablePast: false,
      disableFuture: false,
      displayPeriodUom: "month",
      displayPeriodCount: 1,
      displayWeekNumbers: false,
      showTimes: true,
      selectionStart: null,
      selectionEnd: null,
      newItemTitle: "",
      newItemStartDate: "",
      newItemEndDate: "",
      useDefaultTheme: true,
      useHolidayTheme: true,
      useTodayIcons: false,
      items: [],
    }
  },
  async created() {
    this.mobileView = handleView()
    await this.getUpcoming()
    await this.getSchedule()
  },
  methods: {
    periodChanged() {
      // range, eventSource) {
      // Demo does nothing with this information, just including the method to demonstrate how
      // you can listen for changes to the displayed range and react to them (by loading items, etc.)
      //console.log(eventSource)
      //console.log(range)
    },
    thisMonth(d, h, m) {
      const t = new Date()
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
    },
    onClickDay(d) {
      this.selectionStart = null
      this.selectionEnd = null
      this.message = `You clicked: ${d.toLocaleDateString()}`
    },
    async onClickItem(item) {
      const resp = confirm(this.$t('удалитьЭлмент'))
      if (resp) {
        let url = `${process.env.VUE_APP_API_URL}doctor/work-schedule/remove`
        const result = await this.$http.post(url, {
              id: item.originalItem.id,
            }
        )
        this.getSchedule()
      }
    },
    setShowDate(d) {
      this.message = `Changing calendar view to ${d.toLocaleDateString()}`
      this.showDate = d
      this.getSchedule()
    },
    setSelection(dateRange) {
      this.selectionEnd = dateRange[1]
      this.selectionStart = dateRange[0]
    },
    finishSelection(dateRange) {
      this.setSelection(dateRange)
      this.message = `You selected: ${this.selectionStart.toLocaleDateString()} -${this.selectionEnd.toLocaleDateString()}`
    },
    async onDrop(item, date) {
      this.message = `You dropped ${item.id} on ${date.toLocaleDateString()}`
      // Determine the delta between the old start date and the date chosen,
      // and apply that delta to both the start and end date to move the item.

      try {

        let url = `${process.env.VUE_APP_API_URL}doctor/work-schedule/edit`
        const result = await this.$http.post(url, {
              id: item.originalItem.id,
              date: moment(date).format('YYYY-MM-DD')
            }
        )
        const eLength = CalendarMathMixin.methods.dayDiff(item.startDate, date)
        item.originalItem.startDate = CalendarMathMixin.methods.addDays(item.startDate, eLength)
        item.originalItem.endDate = CalendarMathMixin.methods.addDays(item.endDate, eLength)

      } catch (e) {
        if (e.response.status === 403) {
          alert(this.$t('датаEсть'))
        }
      }
    },
    clickTestAddItem() {
      this.items.push({
        startDate: this.newItemStartDate,
        endDate: this.newItemEndDate,
        title: this.newItemTitle,
        id: "e" + Math.random().toString(36).substr(2, 10),
      })
      this.message = "You added a calendar item!"
    },
    getFormattedDate(e) {
      var arr = []
      var arrs = e.split('-')
      arr.push(parseInt(arrs[0]))
      arr.push(parseInt(arrs[1]) - 1)
      arr.push(parseInt(arrs[2]))
      arr.push(0)
      arr.push(0)
      arr.push(0)
      arr.push(0)
      return dateUtil.formatDate(arr, 'MMMM D')
    },
    dayClick(event) {
    },
    async getSchedule() {
      this.loader = true
      let url = `${process.env.VUE_APP_API_URL}doctor/work-schedule/get`
      const result = await this.$http.post(url, {
        doctor_info_id: JSON.parse(localStorage.getItem('user')).id,
        date: moment(this.showDate).format('YYYY-MM-DD')
      })
      this.items = result.data.map(item => {
        const [start, end] = item.title_time.split('-')
        const res = {
          ...item,
          title: ' ',
          startDate: `${item.date} ${start}`,
          endDate: `${item.date} ${end}`,
          requestType: item.requestType
        }
        return res
      })
    },
    async getUpcoming() {
      this.loader = true
      var url = `${process.env.VUE_APP_API_URL}${this.role}/schedule/upcoming`
      const result = await this.$http.post(url, {})

      const sortedOfDate = result.data.sort((a, b) => {
        const Adate = `${a.date}T${a.time}`
        const Bdate = `${b.date}T${b.time}`
        return new Date(Adate) - new Date(Bdate)
      })
      sortedOfDate.map(item => {
        this.fcEvents.push({
          title: item.name,
          start: item.date,
          time: item.time
        })
        return item
      })
      // this.fcEvents.sort(function (a, b) {
      //   return a.start.localeCompare(b.start)
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";
@import "../../assets/css/profile";

.cv-item-offline {
  border-color: red !important;
  background-color: red !important;
  color: white !important;
}

.theme-default .cv-item-offline .startTime, .theme-default .cv-item-offline .endTime {
  font-weight: bold;
  color: white !important;
}

.profile_content .btn {
  padding: 12px;
}

.calendar_view {
  height: 800px;
}

@media only screen and (max-width: 450px) {
  .vc-weeks {
    //grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: auto auto 1fr 1fr 1fr auto auto;
    //grid-gap: 0.65vw;
    //height: calc(100vh - 0.65vw);
  }

  .vc-day > span,
  .vc-weekday {
    font-family: Gotham-Pro, sans-serif !important;
    //font-size: 1.6rem !important;
  }

  .profile_content {
  }

  .event {
    &__list {
      margin-top: 67px;
    }

    &__title {
      font-family: Gotham, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 6vw;
      color: #4D4D4D;
      margin-bottom: 0.65vw;
    }

    &__date {
      font-family: PlumbKaz;
      font-style: normal;
      font-weight: 500;
      font-size: 4vw;
      margin: 0;
      color: #9590A0;
    }

    &__cards {
      display: flex;
      flex-direction: column;
      margin-top: 1.95vw;
    }

    &__card {
      border-left: 10px solid #0b7d75;
      background: #FFFFFF;
      border-top: 0.5px solid #828282;
      border-right: 0.5px solid #828282;
      border-bottom: 0.5px solid #828282;
      box-sizing: border-box;
      border-radius: 0.65vw;
      position: relative;
      padding: 1.625vw 2.6vw;

      &::before {
        profile_content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0.52vw;
        height: 100%;
        background: #03887F;
        border-radius: 0.65vw 0px 0px 0.65vw;
      }

      &:not(:last-child) {
        margin-bottom: 0.975vw;
      }
    }
  }

  .client {
    &__name {
      margin: 0;
      font-family: Gotham, serif;
      font-weight: bold;
      font-size: 5vw;
      color: #828282;
    }

    &__time {
      margin: 0;
      font-family: PlumbKaz, serif;
      font-weight: 500;
      font-size: 4vw;
      color: #9590A0;
      margin-top: 0.65vw;
    }
  }
}

@media only screen and (min-width: 451px) {
  .vc-weeks {
    //grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: auto auto 1fr 1fr 1fr auto auto;
    //grid-gap: 0.65vw;
    //height: calc(100vh - 0.65vw);
  }

  .vc-day > span,
  .vc-weekday {
    font-family: Gotham-Pro, sans-serif !important;
    //font-size: 1.6rem !important;
  }

  .profile_content {
  }

  .event {
    &__list {
      margin-top: 67px;
    }

    &__title {
      font-family: Gotham, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.17vw;
      color: #4D4D4D;
      margin-bottom: 0.65vw;
    }

    &__date {
      font-family: PlumbKaz;
      font-style: normal;
      font-weight: 500;
      font-size: 1.105vw;
      margin: 0;
      color: #9590A0;
    }

    &__cards {
      display: flex;
      flex-direction: column;
      margin-top: 1.95vw;
    }

    &__card {
      border-left: 10px solid #0b7d75;
      background: #FFFFFF;
      border-top: 0.5px solid #828282;
      border-right: 0.5px solid #828282;
      border-bottom: 0.5px solid #828282;
      box-sizing: border-box;
      border-radius: 0.65vw;
      position: relative;
      padding: 1.625vw 2.6vw;

      &::before {
        profile_content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0.52vw;
        height: 100%;
        background: #03887F;
        border-radius: 0.65vw 0px 0px 0.65vw;
      }

      &:not(:last-child) {
        margin-bottom: 0.975vw;
      }
    }
  }

  .client {
    &__name {
      margin: 0;
      font-family: Gotham, serif;
      font-weight: bold;
      font-size: 1.3vw;
      color: #828282;
    }

    &__time {
      margin: 0;
      font-family: PlumbKaz, serif;
      font-weight: 500;
      font-size: 1.04vw;
      color: #9590A0;
      margin-top: 0.65vw;
    }
  }
}
</style>
