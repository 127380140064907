<template>
  <el-dialog
      :visible="dialogVisible"
      width="100%"
      :lock-scroll="true"
      top="0"
  >
    <div class="modal__header">
      <div class="modal__close bold right " @click="closeModal()">✕</div>
      <img class="modal__header_logo" src="../../assets/img/logo.svg" width="150">
    </div>
    <div class="modal__body">
      <div class="tabs">
        <div class="tab tabs_login" v-bind:class="login?'active':''" v-on:click="login=true">
          {{$t('Вход')}}
        </div>
        <div class="tab tabs_logon" v-bind:class="!login?'active':''" v-on:click="login=false">
         {{$t('Регистрация')}}
        </div>
      </div>
      <div v-show="login">
        <p>
        {{$t('войдитеЧтобыУправлять')}}
        </p>
        <div class="form_wrapper">
          <form v-on:submit.prevent="submitForm">
            <div v-if="message" class="text-danger">{{ message }}</div>
            <div class="form-group">
              <input ref="email" class="input" name="email" placeholder="E-mail" required type="email">
            </div>
            <div class="form-group">
              <input id="password" ref="password" class="input" name="password"
                     :placeholder="$t('пароль')"
                     type="password">
            </div>
            <div class="form-group">
              <input :disabled="loader" class="input gr-success w100" required type="submit" :value=$t('войти')>
              <!--              <button class="input gr-success w100" type="submit" disabled>Войти</button>-->
            </div>
          </form>
        </div>
      </div>
      <div v-show="!login">
        <p>
         {{$t('зарегистрируйтесьЧтобыУправлять')}}
        </p>
        <div class="form_wrapper">
          <form v-on:submit.prevent="registration">
            <div class="form-group">
              {{$t('ФИО')}}
              <input ref="regname" class="input" :placeholder="$t('введитеДанные')" required>
            </div>
            <div class="form-group">
              {{$t('номерТелефона')}}
              <input id="tel__number" ref="regnumber" class="input tel__number" :placeholder="$t('введитеДанные')"
                     required
                     type="tel">
            </div>
            <div class="form-group">
              E-mail
              <input id="email" ref="regemail" class="input" :placeholder="$t('введитеДанные')" required type="email">
            </div>
            <div class="form-group">
            {{$t('датаРождения')}}
              <input id="data-birth" ref="regbirth" class="input" max='2015-12-31' min='1899-01-01'
                     :placeholder="$t('введитеДанные')" required type="date">
            </div>
            <div class="form-group">
              {{$t('пароль')}}
              <input ref="regpass" class="input tel__number" min="8" :placeholder="$t('введитеДанные')" required
                     type="password">
            </div>
            <div class="form-group">
              <input :disabled="loader" class="input gr-success w100" type="submit" value="Зарегистрироваться">
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <slot name="footer"/>
    </div>
  </el-dialog>
</template>

<script>

import router from '@/router'
import Inputmask from "inputmask";

export default {
  name: "AuthModal",
  data() {
    return {
      dialogVisible: false,
      message: null,
      show: true,
      login: true,
      loader: false
    }
  },
  mounted() {
    // var im = new Inputmask('+7 (799) 999 99 99')
    // im.mask(document.getElementById('tel__number'))
    // var iin = new Inputmask('999999999999')
    // iin.mask(document.getElementById('iin'))
    // var birthData = new Inputmask('99-99-9999')
    // birthData.mask(document.getElementById('data-birth'))
  },
  methods: {

    async submitForm() {
      try {
        this.loader = true
        await this.$store.dispatch('login', {
          email: this.$refs.email.value,
          password: this.$refs.password.value
        })

        this.$store.commit('openLogInDialog')

        // // eslint-disable-next-line eqeqeq
        // if (localStorage.role == 'doctor') {
        //   router.push('doctor')
        //   // eslint-disable-next-line eqeqeq
        // } else if (localStorage.role == 'clinic') {
        //   router.push('clinic')
        // } else {
        //   router.push('profile')
        // }
        location.reload()
      } catch (e) {
        this.message = 'Неверный логин или пароль'
      }
      this.loader = false
    },
    async registration() {
      this.loader = true
      try {
        await this.$store.dispatch('registration', {
          name: this.$refs.regname.value,
          email: this.$refs.regemail.value,
          phone: this.$refs.regnumber.value,
          birth: this.$refs.regbirth.value,
          pass: this.$refs.regpass.value
        })


        await this.$store.dispatch('login', {
          email: this.$refs.regemail.value,
          password: this.$refs.regpass.value
        })
        this.$store.commit('openLogInDialog')
        localStorage.setItem('role', 'user')
        router.push('/user/profile')
        // this.login = !this.login;
      } catch (e) {
        this.message = 'Неверный логин или пароль'
      }
      this.loader = false
    },
    closeModal() {
      this.$store.commit('openLogInDialog')
      this.message = null
      this.show = false

    },
    openModal() {
      this.show = true
    }
  }
}
</script>

<style scoped>

</style>
