<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import Vue from 'vue';

export default {
  name: 'App',
  beforeCreate() {
    if (!localStorage.getItem('locale')) {
      localStorage.setItem('locale', this.$i18n.locale);
    } else {
      this.$i18n.locale = localStorage.getItem('locale');
    }
  },
}
</script>

<style lang="scss">
@import "assets/css/colors";
@import "assets/css/main";

* {
  box-sizing: border-box;
}

html, body {
  //background: red !important;
  touch-action: manipulation;
}

html {

}

html, body, #app, .main-layout {
  //-webkit-overflow-scrolling: touch;
  //-webkit-user-select: none;
  //-khtml-user-select: none;
  //-moz-user-select: none;
  //-ms-user-select: none;
  //-o-user-select: none;
  //user-select: none;
}

#zmmtg-root {
  display: block;
}

a {
  text-decoration: none;
}

.text-overflow-handle {
  //overflow: hidden;

  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

button[disabled],
input[disabled] {
  color: gainsboro;
  background: gray !important;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

@font-face {
  font-family: "Gotham";
  src: url(assets/fonts/Gotham.ttf) format("woff2");
}

@font-face {
  font-family: "Gotham-Pro";
  src: url(assets/fonts/gotham-pro.ttf) format("woff2");
}

@font-face {
  font-family: "Gotham-Light";
  src: url(assets/fonts/Gotham-Light.ttf) format("woff2")
}

@font-face {
  font-family: "Gotham-Medium";
  src: url(assets/fonts/Gotham-Medium.ttf) format("woff2")
}

@font-face {
  font-family: "Gotham-Black";
  src: url(assets/fonts/Gotham-Black.ttf) format("woff2")
}

@font-face {
  font-family: "Gotham-Bold";
  src: url(assets/fonts/Gotham-Bold.ttf) format("woff2");
}

@font-face {
  font-family: "Manrope";
  src: url(assets/fonts/Manrope-Medium.ttf) format("woff2");
}

@font-face {
  font-family: "Manrope-Bold";
  src: url(assets/fonts/Manrope-Bold.ttf) format("woff2");
}

@font-face {
  font-family: "Manrope-Regular";
  src: url(assets/fonts/Manrope-Regular.ttf) format("woff2");
}

@font-face {
  font-family: "Roboto";
  src: url(assets/fonts/Roboto-Regular.ttf) format("woff2");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url(assets/fonts/Roboto-Medium.ttf) format("woff2");
}

@font-face {
  font-family: "PlumbKaz-Bold";
  src: url(assets/fonts/PlumbKaz-Bold.ttf) format("woff2");
}

@font-face {
  font-family: "PlumbKaz";
  src: url(assets/fonts/PlumbKaz-Regular.ttf) format("woff2");
}

//@font-face {
//  font-family: "Roboto";
//  src: url(../fonts/Roboto) format("woff2");
//}

%text-default {
  color: $black_secondary;
  font-family: "Gotham", serif;
  font-size: 1.17vw;
}

.text-default {
  @extend %text-default;
}

.text-white {
  @extend %text-default;
  color: $white;
}

.tooltip {
  font-family: Gotham-Medium, sans-serif;
  background-color: white;
  margin: 0.13vw;
  border: 0.065vw solid gray;
  padding: 0.52vw;
  font-size: 1.04vw;
}

.text-red {
  @extend %text-default;
  color: $red;
}

.t14 {
  font-size: 0.91vw !important;
}

.t15 {
  font-size: 0.975vw !important;
}

.t16 {
  font-size: 1.04vw !important;

}

.t17 {
  font-size: 1.105vw !important;
  line-height: 1.8px;
}

.t18 {
  font-size: 1.17vw !important;
  font-family: "Gotham", sans-serif;
  line-height: 1.48px;
}

.t24 {
  font-size: 1.56vw !important;
  line-height: 1.8px;
}

.t27 {
  font-size: 1.755vw !important;
  font-family: "Gotham-Bold", sans-serif;
  line-height: 1.755vw;
}

.t26 {
  font-size: 1.69vw !important;
  font-family: "Roboto", sans-serif;
  line-height: 1.755vw;
}

.t30 {
  font-size: 1.95vw !important;
  line-height: 1.8px;
}

.t48 {
  font-size: 3.12vw !important;
}

.t96 {
  font-size: 6.24vw !important;
  font-family: Gotham-Black, sans-serif;
}

#app {
  background-color: #F9FEFF;
  font-family: Gotham-Light, Gotham Pro, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.full {
  width: 100%;
}

@media only screen and (max-device-width: 450px) {
  .right {
    display: flex;
    flex-direction: row-reverse;
    justify-content: revert;
  }
  .sinput {
    margin: 4vw 0 !important;
    font-family: Gotham-Pro, sans-serif;
    font-size: 4vw !important;
    color: #4d4d4d;
    font-weight: normal;
    background: white;
    border: 0.065vw solid #00938A;
    border-radius: 0.78vw;
    padding: 0.325vw 1.3vw;
    box-sizing: content-box !important;
    display: block;

    & {
      select {
        font-size: 4vw !important;
        color: #4d4d4d !important;
      }
    }
  }
  .popup_body {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }

  .web {
    display: none !important;
  }
  .mobile {
    &__center {
      display: flex;
      justify-content: center;
      padding-bottom: 2vh;
    }
  }
  .profile {
    &__main {
      padding: 16px;
      text-align: left;

      & li {
        cursor: pointer;
        width: 90%;
        display: flex;
        padding: 0.52vw;
        line-height: 20px;
        align-items: center;

        & img {
          width: 1.495vw;
          height: 1.495vw;
          padding-right: 0.78vw;
        }
      }

      & .active {
        font-family: Gotham-Pro, sans-serif !important;
        color: black !important;
        border-radius: 0.78vw;
        background-color: $green_14 !important;
        margin-bottom: 0.325vw;
      }
    }
  }
  .vc-title {
    font-family: Gotham-Pro, sans-serif !important;
    font-size: 4vw !important;
    font-weight: 500 !important;
    color: $green !important;
  }
  .f20 {
    font-size: 4vw;
  }
  .btn-success {
    padding: 1.5vw 1.7875vw;
    background-color: $green;
    color: $white;
    border: 0 solid;
    outline: none;
  }

  .input {
    background: #F2F2F2;
    border-radius: 2vw;
    font-size: 4vw;
    line-height: 7vw;
    padding: 8px 12px;
    outline: none;
    border: 0 solid;
    color: $green;
    flex-basis: calc(30% - 1.56vw);
    margin: 2vw 1vw;
    width: 80vw;
    box-sizing: border-box;
  }

  .t23 {
    font-size: 4vw;
  }
  .btn {
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    font-size: 4vw;
    padding: 2vw;
    font-family: Gotham, serif;
    border: none;
    width: auto;
    text-align: center;
  }
  body {
    margin: 0 !important;
    //overflow-y: hidden !important;
  }
  #app {
    //overflow-y: hidden !important;
  }
  .main-layout {
    margin: 8px;
    //overflow-y: hidden !important;
    overflow-x: hidden !important;
    //-webkit-overflow-scrolling: touch !important;
    width: auto !important;
    //padding-bottom: 110px !important;
  }
  .dots li {
    margin: 5.495vw 2.325vw;
    width: 1.325vw;
    height: 1.325vw;
    border-radius: 1.325vw;
    background: rgba(0, 0, 0, 0.3);
    display: inline-block;
  }

  .dots li[option][selected] {
    height: 1.52vw;
    width: 1.52vw;
    border-radius: 13vw;
    background: rgba(0, 0, 0, 0.6);
  }
  .flex-row-space {
    flex-flow: column;
    justify-content: space-between;
  }
  .input-sm {
    border-radius: 0.78vw !important;
    font-size: 1.04vw !important;
    margin: 0.325vw !important;
    width: 80%;
  }
  body, html {
    overflow-x: hidden;
  }
  body, #app, .content, #nav, html, body {
    text-align: center !important;
  }
  body {
    position: relative
  }
  .t40 {
    font-size: 5vm !important;
  }
  .page-info {
    font-size: 4vw !important;
    line-height: 6vw !important;
    padding-bottom: 6vw !important;
    color: black;
  }
  .h1 {
    font-weight: 500;
    font-size: 6vw !important;
    margin-top: 7vw;
    line-height: 57.6px;
  }
  .h2 {
    font-size: 5vw;
    line-height: 22.62px;
    font-style: normal;
    font-weight: 700;
    color: $black_secondary;
    font-family: "Gotham-Bold", sans-serif;
  }

  .content {
    font-size: 1.04vw !important;
    padding: 0 16px;
    vertical-align: middle;
    text-align: center;
  }

  .t20 {
    font-size: 0.975vw !important;
    font-family: Gotham-Pro, sans-serif;
  }
  #nav {
    vertical-align: middle;
  }
  .card {
    display: flex;
    margin: 4vw 0;
    border-radius: 2.625vw;
    padding: 10vw;
    text-align: center;
  }
  .dots {
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
    vertical-align: center;
  }
}

.pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden !important;
}

@media only screen and (min-width: 451px) {
  .right {
    display: flex;
    flex-direction: row-reverse;
    justify-content: revert;
  }
  .sinput {
    width: -webkit-fill-available;
    margin: 1.095vw 0 !important;
    font-family: Gotham-Pro, sans-serif;
    font-size: 1.3vw !important;
    color: #4d4d4d;
    font-weight: normal;
    background: white;
    border: 0.065vw solid #00938A;
    border-radius: 0.78vw;
    padding: 0.325vw 1.3vw;
    box-sizing: content-box !important;
    display: block;

    & {
      select {
        font-size: 1.3vw !important;
        color: #4d4d4d !important;
      }
    }
  }
  .popup_body {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .mobile {
    display: none !important;
  }
  .web {
  }

  .profile {
    &__main {
      padding: 0 84px;
      display: flex;
      flex-direction: row;
      margin-bottom: -7px;

      &__menu {
        text-align: left;
        padding-left: 1.3vw;
        padding-top: 1.3vw;
        background-color: white;
        min-height: 100%;
        width: 20%;
        border-top: 0.065vw solid gainsboro;
        border-right: 0.065vw solid gainsboro;
      }

      &__content {
        text-align: left;
        padding: 0px 0 2.08vw 2.08vw;
        border-top: 0.065vw solid gainsboro;
        width: 75%;
        background-color: rgba(0, 147, 138, 0.02);
      }
    }
  }
  .btn-success {
    padding: 0.6825vw 1.7875vw;
    background-color: $green;
    color: $white;
    border: 0 solid;
    outline: none;
  }

  .vc-title {
    font-family: Gotham-Pro, sans-serif !important;
    font-size: 1.04vw !important;
    font-weight: 500 !important;
    color: $green !important;
  }
  .f20 {
    font-size: 1.3vw;
  }

  .input {
    background: #F2F2F2;
    border-radius: 1.105vw;
    font-size: 1.495vw;
    padding: 0.78vw 1.755vw;
    outline: none;
    border: 0 solid;
    color: $green;
    flex-basis: calc(30% - 1.56vw);
    margin: 1.095vw 0.78vw;
    width: 80%;
  }

  .t23 {
    font-size: 1.495vw;
  }
  .btn {
    outline: none;
    cursor: pointer;
    border-radius: 0.52vw;
    font-family: Gotham, serif;
    border: none;
    padding: 0.78vw 1.04vw;
    font-size: 1.625vw;
    width: auto;
    text-align: center;
  }
  .t40 {
    font-size: 2.6vw !important;
  }
  .dots li {
    margin: 1.495vw 0.325vw;
    width: 0.325vw;
    height: 0.325vw;
    border-radius: 0.325vw;
    background: rgba(0, 0, 0, 0.3);
    display: inline-block;
  }

  .dots li[option][selected] {
    height: 0.52vw;
    width: 0.52vw;
    border-radius: 13vw;
    background: rgba(0, 0, 0, 0.6);
  }
  .card {
    display: flex;
    margin: 2.86vw 0;
    border-radius: 1.625vw;
    padding: 3.25vw;
  }

  .content, #nav {
    padding-left: 5.07vw;
    padding-right: 5.07vw;
  }
  .h2 {
    font-size: 1.755vw;
    line-height: 37.62px;
    font-style: normal;
    font-weight: 700;
    color: $black_secondary;
    font-family: "Gotham-Bold", sans-serif;
  }
  .t20 {
    font-size: 1.3vw !important;
    font-family: Gotham-Pro, sans-serif;
  }
  .flex-row-space {
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  input:focus, input:focus {
    outline: none;
  }
  .input-sm {
    width: 100%;
    border-radius: 0.78vw !important;
    font-size: 1.3vw !important;
    padding: 0.65vw 0.65vw !important;
    margin-right: 2.665vw;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
  }
}

.content {
  min-height: 100%;
}

.active {
  background: $green !important;
  color: white !important;
  padding-top: 7px;
  padding-bottom: 7px;
}

.plumb-kaz {
  font-family: "PlumbKaz", serif;
}

//.roboto {
//  font-family: "Roboto", sefif;
//}

.bold {
  font-family: Gotham-Medium, serif;
  font-weight: 700;
}

.left {
  text-align: left;
  justify-content: left;
}

.left-top {
  text-align: left;
  vertical-align: top;
}

.primary {
  color: $green !important;
}

.h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 3.12vw;
  line-height: 5vw;
  color: $black_secondary;
  text-align: left;
}

.secondary-text {
  color: $white_60 !important;
  padding: 0.65vw 0;
}

.btn-secondary {
  padding: 0.6825vw 1.7875vw;
  background-color: $gray_60;
  color: $grey;
  border: 0 solid;
  outline: none;
}

.row {
  display: table-row;
}

.col {
  display: table-cell;
  width: 100%;
}

.col-1 {
  display: table-cell;
  width: 10%;
}

.col-2 {
  display: table-cell;
  width: 25%;
}

.col-80 {
  width: 80%;
}

.flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.page-info {
  font-weight: 400;
  font-size: 1.5vw;
  color: black;
}

.flex-col {
  flex-flow: column;
}

.text-45 {
  font-size: 2.925vw;
}

.how-it-work {
  display: flex;
  flex-flow: column;
  width: 100%;
  justify-content: space-between;
}

.advantage {
  display: flex;
  flex-flow: column;
  width: 100%;
  justify-content: flex-start;
}

.content {
  text-align: left;
}

.text-white {
  color: white !important;
}

.text-accent {
  color: $green !important;
}

.text-danger {
  color: $red !important;
  padding: 1.04vw 0;
}

.ptb-2 {
  padding: 1.04vw 0;
}

.danger {
  color: white;
  background: #FF441F !important;
  box-shadow: 0 0 1.235vw 0.065vw rgba(255, 68, 31, 0.31);
}

.input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $grey_70;
}

.gr-success {
  color: white;
  background: linear-gradient(180deg, #00938A 0%, #0B7D75 100%);
  box-shadow: 0 0.26vw 1.105vw 0.13vw rgba(6, 147, 138, 0.37);
  border-radius: 1.755vw;
}

.invis-hr {
  border: 0 solid;
}

.float-l {
  float: left;
}

.dots {
  text-align: center;
  vertical-align: center;
}

.vc-container {
  box-shadow: 0 0.26vw 1.04vw rgba(47, 59, 87, 0.2);
}

.vc-time-picker {
  display: flex;
  justify-content: center !important;
}

.vc-highlight {
  border-radius: 0 !important;
  background-color: $red !important;
}

.is-today {
  border: 0.13vw solid white;
  background-color: $green_14;
}

.vc-highlight > .vc-day-content {
  color: $green !important;
  border-radius: 0.13vw !important;
}

.text-center {
  text-align: center;
}

.dropbtn {
  color: white;
  padding: 4px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  text-align: left;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  min-width: 160px;
  left: -160px;
  top: 0;
  z-index: 1;
}

.dropdown-border {
  border-bottom: 1px solid #F2F0F9;

  &:last-child {
    border-bottom: unset;
  }
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.slick-track {
  display: flex !important;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.feedback-block {
  padding-bottom: 6.24vw;
}

.slick-arrow {
  top: 35% !important;
}

.slick-arrow:before, .slick-arrow:before {
  color: #00938A !important;
  font-size: xx-large;
  font-weight: lighter;
}

.slick-slider {
  margin: 1.95vw;
}

.slick-dots {
  bottom: -51px;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #00938A;
  transform: scale(2);
}

.slick-dots li button:before {
  opacity: 0.75;
  color: #00938A;
}

.slick-list {
  margin: 0 0.65vw !important;
}

.slick-slide > div {
  padding: 0 0.325vw;
  flex: 1;
}

.slick-slide, .slick-slide * {
  outline: none !important;
  display: flex;
  flex-direction: column;
}

.slick-slide {
  height: inherit !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.feedback-block {
  padding-bottom: 6.24vw;
}

.slick-arrow {
  top: 35% !important;
}

.slick-arrow:before, .slick-arrow:before {
  color: #00938A !important;
  font-size: xx-large;
  font-weight: lighter;
}

.slick-slider {
  margin: 1.95vw;
}

.slick-dots {
  bottom: -51px;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #00938A;
  transform: scale(2);
}

.slick-dots li button:before {
  opacity: 0.75;
  color: #00938A;
}

.slick-list {
  margin: 0 0.65vw !important;
}

.slick-slide > div {
  padding: 0 0.325vw;
  flex: 1;
}

.slick-slide, .slick-slide * {
  outline: none !important;
  display: flex;
  flex-direction: column;
}

</style>
