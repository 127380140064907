<template>

  <div class="modal">
    <div class="modal__backdrop" @click="closeModal()"/>
    <div class="disable_scroll" @scroll.prevent="test">
      <div class="modal__dialog" :class="register ?'modal__dialog__new': 'modal__login'">
        <div class="modal__header">
          <div class="modal__close bold right " @click="closeModal()">✕</div>
          <img class="modal__header_logo" src="../../assets/img/logo.svg" width="150">
        </div>
        <div class="modal__body">
          <div class="tabs" style="margin-top: 1rem">
            <div class="tab tabs_login" v-bind:class="login?'active':''"
                 v-on:click="login=true;register=false;reset=false">
              {{ $t('Вход') }}
            </div>
            <div class="tab tabs_logon" v-bind:class="register?'active':''"
                 v-on:click="login=false;register=true;reset=false">
              {{ $t('Регистрация') }}
            </div>
            <div class="tab tabs_logon" v-bind:class="reset?'active':''"
                 v-on:click="login=false;register=false;reset=true">
              {{$t('ВосстановлениеПароля')}}
            </div>
          </div>
          <div v-show="login">
            <p>
              {{ $t('войдитеЧтобыУправлять') }}
            </p>
            <div class="form_wrapper">
              <form v-on:submit.prevent="submitForm">
                <div v-if="message" class="text-danger">{{ message }}</div>
                <div class="form-group">
                  <input ref="email" class="input" name="email" placeholder="E-mail" required type="email">
                </div>
                <div class="form-group">
                  <input id="password" ref="password" class="input" name="password"
                         :placeholder="$t('пароль')"
                         type="password">
                </div>
                <div class="form-group">
                  <input :disabled="loader" class="input gr-success w100" required type="submit" :value="$t('войти')">
                  <!--              <button class="input gr-success w100" type="submit" disabled>Войти</button>-->
                </div>
              </form>
            </div>
          </div>
          <div v-show="reset">
            <p v-if="emailSended==false">
              {{$t('ВосстановлениеПароля')}}
            </p>
            <div v-if="emailSended==false" class="form_wrapper">
              <form v-on:submit.prevent="sendEmail">
                <div class="form-group">
                  <input v-model="emailSend"  class="input" name="email" placeholder="E-mail"
                         type="email">
                </div>
                <div class="form-group">
                  <input  :disabled="loader" class="input gr-success w100"  type="submit"
                         :value="$t('Восстановитьпароль')">
                  <!--              <button class="input gr-success w100" type="submit" disabled>Войти</button>-->
                </div>
              </form>
            </div>
            <div v-if="emailSended" style="margin: 2rem" class="form_wrapper">
             {{$t('письмоОтправлено')}}
            </div>
          </div>
          <div v-show="register">
            <p>
              {{ $t('зарегистрируйтесьЧтобыУправлять') }}
            </p>
            <div class="form_wrapper">
              <form v-on:submit.prevent="registration">
                <div class="form-group">
                  {{ $t('Имя') }}
                  <input ref="regname" class="input" :placeholder="$t('введитеДанные')" required>
                </div>
                <div class="form-group">
                  {{ $t('Фамилия') }}
                  <input ref="regsurname" class="input" :placeholder="$t('введитеДанные')" required>
                </div>
                <div class="form-group">
                  {{ $t('Отчество') }}
                  <input ref="regpatronymic" class="input" :placeholder="$t('введитеДанные')" required>
                </div>
                <div class="form-group">
                  {{ $t('номерТелефона') }}
                  <input id="tel__number" ref="regnumber" class="input tel__number" :placeholder="$t('введитеДанные')"
                         required
                         type="tel">
                </div>
                <div class="form-group">
                  E-mail
                  <input id="email" ref="regemail" class="input" :placeholder="$t('введитеДанные')" required
                         type="email">
                </div>
                <div class="form-group">
                  {{ $t('датаРождения') }}
                  <input id="data-birth" ref="regbirth" class="input" max='2015-12-31' min='1899-01-01'
                         :placeholder="$t('введитеДанные')" required type="date">
                </div>
                <div class="form-group">
                  {{ $t('пароль') }}
                  <input ref="regpass" class="input tel__number" min="8" :placeholder="$t('введитеДанные')" required
                         type="password">
                </div>


                <div class="form-group">
                  {{ $t('Город') }}
                  <v-select :isModal="true" :placeholder="$t('Город')" @change="changeCity"/>
                </div>
                <div class="form-group">
                  {{ $t('Пол') }}
                  <v-select
                      :isModal="true"
                      :list="[{id:1, title:$t('Мужской'), title_en:$t('Мужской'),title_kz:$t('Мужской')},{id:2, title:$t('Женский'),title_en:$t('Женский'),title_kz:$t('Женский')}]"
                      :placeholder="$t('Пол')" @change="selectGender"/>
                </div>

                <div class="form-group" style="margin-top: 1rem">
                  <input :disabled="loader" class="input gr-success w100" type="submit" :value="$t('Зарегистрироваться')">
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal__footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import router from '@/router'
import Inputmask from 'inputmask'
import vSelect from "@/components/select";
import axios from "axios";

export default {
  name: 'Modal',
  components: {vSelect},
  data() {
    return {
      emailSended:false,
      cities: [],
      message: null,
      show: true,
      login: true,
      reset: false,
      register: false,
      loader: false,
      regCity: '1',
      gender: '1',
      emailSend: '',
    }
  },
  mounted() {
    this.cities = this.$store.state.reference.cities
    // document.querySelector('body').classList.remove('overflow-hidden')
    // document.querySelector('body').classList.remove('overflow-ios')
    var im = new Inputmask('+9 (999) 999 99 99')
    im.mask(document.getElementById('tel__number'))
    var birthData = new Inputmask('99-99-9999')
    birthData.mask(document.getElementById('data-birth'))
  },
  methods: {
     sendEmail() {
      axios.post(`${process.env.VUE_APP_API_URL}user/send-restore-code`, {
        email: this.emailSend,
      }).then(r => this.emailSended=true)

    },
    test() {
      return
    },
    selectGender(gender) {
      this.gender = gender
    },
    changeCity(city) {
      this.regCity = city
    },
    async submitForm() {
      try {
        this.loader = true
        await this.$store.dispatch('login', {
          email: this.$refs.email.value,
          password: this.$refs.password.value
        })
        document.querySelector('body').classList.remove('overflow-hidden')
        document.querySelector('body').classList.remove('overflow-ios')
        document.querySelector('#app').classList.remove('overflow-hidden')
        document.querySelector('html').classList.remove('overflow-hidden')
        document.querySelector('html').classList.remove('overflow-ios')
        document.querySelector('.main-layout').classList.remove('overflow-hidden')
        document.querySelector('.content').classList.remove('overflow-hidden')
        this.$store.commit('openLogInDialog')

        // eslint-disable-next-line eqeqeq
        // if (localStorage.role == 'doctor') {
        //   router.push('doctor')
        //   // eslint-disable-next-line eqeqeq
        // } else if (localStorage.role == 'clinic') {
        //   router.push('clinic')
        // } else {
        //   router.push('profile')
        // }
        location.reload()
      } catch (e) {
        this.message = 'Неверный логин или пароль'
      }
      this.loader = false
    },
    async registration() {
      let phoneValid = !(this.$refs.regnumber.value.indexOf('_') > -1)

      if (phoneValid) {
        this.loader = true
        try {
          await this.$store.dispatch('registration', {
            name: this.$refs.regname.value,
            surname: this.$refs.regsurname.value,
            patronymic: this.$refs.regpatronymic.value,
            email: this.$refs.regemail.value,
            phone: this.$refs.regnumber.value,
            birth: this.$refs.regbirth.value,
            pass: this.$refs.regpass.value,
            city_id: this.regCity,
            sex: this.gender
          })

          await this.$store.dispatch('login', {
            email: this.$refs.regemail.value,
            password: this.$refs.regpass.value
          })
          document.querySelector('body').classList.remove('overflow-hidden')
          document.querySelector('body').classList.remove('overflow-ios')
          document.querySelector('#app').classList.remove('overflow-hidden')
          document.querySelector('html').classList.remove('overflow-hidden')
          document.querySelector('html').classList.remove('overflow-ios')
          document.querySelector('.main-layout').classList.remove('overflow-hidden')
          document.querySelector('.content').classList.remove('overflow-hidden')
          this.$store.commit('openLogInDialog')
          localStorage.setItem('role', 'user')
          router.push('/user/profile')
          // this.login = !this.login;

        } catch (e) {
          this.message = 'Неверный логин или пароль'
        }
        this.loader = false
      }
    },
    closeModal() {
      this.$store.commit('openLogInDialog')
      this.message = null
      this.show = false
      document.querySelector('body').classList.remove('overflow-hidden')
      document.querySelector('body').classList.remove('overflow-ios')
      document.querySelector('#app').classList.remove('overflow-hidden')
      document.querySelector('html').classList.remove('overflow-hidden')
      document.querySelector('html').classList.remove('overflow-ios')
      document.querySelector('.main-layout').classList.remove('overflow-hidden')
      document.querySelector('.content').classList.remove('overflow-hidden')
    },
    openModal() {
      this.show = true
      document.querySelector('body').classList.add('overflow-hidden')
      document.querySelector('body').classList.add('overflow-ios')
      document.querySelector('#app').classList.add('overflow-hidden')
      document.querySelector('html').classList.add('overflow-hidden')
      document.querySelector('html').classList.add('overflow-ios')
      document.querySelector('.main-layout').classList.add('overflow-hidden')
      document.querySelector('.content').classList.add('overflow-hidden')
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/css/colors";

.modal__backdrop {
  user-select: none;
  touch-action: none;
  z-index: 5;
  height: 100vh;
  overflow: hidden;
}

.disable_scroll {
  z-index: 10;
}

.form_wrapper {
  display: flex;
  justify-content: center;

  & form {
    width: 100%;
    padding: 0 8px;

    input {
      width: auto;
    }
  }
}

@media only screen and (max-device-width: 450px) {
  input[type=button],
  input[type=submit] {
    cursor: pointer !important;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }

  .modal__header_logo {
    display: flex;
    justify-content: right;
  }

  .modal__header {
    display: flex;
    flex-direction: column;
    padding: 3vw;
    font-size: 5vw !important;
    align-items: center;
    justify-content: center;
  }

  .modal__body {
    text-align: left;
    line-height: 6vw;
  }

  .modal {
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    font-family: PlumbKaz, sans-serif;
    line-height: 2.625vw;
    left: 0;
    z-index: 10000;

    &__backdrop {
      background-color: rgba(0, 0, 0, 0.4);
      filter: blur(2px);
      backdrop-filter: blur(2px);
      -webkit-filter: blur(2px);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    &__dialog {
      background-color: #ffffff;
      position: relative;
      padding: 1.495vw 3.445vw;
      width: 100%;
      height: max-content;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      border-radius: 0.325vw;
      z-index: 2;

    }

    &__login {
      //height: 100%;
      max-height: 450px;
      width: auto;
      padding-bottom: 30px;
    }

    &__header {
    }

    &__close {
      display: flex;
      width: 100%;
      cursor: pointer;
      font-weight: 800;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    line-height: 6vw;
    padding: 1.26vw 0;

    & {
      input[type='button'],
      input[type='submit'] {
        box-sizing: content-box
      }
    }
  }

  .form-group input {
    line-height: 7vw !important;
    border-radius: 1.78vw !important;
    font-family: Gotham, sans-serif;
    font-size: 5vw;
    margin: 0 !important;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    line-height: 8vw;
    border-bottom: 0.065vw solid gainsboro;
  }

  .tab {
    cursor: pointer;
    padding: 0 3vw 1vw 0;
    font-size: 5vw !important;
    line-height: 8vw !important;

    &.active {
      padding: 0 3vw 1vw 0;
      font-size: 5vw !important;
      line-height: 8vw !important;
      color: $green !important;
      background-color: white !important;
      border-bottom: 0.13vw solid $green;

    }
  }
}

.modal__dialog__new {
  height: 87vh;
  overflow-y: auto;
  width: auto;
}

@media only screen and (min-device-width: 451px) {
  input[type=button],
  input[type=submit] {
    cursor: pointer !important;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }

  .modal__header_logo {
    display: flex;
    justify-content: right;
  }

  .modal__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .modal__body {
    text-align: left;
  }

  .modal {
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    font-family: PlumbKaz, sans-serif;
    line-height: 1.625vw;
    left: 0;
    z-index: 10000;

    &__backdrop {
      background-color: rgba(0, 0, 0, 0.4);
      filter: blur(2px);
      backdrop-filter: blur(2px);
      -webkit-filter: blur(2px);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    &__dialog {
      background-color: #ffffff;
      position: relative;
      padding: 1.495vw 3.445vw;
      width: 50%;
      margin: 2% auto;
      display: flex;
      flex-direction: column;
      border-radius: 0.325vw;
      z-index: 2;
      @media screen and (max-width: 992px) {
        width: 90%;
      }
    }

    &__header {
    }

    &__close {
      display: flex;
      width: 100%;
      cursor: pointer;
      font-weight: 800;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    line-height: 1.625vw;
    padding: 0.26vw 0;

    & {
      input[type='button'],
      input[type='submit'] {
        box-sizing: content-box
      }
    }
  }

  .form-group input {
    border-radius: 0.78vw !important;
    font-family: Gotham-Medium, sans-serif;
    font-size: 0.91vw;
    margin: 0 !important;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    line-height: 2.6vw;
    border-bottom: 0.065vw solid gainsboro;
  }

  .tab {
    cursor: pointer;
    padding: 0 1.235vw 0.065vw 0;

    &.active {
      color: $green !important;
      background-color: white !important;
      border-bottom: 0.13vw solid $green;

    }
  }
}
</style>
