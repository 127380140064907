<template>
  <div class="feedback">
    <div class="c-box c-box--arrow-bottom">
      <div class="feedback-header">
        {{ $i18n.locale == 'kk' ? comment.title_kz : $i18n.locale == 'ru' ? comment.title : comment.title_en }}
      </div>
      {{ $i18n.locale == 'kk' ? comment.text_kz : $i18n.locale == 'ru' ? comment.text : comment.text_en }}
    </div>
    <div class="feedback-user">
      <!--      <div class="feedback-user-icon">-->
      <!--        <circle-img :pic="comment.image" size="xsm"/>-->
      <!--      </div>-->
      <div class="feedback-user-name">
        {{ $i18n.locale == 'kk' ? comment.author_kz : $i18n.locale == 'ru' ? comment.author : comment.author_en }}
      </div>
    </div>
  </div>
</template>

<script>
import CircleImg from '@/components/img/circle-img'

export default {
  name: 'comment',
  components: {CircleImg},
  props: ['comment']
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";
// set some variables to reuse
@media only screen and (max-device-width: 450px) {

  /* This mixin is for generating CSS arrows on a box */
  @mixin box-arrow($arrowDirection, $arrowColor, $arrowSize: 2.65vw) {
    position: relative;
    z-index: 10;
    &::after {
      content: "";
      width: 0;
      height: 0;
      display: block;
      position: absolute;
      z-index: 10;
      border: 0;

      @if $arrowDirection == bottom {
        border-left: $arrowSize solid transparent;
        border-right: $arrowSize solid transparent;
        margin-left: -$arrowSize;
        left: 50%;
        @if $arrowDirection == bottom {
          border-top: $arrowSize solid $arrowColor;
          bottom: -$arrowSize;
        }
      }
    }
  }
  body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 5vmin;
    margin: 0;
    font-family: "Gotham", sans-serif;
    background: $bodybkg;
  }

  .slick-current .c-box {
    border-radius: 2vw;
    background: $green;
    font-size: 4vw;
    line-height: 6vw;
    height: auto;
    text-align: left;
    color: #fff;
    align-items: stretch;
    padding: 4vw;
    margin-bottom: 5vmin;

    &--arrow-bottom {
      @include box-arrow(bottom, $green);
    }
  }

  .c-box {
    flex: 1 auto;
    height: 10rem;
    border-radius: 2vw;
    background: #fff;
    font-size: 4vw;
    line-height: 6vw;
    text-align: left;
    color: #18191F;
    padding: 2.6vw;
    margin-bottom: 5vmin;
    box-shadow: 0 1.95vw 2.6vw rgba(212, 217, 232, 0.2);

    &--arrow-bottom {
      @include box-arrow(bottom, #fff);
    }
  }

  .feedback {
    display: flex;
    alignment: center;
    font-family: Manrope, sans-serif;
    color: $gray900;
    text-align: center;
    font-weight: 400;
    display: flex;
    flex: 1;
    align-content: center;
  }

  .feedback-user {
    display: inline-block;
    text-align: center;
    width: auto;
    position: relative;
    z-index: 10;
  }

  .feedback-user-icon {
    display: inline-block;
    width: 5vw;
    height: 5vw;
    margin: auto auto;
    border-radius: 50%;
    //background: url("../../assets/img/user.png") no-repeat;
    //background-size: 5vw 5vw;
  }

  .feedback-header {
    font-style: normal;
    font-weight: 600;
    font-size: 5vw;
    padding-bottom: 3vw;
    line-height: 6.5vw;
  }

  .feedback-user-name {
    font-size: 4vw;
    line-height: 6vw;
    font-family: Manrope, serif;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-feature-settings: 'liga' off;
  }
}

@media only screen and (min-device-width: 451px) {

  /* This mixin is for generating CSS arrows on a box */
  @mixin box-arrow($arrowDirection, $arrowColor, $arrowSize: 0.65vw) {
    position: relative;
    z-index: 10;
    &::after {
      content: "";
      width: 0;
      height: 0;
      display: block;
      position: absolute;
      z-index: 10;
      border: 0;

      @if $arrowDirection == bottom {
        border-left: $arrowSize solid transparent;
        border-right: $arrowSize solid transparent;
        margin-left: -$arrowSize;
        left: 50%;
        @if $arrowDirection == bottom {
          border-top: $arrowSize solid $arrowColor;
          bottom: -$arrowSize;
        }
      }
    }
  }
  body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 5vmin;
    margin: 0;
    font-family: "Gotham", sans-serif;
    background: $bodybkg;
  }

  .slick-current .c-box {
    border-radius: 0.65vw;
    background: $green;
    font-size: 0.91vw;
    line-height: 1.235vw;
    text-align: left;
    color: #fff;
    align-items: stretch;
    padding: 2.6vw;
    margin-bottom: 5vmin;

    &--arrow-bottom {
      @include box-arrow(bottom, $green);
    }
  }

  .c-box {
    flex: 1 auto;
    border-radius: 0.65vw;
    background: #fff;
    font-size: 0.91vw;
    line-height: 1.235vw;
    text-align: left;
    color: #18191F;
    padding: 2.6vw;
    margin-bottom: 5vmin;
    box-shadow: 0 1.95vw 2.6vw rgba(212, 217, 232, 0.2);

    &--arrow-bottom {
      @include box-arrow(bottom, #fff);
    }
  }

  .feedback {
    alignment: center;
    font-family: Manrope, sans-serif;
    color: $gray900;
    text-align: center;
    font-weight: 400;
    display: flex;
    flex: 1;
    align-content: center;
  }

  .feedback-user {
    display: inline-block;
    text-align: center;
    width: auto;
    position: relative;
    z-index: 10;
  }

  .feedback-user-icon {
    display: inline-block;
    width: 56px;
    height: 56px;
    margin: auto auto;
    border-radius: 50%;
    //background: url("../../assets/img/user.png") no-repeat;
    //background-repeat: no-repeat;
    //background-size: auto;
  }

  .feedback-header {
    font-style: normal;
    font-weight: 600;
    font-size: 1.56vw;
    padding-bottom: 0.52vw;
    line-height: 2.08vw;
  }

  .feedback-user-name {
    font-size: 1.17vw;
    line-height: 1.82vw;
    font-family: Manrope, serif;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-feature-settings: 'liga' off;
  }
}
</style>
