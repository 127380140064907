<template>
  <div v-if="comments.length > 0" class="feedback-block">
    <vue-slick-carousel v-bind="settings">
      <v-comment v-for="(comment,index) in comments" :key="index+comment.image" :comment="comment"/>
    </vue-slick-carousel>
  </div>
</template>

<script>
import vComment from '@/components/comment/comment'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'comments',
  components: {
    vComment,
    VueSlickCarousel
  },
  props: ['mobile'],
  data: function () {
    return {
      settings: !this.mobile ? {
        dots: true,
        arrows:true,
        infinite: true,
        initialSlide: 2,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true
      } : {
        dots: true,
        arrows:false,
        infinite: true,
        initialSlide: 2,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true
      },
      comments: {}
    }
  },
  created () {
    this.getComments()
  },
  methods: {
    async getComments () {
      const result = await this.$http.post(
        `${process.env.VUE_APP_API_URL}web/feedback`, {}
      )
      if (result) {
        this.comments = result.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-device-width: 450px) {
  .slick-slider {
    margin: 0 !important;
  }
  .slick-arrow {
    display: none !important;
  }
}
@media only screen and (min-device-width: 451px) {
}

//.slick-next {
//  /*my style*/
//  background: url(../assets/img/right-arrow-64.svg);
//}
</style>
