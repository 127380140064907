import Vue from 'vue'
import Vuex from 'vuex'
import { appAuth } from './auth'
import { reference } from './reference'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    reference: reference,
    auth: appAuth
  }
})
