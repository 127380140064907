<template>
  <div ref="v-popup-wrapper" class="v-popup-wrapper">
    <div style="height: 100%!important;" class="v-popup" :class="stylen">
      <div :class="closePosition?closePosition:''" class="v-popup__header">
        <span class="v-popup__header__title">{{ title }}</span>
        <span class="v-popup__header__close bold" @click="closePopup">✕</span>
      </div>
      <div class="v-popup__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'v-popup',
  props: ['title', 'closePosition', 'isVisible', 'stylen'],
  methods: {
    closePopup() {
      this.$emit('closePopup')
    }
  },
  mounted() {
    var vm = this
    document.addEventListener('click', function (item) {
      if (item.target === vm.$refs['v-popup-wrapper']) {
        vm.closePopup()
      }
    })
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .v-popup-wrapper {
    user-select: none;
    touch-action: none;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gray4;
    z-index: 1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .big-icon {
    font-size: 3.25vw !important;
  }
  .center {
    display: flex;
    justify-content: center;
    margin: 0.65vw;
    font-size: larger;
    text-align: center;
  }

  .v-popup {
    color: $black_secondary;
    min-height: 20vh;
    max-height: 100%;
    overflow-y: auto !important;
    overflow-x: hidden;
    z-index: 10;
    border-radius: 40px 40px 0 0 !important;
    padding: 20px !important;
    position: fixed;
    min-width: 310px;
    width: 100%;
    bottom: 0;
    background: white;
    box-shadow: -1px 6px 9px 3px rgba(0, 0, 0, 0.09);

    &__header, &__footer {
      display: flex;
      padding: 2vw;
      justify-content: space-between;
      align-items: center;

      &__close {
        padding: 2vw;
        font-size: 5vw;
        cursor: pointer;
      }

      &__title {
        border-radius: 16px 16px 0 0 !important;
        font-family: Gotham, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 4vw !important;
        line-height: 6vw;
      }
    }

    &__content {
      font-size: 4vw;
      margin-top: 2vw;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .form-group {
    line-height: 4vw;
    width: 100%;
    font-size: 4vw !important;

    input::-webkit-search-cancel-button {
      -webkit-appearance: searchfield-cancel-button;
    }

    input {
      width: 100%;
      font-family: Gotham-Pro, sans-serif;
      font-size: 4vw !important;
      color: $black_secondary;
      font-weight: normal;
      background: white;
      border: 0.065vw solid $green;
      box-sizing: border-box;
    }
  }

  .right-close {
    justify-content: space-between !important;
  }
}

@media only screen and (min-device-width: 451px) {
  .v-popup-wrapper {
    user-select: none;
    touch-action: none;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gray4;
    z-index: 1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .big-icon {
    font-size: 3.25vw !important;
  }
  .center {
    display: flex;
    justify-content: center;
    margin: 0.65vw;
    font-size: larger;
    text-align: center;
  }

  .v-popup {
    color: $black_secondary;
    z-index: 1010;
    padding: 33px;
    position: fixed;
    min-width: 27vw;
    width: auto;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    background: white;
    box-shadow: -1px 6px 9px 3px rgba(0, 0, 0, 0.09);
    border-radius: 9px;

    &__header, &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__close {
        cursor: pointer;
      }

      &__title {
        font-family: Gotham, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1.56vw;
        line-height: 29px;
      }
    }

    &__content {
      margin-top: 1.3vw;
      width: 100%;
      display: flex;
      justify-content: left;
    }
  }

  .form-group {
    line-height: 2vw;
    width: 100%;

    input::-webkit-search-cancel-button {
      -webkit-appearance: searchfield-cancel-button;
    }

    .input {
      margin: 1.095vw 0 !important;
    }

    input {
      width: 100%;
      font-family: Gotham-Pro, sans-serif;
      font-size: 1.3vw;
      color: $black_secondary;
      font-weight: normal;
      background: white;
      border: 0.065vw solid $green;
      box-sizing: border-box;
    }
  }

  .right-close {
    justify-content: space-between !important;
  }
}

.v-popup {
  .right {
    @media only screen and (max-device-width: 450px) {
      justify-content: center;
      margin-top: 12px;

      .btn {
        //padding:8px 12px;
      }
    }
  }
}

</style>
