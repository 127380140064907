<template>
  <div class="profile">
    <v-popup
        v-show="message"
        :title="title"
        close-position="text-accent"
        @closePopup="closeAlertPopup"
    >
      <div class="h2">
        <span
            class="center"
            v-html="message"
        />
        <div class="center">
          <v-button
              size="text-accent p-1"
              value="OK"
              @click="closeAlertPopup"
          />
        </div>
      </div>
    </v-popup>
    <vue-title :title="nav_title"/>
    <main-layout>
      <div class="profile__main">
        <left-navigation/>
        <div class="profile__main__content">
          <profile-title padding="p-2" :title="$t('личныеДанные')"/>
          <profile-info/>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import ProfileInfo from '@/components/user/profile-info'
import ProfileTitle from '@/components/titles/profile-title'
import LeftNavigation from '@/components/user/left-navigation'
import VPopup from '@/components/modals/v-popup'
import VButton from '@/components/btn/v-button'
import {handleView} from '@/utils/functions'

export default {
  components: {
    VButton,
    VPopup,
    LeftNavigation,
    ProfileTitle,
    ProfileInfo,
    MainLayout
  },
  name: 'profile',
  data: function () {
    return {
      mobileView: true,
      isTokenIsEmpty: null,
      showNav: false,
      message: '',
      title: '',
      nav_title: ''
    }
  },
  async created() {
    if (this.$route.query.status) {
      if (this.$route.query.status == 'success') {
        this.title = 'Успешно'
        this.message = 'Вы успешно оплатили заказ'
      } else {
        this.title = 'Ошибка'
        this.message = 'Платеж отклонен'
      }
    }
    this.mobileView = handleView()
  },
  methods: {
    closeAlertPopup() {
      this.message = ''
      this.$router.push('profile')
    },
    scroll() {
      const element = document.getElementById('feedback-partner')
      element.scrollIntoView({behavior: 'smooth'})
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .profile {
    &__main {
      padding: 1.04vw;
      text-align: left;

      & li {
        cursor: pointer;
        width: 90%;
        display: flex;
        padding: 0.52vw;
        line-height: 20px;
        align-items: center;

        & img {
          width: 1.495vw;
          height: 1.495vw;
          padding-right: 0.78vw;
        }
      }

      & .active {
        font-family: Gotham-Pro, sans-serif !important;
        color: black !important;
        border-radius: 0.78vw;
        background-color: $green_14 !important;
        margin-bottom: 0.325vw;
      }
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .profile {
    &__main {
      padding: 0 84px;
      display: flex;
      flex-direction: row;
      margin-bottom: -7px;

      &__menu {
        text-align: left;
        padding-left: 1.3vw;
        padding-top: 1.3vw;
        background-color: white;
        min-height: 100%;
        width: 20%;
        border-top: 0.065vw solid gainsboro;
        border-right: 0.065vw solid gainsboro;
      }

      &__content {
        text-align: left;
        padding: 0px 0 2.08vw 2.08vw;
        border-top: 0.065vw solid gainsboro;
        width: 75%;
        background-color: rgba(0, 147, 138, 0.02);
      }
    }
  }
}
</style>
