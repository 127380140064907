<template>
  <div class="chat__item" v-bind:style="{'flex-direction': role == message.role?'row-reverse':'row'}">
    <circle-img :name="message.name" pic="" size="xsm"/>
    <div class="chat__item__content" v-bind:style="{'flex-direction': role == message.role?'row-reverse':'row'}">
      <!--      v-bind:style="{'flex-direction': 'user' == message.role?'row-reverse':'row', background: 'user' == message.role?'grey':'black'}"-->
      <div :class="role == message.role?'arrow-right':'arrow-left'"></div>
      <div class="chat__item__content__msg " v-bind:style="{'background': role == message.role?'#00938A':''}">
        {{ message.text }}
      </div>
    </div>
    <div class="chat__item__content__time">
      {{ format_date(message.created_at) }}
    </div>
  </div>
</template>

<script>
import CircleImg from '@/components/img/circle-img'
import moment from 'moment'

export default {
  props: ['from', 'message'],
  name: 'chat-item',
  components: {CircleImg},
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YY HH:mm')
      }
    }
  },
  data() {
    return {
      role: localStorage.getItem('role')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

.chat__item {
  .initials {
    font-size: 16px !important;
  }
}

@media only screen and (max-device-width: 450px) {
  .chat__item {
    color: white;
    width: 90%;
    margin: 1.3vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;

    &__content {
      padding: 0.52vw;
      display: flex;
      flex-direction: row;
      width: 80%;
      align-items: center;
      justify-items: center;

      &__time {
        padding: 1.04vw;
        font-size: 3vw;
        color: $gray2;
      }

      &__msg {
        align-items: center;
        justify-items: center;
        justify-content: center;
        overflow-wrap: anywhere;
        border-radius: 1vw;
        padding: 1.04vw;
        width: 100%;
        background-color: $gray2;
      }
    }
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 2vw solid transparent;
    border-bottom: 2vw solid transparent;
    border-left: 2vw solid $green;
  }

  .arrow-left {
    width: 0;
    height: 0;
    border-top: 2vw solid transparent;
    border-bottom: 2vw solid transparent;
    border-right: 2vw solid $gray2;
  }
}

@media only screen and (min-device-width: 451px) {
  .chat__item {
    color: white;
    width: 90%;
    margin: 1.3vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;

    &__content {
      padding: 0.52vw;
      display: flex;
      flex-direction: row;
      width: 80%;
      align-items: center;
      justify-items: center;

      &__time {
        padding: 1.04vw;
        color: $gray2;
      }

      &__msg {
        align-items: center;
        justify-items: center;
        justify-content: center;
        border-radius: 0.52vw;
        overflow-wrap: anywhere;
        padding: 1.04vw;
        width: 100%;
        background-color: $gray2;
      }
    }
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 0.65vw solid transparent;
    border-bottom: 0.65vw solid transparent;
    border-left: 0.65vw solid $green;
  }

  .arrow-left {
    width: 0;
    height: 0;
    border-top: 0.65vw solid transparent;
    border-bottom: 0.65vw solid transparent;
    border-right: 0.65vw solid $gray2;
  }
}
</style>
