<template>
  <div class="consultation__call">
    <ul>
      <li v-bind:class="tab==1?'active':''" @click="changeToggle(1)">
        <font-awesome-icon icon="video" size="2x"/>
      </li>
      <li v-bind:class="tab==2?'active':''" @click="changeToggle(2)">
        <font-awesome-icon icon="comment" size="2x"/>
      </li>
    </ul>
    <div v-if="tab==1" class="consultation__call__info">
      <template>
        <vue-jitsi-meet
            style="height:auto;"
            ref="jitsiRef"
            domain="meet.jit.si"
            :options="jitsiOptions"
        ></vue-jitsi-meet>
      </template>
      <!--      <iframe v-if="zoom.conference_id"-->
      <!--              :src="'https://zoom.texme.kz/meeting?nickname='+(user?user.name:'')+'&meetingId='+zoom.conference_id+'&pw='+zoom.password"-->
      <!--              allow="microphone; camera; fullscreen" @load="loader=false"/>-->
      <!--      <div v-if="!zoom.conference_id" class="consultation__call__info__phone">-->
      <!--        <preloader v-if="loader"/>-->
      <!--        <font-awesome-icon v-if="!loader" icon="phone" @click="call"/>-->
      <!--      </div>-->
    </div>
    <chat v-if="tab==2" :channel="channel" :role="role"/>
  </div>
</template>

<script>
import Chat from '@/components/comment/chat'
import Preloader from '@/components/preloader'
import {JitsiMeet} from '@mycure/vue-jitsi-meet';

export default {
  props: {
    item: String,
    name: String,
    channel: String,
    role: String,
    consultationId: Number,
    zoom: Object,
  },
  computed: {
    jitsiOptions() {
      return {
        roomName: this.$t('Запись')+'№' + this.consultationId,
        noSSL: false,
        lang: 'ru',
        userInfo: {
          email: this.user.email,
          displayName: this.user.name,
        },
        configOverwrite: {
          enableNoisyMicDetection: false
        },
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_CHROME_EXTENSION_BANNER: false
        },
        onload: this.onIFrameLoad
      };
    },
  },
  data() {
    return {
      loader: false,
      user: localStorage.user ? JSON.parse(localStorage.user) : null,
      tab: 0
    }
  },
  name: 'chatCall',
  components: {
    Preloader,
    Chat,
    VueJitsiMeet: JitsiMeet

  },
  mounted() {
    this.tab = this.item
  },
  methods: {
    changeToggle(item) {
      this.tab = item
      // this.$emit('changeToggle', item)
      // eslint-disable-next-line eqeqeq
    },
    call() {
      // this.createZoom()
      this.loader = true
    },
    async createZoom() {
      var url = `${process.env.VUE_APP_API_URL}zoom`
      const result = await this.$http.post(
          url, {id: this.consultationId}
      )
      if (result) {
        this.zoom = result.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-device-width: 450px) {
  .consultation__call {
    width: 100%;
    height: 600px;
    border-radius: 0.78vw;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column-reverse;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;

    &__info {
      width: 100%;
      height: 100%;
      display: flex;

      &__phone {
        display: flex;
        position: absolute;
        width: 3.25vw;
        bottom: -10%;
        height: 3.25vw;
        left: 60%;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: gainsboro;
        color: gray;
      }
    }

    & {
      iframe {
        width: 100%;
      }

      ul {
        padding: 0;
        margin: 0;
        border-radius: 0 0 2vw 2vw;
        width: auto;
        height: 3rem;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        justify-items: center;
        align-content: center;
        background-color: #4d4d4d;

        & {
          li:first-child {
            -moz-border-radius-bottomleft: 2vw;
            -moz-border-radius-bottomright: 0;
            -webkit-border-top-left-radius: 2vw;
            -webkit-border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 2vw;
          }

          li:last-child {
            -moz-border-radius-bottomleft: 0;
            -moz-border-radius-bottomright: 2vw;
            -webkit-border-bottom-left-radius: 0;
            -webkit-border-bottom-right-radius: 2vw;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 2vw;
          }

          li {
            color: white;
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            justify-content: center;
            align-content: center;
            width: 50%;

            &.active {
              color: white !important;
              background-color: #0b7d75 !important;
              padding: 0 !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .consultation__call {
    width: 100%;
    height: 600px;
    border-radius: 0.78vw;
    background-color: whitesmoke;
    display: flex;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;

    &__info {
      width: 100%;
      height: 100%;
      display: flex;

      &__phone {
        display: flex;
        position: absolute;
        width: 3.25vw;
        bottom: -10%;
        height: 3.25vw;
        left: 60%;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: gainsboro;
        color: gray;
      }
    }

    & {
      iframe {
        width: 100%;
      }

      ul {
        padding: 0;
        margin: 0;
        border-radius: 0.78vw 0 0 0.78vw;
        width: 5rem;
        height: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        justify-items: center;
        align-content: center;
        background-color: #4d4d4d;

        & {
          li:first-child {
            -moz-border-radius-topleft: 0.78vw;
            -moz-border-radius-topright: 0;
            -webkit-border-top-left-radius: 0.78vw;
            -webkit-border-top-right-radius: 0;
            border-top-right-radius: 0;
            border-top-left-radius: 0.78vw;
          }

          li:last-child {
            -moz-border-radius-bottomleft: 0.78vw;
            -moz-border-radius-bottomright: 0;
            -webkit-border-bottom-left-radius: 0.78vw;
            -webkit-border-bottom-right-radius: 0;
            border-bottom-left-radius: 0.78vw;
            border-bottom-right-radius: 0;
          }

          li {
            color: white;
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            justify-content: center;
            align-content: center;
            height: 50%;

            &.active {
              background-color: #0b7d75;
              padding: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
