<template>
  <div class="About">
    <vue-title :title="nav_title"></vue-title>
    <main-layout>
      <div class="content">
        <v-navigation-history v-if="!mobileView" :tab="$t('оНас')"/>
        <v-page-title :title="$t('оКомпании')"></v-page-title>
        <p class="page-info">{{ $t('оTexme') }}</p>
        <v-block-title :title="$t('КакЭтоРаботает')"/>
        <v-how-text :mobile-view="this.mobileView"/>
        <v-block-title :title="$t('Преимущества')"/>
        <advantage :mobile-view="this.mobileView"/>
        <v-feedback/>
      </div>
      <div style="height: 1.3vw"></div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import vBlockTitle from '@/components/titles/block-title'
import vPageTitle from '@/components/titles/page-title'
import vNavigationHistory from '@/components/navigation-history'
import VHowText from '@/components/v-how-text'
import Advantage from '@/components/adv/advantage'
import vFeedback from '@/components/feedbacks/feedback'
import {handleView} from '@/utils/functions'

export default {
  name: 'About',
  components: {
    Advantage,
    VHowText,
    MainLayout,
    vBlockTitle,
    vPageTitle,
    vNavigationHistory,
    vFeedback
  },
  data: function () {
    return {
      nav_title: this.$t('оНас'),
      mobileView: true,
      showNav: false
    }
  },
  created() {
    this.mobileView = handleView()
  }
}
</script>
<style>
</style>
