<template>
  <div class="service">
    <div>
      <vue-title :title="name"/>
      <main-layout>
        <div class="profile__main">
          <left-navigation/>
          <div class="profile__main__content">
            <page-title
                :title="name"
                weight="normal"
            />
            <v-toggle @changeToggle="changeToggle"/>
            <consultations  v-show="item == 0" type="0"/>
            <consultations  v-show="item == 1" type="1"/>
            <consultations  v-show="item == 2" type="2"/>
            <consultations  v-show="item == 3" type="3"/>
          </div>
        </div>
      </main-layout>
    </div>
  </div>
</template>

<script>
import LeftNavigation from '@/components/user/left-navigation'
import MainLayout from '@/views/MainLayout'
import PageTitle from '@/components/titles/page-title'
import VToggle from '@/components/toggle/v-toggle'
import Consultations from '@/components/consultations/consultations'
import {handleView} from '@/utils/functions'

export default {
  name: 'Checkup',
  components: {
    Consultations,
    VToggle,
    PageTitle,
    LeftNavigation,
    MainLayout
  },
  data: function () {
    return {
      name: this.$t('записиНаЧекапы'),
      item: 0,
      mobileView: false
    }
  },
  async created() {
    this.mobileView = handleView()
  },
  methods: {
    changeToggle(item) {
      this.item = item
    }
  }
}
</script>

<style scoped>

</style>
