<template>
  <div class="service-filter" :class="{'mobile_filter':mobile}">
    <div v-if="title" class="filter-element filter-element-text">{{ $t('Фильтр') }}</div>
    <div class="filter-element">
      <v-select

          :list="[{id:1, title: $t('онлайнИОфллайн'),title_en: $t('онлайнИОфллайн'),title_kz: $t('онлайнИОфллайн')},
          {id:3, title:$t('онлайн'), title_en:$t('онлайн'), title_kz:$t('онлайн')},
          {id:2, title:$t('оффлайн'),title_en:$t('оффлайн'),title_kz:$t('оффлайн')}]"
          placeholder="" @change="changeRequestType" :selected="selectedItem"/>
    </div>
    <div class="filter-element">
      <v-select :selected="city_id" :placeholder="$t('Город')" @change="changeCity"/>
    </div>
    <div style="display: none!important;" class="filter-element">
      <p :class="{'mobile_price':mobile}">{{ $t('Цена') }}</p>
      <ul>
        <li>
          <label>
            <div class="float-l w85">
              {{ $t('до5') }}
            </div>
            <div class="right">
              <input name="group1" type="radio" value="val1" v-on:change="changeSum(0,5000)">
            </div>
          </label>
        </li>
        <li>
          <label>
            <div class="float-l w85">
              {{ $t('от5до10') }}
            </div>
            <div class="right">
              <input name="group1" type="radio" value="val1" v-on:change="changeSum(5000,10000)">
            </div>
          </label>
        </li>
        <li>
          <label for="gr1">
            <div class="float-l w85">
              {{ $t('от10до15') }}
            </div>
            <div class="right">
              <input id="gr1" name="group1" type="radio" value="val1" v-on:change="changeSum(10000,15000)">
            </div>
          </label>
        </li>
        <li>
          <label>
            <div class="float-l w85">
              {{ $t('от15до20') }}
            </div>
            <div class="right">
              <input name="group1" type="radio" value="val1" v-on:change="changeSum(15000,20000)">
            </div>
          </label>
        </li>
      </ul>
    </div>
    <div class="filter-element">
      <v-select
          :list="[{id:1, title:$t('Специалист'), title_en:$t('Специалист')  ,title_kz:$t('Специалист')},
          {id:2, title:$t('Клиника'),title_en:$t('Клиника'),title_kz:$t('Клиника')},
          {id:3, title: $t('чекапы'),title_en: $t('чекапы'),title_kz:$t('чекапы')}]"
          placeholder="" @change="changeType" :selected="type"/>
    </div>
  </div>
</template>

<script>
import vSelect from '../select'
import {handleView} from "@/utils/functions";

export default {
  props: ['selected', 'mobile', 'title'],
  name: 'service-filter',
  components: {vSelect},
  data: function () {
    return {
      type: '1',
      city_id:'',
      selectedItem:'',
    }
  },
  async created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    if (this.$route.query.city_id) {
      this.city_id = this.$route.query.city_id
    }
    if (this.$route.query.requestType) {
      this.selectedItem = this.$route.query.requestType
    }
    else {
      this.selectedItem=this.selected
    }




  },
  methods: {
    changeRequestType(val) {
      this.$emit('changeRequestType', val)
    },
    changeType(val) {
      this.$emit('changeType', val)
    },
    // changeCity(val) {
    //   this.$emit('changeCity', val)
    // },
    changeCity(val) {
      if (val === "1") {
        // Выполняем редирект на главную страницу, если выбран город с city_id=1 Костыль
        window.location.href = '/'; // Замените на ваш путь к главной странице
      } else {
        // В остальных случаях эмитируем событие changeCity
        this.$emit('changeCity', val);
      }
    },
    changeSum(min, max) {
      this.$emit('changeSum', min, max)
    },
  }
}
</script>

<style lang="scss" scoped>
.w85 {
  width: 85%;
}

.mobile_price {
  margin-bottom: 12px;
}

.service-filter {
  line-height: 1.3vw !important;
  top: 0;
  margin-right: 13px;
  background: #FFFFFF;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  border-radius: 0.78vw;
  font-family: Gotham, serif;
  font-size: 0.975vw !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.mobile_filter {
  font-size: 14px !important;
}

.all {
  width: 100%;
}

.filter-element-text {
  border: none;
  font-size: 0.975vw;
  font-style: normal;
  font-weight: 400;
  padding-top: 0.65vw;
  color: black;
}

.filter-element {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
  letter-spacing: -0.23999999463558197px;
  text-align: left;
  padding: 1.105vw 1.04vw;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  //margin-left: -38px;
}

li {
  padding: 9px 0;
}
</style>
