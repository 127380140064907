<template>
  <div class="cover">
    <div v-html="$t('проверкаСостояния')" class="cover-main-ad">
    </div>
    <div class="cover-main " v-bind:class="covertype">
      <div class="cover-secondary">
      </div>
    </div>
    <label class="cover__label" v-on:click="scroll">
      <div class="btn cover-main-ad-btn">{{$t('выбратьПрограмму')}}</div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'cover',
  props: ['toScroll', 'covertype'],
  methods: {
    scroll () {
      const element = document.getElementById(this.toScroll)
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-device-width: 450px) {
  .cover {
    padding-top: 8vw;
    display: flex;
    flex-direction: column;
  }

  .cover-main-ad {
    position: relative;
    font-size: 29px!important;
    font-style: normal;
    font-weight: bold;
    font-family: Gotham-Bold, sans-serif;
  }

  .cover-main {
    position: relative;
    left: -363px;
    width: 212vw;
    height: 25rem;
    background-position: center center;
    background-size: 100% 100%;
    background: url("../assets/img/cover-main.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .cover-secondary {
    position: absolute;
    width: 179%;
    height: 25rem;
    left: 14%;
    z-index: 59;
    background-size: 44% 100% !important;
    background: url("../assets/img/cover-doc.png") no-repeat;
  }
  .cover-main-ad-btn {
    margin: 10%;
    cursor: pointer !important;
    padding: 3.04vw 2.04vw;
    font-weight: bold;
    font-size: 5.4vw;
    border-radius: 1.78vw;
    line-height: 7.4vw;
    background: red;
    color: white;
  }
}

@media only screen and (min-device-width: 451px) {
  .cover {
    display: flex;
    flex-direction: inherit;
  }
  .cover__label {
    position: absolute;
    z-index: 100;
    width: 50%;
    left: 84px;
    top: 68%;
  }
  .cover-main-ad {
    position: absolute;
    left: 80px;
    text-align: left;
    z-index: 1;
    width: 40%;
    font-style: normal;
    font-weight: bold;
    font-size: 2.6vw;
    line-height: 4.16vw;
    font-family: Gotham-Bold, sans-serif;
    top: 20%;
  }
  .cover-main-checkups {
    background: url("../assets/img/cover_checkup.jpg");
    background-size: 100% 100%;
    background-size: cover;
  }
  .cover-main-records {
    background: url("../assets/img/cover_records.png");
    background-size: 100% 100%;
    background-size: cover;
  }
  .cover-main {
    position: relative;
    width: 100%;
    height: 50rem;
    background-repeat: no-repeat;
  }

  .cover-secondary {
    display: none;
    position: absolute;
    height: 50rem;
    top: 15.1%;
    width: 80%;
    left: 20%;
    background-size: 50% 50%;
    background: url("../assets/img/cover-doc.png") no-repeat;
  }

  .cover-main-ad-btn {
    padding: 1.04vw 1.56vw;
    position: absolute;
    font-weight: bold;
    font-size: 1vw;
    line-height: 1.95vw;
    top: 150%;
    background: red;
    z-index: 2;
    color: white;
  }
  .cover__label {
  }
}
</style>
