<template>
  <div class="search">
    <el-input
        :placeholder="$t('поискУслуг')"
        prefix-icon="el-icon-search"
        class="search-input"
        @input="handleInput"
        :value="value"
    >
    </el-input>
    <button class="btn-search" @click="handleClick">{{ $t('поиск') }}</button>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: ['value', 'click'],
  methods: {
    handleInput(e) {
      this.$emit('input', e)
    },
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>
<style>
.el-input__inner {
  border: none !important;
}

.el-input--prefix .el-input__inner {
  padding-left: 50px !important;
}

.el-input__inner::placeholder {
  font-size: 18px;
}

@media (max-width: 468px) {
  .el-input__inner {
    height: 32px !important;
  }
}
</style>
<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  max-width: 880px;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px #E5E5E5;
  border-radius: 12px;
  padding: 4px 24px 4px 12px;
  @media(max-width: 468px) {
    width: 100%;
    padding: 8px 16px 8px 8px;

  }

  .btn-search {
    font-family: PlumbKaz, sans-serif !important;
    border: none;
    background: #00938A;
    color: #ffffff;
    border-radius: 12px;
    padding: 7px 44px;
    font-size: 18px;
    @media(max-width: 468px) {
      padding: 7px 16px;
    }
  }
}
</style>
