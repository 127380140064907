<template>
<div class="consultation">
    <div>
      <vue-title :title="$t('Запись')+'№' + consultation"/>
      <main-layout>
        <div class="profile__main">
          <left-navigation/>
          <div class="profile__main__content">
            <page-title
                :title="$t('Запись')+'№' + consultation"
                weight="normal"
            />
            <el-button type="danger" @click="$router.go(-1)">Назад, к списку консультаций</el-button>
            <div class="consultation__header">
              <div class="consultation__header__logo">
                <div class="logo" v-bind:style="{ backgroundImage: 'url(' + consultationById.profile_image + ')' }">
                </div>
              </div>
              <div class="consultation__header__info">
                <div class="consultation__header__info__name h2">{{ consultationById.name }}</div>
                <div
                    :class="showHide ? '' : 'text-overflow-handle'"
                    class="consultation__header__info__bio"
                    @click="showHide =! showHide"
                >{{ consultationById.bio }}
                </div>
              </div>
            </div>
            <div v-if="$route.name === 'UserBookingsService'">
              <div class="consultation__analyze">
                <div class="consultation__analyze__title h2">Чат</div>
                <div v-if="checkAvailability(consultationById)">
                  <chat-call
                      v-if="zoom"
                      :channel="consultationById.channel_id"
                      :consultation-id="consultationById.id"
                      :item="type"
                      :name="name"
                      :zoom="zoom"
                      role="user"
                  />
                </div>
                <div v-else>
                  <p>Чат недоступен</p>
                </div>
              </div>
            </div>
            <div class="consultation__analyze">
              <div class="consultation__analyze__title h2">{{$t('Заключениеотврача')}}</div>
              <p>{{ consultationById.conclusion ? consultationById.conclusion :  $t('неНаписано') }}</p>
            </div>
            <div class="consultation__analyze">
              <div class="consultation__analyze__title h2">{{$t('результатыАнализов')}}</div>
              <analysis-item
                  v-for="(analyze,index) in consultationById.analyses"
                  :key="analyze.id + '' + index"
                  :name="$t('результатыАнализов')+analyze.id"
                  :url="analyze.file_url"
              />
            </div>
            <preloader v-show="loader"/>
          </div>
        </div>
      </main-layout>
    </div>
  </div>
</template>

<script>
import LeftNavigation from '@/components/user/left-navigation'
import MainLayout from '@/views/MainLayout'
import PageTitle from '@/components/titles/page-title'
import AnalysisItem from '@/components/analysis/analysis-item'
import Preloader from '@/components/preloader'
import ChatCall from '@/components/zoom/chatCall'
import { handleView } from '@/utils/functions'
// import Meeting from '@/components/zoom/Meeting'

export default {
  name: 'UserConsultationCheckup',
  components: {
    ChatCall,
    // Meeting,
    Preloader,
    AnalysisItem,
    PageTitle,
    LeftNavigation,
    MainLayout
  },
  data () {
    return {
      type: undefined,
      consultation: {},
      showHide: false,
      service: {},
      mobileView: false,
      consultationById: {},
      load: false,
      name: '',
      zoom: {}
    }
  },
  async created() {
    this.type = this.$route.query.type
    this.consultation = this.$route.params.id
    this.mobileView = handleView()
    await this.getConsultation(this.consultation)
  },
  mounted() {
  },
  methods: {
    checkAvailability (value) {
      if (value.is_approved == 1 || (value.is_approved == 2 && value.updated_at <= moment().subtract(1, 'days').format('Y-m-d'))) {
        return 1;
      } else {
        return 0;
      }
    },
    async getConsultation(id) {
      this.loader = true

      var url = `${process.env.VUE_APP_API_URL}user/consultations/checkup/get-by-id`

      const result = await this.$http.post(url, {id: id})
      const data = result.data
      if (data) {
        this.consultationById = data
        this.name = this.consultationById.name
        this.loader = false
        this.zoom = {
          conference_id: data.zoom_conference_id,
          password: data.zoom_password
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (max-device-width: 450px) {
  .consultation {
    background-color: rgba(0, 147, 138, 0.02);

    &__header {
      display: flex;
      flex-direction: row;
      padding-bottom: 5vw;
      padding-top: 5vw;

      &__logo {
        margin: 1.04vw 0;
        background-size: cover;

        & {
          .logo {
            width: 100px;
            height: 100px;
            -moz-box-shadow: 0 0 3px #ccc;
            -webkit-box-shadow: 0 0 3px #ccc;
            box-shadow: 0 0 3px #ccc;
            border-radius: 0.78vw;
            background-size: cover;
          }
        }
      }

      &__info {
        padding: 1.69vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;

        &__bio {
          line-height: 6vw;
          font-family: Gotham-Pro, sans-serif;
          font-size: 4vw !important;
        }
      }
    }

    .consultation__header__info__status,
    p {
      line-height: 6vw;
      font-size: 4vw;
      font-weight: 500;
      font-style: normal;
    }

    &__analyze {
      margin: 3.25vw 0;

      & {
        p {
          padding: 1.04vw 0;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .consultation {
    background-color: rgba(0, 147, 138, 0.02);

    &__header {
      display: flex;
      flex-direction: row;
      padding-bottom: 2.6vw;

      &__logo {
        margin: 1.04vw 0;
        background-size: cover;

        & {
          .logo {
            width: 150px;
            height: 150px;
            -moz-box-shadow: 0 0 3px #ccc;
            -webkit-box-shadow: 0 0 3px #ccc;
            box-shadow: 0 0 3px #ccc;
            border-radius: 0.78vw;
            background-size: cover;
          }
        }
      }

      &__info {
        padding: 1.69vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;

        &__bio {
          line-height: 1.69vw;
          font-family: Gotham-Pro, sans-serif;
          font-size: 1.3vw !important;
        }
      }
    }

    .consultation__header__info__status,
    p {
      line-height: 42px;
      font-size: 1.69vw;
      font-weight: 500;
      font-style: normal;
    }

    &__analyze {
      margin: 3.25vw 0;

      & {
        p {
          padding: 1.04vw 0;
        }
      }
    }
  }
}
</style>
