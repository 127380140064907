<template>
  <div class="profile">
    <vue-title :title="$t('услугиКлиники')"/>
    <main-layout>
      <div class="profile__main">
        <left-navigation type="doctor"/>
        <div class="profile__main__content">
          <page-title :title="name" weight="normal"/>
          <table class="table web">
            <tr>
              <th>{{ $t('Услуга') }}</th>
              <th>{{ $t('Описание') }}</th>
              <th align="center">{{ $t('Стоимость') }}</th>
              <th align="center">{{ $t('Действия') }}</th>
            </tr>
            <tr v-for="(service, index) in services" :key="service.id">
              <td>
                {{ $i18n.locale == 'kk' ? service.title_kz : $i18n.locale == 'ru' ? service.title : service.title_en }}
              </td>
              <td>{{
                  $i18n.locale == 'kk' ? service.description : $i18n.locale == 'ru' ? service.description : service.description_en
                }}
              </td>
              <td>{{ service.price }}</td>
              <td>
                <div v-if="service.is_archived === 0" class="text-danger pointer dropdown-border"
                     @click="disable(service.id)">
                  <span style="padding: 1rem">{{ $t('Отключить') }}</span>
                </div>
                <div v-if="service.is_archived === 1" class="text-accent pointer dropdown-border"
                     @click="enable(service.id)">
                  <span style="padding: 1rem">{{ $t('Включить') }}</span>
                </div>
              </td>
            </tr>
          </table>
          <preloader v-show="loader"/>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import LeftNavigation from '@/components/user/left-navigation'
import PageTitle from '@/components/titles/page-title'
import VPopup from '@/components/modals/v-popup'
import Calendar from '@/components/Calendar.vue'
import Preloader from '@/components/preloader'
import * as dateUtil from '@/utils/dateUtil'
import {handleView} from '@/utils/functions'

export default {
  name: 'ClinicServicesList',
  components: {
    VPopup,
    PageTitle,
    LeftNavigation,
    MainLayout,
    Calendar,
    Preloader
  },
  data: function () {
    return {
      services: [],
      loader: false,
      role: localStorage.role ? localStorage.role : null,
      name: this.$t('услугиКлиники'),
      mobileView: true,
      isTokenIsEmpty: null,
      showNav: false,
      showPopup: false,
      range: {
        start: new Date(2021, 2, 1),
        end: new Date(2021, 2, 3)
      },
      fcEvents: [],
      events: []
    }
  },
  async created() {
    this.mobileView = handleView()
    this.getAction()
  },
  methods: {
    async getAction() {
      const result = await this.$http.post(`${process.env.VUE_APP_API_URL}clinic/service-list/get`, {})
      if (result) {
        this.services = result.data
      }
    },
    async enable(idRow) {
      const result = await this.$http.post(`${process.env.VUE_APP_API_URL}clinic/service-list/enable`, {id: idRow})
      if (result) {
        this.getAction()
      }
    },
    async disable(idRow) {
      const result = await this.$http.post(`${process.env.VUE_APP_API_URL}clinic/service-list/disable`, {id: idRow})
      if (result) {
        this.getAction()
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/css/colors";

@import "../../assets/css/profile";

.table {
  width: 100%;
  box-shadow: 14px 23px 64px 53px rgb(212 217 232 / 20%);
  border-radius: 3px;
  background: white;
  padding: 1rem;
  border: 1px solid gainsboro;

  & {
    tr {
      width: 100%;
    }
  }
}

.text-center {
  text-align: center;
}

</style>
