import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import toArray from 'dayjs/plugin/toArray'
import duration from 'dayjs/plugin/duration'
import dayOfYear from 'dayjs/plugin/dayOfYear'

import 'dayjs/locale/ru'
import 'dayjs/locale/en'

dayjs.extend(localeData)
dayjs.extend(toArray)
dayjs.extend(duration)
dayjs.extend(dayOfYear)

export function getWeekdays (lang) {
  dayjs.locale(lang)
  const weekdays = dayjs.weekdaysMin()
  return [...weekdays.slice(1, 7), weekdays[0]]
}

export function getMonthNames (lang) {
  dayjs.locale(lang)
  return dayjs.monthsShort()
}

export function getYearsRange () {
  const years = []
  const startYear = 1970
  const currentYear = new Date().getFullYear()

  for (let i = startYear; i <= currentYear; i++) {
    years.push(i)
  }

  return years
}

export function getNow () {
  return dayjs(new Date()).format('MMMM YYYY')
}

export function getDaysCountInMonth (month, year) {
  return new Date(year, month + 1, 0).getDate()
}

export function getDateAsArray () {
  const today = dayjs(new Date()).format('YYYY-M-D')
  return dayjs(today).toArray()
}

export function addMonth (date) {
  const changedDate = dayjs(`${date[0]}/${date[1]}/${date[2]}`).add(2, 'month'
  )
  return dayjs(changedDate).toArray()
}

export function subMonth (date) {
  const changedDate = dayjs(`${date[0]}-${date[1]}-${date[2]}`).subtract(
    0, 'month'
  )
  return dayjs(changedDate).toArray()
}

export function formatDate (date, formatTemplate) {
  const formattedDate = dayjs(`${date[0]}-${date[1] + 1}-${date[2]}`).format(formatTemplate)
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
}

export function getDayOfYear (date) {
  let dayOfWeek
  // eslint-disable-next-line eqeqeq
  if (date[2] == 0) {
    dayOfWeek = new Date(date[0], date[1] + 1, date[2]).getDay() - 1
  } else {
    dayOfWeek = new Date(date[0], date[1], date[2]).getDay() - 1
  }

  if (dayOfWeek < 0) {
    return 6
  }
  return dayOfWeek
}
