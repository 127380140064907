<template>
  <div class="consultation">
    <div>
      <vue-title :title="$t('Запись')+'№' + consultation"/>
      <main-layout>
        <div class="profile__main">
          <left-navigation type="doctor"/>
          <div class="profile__main__content">
            <page-title :title="$t('Запись')+'№' + consultation " weight="normal"/>
            <div class="consultation__header">
              <div class="consultation__header__logo">
                <circle-img
                    :name="consultationById.name"
                    :pic="consultationById.profile_image"
                    size="sm"
                />
              </div>
              <div class="consultation__header__info">
                <div class="consultation__header__info__name h2">{{ consultationById.name }}</div>
                <span>{{ $i18n.locale=='ru'? consultationById.title : consultationById.title_en}} <span style="color:red;">
                  <template v-if="consultationById.record_type===1">{{$t('онлайн')}}</template>
                  <template v-else-if="consultationById.record_type===2">{{$t('оффлайн')}}</template>
                 </span>
                </span>
                <div
                    :class="showHide ? '' : 'text-overflow-handle'"
                    class="consultation__header__info__bio"
                    @click="showHide =! showHide"
                >{{ consultationById.bio }}
                </div>
              </div>
            </div>
            <div class="consultation__analyze">
              <div class="consultation__analyze__title h2">
                <div>{{$t('результатыАнализов')}}</div>
                <v-button v-if="editTo" size="sm btn-success text-white" :value="$t('Изменить')" @click="update"/>
              </div>
              <analyzes-download
                  v-for="(analyze,index) in consultationById.analyses"
                  :id="consultation"
                  :key="analyze.id"
                  :name="$t('результатыАнализов') + analyze.id"
                  :showUpload="index + 1 == consultationById.analyses.length"
                  :url="analyze.file_url"
                  type="clinic"
                  @uploaded="uploaded"/>
              <analyzes-download
                  v-if="!consultationById.analyses || consultationById.analyses.length == 0"
                  :id="consultation"
                  type="clinic"
                  :name="$t('новыйАнализ')"
                  url=""
                  @uploaded="uploaded"
              />
            </div>
            <div class="consultation__analyze" v-if="consultationById.user">
              <div class="consultation__analyze__title h2">
                <div>{{$t('карточкаПациента')}}</div>
              </div>
              <analyzes-download
                  :userAnalyze="true"
                  v-for="(analyze, index) in consultationById.user.analyses_from_user"
                  :id="consultation"
                  :key="analyze.id"
                  :name="analyze.filename"
                  :url="analyze.file_url"
                  @uploaded="uploaded"
              />
            </div>
            <preloader v-show="loader"/>
          </div>
        </div>
      </main-layout>
    </div>
  </div>
</template>

<script>
import LeftNavigation from '@/components/user/left-navigation'
import MainLayout from '@/views/MainLayout'
import PageTitle from '@/components/titles/page-title'
import Preloader from '@/components/preloader'
import ChatCall from '@/components/zoom/chatCall'
import CircleImg from '@/components/img/circle-img'
import AnalyzesDownload from '@/components/analysis/analyzes-download'
import VButton from '@/components/btn/v-button'
import {handleView} from '@/utils/functions'

export default {
  components: {
    VButton,
    AnalyzesDownload,
    CircleImg,
    ChatCall,
    Preloader,
    PageTitle,
    LeftNavigation,
    MainLayout
  },
  data() {
    return {
      editTo: false,
      type: undefined,
      consultation: {},
      showHide: false,
      role: 'user',
      service: {},
      mobileView: false,
      consultationById: {
        user: {
          analyses_from_user: []
        }
      }, load: false,
      name: '',
      zoom: {},
      user: localStorage.user ? JSON.parse(localStorage.user) : null
    }
  },
  name: 'ClinicConsultation',
  async created() {
    this.mobileView = handleView()
    this.type = Number.parseInt(this.$route.query.type)
    this.consultation = this.$route.params.id
    this.name = this.consultationById.name
    await this.getConsultation(this.consultation)
  },
  methods: {
    edit() {
      this.editTo = !this.editTo
    },
    async update() {
      var url = `${process.env.VUE_APP_API_URL}clinic/bookings/conclusion/edit`
      const result = await this.$http.post(url, {
        id: this.consultation,
        conclusion: this.$refs.conc.value
      })
      const data = result.data
      if (data) {
        this.editTo = !this.editTo
      }
    },
    uploaded(result) {
      this.consultationById.analyses.push({
        name: result.file_url,
        id: 'new'
      })
    },
    async getConsultation(id) {
      this.loader = true
      var url = `${process.env.VUE_APP_API_URL}clinic/bookings/get-by-id`
      const result = await this.$http.post(url, {id: id})
      const data = result.data
      if (data) {
        this.consultationById = data
        this.loader = false
        this.zoom = {
          conference_id: data.zoom_conference_id,
          password: data.zoom_password,
          name: this.user.name
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-device-width: 450px) {
  .consultation {
    background-color: rgba(0, 147, 138, 0.02);

    &__header {
      display: flex;
      flex-direction: row;
      padding-bottom: 3.6vw;

      &__logo {
        margin: 1.04vw 0;

        & {
          .logo {
            width: 150px;
            height: 150px;
            filter: drop-shadow(0 0 0.13vw rgba(0, 0, 0, 0.08), revert), drop-shadow(0 0.13vw 1.56vw rgba(0, 0, 0, 0.08), revert);
            border-radius: 0.78vw;
            background-size: 100% 100%;
          }
        }
      }

      &__info {
        padding: 1.69vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;

        &__bio {
          padding: 6px 0;
          line-height: 6vw;
          font-family: Gotham-Pro, sans-serif;
          font-size: 4vw !important;
        }
      }
    }

    .consultation__header__info__status,
    p {
      line-height: 6vw;
      font-size: 4vw;
      font-weight: 500;
      font-style: normal;
    }

    &__analyze {
      margin: 3.25vw 0;

      &__title {
        padding: 3.04vw 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      & {
        p {
          padding: 2.04vw 0;
        }
      }
    }

    textarea {
      margin: 1.04vw 0;
      width: 100%;
      line-height: 42px;
      font-size: 4vw;
      font-weight: 500;
      font-style: normal;
      box-sizing: border-box;
      min-height: 300px;
      height: 100%;
      resize: none;
      font-family: Gotham, sans-serif;
      background-color: rgba(0, 147, 138, 0.02);
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .consultation {
    background-color: rgba(0, 147, 138, 0.02);

    &__header {
      display: flex;
      flex-direction: row;
      padding-bottom: 2.6vw;

      &__logo {
        margin: 1.04vw 0;

        & {
          .logo {
            width: 150px;
            height: 150px;
            filter: drop-shadow(0px 0px 0.13vw rgba(0, 0, 0, 0.08), revert), drop-shadow(0px 0.13vw 1.56vw rgba(0, 0, 0, 0.08), revert);
            border-radius: 0.78vw;
            background-size: 100% 100%;
          }
        }
      }

      &__info {
        padding: 1.69vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;

        &__bio {
          padding: 6px 0;
          line-height: 1.625vw;
          font-family: Gotham-Pro, sans-serif;
          font-size: 1.3vw !important;
        }
      }
    }

    .consultation__header__info__status,
    p {
      line-height: 42px;
      font-size: 1.69vw;
      font-weight: 500;
      font-style: normal;
    }

    &__analyze {
      margin: 3.25vw 0;

      &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      & {
        p {
          padding: 1.04vw 0;
        }
      }
    }

    textarea {
      margin: 1.04vw 0;
      width: 100%;
      line-height: 42px;
      font-size: 1.69vw;
      font-weight: 500;
      font-style: normal;
      box-sizing: border-box;
      min-height: 300px;
      height: 100%;
      resize: none;
      font-family: Gotham, sans-serif;
      background-color: rgba(0, 147, 138, 0.02);
    }
  }
}
</style>
