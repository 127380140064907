<template>
  <div class="advantage">
    <div class="advantage-card">
      <v-advantage-item
        :content="$t('вЛюбоеВремяСуток')"
        :header="$t('медицинаВКармане')"
        img="healthcare.svg"/>
      <v-advantage-item
        :content="$t('ОбследованиеДешевле')"
        :header="$t('ценаЦенаЦена')"
        img="money-bag.svg"/>
      <v-advantage-item
        :content="$t('покупаяПакет')"
        :header="$t('НеизменностьЦены')"
        img="budget.svg"/>
      <v-advantage-item
        :content="$t('мыСотрудничаем')"
        :header="$t('ПартнерыДоказавшие')"
        img="professionals.svg"/>
      <v-advantage-item
        :content="$t('вСлучае')"
        :header="$t('МыНеоставляем')"
        img="customer-support.svg"/>
    </div>
    <div v-if="mobileView">
      <ul id="carouselDots" class="dots">
        <li option="0" selected></li>
        <li option="1"></li>
        <li option="2"></li>
        <li option="3"></li>
      </ul>
    </div>
  </div>
</template>

<script>
import VAdvantageItem from '@/components/adv/v-advantage-item'

export default {
  name: 'advantage',
  components: { VAdvantageItem },
  props: ['mobileView']
}
</script>

<style scoped>
@media only screen and (max-device-width: 450px) {
  .advantage-card {
    box-shadow: 0.91vw 1.495vw 4.16vw 3.445vw rgba(212, 217, 232, 0.2);
    border-radius: 0.78vw;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

@media only screen and (min-device-width: 451px) {
  .advantage-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

</style>
