import { render, staticRenderFns } from "./sm-doctor-info.vue?vue&type=template&id=47c3f769&scoped=true&"
import script from "./sm-doctor-info.vue?vue&type=script&lang=js&"
export * from "./sm-doctor-info.vue?vue&type=script&lang=js&"
import style0 from "./sm-doctor-info.vue?vue&type=style&index=0&id=47c3f769&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47c3f769",
  null
  
)

export default component.exports