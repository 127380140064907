<template>
  <div class="profile">
    <v-popup v-show="message" close-position="text-accent" @closePopup="closeInfoPopup">
      <div class="h2">
        <span class="center" v-html="message"></span>
        <div class="right">
          <v-button size="btn btn-success text-white m-1" value="Да" @click="cancelConsultation"/>
          <v-button size="btn text m-1 " value="Oтмена" @click="closeInfoPopup"/>
        </div>
      </div>
    </v-popup>
    <vue-title :title="$t('cотрудники')"/>
    <main-layout>
      <div class="profile__main">
        <left-navigation type="doctor"/>
        <div class="profile__main__content">
          <page-title :title="$t('cотрудники')" weight="normal"/>
          <br/>
          <!--          <v-toggle :options="[{id:1, title:'Текущие'}]"-->
          <!--          @changeToggle="changeToggle"/>-->
          <br/>
          <preloader v-show="loading"/>
          <table v-if="!loading" class="table web">
            <tr>
              <th>{{$t('ФИО')}}</th>
              <th>Email</th>
              <th>{{ $t('ДатаРегистрации') }}</th>
              <!--                    {id:2, title:'Открепленные'}-->
              <!--              <th>Действия</th>-->
            </tr>
            <tr v-for="d in doctors" :key="d.id">
              <td>{{ $i18n.locale=='kk'?d.name_kz:$i18n.locale=='ru'?d.name:d.name_en }}</td>
              <td>{{ d.email }}</td>
              <td>{{ format_date(d.created_at) }}</td>
              <td class="text-center">
                <!--                <div class="dropdown">-->
                <!--                  <img class="dropbtn" src="../../assets/icons/more.svg"/>-->
                <!--                  <div class="dropdown-content">-->
                <!--                    <div @click="unpin(d.id)" class="pointer">Открепить</div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </td>
            </tr>
          </table>
          <div v-for="d in doctors" :key="d.id" class="mobile clinic-card">
            <div class="clinic-card__row">
              <div class="clinic-card__col">
                <div class="bold">{{$t('ФИО')}}</div>
                <div class="sm-text">{{  $i18n.locale=='kk'?d.name_kz:$i18n.locale=='ru'?d.name:d.name_en  }}</div>
              </div>
              <div class="clinic-card__col">
                <div class="bold">E-mail</div>
                <div class="sm-text">{{ d.email }}</div>
              </div>
            </div>
            <div class="clinic-card__row">
              <div class="col">
                <div class="bold">{{ $t('ДатаРегистрации') }}</div>
                <div class="sm-text">{{ format_date(d.created_at) }}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import LeftNavigation from '@/components/user/left-navigation'
import axios from 'axios'
import moment from 'moment'
// import VToggle from '@/components/toggle/v-toggle'
import Preloader from '@/components/preloader'
import PageTitle from '@/components/titles/page-title'
import VPopup from '@/components/modals/v-popup'
import VButton from '@/components/btn/v-button'
import {cancelAlert, cancelOrder, handleView} from '@/utils/functions'

export default {
  name: 'Staff',
  components: {
    VButton,
    VPopup,
    PageTitle,
    Preloader,
    // VToggle,
    LeftNavigation,
    MainLayout
  },
  data: function () {
    return {
      changeImg: '',
      message: '',
      mobileView: true,
      doctors: [],
      isTokenIsEmpty: null,
      showNav: false,
      doctor: JSON.parse(localStorage.user),
      url: '',
      loading: false
    }
  },
  async created() {
    this.mobileView = handleView()
    await this.getDoctors()
  },
  methods: {
    getDate(date) {
      const months = {
        '01': this.$t('Января'),
        '02': this.$t('Февраля'),
        '03': this.$t('Марта'),
        '04': this.$t('Апреля'),
        '05': this.$t('Мая'),
        '06': this.$t('Июня'),
        '07': this.$t('Июля'),
        '08': this.$t('Августа'),
        '09': this.$t('Сентября'),
        '10': this.$t('Октября'),
        '11': this.$t('Ноября'),
        '12': this.$t('Декабря')
      }
      return date.slice(date.length - 2) + ' ' + months[date.slice(5, 7)] + ', ' + date.slice(0, 4)
    },
    closeInfoPopup() {
      this.message = ''
      this.id = ''
      this.action = ''
    },
    confirm() {
      this.message = cancelAlert('запись')
    },
    unpin(id) {
      this.confirm()
    },
    format_date(value) {
      if (value) {
        return this.getDate(moment(String(value)).format('YYYY-MM-DD')) + ' ' + moment(String(value)).format('HH:mm')
      }
    },
    changeToggle() {
    },
    async getDoctors() {
      this.loading = true
      var url = `${process.env.VUE_APP_API_URL}clinic/doctors/get`
      const result = await this.$http.post(
          url, {}
      )
      const data = result.data.map(item => {
        return item
      })
      if (data) {
        this.doctors = data
        this.loader = false
      }
      this.loading = false
    },
    async cancelConsultation() {
      const result = await this.$http.post(
          `${process.env.VUE_APP_API_URL}user/consultations/cancel`,
          {
            id: this.consultation.id
          }
      )
      if (result) {
        this.removed = true
        this.message = cancelOrder()
      }
    },
    async uploadAvatar(event) {
      this.changeImg = 'load'
      var file = event.target.files[0]
      this.url = URL.createObjectURL(file)
      var formData = new FormData()
      formData.append('avatar', file)
      await axios.post(`${process.env.VUE_APP_API_URL}clinic/profile/upload-avatar`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function () {
      })
          .catch(function () {
          })
      this.updateProfile()
      this.changeImg = ''
    },
    fileUpload() {
      this.$refs.myFile.click()
    },
    scroll() {
      const element = document.getElementById('feedback-partner')
      element.scrollIntoView({behavior: 'smooth'})
    },
    async updateProfile() {
      var urlProfile = `${process.env.VUE_APP_API_URL}user/profile`
      // eslint-disable-next-line eqeqeq
      var role = localStorage.getItem('role')
      // eslint-disable-next-line eqeqeq
      if (role == 'clinic') {
        urlProfile = 'api/clinic/profile'
      }
      // eslint-disable-next-line eqeqeq
      if (role == 'doctor') {
        urlProfile = 'api/doctor/profile'
      }
      const user = (await axios(urlProfile, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      })).data
      localStorage.setItem('user', JSON.stringify(user))
    }
  }
}
</script>

<style lang="scss" scoped>

@media only screen and (max-width: 450px) {
  .col {
    width: 50%;
  }
  .row {
    line-height: 25px;
  }
  .sm-text {
    font-size: 12px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }
}

@import "../../assets/css/colors";
@import "../../assets/css/profile";

.table {
  width: 100%;
  box-shadow: 14px 23px 64px 53px rgb(212 217 232 / 20%);
  border-radius: 3px;
  background: white;
  padding: 1rem;
  border: 1px solid gainsboro;

  & {
    tr {
      width: 100%;
      line-height: 5vh;
    }
  }
}

.clinic-card {
  margin: 16px 0;
  line-height: 20px;
  padding: 16px;
  font-size: 14px;
  background: #FFFFFF;
  box-shadow: 14px 23px 64px 53px rgba(212, 217, 232, 0.2);
  border-radius: 3.52941px;
  border: 1px solid gainsboro;

  &__row {
    display: flex;
    flex-direction: row;
    line-height: 30px;
    padding: 10px 0;
  }

  &__col {
    line-height: 20px;
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
  }

  & {
    .dropbtn {
      width: 5px;
    }
  }
}

.pointer {
  cursor: pointer;
}

.right {
  display: flex;
  flex-direction: row-reverse;
}
</style>
