<template>
  <div style="display: flex; height: 100vh; overflow: hidden">

    <div style="margin: auto;     border: 1px solid #DCDFE6; padding: 1rem">
      <el-input style="margin-bottom: 1rem;    border: gainsboro 0.065vw solid; " placeholder="Введите новый пароль"
                v-model="password" show-password></el-input>
      <el-input style="margin-bottom: 1rem;    border: gainsboro 0.065vw solid; " placeholder="Повторите новый пароль"
                v-model="password_repeat" show-password></el-input>
      <el-button @click="changePassword" style="    background-color: #00938A;" type="success">Сменить пароль
      </el-button>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "reset-password",
  data() {
    return {
      token: '',
      password: '',
      password_repeat: ''
    }
  },
  mounted() {
    this.token = this.$route.query.token
    console.log(this.token)
  },
  methods: {

    changePassword: function () {
      if (this.password == this.password_repeat) {
        if (this.password.length >= 4) {
          axios.post(`${process.env.VUE_APP_API_URL}user/restore-by-code`, {
            password: this.password,
            password_conf: this.password_repeat,
            token: this.token,
            device_name: 'web'
          }).then(
              async response => {
                const url = process.env.VUE_APP_API_URL
                const accessToken = response.data.token
                const role = response.data.role
                localStorage.setItem('accessToken', accessToken)
                localStorage.setItem('role', role)
                axios.defaults.headers.common.Token = 'Bearer ' + accessToken
                axios.defaults.headers.common.xAccessToken = 'Bearer ' + accessToken
                axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken

                var urlProfile = `${url}user/profile`

                if (role == 'clinic') {
                  urlProfile = `${url}clinic/profile`
                } else if (role == 'doctor') {
                  urlProfile = `${url}doctor/profile`
                }
                const user = (await axios(urlProfile, {
                  method: 'POST',
                  mode: 'no-cors',
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                  },
                  credentials: 'same-origin'
                })).data
                localStorage.setItem('user', JSON.stringify(user))
                await this.$router.push('/')
              }
        )
        } else {
          alert('Минимальная длина 4 символа')
        }
      } else {
        alert('Разные пароли')
      }
    }
  }
}
</script>
<style>
body {
  margin: 0;
}
</style>
