<template>
  <div class="v-toggle">
    <ul v-if="options" class="tab_container">
      <li
          v-for="(option) in options"
          :key="option.id"
          :class="item == option.id ? 'active' : ''"
          @click="changeToggle(option.id)"
      >
        {{ option.title }}
      </li>
    </ul>
    <ul v-if="!options" class="tab_container">
      <li :class="item == 0 ? 'active' : ''" @click="changeToggle(0)">{{ $t('Ожидает') }}</li>
      <li :class="item == 1 ? 'active' : ''" @click="changeToggle(1)">{{ $t('Текущие') }}</li>
      <li :class="item == 2 ? 'active' : ''" @click="changeToggle(2)">{{ $t('Завершенные') }}</li>
      <li :class="item == 3 ? 'active' : ''" @click="changeToggle(3)">{{ $t('Отмененные') }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'v-toggle',
  props: ['options'],
  data: function () {
    return {
      item: 0
    }
  },
  methods: {
    changeToggle(item) {
      this.item = item
      this.$emit('changeToggle', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-device-width: 450px) {

  .v-toggle {
    margin: 4vw 0;
    width: 100%;

    & {
      ul {
        box-shadow: 0px 0px 0.13vw rgba(0, 0, 0, 0.08), 0px 0.13vw 1.56vw rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        background: white;
        width: 95%;
        list-style-type: none;
        display: flex;;
        flex-direction: column;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        //overflow-x: hidden;
        text-overflow: ellipsis;

        & {
          li {
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 5vw;
            line-height: 7vw;
            font-family: Gotham-Pro, sans-serif;
            cursor: pointer;
            //padding: 0.65vw;
            padding: 4px 12px;
            border-radius: 3px;
            //margin: 0.13vw;
            width: 100%;
          }
        }
      }
    }
  }
  .tab_container {
    user-select: auto;
    width: 100% !important;
    overflow-y: auto;
    padding-bottom: 12px !important;
  }
  .active {
    color: white !important;
    //background: #00938A !important;
    background-color: #00938A !important;
  }
}

@media only screen and (min-device-width: 451px) {
  .v-toggle {
    margin-bottom: 1.04vw;

    & {
      ul {
        box-shadow: 0px 0px 0.13vw rgba(0, 0, 0, 0.08), 0px 0.13vw 1.56vw rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        background: white;
        width: fit-content;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;

        & {
          li {
            font-size: 1.3vw;
            font-family: Gotham-Pro, sans-serif;
            cursor: pointer;
            padding: 0.65vw 1.95vw;
            border-radius: 3px;
            margin: 0.13vw;
            width: auto;
          }
        }
      }
    }
  }
}
</style>
