<template>
  <div class="checkup">
    <div class="row">
      <v-service-title v-if="!mobile" :checkup_id="checkup_id" size="sm" @change="sortBy" @click="changeType(3)"/>
    </div>
    <v-popup v-show="filter" :value="$t('Фильтр')" @closePopup="filter=false">
      <div class="mob_filter">
        <div v-if="mobile" class="col-12 top">
          <v-service-filter :mobile="true" selected="1" @changeRequestType="changeRequestType" @changeCity="changeCity"
                            @changeSum="changeSum"
                            @changeType="changeType"/>
        </div>
      </div>
    </v-popup>
    <div class="mobile_filter_btn">
      <v-button v-if="mobileView" fa-icon="sliders-h" size="success red long" :value="$t('Фильтр')" @click="filter=true"/>
    </div>
    <div class="checkup__filter">

      <div v-if="!mobile" class="col-2 top">
        <v-service-filter selected="1" @changeRequestType="changeRequestType" @changeCity="changeCity"
                          @changeSum="changeSum" @changeType="changeType"/>
      </div>
      <div class="checkup__content">

        <div v-if="type==1">
          <service-item v-for="(service) in services" v-show="type==1" :key="service.id"
                        :mobile="mobileView"
                        :service="service"/>
        </div>
        <div v-if="type==2 && !checkup_id" class="clinic_container">
          <clinic-item v-for="(service) in services" v-show="type==2" :key="service.id" :clinic="service"
                       :mobile="mobileView"/>
        </div>
        <div v-if="type==2&&checkup_id">
          <clinic-item-2 v-for="(service) in services" v-show="type==2" :key="service.id" :checkupid="checkup_id"
                         :clinic="service" :mobile="mobileView"/>
        </div>
        <div v-if="type==3 || !type || type==null">
          <checkup-item v-for="(service) in services" v-show="type==3||type==null" :key="service.id" :checkup="service"
                        :mobile="mobileView" @selectClinic="selectClinic"/>
        </div>
        <preloader v-show="load"/>
      </div>
    </div>
  </div>
</template>

<script>
import vServiceFilter from '@/components/checkups/service-filter'
import vServiceTitle from '@/components/titles/service-title'
import CheckupItem from '@/components/checkups/checkup-item'
import Preloader from '@/components/preloader'
import ClinicItem from '@/components/clinic/clinic-item'
import ServiceItem from '@/components/services/service-item'
import ClinicItem2 from '@/components/clinic/clinic-item-2'
import {handleView} from '@/utils/functions'
import VPopup from '@/components/modals/v-popup'
import VButton from '@/components/btn/v-button'

export default {
  name: 'service',
  components: {
    ClinicItem2,
    ServiceItem,
    ClinicItem,
    Preloader,
    CheckupItem,
    vServiceFilter,
    VPopup,
    VButton,
    vServiceTitle
  },
  data: function () {
    return {
      filter: false,
      services: [],
      checkup_id: undefined,
      RequestType: '3',
      service: '',
      data: {},
      mobileView: false,
      load: false,
      filterQuery: {
        search_query: ''
      },
      startTimer: false,
      type: 1
    }
  },
  async created() {
    if (this.$route.query) {
      this.filterQuery = this.$route.query

    }
    if (this.$route.query.requestType) {
      this.RequestType = this.$route.query.requestType

    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }

    await this.getService()
    this.mobileView = handleView()
    this.scroll()
  },
  props: ['viewIndex', 'mobile', 'search'],
  methods: {
    changeRequestType(val) {
      if (val == 1) {
        this.RequestType = "3"
      } else if (val == 3) {
        this.RequestType = "1"
      } else {
        this.RequestType = val
      }

      this.services = []
      this.getService()
    },
    selectClinic(val) {
      this.checkup_id = val
      this.services = []
      this.getService()
    },
    changeType(even) {
      this.checkup_id = null
      this.type = even
      this.services = []
      this.getService()
    },
    changeSum(min, max) {
      this.filterQuery.price_from = min
      this.filterQuery.price_to = max
      this.callAfterSort()
    },
    changeCity(even) {
      this.filterQuery.city_id = even
      this.callAfterSort()
    },
    sortBy(even) {
      this.filterQuery.sort_by = even
      this.callAfterSort()
    },
    callAfterSort() {
      if (!this.startTimer) {
        this.startTimer = true
        setTimeout(() => {
          this.services = []
          this.getService()
          this.startTimer = false
        }, 3000)
      }
    },
    clear() {
      this.getService('', true)
    },
    async getService(url = '', clear = false,) {
      console.log(this.$route.query)
      url = url.replace('http://', 'https://')
      // this.services = []
      var textType = 'services'
      // eslint-disable-next-line eqeqeq
      if (this.type == 1) {
        textType = 'services'
        this.filterQuery.requestType = this.RequestType
        // eslint-disable-next-line eqeqeq
      } else if (this.type == 2) {
        textType = 'clinics'
        // eslint-disable-next-line eqeqeq
      } else if (this.type == 3 || this.type == null) {
        textType = 'checkups'
        if (this.checkup_id) {
          textType = 'checkups/get-clinics'
          this.filterQuery.id_checkup = this.checkup_id
        }
      }
      this.load = true
      this.filterQuery.search_query = this.search
      this.filterQuery.locale = this.$i18n.locale

      if (history.pushState) {
        window.history.pushState({}, document.title, window.location.pathname);
        let filterQuery = this.filterQuery
        filterQuery.type = this.type
        var baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        var newUrl = baseUrl + '?' + (new URLSearchParams(filterQuery)).toString();
        history.pushState(null, null, newUrl);
      } else {
        console.warn('History API не поддерживается');
      }


      const result = await this.$http.post(
          url ? url : `${process.env.VUE_APP_API_URL}web/` + textType,
          this.filterQuery
      )
      this.data = result.data
      var data = null
      if (clear) {
        this.services = []
      }
      if (this.checkup_id) {
        data = result.data.map(item => {
          this.services.push(item)
          this.type = 2
          return item
        })
      } else {
        data = result.data.data.map(item => {
          this.services.push(item)
          return item
        })
      }
      if (data) {
        this.load = false
      }
    },
    scroll() {
      window.onscroll = () => {
        var bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 400 > document.documentElement.offsetHeight
        if (bottomOfWindow && !this.load) {
          if (this.data.next_page_url) {
            this.getService(this.data.next_page_url)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.red{
  background-color: red!important;
}
.mobile_filter_btn {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mob_filter {
  height: 40vh;

  & {
    .form-control {
      padding: 2vw 0;
    }
  }

  width: 100%;
  display: flex;
  flex-direction: column;
}

@import "../../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .mobile_filter_btn {
    display: flex;
  }
  .clinic_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
  }
  .checkup {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &__content {
      width: 100% !important;
    }

    &__filter {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  .service-item-cell_center {
    width: 100%;
  }
}

@media only screen and (min-device-width: 451px) {
  .checkup {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    &__content {
      width: 85% !important;
    }

    &__filter {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }
}

.top {
  vertical-align: top;
}
</style>
