<template>
  <div class="consultation">
    <div>
      <vue-title :title="$t('Запись')+'№' + consultation"/>
      <main-layout>
        <div class="profile__main">
          <left-navigation/>
          <div class="profile__main__content">
            <page-title
                :title="$t('Запись')+'№' + consultation"
                weight="normal"
            />
            <el-button type="danger" @click="$router.go(-1)">{{ $t('Назад') }}</el-button>
            <div class="consultation__header">
              <div class="consultation__header__logo">
                <!--                <div class="logo" v-bind:style="{ backgroundImage: 'url(' + consultationById.profile_image + ')' }">-->
                <circle-img :name="consultationById.name" :pic="consultationById.profile_image"/>

                <!--                </div>-->
              </div>
              <div class="consultation__header__info">
                <div class="consultation__header__info__name h2">{{
                    $i18n.locale == 'kk' ? consultationById.name_kz : $i18n.locale == ru ? consultationById.name : consultationById.name_en
                  }}
                </div>
                <div
                    :class="showHide ? '' : 'text-overflow-handle'"
                    class="consultation__header__info__bio"
                    @click="showHide =! showHide"
                >{{
                    $i18n.locale == 'kk' ? consultationById.bio_kz : $i18n.locale == 'ru' ? consultationById.bio : consultationById.bio_en
                  }}
                </div>
              </div>
            </div>
            <!--              <div>$route.name</div>-->

            <div v-if="$route.name === 'UserConsultationService'">

              <!--            <div v-if="$route.name === 'UserConsultationService'">-->
              <div class="consultation__analyze">
                <div class="consultation__analyze__title h2">{{ $t('chat') }}</div>
                <div v-if="checkAvailability(consultationById)">
                  <chat-call
                      v-if="zoom"
                      :channel="consultationById.channel_id"
                      :consultation-id="consultationById.id"
                      :item="type"
                      :name="name"
                      :zoom="zoom"
                      role="user"
                  />
                </div>
                <div v-else>
                  <p>{{ $t('ЧатНедоступен') }}</p>
                </div>
              </div>
            </div>
            <div class="consultation__analyze">
              <div class="consultation__analyze__title h2">{{ $t('Заключениеотврача') }}</div>
              <p>{{ consultationById.conclusion ? consultationById.conclusion : $t('неНаписано') }}</p>
            </div>
            <div class="consultation__analyze">
              <div class="consultation__analyze__title h2">{{ $t('результатыАнализов') }}</div>
              <analysis-item
                  v-for="(analyze,index) in consultationById.analyses"
                  :key="analyze.id + '' + index"
                  :name="$t('результатыАнализов')+analyze.id"
                  :url="analyze.file_url"
              />
            </div>
            <div class="consultation__analyze">
              <div class="consultation__analyze__title h2">
                <div>{{ $t('ОставьтеОтзыв') }} <img src="../../assets/icons/ant-design_edit-filled.svg" @click="edit"/>
                </div>
              </div>
              <p v-if="!editTo">
                {{ consultationById.feedback_from_user ? consultationById.feedback_from_user : $t('ОтзываНет') }}
              </p>
              <textarea
                  v-if="editTo"
                  ref="conc"
                  rows="3"
                  cols="100"
                  v-model="consultationById.feedback_from_user"
              ></textarea>
              <v-button v-if="editTo" size="sm btn-success text-white" :value="$t('Изменить')" @click="update"/>
            </div>
            <preloader v-show="loader"/>
          </div>
        </div>
      </main-layout>
    </div>
  </div>
</template>

<script>
import LeftNavigation from '@/components/user/left-navigation'
import MainLayout from '@/views/MainLayout'
import PageTitle from '@/components/titles/page-title'
import AnalysisItem from '@/components/analysis/analysis-item'
import Preloader from '@/components/preloader'
import ChatCall from '@/components/zoom/chatCall'
import {handleView} from '@/utils/functions'
// import Meeting from '@/components/zoom/Meeting'
import VButton from '@/components/btn/v-button'
// import { JitsiMeet } from '@mycure/vue-jitsi-meet';
import moment from "moment";
import CircleImg from '@/components/img/circle-img'

export default {
  name: 'UserConsultationService',
  components: {
    ChatCall,
    // Meeting,
    Preloader,
    VButton,
    AnalysisItem,
    PageTitle,
    LeftNavigation,
    MainLayout,
    CircleImg,
    // VueJitsiMeet: JitsiMeet

  },
  computed: {
    // jitsiOptions () {
    //   return {
    //     roomName: 'Запись №' + this.consultation,
    //     noSSL: false,
    //     userInfo: {
    //       email: 'user@email.com',
    //       displayName: '',
    //     },
    //     configOverwrite: {
    //       enableNoisyMicDetection: false
    //     },
    //     interfaceConfigOverwrite: {
    //       SHOW_JITSI_WATERMARK: false,
    //       SHOW_WATERMARK_FOR_GUESTS: false,
    //       SHOW_CHROME_EXTENSION_BANNER: false
    //     },
    //     onload: this.onIFrameLoad
    //   };
    // },
  },
  data() {
    return {
      editTo: false,
      type: undefined,
      consultation: {},
      showHide: false,
      service: {},
      mobileView: false,
      consultationById: {
        channel_id: ''
      },
      load: false,
      name: '',
      zoom: {}
    }
  },
  async created() {
    this.type = this.$route.query.type
    this.consultation = this.$route.params.id
    this.mobileView = handleView()
    await this.getConsultation(this.consultation)


  },
  mounted() {
  },
  methods: {
    onIFrameLoad() {
      // do stuff
    },
    edit() {
      this.editTo = !this.editTo
    },
    async update() {
      var url = `${process.env.VUE_APP_API_URL}user/consultations/service/post-feedback`
      const result = await this.$http.post(url, {
        id: this.consultation,
        feedback: this.$refs.conc.value
      })
      const data = result.data
      if (data) {
        this.editTo = !this.editTo
      }
    },
    checkAvailability(value) {
      if (value.is_approved == 1 || (value.is_approved == 2 && value.updated_at <= moment().subtract(1, 'days').format('Y-m-d'))) {
        return 1;
      } else {
        return 0;
      }
      // return 1;
    },
    async getConsultation(id) {
      this.loader = true

      var url = `${process.env.VUE_APP_API_URL}user/consultations/service/get-by-id`

      const result = await this.$http.post(url, {id: id})
      const data = result.data
      if (data) {
        this.consultationById = data
        this.name = this.consultationById.name
        this.loader = false
        this.zoom = {
          conference_id: data.zoom_conference_id,
          password: data.zoom_password
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (max-device-width: 450px) {
  .consultation {
    background-color: rgba(0, 147, 138, 0.02);

    &__header {
      display: flex;
      flex-direction: row;
      padding-bottom: 5vw;
      padding-top: 5vw;

      &__logo {
        margin: 1.04vw 0;
        background-size: cover;

        & {
          .logo {
            width: 100px;
            height: 100px;
            -moz-box-shadow: 0 0 3px #ccc;
            -webkit-box-shadow: 0 0 3px #ccc;
            box-shadow: 0 0 3px #ccc;
            border-radius: 0.78vw;
            background-size: cover;
          }
        }
      }

      &__info {
        padding: 1.69vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;

        &__bio {
          line-height: 6vw;
          font-family: Gotham-Pro, sans-serif;
          font-size: 4vw !important;
        }
      }
    }

    .consultation__header__info__status,
    p {
      line-height: 6vw;
      font-size: 4vw;
      font-weight: 500;
      font-style: normal;
    }

    &__analyze {
      margin: 3.25vw 0;

      & {
        p {
          padding: 1.04vw 0;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .consultation {
    background-color: rgba(0, 147, 138, 0.02);

    &__header {
      display: flex;
      flex-direction: row;
      padding-bottom: 2.6vw;

      &__logo {
        margin: 1.04vw 0;
        background-size: cover;

        & {
          .logo {
            width: 150px;
            height: 150px;
            -moz-box-shadow: 0 0 3px #ccc;
            -webkit-box-shadow: 0 0 3px #ccc;
            box-shadow: 0 0 3px #ccc;
            border-radius: 0.78vw;
            background-size: cover;
          }
        }
      }

      &__info {
        padding: 1.69vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;

        &__bio {
          line-height: 1.69vw;
          font-family: Gotham-Pro, sans-serif;
          font-size: 1.3vw !important;
        }
      }
    }

    .consultation__header__info__status,
    p {
      line-height: 42px;
      font-size: 1.69vw;
      font-weight: 500;
      font-style: normal;
    }

    &__analyze {
      margin: 3.25vw 0;

      & {
        p {
          padding: 1.04vw 0;
        }
      }
    }
  }
}
</style>
