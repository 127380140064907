<template>
  <div id="slider-container-transition" class="v-advantage-item">
    <img class="v-advantage-item-logo" height="124" v-bind:src="require('@/assets/img/'+img)" width="124"/>
    <div class="v-advantage-item-title h2">
      {{ header }}
    </div>
    <p class="v-advantage-item-content">
      {{ content }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'v-advantage-item',
  props: ['img', 'header', 'content']
}
</script>
<style scoped>
@media only screen and (max-device-width: 450px) {
  .v-advantage-item {
    scroll-snap-align: start;
    flex-basis: 80%;
    border-radius: 1.78vw;
    background-color: white;
    padding: 10vw 8vw 6vw;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .v-advantage-item-content {
    font-family: Gotham, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 4vw;
    line-height: 6vw;
    color: #000000;
  }
  .v-advantage-item {
    min-width: 100%;
  }

  .v-advantage-item-title {
    padding-bottom: 5vw;
  }

  .v-advantage-item-logo {
    padding-bottom: 29px;
  }
  #slider-container-transition {
    transition: transform 0.7s ease-in-out;
  }
}

@media only screen and (min-device-width: 451px) {
  .v-advantage-item {
    flex-basis: calc(34% - 3.25vw);
    flex-direction: column;
    width: 29%;
    padding-right: 37px;
    padding-top: 33px;
    text-align: left;
  }

  .v-advantage-item-content {
    font-family: Gotham, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.17vw;
    line-height: 148%;
    color: #000000;
  }
  .v-advantage-item {
    width: 29%;
  }

  .v-advantage-item-title {
    padding-bottom: 2.08vw;
  }

  .v-advantage-item-logo {
    padding-bottom: 29px;
  }
}
</style>
