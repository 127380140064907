import Vue from 'vue'

import App from './App.vue'
import router from './router'
import titleComponent from '@/components/titles/title'
import store from './modules/store'
import axios from 'axios'
axios.defaults.baseURL = 'http://api.texme.kz/api';
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import ElementUI from 'element-ui'
import Vuetify from 'vuetify'
import DaySpanVuetify from 'dayspan-vuetify'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faCcMastercard, faCcVisa} from '@fortawesome/free-brands-svg-icons'
import {faTrashAlt, faCommentAlt, faCommentDots} from '@fortawesome/free-regular-svg-icons'
import i18n from "./i18n"
import {
    faUserSecret,
    faSlidersH,
    faPhone,
    faPhoneAlt,
    faTrash,
    faLocationArrow,
    faClock,
    faCheck,
    faCreditCard,
    faArrowRight,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faComment,
    faComments,
    faCalendar,
    faFile,
    faSearch,
    faMapMarker,
    faFileWord,
    faDownload,
    faFilePdf,
    faVideo
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import fullCalendar from 'vue-fullcalendar'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU'
import VuePusher from 'vue-pusher'

Vue.component('full-calendar', fullCalendar)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)

Vue.use(VuePusher, {
    api_key: 'c513ff32afc8cf5f6207',
    options: {
        cluster: 'ap2',
        // encrypted: true,
    }
});
Vue.use(ElementUI, {locale})
library.add(faUserSecret, faSlidersH, faCreditCard, faArrowRight, faCcMastercard, faCcVisa, faCheckCircle, faCheck, faChevronRight, faChevronLeft, faLocationArrow, faMapMarker, faClock, faCalendar, faDownload, faTrashAlt, faSearch, faTrash, faComment, faComments, faFile, faFilePdf, faFileWord, faPhone, faPhoneAlt, faCommentDots, faComments, faCommentAlt, faComment, faVideo)

Vue.prototype.$http = axios
Vue.prototype.$http.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
Vue.prototype.$http.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

const token = localStorage.getItem('accessToken')
if (token) {
    Vue.prototype.$http.defaults.headers.common.Token = 'Bearer ' + token
    axios.defaults.headers.common.xAccessToken = 'Bearer ' + token
    Vue.prototype.$http.defaults.headers.common.Authorization = 'Bearer ' + token
}
Vue.component('vue-title', titleComponent)
Vue.use(Vuetify)
Vue.use(DaySpanVuetify, {
    methods: {
        getDefaultEventColor: () => '#1976d2'
    }
})

store.dispatch('getCities')
store.dispatch('getProfessions')
store.dispatch('getTime')

// document.addEventListener('touchmove', function (event) {
//   if (event.scale !== 1) { event.preventDefault(); }
// }, { passive: false });
Vue.filter('phoneFormat', function (value) {
    var phone = value;
    var lenPhone = phone.length;
    var tt=phone.split('');
    if(lenPhone == 12){
        tt.splice(2,"", " (");
        tt.splice(6,"", ") ");
        tt.splice(10,"", "-");
        tt.splice(13,"", "-");
    }else if(lenPhone == 13){
        tt.splice(3,"", "(");
        tt.splice(7,"", ")");
        tt.splice(11,"", "-");
        tt.splice(14,"", "-");
    }
    return tt.join('')
})

// axios.defaults.withCredentials = true;
// axios.interceptors.request.use(config => {
//     // Check if the request is being sent over HTTP
//     if (config.url.startsWith('http://')) {
//         // Replace the protocol with HTTPS
//         config.url = config.url.replace('http://', 'https://');
//     }
//
//     return config;
// });

new Vue({
    store,
    i18n,
    router,
    render: h => h(App)
}).$mount('#app')
