<template>
  <div class="profile">
    <v-popup
        v-show="message"
        close-position="text-accent"
        @closePopup="closeInfoPopup"
    >
      <div class="h2">
        <span
            class="center"
            v-html="message"
        />
        <div class="right">
          <v-button
              :class="removed ? 'dark' : ''"
              size="btn btn-success text-white m-1"
              value="OK"
              @click="postAction"
          />
          <v-button
              size="text m-1"
              value="Oтмена"
              @click="closeInfoPopup"
          />
        </div>
      </div>
    </v-popup>
    <vue-title :title="$t('чекапы')"/>
    <main-layout>
      <div class="profile__main">
        <left-navigation type="doctor"/>
        <div class="profile__main__content">
          <page-title
              :title="name"
              weight="normal"
          />
          <v-toggle
              :options="options"
              @changeToggle="changeToggle"
          />
          <br/>
          <div>
            <el-date-picker
                v-model="filterDate"
                type="daterange"
                range-separator="До"
                start-placeholder=""
                end-placeholder="">
            </el-date-picker>
            <button
                v-if="filterDate"
                class="btn-search expo"
                @click="filterDate = ''"
            >{{ $t('Сбросить фильтр') }}
            </button>
            <button
                class="btn-search export"
                @click="download"
            >{{ $t('Экспорт') }}
            </button>
          </div>
          <br/>
          <preloader v-show="loader"/>
          <el-table
              ref="elTable"
              v-if="!loader"
              stripe
              :fit="false"
              class="table web"
              header-cell-class-name="table_header"
              cell-class-name="table_cell"
              :data="filterDate? filteredData:orders"
          >
            <el-table-column prop="name" :label="$t('ФИОКлиентa')">
              <template slot-scope="scope">
                {{ scope.row.user.name }} {{ scope.row.user.surname }} {{ scope.row.user.patronymic }}
              </template>
            </el-table-column>
            <el-table-column prop="phone" :label="$t('номерТелефона')">
              <template slot-scope="scope">
                <a :href="'tel:+'+scope.row.user.phone">{{ '+' + scope.row.user.phone |phoneFormat }}</a>
              </template>
            </el-table-column>
            <el-table-column :prop=" $i18n.locale == 'kk' ? 'title_kz' :$i18n.locale=='ru'?'title':'title_en'" :label="$t('Услуга')">
            </el-table-column>
            <el-table-column prop="conclusion" :label="$t('Заключениеотврача')">
            </el-table-column>
            <el-table-column prop="feedback_from_user" :label="$t('Отзывы')">
            </el-table-column>
            <el-table-column prop="price" :label="$t('Стоимость')">

            </el-table-column>
            <el-table-column prop="date_time" :label="$t('датаИВремя')">
              <template slot-scope="scope">
                {{ format_date(scope.row.date, scope.row.time) }}
              </template>
            </el-table-column>

            <el-table-column label="">
              <template slot-scope="scope">
                <div class="dropdown-border">
                  <router-link
                      :to="{ path: '/clinic/checkups/' + scope.row.id, query: { checkup: JSON.stringify(scope.row) } }">
                    <span style="padding: 1rem">{{ $t('Просмотр') }}</span>
                  </router-link>
                </div>
                <div v-if="type === 0" class="dropdown-border text-accent pointer" href="#"
                     @click="confirm(scope.row.id, index)">
                  <span style="padding: 1rem">{{ $t('Подтвердить') }}</span>
                </div>
                <div v-if="type === 1" class="dropdown-border text-accent pointer" href="#"
                     @click="approve(scope.row.id, index)">
                  <span style="padding: 1rem">{{ $t('Завершить') }}</span>
                </div>
                <div v-if="type != 3 && type != 2" class="dropdown-border text-danger pointer" href="#"
                     style="padding: 0"
                     @click="cancel(scope.row.id, index)">
                  <span style="padding: 1rem">{{ $t('Отменить') }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div
              v-for="d in filterDate? filteredData:orders"
              :key="d.id"
              class="mobile clinic-card"
              style="margin-bottom: 30px"
          >
            <div class="clinic-card__row">
              <div class="clinic-card__col">
                <div class="bold">{{ $t('ФИОКлиента') }}</div>
                <div v-if="d.user" class="sm-text">{{ d.user.name }} {{ d.user.surname }} {{ d.user.patronymic }}</div>
              </div>
              <div class="clinic-card__col">
                <div style="display: flex; flex-direction: row; justify-content:space-between">
                  <div class="clinic-card__col">
                    <div class="bold">{{ $t('Услуга') }}</div>
                    <div class="sm-text">
                      {{ $i18n.locale == 'kk' ? d.title_kz : $i18n.locale == 'ru' ? d.title : d.title_en }}
                    </div>
                  </div>
                  <div class="dropdown">
                    <img class="dropbtn" src="../../assets/icons/more.svg"/>
                    <div class="dropdown-content">
                      <div class="dropdown-border">
                        <router-link :to="{ path: `/clinic/checkups/${d.id}`, query: { service: d } }">
                          <span style="padding: 1rem">{{ $t('Просмотр') }}</span>
                        </router-link>
                      </div>
                      <div
                          v-if="type == 0"
                          class="text-accent pointer dropdown-border"
                          @click="confirm(d.id, index)"
                      >
                        <span style="padding: 1rem">{{ $t('Подтвердить') }}</span>
                      </div>
                      <div
                          v-if="type == 1"
                          class="text-accent pointer dropdown-border"
                          @click="approve(d.id, index)"
                      >
                        <span style="padding: 1rem">{{ $t('Завершить') }}</span>
                      </div>
                      <div
                          v-if="type != 3 && type != 2"
                          class="text-danger pointer dropdown-border"
                          style="padding: 0"
                          @click="cancel(d.id, index)"
                      >
                        <span style="padding: 1rem">{{ $t('Отменить') }} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clinic-card__row">
              <div class="clinic-card__col">
                <div class="bold">{{ $t('Стоимость') }}</div>
                <div class="sm-text">{{ d.price }} {{ $t('тенге') }}</div>
              </div>
              <div class="clinic-card__col">
                <div class="bold">{{ $t('Дата') }}</div>
                <div class="sm-text">{{ format_date(d.date, d.time) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import LeftNavigation from '@/components/user/left-navigation'
import PageTitle from '@/components/titles/page-title'
import VToggle from '@/components/toggle/v-toggle'
import Preloader from '@/components/preloader'
import moment from 'moment'
import VPopup from '@/components/modals/v-popup'
import VButton from '@/components/btn/v-button'
import {approveAlert, cancelAlert, confirmAlert, handleView} from '@/utils/functions'
import elTableExport from "el-table-export";

export default {
  name: 'ClinicCheckups',
  components: {
    VButton,
    VPopup,
    Preloader,
    VToggle,
    PageTitle,
    LeftNavigation,
    MainLayout
  },
  data: function () {
    return {
      filterDate: '',
      role: localStorage.role,
      action: '',
      message: '',
      id: '',
      index: '',
      removed: false,
      name: this.$t('чекапы'),
      mobileView: true,
      orders: [],
      loader: false,
      isTokenIsEmpty: null,
      showNav: false,
      type: 0,
      options: [
        {
          id: 0,
          title: this.$t('Новые')
        },
        {
          id: 1,
          title: this.$t('Подтвержденные')
        },
        {
          id: 2,
          title: this.$t('Завершенные')
        },
        {
          id: 3,
          title: this.$t('Отменённые')
        }
      ]
    }
  },
  async created() {
    this.mobileView = handleView()
  },
  computed: {
    filteredData() {
      const [start, end] = this.filterDate
      return this.orders.filter(item => moment(item.date).isBetween(start, end))
    }
  },
  methods: {
    download() {
      var link = document.createElement('a');
      link.setAttribute('href', `${process.env.VUE_APP_API_URL}web/clinic_checkup/export?user_id=${JSON.parse(localStorage.getItem('user')).id}`);
      link.setAttribute('download', 'download');
      onload = link.click();
    },
    exportExcel() {
      elTableExport(this.$refs.elTable, {
        fileName: "export-checkup-" + moment().format('DD-MM-YYYY HH:mm'),
        type: "xls",
        withBOM: false,
        useFormatter: true,
      }).then(() => {
        this.$message.success({
          showClose: true,
          message: this.$t('экспортЗавершен'),
          duration: 3000
        });
      }).catch(() => {
        this.$message.error({
          showClose: true,
          message: this.$t('ВозниклаОшибка'),
          duration: 7500
        });
      });
    },
    closeInfoPopup() {
      this.message = ''
      this.id = ''
      this.action = ''
      this.index = undefined
    },
    confirm(id, index) {
      this.message = confirmAlert('чекап')
      this.action = 'confirm'
      this.index = index
      this.id = id
    },
    cancel(id, index) {
      this.message = cancelAlert('чекап')
      this.action = 'cancel'
      this.index = index
      this.id = id
    },
    approve(id, index) {
      this.message = approveAlert('чекап')
      this.action = 'approve'
      this.index = index
      this.id = id
    },
    async postAction() {
      this.removed = true
      var url = `${process.env.VUE_APP_API_URL}${this.role}/checkups/` + this.action
      const result = await this.$http.post(
          url, {id: this.id}
      )
      if (result) {
        this.removed = false
        this.orders.splice(this.index, this.index + 1)
        await this.getOrders()
        this.closeInfoPopup()
      }
    },
    getDate(date) {
      const months = {
        '01': this.$t('Января'),
        '02': this.$t('Февраля'),
        '03': this.$t('Марта'),
        '04': this.$t('Апреля'),
        '05': this.$t('Мая'),
        '06': this.$t('Июня'),
        '07': this.$t('Июля'),
        '08': this.$t('Августа'),
        '09': this.$t('Сентября'),
        '10': this.$t('Октября'),
        '11': this.$t('Ноября'),
        '12': this.$t('Декабря')
      }
      return date.slice(date.length - 2) + ' ' + months[date.slice(5, 7)] + ', ' + date.slice(0, 4)
    },
    format_date(date, time) {
      if (date) {
        return this.getDate(moment(String(date)).format('YYYY-MM-DD')) + ' ' + time.slice(0, 5)
      }
    },
    changeToggle(item) {
      this.type = item
      this.orders = []
      this.getOrders()
    },
    scroll() {
      const element = document.getElementById('feedback-partner')
      element.scrollIntoView({behavior: 'smooth'})
    },
    async getOrders() {
      this.loader = true
      var url = `${process.env.VUE_APP_API_URL}clinic/checkups/get`

      const result = await this.$http.post(url, {
        status: this.type,
        dateFilter: this.filterDate
      })
      const data = result.data.data.map(item => {
        item.date_time = this.format_date(item.date, item.time)
        return item
      })
      if (data) {
        this.orders = data
      }
      this.loader = false
    }
  },

  async mounted() {
    await this.getOrders()
  },
  watch: {
    async filterDate() {
      await this.getOrders()
      this.scroll()
    }
  },
}
</script>
<style>

.el-table__body, .el-table__footer, .el-table__header {
  width: 100% !important;
}
</style>
<style lang="scss">
.table_header {
  .cell {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 700;
  }
}

.table_cell {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 400;
}
</style>
<style lang="scss" scoped>
.dropdown-border {
  span {
    display: block;
    padding: 0.5rem !important;
  }
}

@import "../../assets/css/colors";

@import "../../assets/css/profile";


.btn-search {
  font-family: PlumbKaz, sans-serif !important;
  border: none;
  background: #00938A;
  color: #ffffff;
  border-radius: 12px;
  padding: 6px 24px;
  font-size: 16px;

  &.export {
    margin-left: 12px;
    @media(max-width: 468px) {
      margin: 12px;
    }
  }

  @media(max-width: 468px) {
    padding: 7px 16px;
  }
}

.table {
  width: 100%;
  box-shadow: 14px 23px 64px 53px rgb(212 217 232 / 20%);
  border-radius: 3px;
  background: white;
  padding: 1rem;
  border: 1px solid gainsboro;

  & {
    tr {
      width: 100%;
      line-height: 5vh;
    }
  }
}

.text-center {
  text-align: center;
}

</style>
