<template>
  <div class="clinic">
    <v-popup-withposter
        v-show="showPopup"
        :imgs="clinic.images"
        close-position="right-close"
        :title="$t('ЗаписьВКлинику')"
        @closePopup="closeInfoPopup"
        stylen="clinic-helper"
    >
      <div
          class="popup_main"
          style="width: 100%"
      >
        <div class="popup__header">
          <div class="popup__header__logo">
            <circle-img :pic="clinic.profile_photo"
                        :name="$i18n.locale=='kk'?clinic.name_kz:$i18n.locale=='ru'?clinic.name:clinic.name_en"
                        size="sm"></circle-img>
          </div>
          <div class="popup__header__name h2">
            {{
              $i18n.locale == 'kk' ? (clinic.users != null ? clinic.users.name_kz : clinic.name_kz) : $i18n.locale == 'ru' ? (clinic.users != null ? clinic.users.name : clinic.name) : (clinic.users != null ? clinic.users.name_en : clinic.name_en)
            }}
          </div>

        </div>
        <div class="popup__body">
          <div class="popup__body__action">
            <v-button
                :value="$i18n.locale=='kk'?clinic.adress_kz:$i18n.locale=='ru'?clinic.address:clinic.address_en"
                fa-icon="map-marker"
                size="bordered-success sm"
            />
            <v-button
                v-if="clinic.work_time"
                :size="(clinic.work_time.length > 0 && clinic.work_time.indexOf('09') !== -1 ? 'bordered-success' : 'bordered-danger') +' sm '"
                :value="clinic.work_time.length > 0 ? clinic.work_time: $t('Круглосуточно')"
                fa-icon="clock"
            />
            <v-button
                :value="'+'+clinic.phone |phoneFormat"
                class="bordered-success sm"
                fa-icon="phone"
                rotation="90"
                @click="showInfoPopup"
            />
          </div>
          <div style="width: 100%;">
            <span class="v-title">{{ $t('Описание') }}</span>
            <br>
            {{
              $i18n.locale == 'kk' ? clinic.description_kz : $i18n.locale == 'ru' ? clinic.description : clinic.description_en
            }}
          </div>
          <div style="display: flex;justify-content: flex-start; align-items: center; margin-top: 2rem;">
            <img style="display: block; width: 70px; height: fit-content; margin-right: 10px; cursor: pointer"
                 v-for="img in clinic.images"
                 :src="img.url"
                 alt=""
                 class="clinic_images"
                 @click="showPhotoFoo(clinic.images)"
            >
          </div>

          <div v-show="showPhoto"
               class="modal_clinic_image"
          >
            <img :src="currentPhoto"
                 @click="showPhoto = false"
                 class="modal_clinic_current_image"/>
          </div>
          <div v-show="!(role === 'user')" class="text-danger">
            {{ $t('авторизуйтесьДляЗаписи') }}
          </div>

          <span class="v-title">{{ $t('услуги') }}</span>
          <div class="popup__body__services">
            <div ref="services" class="popup__body__services__item">
              <sm-service-info
                  v-for="(serv, index) in clinic.services"
                  :key="index+serv.id"
                  :service="serv"
              />
            </div>
            <!--            <div class="paddles-service">-->
            <!--              <font-awesome-icon-->
            <!--                  ref="rightService"-->
            <!--                  :class="scrollLeftService < 10 ? 'hidden' : ''"-->
            <!--                  :icon="['fa','chevron-left']"-->
            <!--                  class="left-paddle paddle "-->
            <!--                  @click="scrollToLeftService"-->
            <!--              />-->
            <!--              <font-awesome-icon-->
            <!--                  :class="scrollLeftService <= maxScrollService ? '' : 'hidden'"-->
            <!--                  :icon="['fa','chevron-right']"-->
            <!--                  class="right-paddle paddle"-->
            <!--                  @click="scrollToRightService"-->
            <!--              />-->
            <!--            </div>-->
          </div>

          <span v-if="clinic.doctors&& clinic.doctors.length>0" class="v-title">{{ $t('услуги') }}</span>
          <div class="popup__body__docs">
            <div ref="doctors" class="popup__body__services__item">
              <sm-doctor-info
                  v-for="(serv, index) in clinic.doctors"
                  :key="index+serv.id"
                  :doctor="serv"
              />
            </div>
            <!--            <div class="paddles-doctor">-->
            <!--              <font-awesome-icon-->
            <!--                  ref="rightDoctor"-->
            <!--                  :class="scrollLeftDoctor < 10 ? 'hidden' : ''"-->
            <!--                  :icon="['fa','chevron-left']"-->
            <!--                  class="left-paddle paddle "-->
            <!--                  @click="scrollToLeftDoctor"-->
            <!--              />-->
            <!--              <font-awesome-icon-->
            <!--                  :class="scrollLeftDoctor <= maxScrollDoctor ? '' : 'hidden'"-->
            <!--                  :icon="['fa','chevron-right']"-->
            <!--                  class="right-paddle paddle"-->
            <!--                  @click="scrollToRightDoctor"-->
            <!--              />-->
            <!--            </div>-->
          </div>
        </div>
        <div class="popup__footer"></div>
      </div>
<!--      <div v-show="!(role === 'user')" class="text-danger">-->
<!--        {{ $t('дляЗаписиАвторизуйтесь') }}-->
<!--      </div>-->
    </v-popup-withposter>
    <div class="clinic__header">
      <div class="clinic__header__logo">
        <circle-img :pic="clinic.profile_photo" size="md"
                    :name="$i18n.locale=='kk' ?clinic.name_kz:$i18n.locale=='ru' ?clinic.name:clinic.name_en"/>
      </div>
      <div class="clinic__header__info">
        <div class="clinic__header__info__title h2">
          <span v-if="$i18n.locale=='ru'">{{ clinic.users != null ? clinic.users.name : clinic.name }}</span>
          <span v-else-if="$i18n.locale=='kk'">{{ clinic.users != null ? clinic.users.name_kz : clinic.name_kz }}</span>
          <span v-else>{{ clinic.users != null ? clinic.users.name_en : clinic.name_en }}</span>
        </div>
        <div  class="clinic__header__info__action">
          <v-button
              :value="$i18n.locale=='kk'?clinic.address_kz:$i18n.locale=='ru'?clinic.address:clinic.address_en"
              fa-icon="map-marker"
              size="bordered-success visibility"
          />
          <v-button
              v-if="clinic.work_time"
              :value="clinic.work_time.length > 0 ? clinic.work_time : $t('Круглосуточно')"
              fa-icon="clock"
              size="bordered-success m-1 visibility"
          />
          <v-button
              class="success text-white"
              :value="$t('Подробно')"
              @click="showInfoPopup"
          />
        </div>
      </div>
    </div>
    <div class="clinic__body">
      {{
        $i18n.locale == 'kk' ? clinic.description_kz : $i18n.locale == 'ru' ? clinic.description : clinic.description_en
      }}
    </div>
    <!--    <div class="clinic_service">-->
    <!--      <v-button-->
    <!--          v-for="service in clinic.services" :key="service.id"-->
    <!--          :disabled="load"-->
    <!--          :value="service.title"-->
    <!--          size="p-1 mr-1"-->
    <!--      />-->
    <!--    </div>-->
    <div class="clinic__footer">
      <div ref="pro" class="clinic__footer__profession clinic_service">
        <v-button
            v-for="profession in clinic.professions"
            :key="profession.id"
            :value="$i18n.locale=='kk'?profession.title_kz:$i18n.locale=='ru'?profession.title:profession.title_en"
            size="p-1 mr-1"
        />
      </div>
      <div class="paddles">
        <font-awesome-icon
            ref="right"
            :class="scrollLeft < 10 ? 'hidden' : ''"
            :icon="['fa','chevron-left']"
            class="left-paddle paddle "
            @click="scrollToLeft"
        />
        <font-awesome-icon
            :class="scrollLeft <= maxScroll ? '' : 'hidden'"
            :icon="['fa','chevron-right']"
            class="right-paddle paddle"
            @click="scrollToRight"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CircleImg from '@/components/img/circle-img'
import VButton from '@/components/btn/v-button'
import VPopupWithposter from '@/components/modals/v-big-popup'
import SmServiceInfo from '@/components/modals/sm-service-info'
import SmDoctorInfo from '@/components/modals/sm-doctor-info'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'

Vue.use(VueViewer)
export default {
  name: 'clinic-item',
  components: {
    SmDoctorInfo,
    SmServiceInfo,
    VPopupWithposter,
    VButton,
    CircleImg
  },
  data: function () {
    return {
      role: localStorage.role ? localStorage.role : null,
      showPopup: false,
      scrollLeft: 0.0,
      scrollLeftDoctor: 0.0,
      scrollLeftService: 0.0,
      maxScroll: 0.0,
      maxScrollDoctor: 0.0,
      maxScrollService: 0.0,
      showPhoto: false,
      currentPhoto: "",
    }
  },
  props: ['clinic'],
  methods: {
    showInfoPopup() {
      this.showPopup = true
      document.querySelector('body').classList.add('overflow-hidden')
      document.querySelector('html').classList.add('overflow-hidden')
      document.querySelector('#app').classList.add('overflow-hidden')
      document.querySelector('.main-layout').classList.add('overflow-hidden')
    },
    closeInfoPopup() {
      this.showPopup = false
      document.querySelector('body').classList.remove('overflow-hidden')
      document.querySelector('html').classList.remove('overflow-hidden')
      document.querySelector('#app').classList.remove('overflow-hidden')
      document.querySelector('.main-layout').classList.remove('overflow-hidden')
    },
    updateScroll() {
      this.scrollLeft = this.$refs.pro.scrollLeft + 2
    },
    scrollToLeft() {
      this.$refs.pro.scroll({
        left: this.scrollLeft = this.scrollLeft - 150,
        behavior: 'smooth'
      })
    },
    scrollToRight() {
      this.$refs.pro.scroll({
        left: this.scrollLeft = this.scrollLeft + 150,
        behavior: 'smooth'
      })
    },
    updateScrollDoctor() {
      this.scrollLeftDoctor = this.$refs.doctors.scrollLeftDoctor + 2
    },
    scrollToLeftDoctor() {
      this.$refs.doctors.scroll({
        left: this.scrollLeftDoctor = this.scrollLeftDoctor - 150,
        behavior: 'smooth'
      })
    },
    scrollToRightDoctor() {
      this.$refs.doctors.scroll({
        left: this.scrollLeftDoctor = this.scrollLeftDoctor + 150,
        behavior: 'smooth'
      })
    },
    updateScrollService() {
      this.scrollLeftService = this.$refs.services.scrollLeftService + 2
    },
    scrollToLeftService() {
      this.$refs.services.scroll({
        left: this.scrollLeftService = this.scrollLeftService - 150,
        behavior: 'smooth'
      })
    },
    scrollToRightService() {
      this.$refs.services.scroll({
        left: this.scrollLeftService = this.scrollLeftService + 150,
        behavior: 'smooth'
      })
    },
    showPhotoFoo(url) {
      console.log(url)
      let array = []
      for (let i = 0; i < url.length; i++) {
        array.push(url[i].url)
      }
      this.$viewerApi({
        "images": array,
      })
    }
  },
  created() {
    if (this.$refs.pro) {
      this.maxScroll = this.$refs.pro.scrollWidth - this.$refs.pro.clientWidth
      this.$refs.pro.addEventListener('scroll', this.updateScroll)
    }
    if (this.$refs.services) {
      this.maxScroll = this.$refs.services.scrollWidth - this.$refs.services.clientWidth
      this.$refs.services.addEventListener('scroll', this.updateScrollService)
    }
    if (this.$refs.doctors) {
      this.maxScroll = this.$refs.doctors.scrollWidth - this.$refs.doctors.clientWidth
      this.$refs.doctors.addEventListener('scroll', this.updateScrollDoctor)
    }
  },
  mounted() {
    if (this.$refs.pro) {
      this.maxScroll = this.$refs.pro.scrollWidth - this.$refs.pro.clientWidth
      this.$refs.pro.addEventListener('scroll', this.updateScroll)
    }
    if (this.$refs.services) {
      this.maxScroll = this.$refs.services.scrollWidth - this.$refs.services.clientWidth
      this.$refs.services.addEventListener('scroll', this.updateScrollService)
    }
    if (this.$refs.doctors) {
      this.maxScroll = this.$refs.doctors.scrollWidth - this.$refs.doctors.clientWidth
      this.$refs.doctors.addEventListener('scroll', this.updateScrollDoctor)
    }
  }
}
</script>
<style>
.clinic-helper {
  width: 50% !important;
}

@media only screen and (max-device-width: 450px) {
  .clinic-helper {
    width: 100% !important;
  }
}

.viewer-title {
  display: none;
}
</style>
<style lang="scss" scoped>
@import "../../assets/css/colors";

.clinic_service {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

@media only screen and (max-device-width: 450px) {
  .clinic-helper {
    width: 100% !important;
  }
  .clinic {
    box-sizing: border-box;
    font-size: 1.17vw;
    width: 100%;
    padding: 3vw 2vw;
    display: flex;
    text-align: left !important;
    flex-direction: column;
    margin-bottom: 4vw;
    background-color: $white;
    font-family: PlumbKaz, sans-serif;
    box-shadow: 0 1vw 2vw rgba(212, 217, 232, 0.2);
    border-radius: 2vw;
    justify-content: space-between;

    .text-danger {
      font-size: 20px;
    }

    &__header {
      display: flex;
      flex-direction: row;

      &__logo {
        margin: 3vw;
      }

      &__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &__action {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    &__body {
      margin: 4vw;
      font-size: 4vw;
    }

    &__footer {
      position: relative;
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      scroll-behavior: smooth;

      &__profession {
        margin: 0 3vw;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .paddles {
      font-size: 2.5vw;
    }

    .paddle {
      position: absolute;
      top: 0.8rem;
      bottom: 0;
      width: 2vw;
    }

    .left-paddle {
      left: -0.325vw;
    }

    .right-paddle {
      right: -0.325vw;
    }

    .hidden {
      display: none;
    }

    .hidden {
      display: none;
    }

    .popup__header {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;

      &__name {
        margin: 2vw;
      }

      &__action {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    }

    .popup__body {
      display: flex;
      flex-direction: column;

      &__action {
        padding: 1.04vw 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }

      &__docs,
      &__services {
        position: relative;
        width: 100%;
        //overflow-x: hidden;
        //overflow-y: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-behavior: smooth;

        &__item {
          margin: 0.65vw 0;
          width: 100%;
          display: flex;
          //flex-direction: row;
          flex-direction: column;
          align-items: flex-start;
          //-webkit-overflow-scrolling: touch;
          overflow-x: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    .circle-border-icon {
      border-radius: 50%;
      padding: 2vw;
      color: white;
      background-color: darkorange;
    }

    .v-title {
      font-size: 4vw;
      padding: 0.65vw 0 0 0;
      margin-top: 12px;
    }

    .clinic_images {
      width: 30px !important;
      height: auto !important;
    }

    .modal_clinic_image {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #FFFFFF;
      z-index: 999999999999999999;
      display: flex;
      align-items: center;
      justify-content: center
    }

    .modal_clinic_current_image {
      width: 100%;
      height: auto;
      cursor: pointer;
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .clinic {
    font-size: 1.17vw;
    padding: 2.08vw 3.25vw 3.25vw 2.08vw;
    display: flex;
    text-align: left !important;
    flex-direction: column;
    margin-bottom: 1.625vw;
    background-color: $white;
    font-family: PlumbKaz, sans-serif;
    box-shadow: 0 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
    border-radius: 0.78vw;
    justify-content: space-between;

    &__header {
      display: flex;
      flex-direction: row;

      &__logo {
        margin: 1.3vw;
      }

      &__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &__action {
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
        }
      }
    }

    &__body {
      margin: 1.3vw;
    }

    &__footer {
      position: relative;
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      scroll-behavior: smooth;

      &__profession {
        margin: 0 1.04vw;
        max-width: 64vw;
        min-width: 58vw;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .paddles {
      & {
        .paddle {
          position: absolute;
          top: 1.2rem;
          bottom: 0;
          width: 1.5em;
        }

        .left-paddle {
          left: -0.325vw;
        }

        .right-paddle {
          right: -0.325vw;
        }
      }
    }

    .paddles-service,
    .paddles-doctor {
      & {
        .paddle {
          position: absolute;
          top: 3rem;
          bottom: 0;
          width: 1.5em;
        }

        .left-paddle {
          left: -0.325vw;
        }

        .right-paddle {
          right: -0.325vw;
        }
      }
    }

    .hidden {
      display: none;
    }

    .hidden {
      display: none;
    }

    .popup__header {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      &__name {
        margin: 0 2vw;
      }

      &__action {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    }

    .popup__body {
      display: flex;
      flex-direction: column;

      &__action {
        padding: 1.04vw 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }

      &__docs,
      &__services {
        position: relative;
        width: 42vw;
        padding: 0 1.2vw;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-behavior: smooth;

        &__item {
          margin: 0.65vw 0;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          flex-direction: row;
          align-items: flex-start;
          -webkit-overflow-scrolling: touch;
          overflow-x: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    .circle-border-icon {
      border-radius: 50%;
      padding: 0.78vw;
      color: white;
      background-color: darkorange;
    }

    .v-title {
      font-size: 1.3vw;
      padding: 0.65vw 0 0 0;
    }

    .modal_clinic_image {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #FFFFFF;
      z-index: 999999999999999999;
      display: flex;
      align-items: center;
      justify-content: center
    }

    .modal_clinic_current_image {
      width: auto;
      height: 100%;
      cursor: pointer;
    }
  }
}
</style>
