<template>
  <div class="alarm">
    <v-popup v-show="message" close-position="text-accent" @closePopup="closeInfoPopup">
      <div class="h2">
        <div class="center text-accent">
          <font-awesome-icon :icon="['fa','check-circle']" class="big-icon"/>
        </div>
        <span class="center" v-html="message"></span>
        <div class="center">
          <v-button size="text-accent p-1" value="OK" @click="closeInfoPopup"/>
        </div>
      </div>
    </v-popup>
    <div class="alarm__icon">
      <img alt="" height="57" src="../../assets/icons/alarm.svg" v-on:click="show"/>
    </div>
    <div v-show="showAlert" class="alarm__content">
      <div class="alarm__content__header">
        <span>{{ $t('Перезвонитемне') }}</span>
        <span v-on:click="showAlert=!showAlert">x</span>
      </div>
      <div class="alarm__content__body">
        <div class="alarm__content__body__s">
          <div class="alarm__content__body__text">
            {{ $t('мыПерезвоним') }}
          </div>
          <input ref="alarmname" :placeholder="$t('Вашеимя')" required type="text">
          <input id="alarmphone" ref="alarmphone" :placeholder="$t('номерТелефона')" required type="text">
          <v-button :disabled="loader" class="mt-10 btn btn-success success xlong text-white border-60 p-1" size="sm"
                    type="submit"
                    :value="$t('Отправить')" @click="send"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Inputmask from 'inputmask'
import VButton from '@/components/btn/v-button'
import VPopup from '@/components/modals/v-popup'

export default {
  name: 'Alarm',
  components: {
    VPopup,
    VButton
  },
  mounted() {
    var im = new Inputmask('+9 (999) 999 99 99')
    im.mask(document.getElementById('alarmphone'))
    setTimeout(this.show, 10000);
  },
  data: function () {
    return {
      showAlert: false,
      loader: false,
      message: ''
    }
  },
  methods: {
    show() {
      this.showAlert = !this.showAlert
    },
    closeInfoPopup() {
      this.message = ''
    },
    async send() {
      this.loader = true
      var url = `${process.env.VUE_APP_API_URL}web/request/alarm`
      const result = await this.$http.post(url, {
        name: this.$refs.alarmname.value,
        phone: this.$refs.alarmphone.value
      })
      const data = result.data
      if (data) {
        this.message = this.$t('заявкаПринята')
        this.$refs.alarmname.value = ''
        this.$refs.alarmphone.value = ''
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .mt-10 {
    margin-top: 1rem !important;
  }
  .alarm {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    bottom: 2.325vw;
    right: 2.325vw;
    height: 20%;
    z-index: 200;
    border-radius: 9px;

    &__content {
      bottom: 0;
      margin: 0.52vw 0.52vw;
      box-shadow: 0 0 2.275vw 6px rgba(93, 92, 92, 0.11);
      border-radius: 9px;
      width: 260px;
      background-color: white;

      &__header {
        display: flex;
        width: 100%;
        color: white;
        justify-content: space-between;
        border-radius: 9px 9px 0 0;
        background-color: $red;

        & {
          span {
            margin: 1.9vw 4.04vw;
          }
        }
      }

      &__body {
        width: 100%;
        background-color: white;
        border-radius: 0 0 9px 9px;

        &__s {
          margin: 4vw;
        }

        & {
          input {
            padding: 6px 1.52vw;
            border-radius: 1.04vw;
            margin: 1vw 0;
            width: 100%;
            border: gainsboro 0.065vw solid;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box; /* Firefox, other Gecko */
            box-sizing: border-box; /* Opera/IE 8+ */
          }

          button {
          }
        }

        &__text {
          font-size: 3.385vw;
          font-family: Gotham-Pro, sans-serif;
          padding: 3.04vw;
          background-color: gainsboro;
          border-radius: 3.52vw;
          margin-bottom: 2.04vw;
          text-align: left;
          color: black;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .alarm {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    bottom: 1vw;
    right: 1vw;
    height: 20%;
    z-index: 200;
    border-radius: 9px;

    &__content {
      bottom: 0;
      margin: 0.52vw 0.52vw;
      box-shadow: 0 0 2.275vw 6px rgba(93, 92, 92, 0.11);
      border-radius: 9px;
      width: 344px;
      height: 377px;
      background-color: white;

      &__header {
        display: flex;
        width: 100%;
        color: white;
        justify-content: space-between;
        border-radius: 9px 9px 0 0;
        background-color: $red;

        & {
          span {
            margin: 0.78vw 1.04vw;
          }
        }
      }

      &__body {
        width: 100%;
        background-color: white;
        border-radius: 0 0 9px 9px;

        &__s {
          margin: 1.625vw;
        }

        & {
          input {
            padding: 10px 0.52vw;
            border-radius: 1.04vw;
            margin: 1rem 0;
            width: 100%;
            border: gainsboro 0.065vw solid;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box; /* Firefox, other Gecko */
            box-sizing: border-box; /* Opera/IE 8+ */
          }

          button {
          }
        }

        &__text {
          font-size: 0.975vw;
          font-family: Gotham-Pro, sans-serif;
          padding: 1.04vw;
          background-color: gainsboro;
          border-radius: 0.52vw;
          margin-bottom: 15px;
          text-align: left;
          color: black;
        }
      }
    }
  }
}
</style>
