<template>
  <div class="consultations">
    <consultation-item
        v-for="(consultation,index) in consultations"
        :key="index + '' + consultation.id"
        :consultation="consultation"
        :type="type"
    />
    <preloader v-show="loader"/>
    <empty v-show="consultations.length <= 0"/>
  </div>
</template>

<script>
import ConsultationItem from '@/components/consultations/consultation-item'
import Empty from '@/components/empty'
import Preloader from '@/components/preloader'

export default {
  name: 'consultations',
  components: {
    Preloader,
    Empty,
    ConsultationItem
  },
  props: ['type'],
  data() {
    return {
      consultations: {},
      consultation: '',
      loader: false
    }
  },
  async created() {
    await this.getConsultations()
  },
  methods: {
    async getConsultations() {
      this.loader = true

      if (this.$route.name === 'UserBookingService') {
        var url = `${process.env.VUE_APP_API_URL}user/consultations/service/get`
      } else if (this.$route.name === 'UserBookingClinic') {
        var url = `${process.env.VUE_APP_API_URL}user/consultations/clinic/get`
      } else if (this.$route.name === 'UserBookingCheckup') {
        var url = `${process.env.VUE_APP_API_URL}user/consultations/checkup/get`
      }

      const result = await this.$http.post(url, {
        status: parseInt(this.type)
      })
      const data = result.data.data.map(item => {
        return item
      })
      if (data) {
        this.consultations = data
        this.loader = false
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

</style>
