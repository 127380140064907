<template>
  <div>
    <select v-if="isModal" class="select full input tel__number modalSelect" name="city" style="width: 100%;margin: 0"
            @change="getSelect">
      <!--    <option hidden>{{ placeholder }}</option>-->
      <option v-for="(city) in cities" v-show="cities" :key="city.id+city.title"
              :selected="selected&&selected==city.id?'selected':''"
              :value="city.id">
        {{ $i18n.locale == 'kk' ? city.title_kz : $i18n.locale == 'ru' ? city.title : city.title_en }}
      </option>
    </select>
    <select v-else class="select full" name="city" @change="getSelect">
      <!--    <option hidden>{{ placeholder }}</option>-->
      <option v-for="(city) in cities" v-show="cities" :key="city.id+city.title"
              :selected="selected&&selected==city.id?'selected':''"
              :value="city.id">
        {{ $i18n.locale == 'kk' ? city.title_kz : $i18n.locale == 'ru' ? city.title : city.title_en }}
      </option>
    </select>
  </div>

</template>

<script>
export default {
  name: 'select',
  props: ['placeholder', 'list', 'selected', 'isModal', 'revert'],
  data() {
    return {
      city: ''
    }
  },
  computed: {
    cities: {
      get() {
        if (this.list == null) {
          return this.$store.state.reference.cities
        } else {
          return this.list
        }
      },
      set(c) {
      }
    },
    get() {
      return this.cities
    },
    set(x) {
      return x
    }
  },
  mounted() {
    if (this.list == null) {
      if (!this.cities) {
        this.fetchItem()
      } else {
        this.cities = this.$store.state.reference.cities
      }
    } else {
      this.cities = this.list
    }
  },
  serverPrefetch() {
    return this.fetchItem()
  },
  methods: {
    fetchItem() {
      // return the Promise from the action
      return this.$store.dispatch('fetchItem', this.$route.params.id)
    },
    getSelect(val) {
      this.$emit('change', val.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .modalSelect {
    height: 44px;
  }
  option,
  .select {
    border: none;
    font-family: Gotham, sans-serif;
    font-size: 4vw !important;
    padding: 2.325vw 0;
    font-style: normal;
    font-weight: 400;
    line-height: 6vw !important;
    color: black;
    outline: none;
  }

  option:hover, option:active {
    background-color: $green;
  }
}

@media only screen and (min-device-width: 451px) {
  .modalSelect {
    height: 50.94px;
  }
  option,
  .select {
    border: none;
    font-family: Gotham, sans-serif;
    font-size: 0.975vw;
    padding: 0.325vw 0;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3vw;
    color: black;
    outline: none;
  }

  option:hover, option:active {
    background-color: $green;
  }
}
</style>
