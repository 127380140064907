<template>
  <div class="chat">
    <div ref="history" class="chat__history">
      <chat-item v-for="(message,index) in messages" :key="index+message" :message="message"/>
      <preloader v-show="loader"/>
    </div>
    <div v-if="roles != 'clinic'" class="chat__send">
      <textarea
          ref="msg"
          v-model="msg"
          class="chat__input"
          :rows="rowcount"
          :placeholder="$t('Напишите')"
          type="text"
          v-on:change="rowss"
          v-on:keyup.enter="onEnter"
      ></textarea>
      <div class="chat__send__btn" @click="sentChat">
        <img src="../../assets/icons/send.svg" width="24"/>
      </div>
    </div>
  </div>
</template>

<script>
import ChatItem from '@/components/comment/chat-item'
import Preloader from '@/components/preloader'
// import Pusher from 'pusher-js';
import Pusher from 'pusher-js/with-encryption';

export default {
  props: ['channel', 'role'],
  name: 'chat',
  components: {
    Preloader,
    ChatItem
  },
  data() {
    return {
      roles: localStorage.role,
      plugin: undefined,
      loader: true,
      messages: [],
      rowcount: 1,
      channel_id: '',
      msg: ''
    }
  },
  computed: {
    lineCount: function () {
      return this.msg ? this.msg.split(/\r\n|\r|\n/).length : 1
    }
  },
  mounted() {
    // this.plugin = document.createElement('script')
    // this.plugin.setAttribute(
    //   'src',
    //   'https://js.pusher.com/7.0/pusher.min.js'
    // )
    // this.plugin.async = true
    // document.head.appendChild(this.plugin)
    this.channel_id = this.channel;
    this.scrollToElement()
    // if (this.roles !== 'clinic') {
    //   this.getChat(this.channel)
    // }

  },
  watch: {
    msg: function (value) {
      this.rowss(value)
    },
    channel_id: function (value) {
      if (this.roles !== 'clinic') {
        this.getChat(value)
      }

      // Pusher.logToConsole = true
      // var pusher = new Pusher('c513ff32afc8cf5f6207', {
      //   cluster: 'ap2'
      // })

      let channel = this.$pusher.subscribe(value)
      let self = this
      channel.bind('my-event', function (data) {
        self.pushToMessage(JSON.stringify(data))
      })
    }
  },
  // updated() {
  //   console.log(this.)
  //   this.scrollToElement()
  // },
  methods: {
    onEnter: function (x) {
      this.sentChat()
    },
    pushToMessage(data) {
      this.messages.push(JSON.parse(data))
      this.getChat(this.channel_id)
    },
    async getChat(value) {
      try {
        this.loader = true
        var u = undefined

        if (this.roles == 'user') {
          u = 'user/consultations/service'
        }
        if (this.roles == 'doctor') {
          u = 'doctor/bookings'
        } else if (this.roles == 'clinic') {
          u = 'clinic/bookings'
        }

        var url = `${process.env.VUE_APP_API_URL}${u}/chat-history`
        const result = await this.$http.post(url, {channel_id: value})
        const data = result.data
        if (data) {
          this.messages = data
        }
        var container = this.$refs.history
        container.scrollTop = container.scrollHeight
        this.scrollToElement()
      } catch (e) {

      } finally {
        this.loader = false
      }
    },
    scrollToElement() {
      const el = this.$refs.history

      if (el) {
        el.scrollIntoView({behavior: 'smooth'})
      }
    },
    rowss(x) {
    },
    async sentChat() {
      this.loader = true
      var u = undefined

      if (this.roles == 'user') {
        u = 'user/consultations'
      }
      if (this.roles == 'doctor') {
        u = 'doctor/bookings'
      } else if (this.roles == 'clinic') {
        u = 'clinic/bookings'
      }

      var msg = this.$refs.msg.value
      this.$refs.msg.value = ''
      var container = this.$refs.history
      container.scrollTop = container.scrollHeight
      var url = `${process.env.VUE_APP_API_URL}${u}/send`
      await this.$http.post(url, {
        channel_id: this.channel,
        text: msg
      })

      this.loader = false
      this.$refs.msg.value = ''
      this.msg = ''
      container.scrollTop = container.scrollHeight
    }
  }
}
</script>

<style lang="scss" scoped>

textarea {
  resize: none;
}

@media only screen and (max-device-width: 450px) {
  .chat {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    justify-content: space-between;
    overflow-y: auto;

    &__history {
      width: 100%;
      margin: 0;
      height: 90%;
      overflow-x: hidden;
      justify-content: space-between;
      overflow-y: auto;
    }

    &__send {
      margin: 0;
      padding: 0.52vw 1.04vw;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      justify-items: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      border-radius: 0.78vw;
      background-color: #C4C4C4;
      box-sizing: border-box;

      &__btn {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 0.975vw;
        border-left: white 0.065vw solid;
        color: gray;
      }

      & {
        textarea {
          width: 100%;
          border: none;
          background-color: #C4C4C4;
          outline: none;
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .chat {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    justify-content: space-between;
    overflow-y: auto;

    &__history {
      width: 100%;
      margin: 1.04vw;
      height: 75%;
      overflow-x: hidden;
      justify-content: space-between;
      overflow-y: auto;
    }

    &__send {
      margin: 1.3vw 2.6vw;
      padding: 0.52vw 1.04vw;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      justify-items: center;
      position: absolute;
      bottom: 0;
      width: 85%;
      border-radius: 0.78vw;
      height: 40px;
      background-color: #C4C4C4;
      box-sizing: border-box;

      &__btn {
        display: flex;
        justify-items: center;
        justify-content: center;
        padding: 0;
        border-left: white 0.065vw solid;
        color: gray;
      }

      & {
        textarea {
          padding: 0;
          overflow: hidden;
          width: 100%;
          border: none;
          display: flex;
          justify-content: center;
          background-color: #C4C4C4;
          outline: none;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
