<template>
  <a target="_blank" v-bind:href="url">
    <div class="social-link" v-bind:class="social"></div>
  </a>
</template>
<script>
export default {
  name: 'social-links',
  props: ['social', 'url']
}
</script>

<style scoped>
.social-link {
  padding-left: 1.04vw;
  width: 24px;
  height: 24px;
}

@media only screen and (max-device-width: 450px) {
  .social-link {
    padding-left: 4vw;
    width: 24px;
    height: 24px;
  }

  .instagram {
    background-size: 100% 100% !important;
    background: url("../../assets/img/instagram.svg") center center fixed no-repeat;
  }

  .youtube {
    background-size: 100% 100% !important;
    background: url("../../assets/img/youtube.svg") no-repeat;
    background-size: cover;
  }

  .twitter {
    background-size: 100% 100% !important;
    background: url("../../assets/img/twitter.svg") no-repeat;
  }

  .dribbble {
    background-size: 100% 100% !important;
    background: url("../../assets/img/dribbble.svg") no-repeat;
  }
}

.instagram {
  background: url("../../assets/img/instagram.svg") no-repeat;
}

.youtube {
  background: url("../../assets/img/youtube.svg") no-repeat;
}

.twitter {
  background: url("../../assets/img/twitter.svg") no-repeat;
}

.dribbble {
  background: url("../../assets/img/dribbble.svg") no-repeat;
}
</style>
