import Vue from 'vue'
import store from '@/modules/store.js'
import VueRouter from 'vue-router'

import WebSiteRecords from '@/views/Records'
import WebSiteCheckups from '@/views/Checkups'
import ResetPassword from '@/views/reset-password'
import WebSitePartners from '@/views/Partners'
import WebSiteAbout from '@/views/About'

import UserProfile from '@/views/user/Profile'
import UserBookingService from '@/views/user/bookings/Service'
import UserBookingCheckup from '@/views/user/bookings/Checkup'
import UserBookingClinic from '@/views/user/bookings/Clinic'
import UserAnalysis from '@/views/user/Analysis'
import UserServices from '@/views/user/Services'

import UserConsultationService from '@/views/user/UserConsultationService'
import UserConsultationCheckup from '@/views/user/UserConsultationCheckup'
import UserConsultationClinic from '@/views/user/UserConsultationClinic'


import DoctorProfile from '@/views/doctor/Profile'
import DoctorOrders from '@/views/doctor/Orders'
import DoctorSchedule from '@/views/doctor/Schedule'
import DoctorScheduleWork from '@/views/doctor/ScheduleWork'
import DoctorOrdersId from '@/views/doctor/Consultation'


import ClinicProfile from '@/views/clinic/Profile'
import ClinicStaff from '@/views/clinic/Staff'
import ClinicOrders from '@/views/clinic/ClinicOrders'
import ClinicServices from '@/views/clinic/ClinicServices'
import ClinicCheckups from '@/views/clinic/ClinicCheckups'
import ClinicSchedule from '@/views/clinic/ClinicSchedule'
import ClinicServicesList from '@/views/clinic/ClinicServicesList'

import ClinicConsultation from '@/views/clinic/ClinicConsultation'
import ClinicServicesById from '@/views/clinic/ClinicServicesById'
import ClinicCheckupById from '@/views/clinic/ClinicCheckupById'
import ClinicDoctorWork from "@/views/clinic/ClinicDoctorWork";
import ClinicScheduleWork from "@/views/clinic/ClinicScheduleWork";
import Empty from "@/views/Empty"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/checkups'
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/about',
    name: 'WebSiteAbout',
    component: WebSiteAbout
  },
  {
    path: '/records',
    name: 'WebSiteRecords',
    component: WebSiteCheckups
  },
  {
    path: '/checkups',
    name: 'WebSiteCheckups',
    component: WebSiteCheckups
  },
  {
    path: '/partners',
    name: 'WebSitePartners',
    component: WebSitePartners
  },

  {
    path: '/user/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/services',
    name: 'UserServices',
    component: UserServices,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/bookings/services/my',
    name: 'UserBookingService',
    component: UserBookingService,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/bookings/checkups/my',
    name: 'UserBookingCheckup',
    component: UserBookingCheckup,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/bookings/clinics/my',
    name: 'UserBookingClinic',
    component: UserBookingClinic,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/consultations/service/:id',
    name: 'UserConsultationService',
    component: UserConsultationService,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/consultations/clinic/:id',
    name: 'UserConsultationClinic',
    component: UserConsultationClinic,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/consultations/checkup/:id',
    name: 'UserConsultationCheckup',
    component: UserConsultationCheckup,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/my-analysis',
    name: 'UserAnalysis',
    component: UserAnalysis,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/doctor/profile',
    name: 'DoctorProfile',
    component: DoctorProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/doctor/orders',
    name: 'DoctorOrders',
    component: DoctorOrders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/doctor/orders/:id',
    name: 'DoctorOrdersId',
    component: DoctorOrdersId,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/doctor/schedule',
    name: 'DoctorSchedule',
    component: DoctorSchedule,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/doctor/work',
    name: 'DoctorScheduleWork',
    component: DoctorScheduleWork,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clinic/profile',
    name: 'ClinicProfile',
    component: ClinicProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clinic/staff',
    name: 'ClinicStaff',
    component: ClinicStaff,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clinic/staff/:id',
    name: 'ClinicDoctorWork',
    component: ClinicDoctorWork,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clinic/scheduleWork',
    name: 'ClinicScheduleWork',
    component: ClinicScheduleWork,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clinic/orders',
    name: 'ClinicOrders',
    component: ClinicOrders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clinic/services',
    name: 'ClinicServices',
    component: ClinicServices,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clinic/services/:id',
    name: 'ClinicServicesById',
    component: ClinicServicesById,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/clinic/checkups',
    name: 'ClinicCheckups',
    component: ClinicCheckups,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clinic/checkups/:id',
    name: 'ClinicCheckupById',
    component: ClinicCheckupById,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/clinic/orders/:id',
    name: 'clinicConsultation',
    component: ClinicConsultation,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clinic/schedule',
    name: 'ClinicSchedule',
    component: ClinicSchedule,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clinic/services-list',
    name: 'ClinicServicesList',
    component: ClinicServicesList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    component: Empty,

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }

    document.body.classList.toggle('overflow-hidden')
    document.body.classList.toggle('overflow-ios')
    store.commit('openLogInDialog')

    next('/records')
  } else {
    next()
  }
})
export default router
