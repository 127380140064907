<template>
  <div class="modal" @click.self="closeModal">
    <div class="modal__content">
      <div class="modal__inner">
        <date-picker v-model="dateTime" :is-required='true'
                     :min-date="new Date()" :minute-increment="10" is-range
                     mode="date" value="multiple"/>
        <img alt="" class="modal__close" src="../../assets/icons/close.svg" @click.self="closeModal">

        <span class="access_time">{{ $t('доступноеВремя') }}</span>
        <select v-if="showSelect" required v-model="record_type" class="select bordered-success">
          <option :value="0">{{ $t('типЗаписи') }}</option>
          <option :value="1">{{ $t('онлайн') }}</option>
          <option :value="2">{{ $t('оффлайн') }}</option>
        </select>
        <select v-model="record_type_clinic" v-if="showClinickSelect" required class="select bordered-success">
          <option :value="1">{{ $t('записи') }}</option>
          <option :value="2">{{ $t('чекапы') }}</option>
        </select>

        <div class="modal__inner__time">
          <select v-model="selectTimes" class="select bordered-success"
                  multiple size="1">
            <option v-for="hour in hours" :key="hour.id" :value="hour.id">{{ hour.title_time }}</option>
          </select>
          <!--          <Timepicker v-model="fromTime" :maxHour="12" :minHour="8" class="timepicker" label="от"></Timepicker>-->
          <!--          <Timepicker v-show="toTime" :maxHour="24" :minHour="fromTime.hour" :minMinute="fromTime.minute + 20"-->
          <!--                      class="timepicker" label="до"></Timepicker>-->
        </div>
        <v-button v-if="clinic !== 0 && clinicSchedule === false" size="btn btn-success text-white w-100"
                  :value="$t('Сохранить')" @click="saveClinic"/>
        <v-button v-if="work" size="btn btn-success text-white w-100" :value="$t('Сохранить')" @click="saveWork"/>
        <v-button v-if="clinicSchedule" size="btn btn-success text-white w-100" :value="$t('Сохранить')"
                  @click="saveClinicSchedule"/>
        <v-button v-if="!work&& clinic === 0 && clinicSchedule === false" size="btn btn-success text-white w-100"
                  :value="$t('Сохранить')" @click="save"/>
      </div>
    </div>
  </div>
</template>

<script>
// import Timepicker from '@/components/Timepicker.vue'
import VButton from '@/components/btn/v-button'
import moment from "moment";

export default {
  components: {
    VButton
    // Timepicker
  },
  props: {
    work: {
      type: Boolean,
      default: false
    },
    clinicSchedule: {
      type: Boolean,
      default: false
    },
    clinic: {
      type: Number,
      default: 0
    },
    times: [],
    post_categories: [],
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showSelect: false,
      showClinickSelect: false,
      record_type_clinic: 0,
      record_type: 0,
      hours: [],
      selectTimes: [],
      dateTime: {
        start: null,
        end: null
      },
      fromTime: {
        hour: 8,
        minute: 0
      },
      toTime: {
        hour: 0,
        minute: 0
      }
    }
  },
  watch: {
    visible(newValue, oldValue) {
    }
  },
  mounted() {
    this.getHours()
    if (localStorage.getItem('role') == 'doctor') {
      let user = JSON.parse(localStorage.getItem('user'))
      if (user.requestType == 3) {
        this.showSelect = true
      } else {
        this.showSelect = false;
        this.record_type = user.requestType;

      }
    }
    if (localStorage.getItem('role') == 'clinic') {
      this.showClinickSelect = true
    }
  },
  methods: {
    async getHours() {
      var url = `${process.env.VUE_APP_API_URL}web/dictionaries/schedule-time`
      const result = await this.$http.post(url, {})
      this.hours = result.data.map(item => {
        return item
      })
    },
    async saveClinic() {
      const url = `${process.env.VUE_APP_API_URL}clinic/work-schedule/add`
      const result = await this.$http.post(url,
          {
            request_type: this.record_type,
            doctor_info_id: this.clinic,
            date_from: this.dateTime.start,
            date_to: this.dateTime.end,
            time_id: this.selectTimes
          }).catch(function (error) {
        if (localStorage.getItem('locale') == 'ru') {
          alert('На это время уже есть запись')
        } else if (localStorage.getItem('locale') == 'kk') {
          alert('Бұл жолғы рекорд қазірдің өзінде бар')
        } else {
          alert('There is already a record for this time')
        }
      })

      const data = result.data
      this.$emit('load-work')
      if (data) {
        this.messages = data
        this.loader = false
      }
      this.closeModal()
    },
    async saveWork() {
      if (this.record_type != 0) {
        const url = `${process.env.VUE_APP_API_URL}doctor/work-schedule/add`
        const result = await this.$http.post(url,
            {
              request_type: this.record_type,
              date_from: this.dateTime.start,
              date_to: this.dateTime.end,
              time_id: this.selectTimes
            }).catch(function (error) {
          if (localStorage.getItem('locale') == 'ru') {
            alert('На это время уже есть запись')
          }
          else if(localStorage.getItem('locale') == 'kk')
          {
            alert('Бұл жолғы рекорд қазірдің өзінде бар')
          }

          else
          {
            alert('There is already a record for this time')
          }
        })
        const data = result.data
        this.$emit('load-work')
        if (data) {
          this.messages = data
          this.loader = false
        }
        this.closeModal()
      } else {
        alert(this.$t('укажитеТип'))
      }
    },
    async saveClinicSchedule() {
      const url = `${process.env.VUE_APP_API_URL}clinic/clinic-work-schedule/add`
      const result = await this.$http.post(url,
          {
            record_type_clinic: this.record_type_clinic,
            date_from: this.dateTime.start,
            date_to: this.dateTime.end,
            time_id: this.selectTimes
          }).catch(function (error) {
        if (localStorage.getItem('locale') == 'ru') {
          alert('На это время уже есть запись')
        }
        else if(localStorage.getItem('locale') == 'kk')
        {
          alert('Бұл жолғы рекорд қазірдің өзінде бар')
        }

        else
        {
          alert('There is already a record for this time')
        }
      })
      const data = result.data
      this.$emit('load-work')
      if (data) {
        this.messages = data
        this.loader = false
      }
      this.closeModal()
    },
    async save() {
      var url = `${process.env.VUE_APP_API_URL}doctor/schedule/create`
      const result = await this.$http.post(url,
          {
            request_type: this.record_type,
            date_from: this.dateTime.start,
            date_to: this.dateTime.end,
            time_id: this.selectTimes
          }).catch(function (error) {
        if (localStorage.getItem('locale') == 'ru') {
          alert('На это время уже есть запись')
        }
        else if(localStorage.getItem('locale') == 'kk')
        {
          alert('Бұл жолғы рекорд қазірдің өзінде бар')
        }

        else
        {
          alert('There is already a record for this time')
        }
      })
      const data = result.data
      if (data) {
        this.messages = data
        this.loader = false
      }
      window.location.reload()
      this.closeModal()
    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.work {
  height: 40px !important;
}

.access_time {
  margin-top: 20px;
}

@media only screen and (max-width: 450px) {
  select,
  .select {
    height: 8vh;
    border: 1px solid green;
    width: 60vw !important;
  }
  .modal {
    background: rgba(93, 92, 92, 0.59);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;

    &__content {
      background: #FFFFFF;
      box-shadow: 0 0.26vw 11px 0.13vw rgba(0, 0, 0, 0.01);
      border-radius: 0.78vw;
      width: 100vw;
      height: 100vh;
      padding-top: 20vw;
      position: relative;
    }

    &__close {
      width: 4.91vw;
      height: 4.91vw;
      position: absolute;
      margin: 2vw;
      right: 1.17vw;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      width: 75%;
      margin: 1.04vw auto;
      align-items: center;
      height: 100%;

      &__time {
        margin: 1.04vw auto;
        display: flex;
        flex-direction: row;
      }
    }
  }

  .datepicker {
    &__wrapper {
      width: 70%;
    }
  }

  .timepicker {
    &:not(:first-child) {
      margin-left: 0.975vw;
    }
  }
}

@media only screen and (min-width: 451px) {
  select {
    width: 15vw !important;
    height: 8vh;
    border: 1px solid green
  }
  .modal {
    background: rgba(93, 92, 92, 0.59);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;

    &__content {
      background: #FFFFFF;
      box-shadow: 0 0.26vw 11px 0.13vw rgba(0, 0, 0, 0.01);
      border-radius: 0.78vw;
      width: 25vw;
      padding-top: 3vw;
      height: 60%;
      position: relative;
    }

    &__close {
      width: 0.91vw;
      height: 0.91vw;
      position: absolute;
      top: 21px;
      right: 1.17vw;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      width: 75%;
      margin: 1.04vw auto;
      align-items: center;
      height: 100%;

      &__time {
        margin: 1.04vw auto;
        display: flex;
        flex-direction: row;
      }
    }
  }

  .datepicker {
    &__wrapper {
      width: 70%;
    }
  }

  .timepicker {
    &:not(:first-child) {
      margin-left: 0.975vw;
    }
  }
}
</style>
