<template>
  <div v-if="show" class="upload__photo">
    <v-popup v-show="message" close-position="text-accent" @closePopup="closeAlertPopup">
      <div class="h2">
        <span class="center" v-html="message"></span>
        <div class="right">
          <v-button size="btn btn-success text-white m-1" value="OK" @click="confirmRemove"/>
          <v-button size="btn text m-1 " value="Oтмена" @click="closeAlertPopup"/>
        </div>
      </div>
    </v-popup>
    <img :src="img.url" size="sm" style="background-color: gainsboro; border-radius: 3px; width: 100px"/>
    <div class="upload__photo__info">
      <progress v-if="(uploadPercentage>0&&uploadPercentage!=100)" :value.prop="uploadPercentage"
                max="100"></progress>
    </div>
    <div>
      <img v-if="img.id" src="../assets/icons/remove.svg" @click="remove"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VPopup from '@/components/modals/v-popup'
import VButton from '@/components/btn/v-button'
import { deleteAlert } from '@/utils/functions'

export default {
  props: ['file', 'img'],
  name: 'UploadPhoto',
  components: {
    VButton,
    VPopup
  },
  data () {
    return {
      user: localStorage.user ? JSON.parse(localStorage.user) : null,
      show: true,
      uploadPercentage: 0,
      message: ''
    }
  },
  created () {
    if (this.file) {
      this.submitFile()
    }
  },
  methods: {
    closeAlertPopup () {
      this.message = ''
    },
    async confirmRemove () {
      this.show = false
      await this.$http.post(
        `${process.env.VUE_APP_API_URL}clinic/profile/gallery/remove`, {
          id: this.img.id,
          clinic_info_id: this.user.id
        }
      )
      this.updateProfile()
    },
    async remove () {
      this.message = deleteAlert('фото')
    },
    async updateProfile() {
      var urlProfile =''
      // eslint-disable-next-line eqeqeq
      var role = localStorage.getItem('role')
      // eslint-disable-next-line eqeqeq
      if (role == 'clinic') {
        urlProfile = 'clinic/profile'
      }
      // eslint-disable-next-line eqeqeq
      if (role == 'doctor') {
        urlProfile = 'doctor/profile'
      }
      const user = (await axios(process.env.VUE_APP_API_URL+urlProfile, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      })).data
      localStorage.setItem('user', JSON.stringify(user))
    },
    async submitFile () {
      this.hasFailed = null
      await axios.post(`${process.env.VUE_APP_API_URL}clinic/profile/gallery/upload`, this.file, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        }.bind(this)
      }).then((resp) => {
        this.img = {}
        this.img.id = resp.data.file_id
        this.img.url = resp.data.file_url
        this.uploadPercentage = 0
        this.file = undefined
        this.updateProfile()
      })
        .catch((error) => {
          this.hasFailed = true
          this.showPorgress = false
          this.uploadPercentage = 0
          // eslint-disable-next-line eqeqeq
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload__photo {
  margin: 20px 0;
  border-radius: 8px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FFFFFF;
  padding: 22px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.08), 0 2px 24px rgba(0, 0, 0, 0.08);

  &__info {
    padding-left: 40px;
    width: 85%;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & {
      progress {
        width: 90%;
        height: 30px;
      }
    }
  }
}
</style>
