<template>
  <div class="profile__info">
    <div class="web">
      <vue-title :title="$i18n.locale == 'kk'?doctor.name_kz:$i18n.locale?doctor.name:doctor.name_en"></vue-title>
      <page-title :title="$i18n.locale == 'kk'?doctor.name_kz:$i18n.locale?doctor.name:doctor.name_en" weight="md"/>
      <profile-info-block :value="'+'+doctor.phone |phoneFormat" :label="$t('номерТелефона')" size="sm"/>
      <profile-info-block
          :value="$i18n.locale == 'kk'?doctor.address_kz:$i18n.locale=='ru'?doctor.address:doctor.address_en"
          :label="$t('Адрес')" size="sm"/>
      <profile-info-block :value="doctor.email" label="E-mail" size="sm"/>
      <profile-info-block :value="doctor.work_time" :label="$t('времяРаботы')" size="sm"/>
    </div>
    <div class="mobile">
      <div class="mobile__center h1 p-1 center">
        {{ $i18n.locale == 'kk' ? doctor.name_kz : $i18n.locale == 'ru' ? doctor.name : doctor.name_en }}
      </div>
      <v-button :value="doctor.work_time" fa-icon="clock" size="bordered-danger long ptb1"/>
      <v-button :value="$i18n.locale == 'kk'?doctor.address_kz:$i18n.locale=='ru'?doctor.address:doctor.address_en"
                fa-icon="map-marker"
                size="ptb1 bordered-success long"/>
      <v-button :value="'+'+doctor.phone |phoneFormat" fa-icon="phone" rotation="90" size="ptb1 bordered-success long"/>
      <v-button :value="doctor.email" fa-icon="email" size="ptb1 bordered-success long"/>
    </div>
    <!--    <profile-info-block :value="$i18n.locale=='ru'?doctor.description:doctor.description_en" :label="$t('Описание')" size="sm"/>-->
    <profile-info-block
        :value="$i18n.locale == 'kk'?doctor.description_kz:$i18n.locale=='ru'?doctor.description: doctor.description_en ? doctor.description_en : desc "
        :label="$t('Описание')" size="sm"/>

    <br>
    <form enctype="multipart/form-data" method="post">
      <input v-show="false" ref="myPhotoFile" accept=".jpg, .jpeg, .png" multiple name="filename"
             required type="file"
             @change="uploadAvatar">
      <input v-show="false" type="submit">
      <div class="btn btn-success wauto web" @click="update">{{ $t('ДобавитьФотографии') }}</div>
      <div class="btn btn-success long mobile" @click="update">{{ $t('ДобавитьФотографии') }}</div>
    </form>
    <div class="edt logout btn text-danger" @click="logout">{{ $t('Выход') }}</div>
    <upload-photo v-for="img in doctor.clinic_images" :key="img.id" :img="img"/>
    <upload-photo v-for="(img,index) in uploadFile" :key="'file'+index" :file="img" @updateProfile="updateProfile"/>
  </div>
</template>

<script>
import PageTitle from '@/components/titles/page-title'
import ProfileInfoBlock from '@/components/user/profile-info-block'
import VPopup from '@/components/modals/v-popup'
import router from '@/router'
import axios from 'axios'
import VButton from '@/components/btn/v-button'
import UploadPhoto from '@/components/UploadPhoto'
import Inputmask from 'inputmask'

export default {
  name: 'clinic-info',
  data: function () {
    return {
      doctor: JSON.parse(localStorage.user),
      showPopup: false,
      updated: false,
      city: undefined,
      profession: undefined,
      cities: [],
      uploadFile: [],
      desc: "Medical center 2 (test)",
    }
  },
  components: {
    UploadPhoto,
    VButton,
    VPopup,
    ProfileInfoBlock,
    PageTitle
  },
  mounted() {
    this.doctor = JSON.parse(localStorage.user)
    var im = new Inputmask('+9 (999) 999 99 99')
    im.mask(document.getElementById('regphone'))
    var iin = new Inputmask('99:99 - 99:99')
    iin.mask(document.getElementById('regwork'))
  },
  created() {
    this.cities = this.$store.state.reference.cities
    this.city = this.getCityName()
  },
  methods: {
    changeCity(item) {
      this.city.id = item
    },
    changeProfession(item) {
      this.profession = item
    },
    uploadAvatar(event) {
      Array.from(event.target.files).forEach(file => {
        this.changeImg = 'load'
        this.url = URL.createObjectURL(file)
        var formData = new FormData()
        formData.append('gallery', file)
        this.uploadFile.push(formData)
      })
      this.updateProfile()
    },
    update() {
      this.$refs.myPhotoFile.click()
    },
    getCityName() {
      var data = this.cities
      return data.filter(item => {
        // eslint-disable-next-line eqeqeq
        return item.id + '' === this.doctor.city_id + ''
      })
    },
    logout() {
      this.$store.dispatch('logout')
      router.replace('/')
    },
    closeInfoPopup() {
      this.showPopup = false
      document.querySelector('body').classList.remove('overflow-hidden')
      document.querySelector('html').classList.remove('overflow-hidden')
      document.querySelector('#app').classList.remove('overflow-hidden')
      document.querySelector('.main-layout').classList.remove('overflow-hidden')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

.wauto {
  width: fit-content !important;
}

@media only screen and (max-device-width: 450px) {
  .profile__info {
    font-family: Gotham-Medium, sans-serif;

    & {
      .edt {
        cursor: pointer;
        color: $green;
        width: auto !important;
        text-align: left !important;
        font-family: Gotham-Medium, sans-serif;
        font-weight: 500 !important;
        font-size: 5vw;
        line-height: 8vw;
      }
    }
  }
  form {
    width: 100%;
  }

  .btn-success {
    background: $green !important;
    color: white !important;
  }

  .sinput {
    margin: 0 !important;
    font-family: Gotham-Pro, sans-serif;
    font-size: 4vw !important;
    color: #4d4d4d;
    font-weight: normal;
    background: white;
    border: 0.065vw solid #00938A;
    border-radius: 0.78vw;
    padding: 0.325vw 1.3vw;
    box-sizing: content-box !important;
    display: block;

    & {
      select {
        font-size: 4vw !important;
        color: #4d4d4d !important;
      }
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .profile__info {
    font-family: Gotham-Medium, sans-serif;

    & {
      .edt {
        cursor: pointer;
        color: $green;
        width: auto !important;
        text-align: left !important;
        font-family: Gotham-Medium, sans-serif;
        font-weight: 500 !important;
        font-size: 22px;
      }
    }
  }

  form {
    width: 100%;
  }

  .btn-success {
    background: $green !important;
    color: white !important;
  }
  textarea {
    line-height: 2vw;
    font-size: 1.6vw;
    font-weight: 500;
    font-style: normal;
    border: 1px solid $green !important;
    border-color: #0b7d75;
    resize: none;
    font-family: Gotham, sans-serif;
    background-color: rgba(0, 147, 138, 0.02);
  }
  textarea:focus {
    outline: none !important;
    border-color: $green;
    box-shadow: 0 0 2px $green;
  }
  .form-group {
    line-height: 1.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1.3vw !important;
    padding: 0 !important;

    & {
      input[type='button'],
      input[type='submit'] {
        font-size: 1.3vw;
        border-radius: 8px !important;
        padding: 16px;
      }
    }
  }

  .form-group input {
    font-family: Gotham, sans-serif;
    margin: 0 !important;
  }
  textarea::-webkit-scrollbar {
    width: 0.2em;
    padding-right: 10px;
  }
  textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  .sinput {
    font-family: Gotham-Pro, sans-serif;
    font-size: 1.3vw !important;
    color: #4d4d4d;
    font-weight: normal;
    background: white;
    border: 0.065vw solid #00938A;
    border-radius: 0.78vw;
    padding: 0.325vw 1.3vw;
    box-sizing: content-box !important;
    display: block;

    & {
      select {
        font-size: 1.3vw !important;
        color: #4d4d4d !important;
      }
    }
  }
}
</style>
