<template>
  <div class="preloader">
    <img alt="" src="../assets/img/preloader.gif">
  </div>
</template>

<script>
export default {
  name: 'preloader'
}
</script>

<style lang="scss" scoped>
.preloader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  justify-content: center;

  & img {
    width: 150px;
    height: 150px;
  }
}
</style>
