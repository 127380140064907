<template>
  <div class="profile__info">
    <vue-title :title="$i18n.locale == 'kk' ? doctor.name_kz:$i18n.locale=='ru'?doctor.name:doctor.name_en"></vue-title>
    <page-title :title="$i18n.locale == 'kk' ? doctor.name_kz:$i18n.locale=='ru'?doctor.name:doctor.name_en" weight="md"/>
    <profile-info-block :value="cityName" :label="$t('Город')" size="sm"/>
    <profile-info-block :value="'+'+doctor.phone | phoneFormat" :label="$t('номерТелефона')" size="sm"/>
    <profile-info-block v-if="doctor.requestType=='1'" :value="$t('онлайн')" :label="$t('типЗаписиПрофиль')" size="sm"/>
    <profile-info-block v-if="doctor.requestType=='2'" :value="$t('оффлайн')" :label="$t('типЗаписиПрофиль')"
                        size="sm"/>
    <profile-info-block v-if="doctor.requestType=='3'" :value="$t('онлайнИОфллайн')" :label="$t('типЗаписиПрофиль')"
                        size="sm"/>


    <profile-info-block :value="doctor.email" label="E-mail" size="sm"/>
    <profile-info-block :value="doctor.professions"
                        :label="$t('Специализация')" size="sm"/>
    <div class="edt logout btn text-danger" @click="logout">{{ $t('Выход') }}</div>
  </div>
</template>

<script>
import PageTitle from '@/components/titles/page-title'
import ProfileInfoBlock from '@/components/user/profile-info-block'
import router from '@/router'
import axios from "axios";

export default {
  name: 'doctor-info',
  data: function () {
    return {
      doctor: JSON.parse(localStorage.user),
      showPopup: false,
      updated: false,
      city: undefined,
      profession: undefined,
      cities: []
    }
  },
  components: {
    ProfileInfoBlock,
    PageTitle
  },
  mounted() {
    this.doctor = JSON.parse(localStorage.user)
  },
  async created() {
    const user = (await axios(`${process.env.VUE_APP_API_URL}doctor/profile`, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    })).data
    localStorage.setItem('user', JSON.stringify(user))
    this.profession = this.doctor.professions[0].id
    this.cities = this.$store.state.reference.cities
    this.getCities();
  },
  computed: {
    cityName() {
      if (this.$i18n.locale == 'ru') {
        return this.cities.find(el => el.id === this.doctor.city_id)?.title || ''
      }
      if (this.$i18n.locale == 'kk') {
        return this.cities.find(el => el.id === this.doctor.city_id)?.title_kz || ''
      }
      else {
        return this.cities.find(el => el.id === this.doctor.city_id)?.title_en || ''
      }
    }
  },
  methods: {
    getDate(date) {
      const months = {
        '01': this.$t('Января'),
        '02': this.$t('Февраля'),
        '03': this.$t('Марта'),
        '04': this.$t('Апреля'),
        '05': this.$t('Мая'),
        '06': this.$t('Июня'),
        '07': this.$t('Июля'),
        '08': this.$t('Августа'),
        '09': this.$t('Сентября'),
        '10': this.$t('Октября'),
        '11': this.$t('Ноября'),
        '12': this.$t('Декабря')
      }
      return date.slice(date.length - 2) + ' ' + months[date.slice(5, 7)] + ', ' + date.slice(0, 4)
    },
    async getCities() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}web/dictionaries/cities`, {});
        this.cities = result.data
      } catch (e) {
        throw e;
      }
    },
    logout() {
      this.$store.dispatch('logout')
      router.replace('/')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .profile__info {
    font-family: Gotham-Medium, sans-serif;

    & {
      .edt {
        cursor: pointer;
        color: $green;
        width: auto !important;
        text-align: left !important;
        font-family: Gotham-Medium, sans-serif;
        font-weight: 500 !important;
        font-size: 5vw;
        line-height: 8vw;
      }
    }
  }
  form {
    width: 100%;
  }

  .btn-success {
    background: $green !important;
    color: white !important;
  }
}

@media only screen and (min-device-width: 451px) {
  .profile__info {
    font-family: Gotham-Medium, sans-serif;

    & {
      .edt {
        cursor: pointer;
        color: $green;
        width: auto !important;
        text-align: left !important;
        font-family: Gotham-Medium, sans-serif;
        font-weight: 500 !important;
        font-size: 22px;
      }
    }
  }

  form {
    width: 100%;
  }

  .btn-success {
    background: $green !important;
    color: white !important;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    font-size: 1.3vw !important;
    line-height: 0 !important;
    padding: 0 !important;

    & {
      input[type='button'],
      input[type='submit'] {
        font-size: 1.3vw;
        border-radius: 8px !important;
        padding: 16px;
      }
    }
  }

  .form-group input {
    font-family: Gotham, sans-serif;
    margin: 0 !important;
  }
}
</style>
