<template>
  <div class="block-title h1">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'block-title',
  props: ['title']
}

</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .block-title {
    text-align: center;
  }
  .block-title {
    padding-top: 1.3vw;
    padding-bottom: 5vw;
  }
}
@media only screen and (min-device-width: 451px) {
  .block-title {
    padding-top: 3.25vw;
    padding-bottom: 2.08vw;
  }
}

.block-title {
  font-family: "Gotham-Medium", serif;
}
</style>
