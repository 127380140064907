<template>
  <div class="navigation-history f20">
    <span class="bold text-accent navigation-history-main ">{{$t('главная')}} </span> • <span class="navigation-history-second">{{ tab }}</span>
  </div>
</template>

<script>
export default {
  name: 'navigation-history',
  props: ['tab']
}
</script>

<style scoped lang="scss">
@import "../assets/css/colors";
.navigation-history {
  padding-top: 3.25vw;
  text-align: left;
  color: $gray3;
}

.navigation-history-main {
  font-family: Gotham-Medium, sans-serif;
}
.navigation-history-second{
  font-family: Gotham,sans-serif;
  color: $gray2;
}
</style>
