<template>
  <div class="profile">
    <vue-title :title="$t('бронирования')"/>
    <main-layout>
      <div class="profile__main">
        <left-navigation type="doctor"/>
        <div class="profile__main__content">
          <page-title :title="name" weight="normal"/>
          <v-toggle
              :options="options"
              @changeToggle="changeToggle"
          />
          <button
              style="cursor: pointer"
              class="btn-search export"
              @click="download"
          >{{ $t('Экспорт') }}
          </button>
          <order-item
              v-for="(order) in orders"
              :key="order.id"
              :order="order"
              :type="type"
          />
          <preloader v-show="loader"/>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import LeftNavigation from '@/components/user/left-navigation'
import PageTitle from '@/components/titles/page-title'
import VToggle from '@/components/toggle/v-toggle'
import OrderItem from '@/components/items/order-item'
import Preloader from '@/components/preloader'
import {handleView} from '@/utils/functions'
import moment from "moment";

export default {
  name: 'Orders',
  components: {
    Preloader,
    OrderItem,
    VToggle,
    PageTitle,
    LeftNavigation,
    MainLayout
  },
  data: function () {
    return {
      name: this.$t('бронирования'),
      mobileView: true,
      orders: [],
      loader: false,
      data: undefined,
      isTokenIsEmpty: null,
      showNav: false,
      type: 0,
      options: [
        {
          id: 0,
          title: this.$t('Новые')
        },
        {
          id: 1,
          title: this.$t('Подтвержденные')
        },
        {
          id: 2,
          title: this.$t('Завершенные')
        },
        {
          id: 3,
          title:  this.$t('Отменённые')
        }
      ]
    }
  },
  async created() {
    this.mobileView = handleView()
  },
  methods: {
    download() {
      var link = document.createElement('a');
      link.setAttribute('href', `${process.env.VUE_APP_API_URL}web/doctor_bookings/export?user_id=${JSON.parse(localStorage.getItem('user')).id}`);
      link.setAttribute('download', 'download');
      onload = link.click();
    },
    getDate(date) {
      const months = {
        '01': this.$t('Января'),
        '02': this.$t('Февраля'),
        '03': this.$t('Марта'),
        '04': this.$t('Апреля'),
        '05': this.$t('Мая'),
        '06': this.$t('Июня'),
        '07': this.$t('Июля'),
        '08': this.$t('Августа'),
        '09': this.$t('Сентября'),
        '10': this.$t('Октября'),
        '11': this.$t('Ноября'),
        '12': this.$t('Декабря')
      }
      return date.slice(date.length - 2) + ' ' + months[date.slice(5, 7)] + ', ' + date.slice(0, 4)
    },
    format_date(date, time) {
      if (date) {
        return this.getDate(moment(String(date)).format('YYYY-MM-DD')) + ' ' + time.slice(0, 5)
      }
    },
    changeToggle(item) {
      this.type = item
      this.orders = []
      this.getOrders()
    },
    scroll() {
      const element = document.getElementById('feedback-partner')
      element.scrollIntoView({behavior: 'smooth'})
    },
    async getOrders(u) {
      if(u){
        u=u.replace('http://','https://')
      }

      this.loader = true
      var url = `${process.env.VUE_APP_API_URL}doctor/bookings/get`
      const result = await this.$http.post(u != null ? u : url, {
        status: this.type
      })

      this.data = result.data
      const data = result.data.data.map(item => {
        item.date_time = this.format_date(item.date, item.time)
        this.orders.push(item)
        return item
      })
      if (data) {
        this.loader = false
      }
      if (data) {
        this.load = false
      }
    },
    scrollToBtm() {
      window.onscroll = () => {
        var bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 200 > document.documentElement.offsetHeight
        if (bottomOfWindow && !this.load) {
          if (this.data.next_page_url) {
            this.getOrders(this.data.next_page_url.replace('http://','https://'))
          }
        }
      }
    }
  },
  async mounted() {
    await this.getOrders()
    this.scrollToBtm()
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@import "../../assets/css/profile";
</style>
<style lang="scss" scoped>
.dropdown-border {
  span {
    display: block;
    padding: 0.5rem !important;


  }
}

@import "../../assets/css/colors";
@import "../../assets/css/profile";

.btn-search {
  font-family: PlumbKaz, sans-serif !important;
  border: none;
  background: #00938A;
  color: #ffffff;
  border-radius: 12px;
  padding: 6px 24px;
  font-size: 16px;

  &.export {
    margin-left: 12px;
    @media(max-width: 468px) {
      margin: 12px;
    }
  }

  @media(max-width: 468px) {
    padding: 7px 16px;
  }
}

.table {
  width: 100%;
  box-shadow: 14px 23px 64px 53px rgb(212 217 232 / 20%);
  border-radius: 3px;
  background: white;
  padding: 1rem;
  border: 1px solid gainsboro;

  & {
    tr {
      width: 100%;
      line-height: 5vh;
    }
  }
}

.text-center {
  text-align: center;
}

.clinic-card {
  margin: 16px 0;
  line-height: 20px;
  padding: 16px;
  font-size: 14px;
  background: #FFFFFF;
  box-shadow: 14px 23px 64px 53px rgba(212, 217, 232, 0.2);
  border-radius: 3.52941px;
  border: 1px solid gainsboro;

  &__row {
    display: flex;
    flex-direction: row;
    line-height: 30px;
  }

  &__col {
    line-height: 20px;
    display: flex;
    flex-direction: column;
    padding: 0 2px;
    flex-basis: 50%;
  }

  & {
    .dropbtn {
      width: 5px;
    }
  }
}
</style>
