<template>
  <div class="clinic">
    <v-popup
        v-show="message"
        @closePopup="closeInfoPopup"
    >
      <div class="popup__body h2">
        {{ message }}
      </div>
    </v-popup>
    <v-popup
        v-show="showPopup"
        :imgs="clinic.images"
        close-position="right-close"
        :title="$t('ЗаписьНаЧекап')"
        @closePopup="closeInfoPopup"
    >
      <div class="popup_main">
        <div class="popup__body">
          <form v-on:submit.prevent="submitOrderForm">
            <input
                ref="c_id"
                :value="clinic.id"
                required
                type="hidden"
            >
            <!--            <div class="form-group">-->
            <!--              Желаемая дата-->
            <!--              <el-date-picker-->
            <!--                  class="picker"-->
            <!--                  v-model="date"-->
            <!--                  type="date"-->
            <!--                  format="yyyy-MM-dd"-->
            <!--              />-->
            <!--            </div>-->
            <!--            <div class="form-group">-->
            <!--              Желаемое время-->
            <!--              <el-time-select-->
            <!--                  class="picker"-->
            <!--                  v-model="time"-->
            <!--                  :picker-options="{ start: '08:30', step: '00:15', end: '18:30' }">-->
            <!--              </el-time-select>-->
            <!--            </div>-->
            <div class="form-group">
              <div class="calendar_picker">
                <date-picker
                    v-model="dateTime"
                    :open="true"
                    :attributes="attrs"
                    :is-required='true'
                    :is24hr='true'
                    :disabled-dates="disableDates"
                    :available-dates='availableDates'
                    :minute-increment="30"
                    :timezone="timezone"
                    mode="date"
                    type="date"
                    value="multiple"
                    @update:from-page="changeMonth"
                />
                <el-select v-model="selectedTime" class="select_time vc-container" :placeholder="$t('выберитеВремя')">
                  <el-option
                      v-for="item in times"
                      :key="item.time_id"
                      :label="item.title_time"
                      :value="item.title_time"
                      :disabled="item.disabled"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="form-group">
              {{ $t('Вашеимя') }}
              <input
                  ref="name"
                  v-model="user.name"
                  class="input-sm bordered"
                  required
                  type="text"
              />
            </div>
            <div class="form-group">
              {{ $t('вашЭлектронныйАдрес') }}
              <input
                  ref="email"
                  v-model="user.email"
                  class="input-sm bordered"
                  required
                  type="email"

              />
            </div>
            <div class="form-group">
              <v-button
                  :disabled="load || errorMessage || !(role === 'user')"
                  size="btn btn-success text-white"
                  type="submit"
                  :value="$t('Записаться')"
              />
            </div>
          </form>
        </div>
        <div class="popup__footer"></div>
      </div>
      <!--      <div v-show="!(role === 'user')" class="text-danger">-->
      <!--        {{ $t('дляЗаписиАвторизуйтесь') }}-->
      <!--      </div>-->
    </v-popup>
    <div class="clinic__header">
      <div class="clinic__header__logo">
        <circle-img :pic="clinic.profile_photo" :name="clinic.name" size="md"/>
      </div>
      <div class="clinic__header__info">
        <div class="clinic__header__info__title">
          <div class="h2">
            {{ clinic.name }}
          </div>

          <div class="surveys">
            <div class="btn btn-secondary left visibility m-1">
              <span class="bold primary plumb-kaz m-1 ">{{ clinic.services.length }}</span> {{ $t('обследований') }}
            </div>
          </div>
          <div class="visibility">
            <span class="bold  plumb-kaz m-1 "><font-awesome-icon class="text-accent"
                                                                  icon="map-marker"/> {{
                $i18n.locale == 'kk' ? clinic.adress_kz : $i18n.locale == 'ru' ? clinic.address : clinic.address_en
              }}</span>
          </div>
          <div class="checkup-item-cell checkup-item-cell_center">
            <div class="price h2 bold primary ">{{ $t('от') }} {{ clinic.price }} {{ $t('тенге') }}</div>
          </div>
        </div>
        <div v-if="(role === 'user')" class="clinic__header__info__action">
          <v-button
              :disabled="!(role === 'user')"
              class="btn btn-success web text-white"
              size="sm"
              :value="$t('выбратьКлинику')"
              @click="showInfoPopup"
          />
          <v-button
              :disabled="!(role === 'user')"
              class="btn btn-success mobile long text-white"
              size="sm"
              :value="$t('выбратьКлинику')"
              @click="showInfoPopup"
          />
        </div>
        <div v-else class="text-danger">
          {{ $t('авторизуйтесьДляЗаписи') }}
        </div>
      </div>
    </div>
    <div class="clinic__body unvisibility">
      {{
        $i18n.locale == 'kk' ? clinic.description_kz : $i18n.locale == 'ru' ? clinic.description : clinic.description_en
      }}
    </div>
    <div class="clinic_service">
      <v-button
          v-for="service in clinic.services" :key="service.id"
          :disabled="load"
          :value=" $i18n.locale == 'kk' ?service.title_kz:$i18n.locale=='ru'?service.title:service.title_en"
          size="p-1 mr-1"
      />
    </div>
    <div class="clinic__footer">
      <div class="paddles">
        <font-awesome-icon
            ref="right"
            :class="scrollLeft <= 10 ? 'hidden' : ''"
            :icon="['fa','chevron-left']"
            class="left-paddle paddle "
            @click="scrollToLeft"
        />
        <font-awesome-icon
            :class="scrollLeft <= maxScroll ? '' : 'hidden'"
            :icon="['fa','chevron-right']"
            class="right-paddle paddle"
            @click="scrollToRight"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CircleImg from '@/components/img/circle-img'
import VButton from '@/components/btn/v-button'
import VPopup from '@/components/modals/v-popup'
import {successOrder} from '@/utils/functions'
import moment from "moment";

export default {
  name: 'clinic-item-2',
  components: {
    VPopup,
    VButton,
    CircleImg
  },
  data: function () {
    return {
      load: false,
      user: localStorage.user ? JSON.parse(localStorage.user) : {name: '', email: ''},
      role: localStorage.role ? localStorage.role : null,
      showPopup: false,
      scrollLeft: 0.0,
      maxScroll: 0.0,
      message: null,
      time: '',
      date: '',
      dateTime: moment(),
      timezone: '',
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          }
        }
      ],
      selectedTime: '',
      dates: [],
      availableDates: [],
      disableDates: [],
    }
  },
  props: ['clinic', 'checkupid'],
  computed: {
    errorMessage: {
      get() {
        if (this.dateTime === '' || this.selectedTime === '') return this.$t('датаОбязательна')
        // var h = this.dateTime.getHours()
        // var m = this.dateTime.getMinutes()
        // // eslint-disable-next-line eqeqeq
        // if (h == new Date().getHours() && m == new Date().getMinutes()) {
        //   return 'Поле дата и время обязательны для заполнения'
        // }
        return this.eMessage
      },
      set() {
      }
    },
    times() {
      const date = moment(this.dateTime).format('YYYY-MM-DD')
      return this.dates[date]?.times
    }
  },

  beforeUpdate() {
    this.showPopup && this.dates.length === 0 && this.getSchedule()
  },
  watch: {
    dateTime: function () {
      this.selectedTime = ''
    }
  },
  methods: {
    changeMonth(objOfDate, ...props) {
      const date = `${objOfDate.month}/01/${objOfDate.year}`
      this.dateTime = date


      this.showPopup && this.getSchedule()

    },
    async getSchedule() {
      try {
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}web/checkup-schedule`, {
              clinic_info_id: this.clinic.id,
              date: this.dateTime !== '' && this.dateTime != 'Invalid date' ? moment(this.dateTime).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),


            }
        )
        for (let i = 0; i < result.data.disable.length; i++) {
          for (let x = 0; x < result.data.all.length; x++) {
            if (result.data.disable[i].date == result.data.all[x].date) {
              for (let time = 0; time < result.data.all[x].times.length; time++) {
                if (result.data.all[x].times[time].title_time.substr(0, 5) == result.data.disable[i].time.substr(0, 5)) {
                  result.data.all[x].times[time].disabled = true
                }
              }
            }
          }
        }

        var today = new Date();
        var time = new Date();

        let Hour = time.getHours();
        let Minutes = time.getMinutes();

        time = Hour + ':' + Minutes

        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;
        console.log(result.data)

        for (let y = 0; y < result.data.all.length; y++) {
          if (result.data.all[y].date == today) {
            for (let x = 0; x < result.data.all[y].times.length; x++) {
              if (result.data.all[y].times[x].title_time.split('-')[1]) {
                var a = time;
                var b = result.data.all[y].times[x].title_time.split('-')[1]
                var amin = a.split(':')[0] * 60 + a.split(':')[1] * 1;
                var bmin = b.split(':')[0] * 60 + b.split(':')[1] * 1;
                if (bmin + 60 * 12 < amin) {
                  bmin += 60 * 24
                }
                var c = bmin - amin

                if (c <= 0) {
                  result.data.all[y].times[x].disabled = true
                }
              }
            }
          }
        }


        this.dates = result.data.all.reduce((acc, item) => {
          acc[item.date] = item;
          return acc;
        }, {})
        this.availableDates = result.data.all.filter(item => {
          return moment(item.date).isSameOrAfter(moment().format('YYYY-MM-DD'))
        }).map(item => ({
          start: moment(item.date).toDate(),
          end: moment(item.date).toDate(),
        }))

        this.disableDates = this.availableDates.length === 0 ? [{start: new Date(1970)}] : []
        this.dateTime = ''
      } catch (e) {
      }
    },
    async submitOrderForm() {
      const time = this.selectedTime.split('-')[0]

      const result = await this.$http.post(
          `${process.env.VUE_APP_API_URL}web/checkups/request-appointment`, {
            id_checkup: this.checkupid,
            id_clinic: this.clinic.id,
            date: moment(this.dateTime).format('YYYY-MM-DD'),
            time,
            name: this.$refs.name.value,
            email: this.$refs.email.value
          }
      ).catch(function (error) {
        if (localStorage.getItem('locale') == 'ru') {
          alert('На это время уже есть запись')
        } else if (localStorage.getItem('locale') == 'kk') {
          alert('Бұл жолғы рекорд қазірдің өзінде бар')
        } else {
          alert('There is already a record for this time')
        }
      })
      if (result) {
        this.message = successOrder()
        setTimeout(() => {
          window.location.href = result.data.redirect_url
        }, 2000)
        this.load = false
        this.date.value = ''
        this.time.value = ''
      }
    },
    showInfoPopup() {

      if (this.role != 'user') {
        document.getElementById('login').click()
      } else {
        this.showPopup = true
        document.querySelector('body').classList.add('overflow-hidden')
        document.querySelector('html').classList.add('overflow-hidden')
        document.querySelector('.main-layout').classList.add('overflow-hidden')
        // if (!this.user) {
        //   this.message = 'У вас нет доступа, авторизуйтесь'
        // } else {

        // }
      }

    },
    closeInfoPopup() {
      this.message = null
      this.showPopup = false
      document.querySelector('body').classList.remove('overflow-hidden')
      document.querySelector('html').classList.remove('overflow-hidden')
      document.querySelector('.main-layout').classList.remove('overflow-hidden')
    },
    updateScroll() {
      this.scrollLeft = this.$refs.pro.scrollLeft + 1
    },
    scrollToLeft() {
      // this.$refs.pro.scrollTo((this.scrollLeft = this.scrollLeft - 100), { behavior: 'smooth' })
      this.$refs.pro.scroll({
        left: this.scrollLeft = this.scrollLeft - 150,
        behavior: 'smooth'
      })
    },
    scrollToRight() {
      // this.$refs.pro.scrollTo((this.scrollLeft = this.scrollLeft + 100), { behavior: 'smooth' })
      this.$refs.pro.scroll({
        left: this.scrollLeft = this.scrollLeft + 150,
        behavior: 'smooth'
      })
    }
  },
  created() {
    if (this.$refs.pro) {
      this.maxScroll = this.$refs.pro.scrollWidth - this.$refs.pro.clientWidth
      this.$refs.pro.addEventListener('scroll', this.updateScroll)
    }
  },
  mounted() {
    if (this.$refs.pro) {
      this.maxScroll = this.$refs.pro.scrollWidth - this.$refs.pro.clientWidth
      this.$refs.pro.addEventListener('scroll', this.updateScroll)
    }
  }
}
</script>

<style>
.el-input__icon {
  line-height: 1 !important;
}

.picker input {
  font-family: Gotham-Pro, sans-serif;
  font-weight: normal;
  background: white;
  border: 0.065vw solid #00938A;
  border-radius: 0.78vw;
  height: 29px;
  font-size: 14px;
}

.picker input:hover {
  border: 0.065vw solid #00938A;
}
</style>

<style lang="scss" scoped>
.is-disabled {
  background-color: red !important;
  color: white !important;
}

.is-disabled:hover {
  background-color: red !important;
  color: white !important;
}


@import "../../assets/css/colors";

.surveys {
  display: flex;
  width: fit-content;
}

.clinic_service {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.calendar_picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.select_time {
  width: 100%;
  max-width: 250px;
  margin-top: 20px;
}

.picker {
  width: 100% !important;
}

@media only screen and (max-device-width: 450px) {
  .btn {
    width: 100% !important;
  }
  .visibility {
    display: block !important;
    font-size: 4vw;
    margin: 2vw 0;
  }
  .unvisibility {
    display: none !important;
  }
  .clinic {
    font-size: 1.17vw;
    padding: 4vw 3.25vw 3.25vw 2.08vw;
    display: flex;
    text-align: left !important;
    flex-direction: column;
    margin-bottom: 5vw;
    background-color: $white;
    font-family: PlumbKaz, sans-serif;
    box-shadow: 0 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
    border-radius: 0.78vw;
    justify-content: space-between;

    &__header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__logo {
        display: flex;
        justify-content: left;
        width: 100%;
        margin: 2vw;
      }

      &__info {
        margin: 2vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &__title {
          width: 100%;
          display: flex;
          line-height: 5vw;
          margin: 2vw 0;
          flex-direction: column;
          justify-items: center;
          justify-content: space-between;

          &__price {
            margin: 2vw 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 17vw;
            font-size: 3vw;
          }
        }

        &__action {
          margin: 2vw 0;
          width: 100%;

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    &__body {
      font-size: 4vw;
      margin: 1.3vw;
    }

    &__footer {
      position: relative;
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      scroll-behavior: smooth;

      &__profession {
        margin: 0 1.04vw;
        max-width: 58rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .paddles {
    }

    .paddle {
      position: absolute;
      top: 0.8rem;
      bottom: 0;
      width: 1em;
    }

    .left-paddle {
      left: -0.325vw;
    }

    .right-paddle {
      right: -0.325vw;
    }

    .hidden {
      display: none;
    }

    .hidden {
      display: none;
    }

    .popup__header {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;

      &__name {
        margin: 1.04vw;
      }

      &__action {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    }

    .popup_main {
      width: 100%;
    }

    .popup__body {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__action {
        padding: 1.04vw 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }

      &__docs,
      &__services {
        position: relative;
        width: 500px;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-behavior: smooth;

        &__item {
          margin: 0.65vw 0;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          -webkit-overflow-scrolling: touch;
          overflow-x: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    .circle-border-icon {
      border-radius: 50%;
      padding: 0.78vw;
      color: white;
      background-color: darkorange;
    }

    .v-title {
      font-size: 1.3vw;
      padding: 0.65vw 0 0 0;
    }

    form {
      width: 100%;
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .visibility {
    display: none !important;
  }
  .unvisibility {
  }
  .clinic {
    font-size: 1.17vw;
    padding: 2.08vw 3.25vw 3.25vw 2.08vw;
    display: flex;
    text-align: left !important;
    flex-direction: column;
    margin-bottom: 1.625vw;
    background-color: $white;
    font-family: PlumbKaz, sans-serif;
    box-shadow: 0 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
    border-radius: 0.78vw;
    justify-content: space-between;

    &__header {
      display: flex;
      flex-direction: row;

      &__logo {
        margin: 1.3vw;
      }

      &__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &__title {
          display: flex;
          flex-direction: row;
          justify-items: center;
          justify-content: space-between;

          &__price {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 1.3vw;
            width: 13vw;
          }
        }

        &__action {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    &__body {
      margin: 1.3vw;
    }

    &__footer {
      position: relative;
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      scroll-behavior: smooth;

      &__profession {
        margin: 0 1.04vw;
        max-width: 58rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .paddles {
    }

    .paddle {
      position: absolute;
      top: 0.8rem;
      bottom: 0;
      width: 1em;
    }

    .left-paddle {
      left: -0.325vw;
    }

    .right-paddle {
      right: -0.325vw;
    }

    .hidden {
      display: none;
    }

    .hidden {
      display: none;
    }

    .popup__header {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;

      &__name {
        margin: 1.04vw;
      }

      &__action {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    }

    .popup_main {
      width: 100%;
    }

    .popup__body {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__action {
        padding: 1.04vw 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }

      &__docs,
      &__services {
        position: relative;
        width: 500px;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-behavior: smooth;

        &__item {
          margin: 0.65vw 0;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          -webkit-overflow-scrolling: touch;
          overflow-x: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    .circle-border-icon {
      border-radius: 50%;
      padding: 0.78vw;
      color: white;
      background-color: darkorange;
    }

    .v-title {
      font-size: 1.3vw;
      padding: 0.65vw 0 0 0;
    }

    form {
      width: 100%;
    }
  }
}

</style>
