<template>
  <div class="circle">
    <div
        v-if="pic && !loader"
        :class="size"
        style=" background-position: center;"
        class="circle__img"
        :style="{ backgroundImage: 'url(' + pic + ')' }"
    ></div>
    <div
        v-if="!pic"
        :class="size"
        class="circle__img"
    >
      <p class="initials">{{ nameFam }}</p>
      <!--        :style="{ backgroundImage: 'url(/storage/person.png)' }"-->
    </div>
    <preloader v-if="loader && pic"/>
  </div>
</template>

<script>
import Preloader from '@/components/preloader'

export default {
  components: {Preloader},
  props: ['pic', 'size', 'name', 'loader'],
  name: 'circle-img',
  computed: {
    nameFam() {
        if (this.name) {
          const [name, surname] = this.name?.split(' ')
          if(surname){
            return name[0] + surname[0]
          }
         else{
            return name[0]
          }


        } else {
          return this.name
        }
    }
  }
}
</script>

<style lang="scss" scoped>

.chat__item {
  .initials {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 450px) {
  .initials {
    font-size: 24px;
  }
  .circle {
    display: flex;
    justify-content: center;
    align-items: initial;
    height: auto;
    border-radius: 50%;

    &__img {
      background-position: center;
      width: 20vw !important;
      height: 20vw !important;
      background-color: darkgrey;
      border-radius: 50%;
      background-size: cover;
      box-shadow: 2vw 3vw 2.6vw rgba(212, 217, 232, 0.2);
      display: flex;
      font-family: Gotham-Pro, sans-serif;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 4vw;
      color: white;
    }

    .sm {
      width: 20vw !important;
      height: 20vw !important;
      font-size: 6vw !important;
    }

    .xsm {
      width: 10vw !important;
      height: 10vw !important;
      font-size: 4vw !important;
    }

    .xxsm {
      width: 5vw !important;
      height: 5vw !important;
      font-size: 2vw !important;
    }

    .md {
      width: 15vw !important;
      height: 15vw !important;
    }
  }
}

@media only screen and (min-width: 451px) {
  .initials {
    font-size: 32px;
  }
  .circle {
    display: flex;
    justify-content: center;
    align-items: initial;
    height: auto;
    border-radius: 50%;

    &__img {
      background-position: center;
      background-color: darkgrey;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-size: cover;
      box-shadow: 1.3vw 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
      display: flex;
      font-family: Gotham-Pro, sans-serif;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 3.25vw;
      color: white;
    }

    .sm {
      width: 6.5vw !important;
      height: 6.5vw !important;
      font-size: 1.95vw !important;
    }

    .xsm {
      width: 3.9vw !important;
      height: 3.9vw !important;
      font-size: 1.3vw !important;
    }

    .xxsm {
      width: 43px !important;
      height: 43px !important;
      font-size: 0.52vw !important;
    }

    .md {
      width: 120px !important;
      height: 120px !important;
    }
  }
}
</style>
