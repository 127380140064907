<template>
  <div class="analysis__item">
    <div class="analysis__item__logo">
      <font-awesome-icon icon="file-word" size="lg"/>
    </div>
    <div class="analysis__item__body">
      <h2>{{ name }}</h2>
    </div>
    <div class="analysis__item__action">
      <a target="_blank" v-bind:href="url">
        <font-awesome-icon icon="download" size="lg"/>
      </a>
      <a v-if="user" @click.prevent="handleClick" class="trash_icon">
        <font-awesome-icon icon="trash" size="lg"/>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'analysis-item',
  props: ['name', 'url', 'user'],
  components: {},
  methods:{
    handleClick(){
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-device-width: 450px) {
  h2 {
    padding: 0 4vw;
    font-size: 4vw !important;
  }
  div p {
    font-family: Gotham-Medium, sans-serif;
    font-weight: 500;
    font-size: 3vw;
  }

  .analysis__item {
    padding: 4vw;
    display: flex;
    flex-direction: row;
    box-shadow: 0 0.95vw 2.6vw rgba(212, 217, 232, 0.2);
    background-color: white;
    margin: 2.625vw;
    border-radius: 2.78vw;

    &__logo {
      margin: 0.52vw;
      width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;

      & {
        img {
          border-radius: 50%;
          box-shadow: 0.13vw 0.13vw 0.65vw 0.65vw rgba(212, 217, 232, 0.2);
        }
      }
    }

    &__body {
      width: 85%;
      display: flex;
      //align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &__action {
      width: 5%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      & .trash_icon{
        margin-left: 12px;
      }
    }
  }
}

@media only screen and (min-device-width: 451px) {
  div p {
    font-family: Gotham-Medium, sans-serif;
    font-weight: 500;
    font-size: 1.69vw;
  }

  .analysis__item {
    padding: 1.04vw;
    display: flex;
    flex-direction: row;
    box-shadow: 0 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
    background-color: white;
    margin-bottom: 1.625vw;
    border-radius: 0.78vw;

    &__logo {
      margin: 0.52vw;
      width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;

      & {
        img {
          border-radius: 50%;
          box-shadow: 0.13vw 0.13vw 0.65vw 0.65vw rgba(212, 217, 232, 0.2);
        }
      }
    }

    &__body {
      width: 85%;
      display: flex;
      //align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &__action {
      width: 5%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      & .trash_icon{
        margin-left: 12px;
      }
    }
  }
}
</style>
