<template>
  <div class="checkups">
    <vue-title :title="navTitle"></vue-title>
    <main-layout>
      <cover class="cover-content"
             :covertype="currentRouteName ==='WebSiteCheckups' ?'cover-main-checkups' :'cover-main-records'"
             to-scroll="services"/>
      <div class="content">
        <v-block-title :title="$t('КакЭтоРаботает')"/>
        <v-how-text :mobile-view="this.mobileView"/>
        <v-block-title :title="$t('Преимущества')"/>
        <advantage :mobile-view="this.mobileView"/>
        <v-block-title :title="$t('ВопросОтвет')"/>
        <v-answer-component/>
        <div class="title">
          <v-block-title id="services" :title="$t('услуги')"/>
          <Search v-model="search_query" @click="handleClick"/>
        </div>
        <v-checkup ref="component" :mobile="this.mobileView" view-index="1" :search="search_query"/>
        <v-block-title :title="$t('Отзывы')"/>
        <v-comments :mobile="this.mobileView"/>
      </div>
    </main-layout>
  </div>
</template>

<script>

import MainLayout from '@/views/MainLayout'
import vBlockTitle from '@/components/titles/block-title'
import Cover from '@/components/cover'
import vComments from '@/components/comment/comments'
import vCheckup from '@/components/checkups/checkup'
import VHowText from '@/components/v-how-text'
import VAnswerComponent from '@/components/answer/v-answer-component'
import Advantage from '@/components/adv/advantage'
import {handleView} from '../utils/functions.js'
import Search from "@/components/Search";

export default {
  name: 'Checkups',
  components: {
    Search,
    Advantage,
    VAnswerComponent,
    VHowText,
    MainLayout,
    vBlockTitle,
    Cover,
    vComments,
    vCheckup
  },
  data: function () {
    return {
      search_query: '',
      nav_title: this.$t('чекапы'),
      mobileView: true,
      showNav: false,
      page: ''
    }
  },
  created() {
    if (this.$route.query.search_query) {
      this.search_query = this.$route.query.search_query
    }
    this.mobileView = handleView()
  },
  methods: {
    handleClick() {
      this.$refs.component.clear()
      // this.search_query = ''
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    navTitle() {
      return this.$route.name === 'WebSiteCheckups' ? this.$t('чекапы') : 'Записи';
    }
  }
}
</script>

<style scoped>
.nav__item {
  font-size: 18px;
  padding: 12px 48px;
  border-radius: 12px;
}

@media (max-width: 960px) {
  .nav__item {
    padding: 7px 28px;
    border-radius: 8px;
  }

  .title {
    flex-wrap: wrap;
  }
}

@media (min-device-width: 451px) {
  .block-title {
    padding: 0;
  }
}

@media (max-device-width: 450px) {
  .nav__item {
    font-size: 4vw !important;
  }
}

.title {
  margin: 32px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.service-title.divsm {
  width: auto;
}
</style>
