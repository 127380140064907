<template>
  <div class="record">
    <vue-title :title="nav_title"></vue-title>
    <main-layout>
      <cover class="cover-content" covertype="cover-main-records" to-scroll="services"/>
      <div class="content">
        <v-block-title :title="$t('КакЭтоРаботает')"/>
        <v-how-text :mobile-view="this.mobileView"/>
        <v-block-title :title="$t('Преимущества')"/>
        <advantage :mobile-view="this.mobileView"/>
        <v-block-title :title="$t('ВопросОтвет')"/>
        <v-answer-component/>
        <div class="title">
          <v-block-title id="services" :title="$t('услуги')"/>
          <Search v-model="search_query" @click="handleClick"/>
        </div>
        <v-service ref="component" :search="search_query" :mobile="this.mobileView" view-index="2"/>
        <v-block-title :title="$t('Отзывы')"/>
        <v-comments :mobile="this.mobileView"/>
      </div>
    </main-layout>
  </div>
</template>

<script>

import MainLayout from '@/views/MainLayout'
import vBlockTitle from '@/components/titles/block-title'
import Cover from '@/components/cover'
import vComments from '@/components/comment/comments'
import vService from '@/components/services/service'
import VHowText from '@/components/v-how-text'
import VAnswerComponent from '@/components/answer/v-answer-component'
import Advantage from '@/components/adv/advantage'
import {handleView} from '@/utils/functions'
import Search from "@/components/Search";

export default {
  name: 'Records',
  components: {
    Search,
    Advantage,
    VAnswerComponent,
    VHowText,
    MainLayout,
    vBlockTitle,
    Cover,
    vComments,
    vService
  },
  data: function () {
    return {
      search_query: '',
      nav_title: 'Записи',
      mobileView: true,
      showNav: false
    }
  },
  created() {
    this.mobileView = handleView()
  },
  methods: {
    handleClick() {
      this.$refs.component.getService()
      // this.search_query = ''
    }
  }
}
</script>
<style scoped>

@media (max-width: 960px) {
  .title {
    flex-wrap: wrap;
  }
}

@media (min-device-width: 451px) {
  .block-title {
    padding: 0;
  }
}

.title {
  margin: 32px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.service-title.divsm {
  width: auto;
}
</style>
