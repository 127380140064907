<template>
  <div class="service">
    <vue-title :title="name"></vue-title>
    <main-layout>
      <div class="profile__main">
        <left-navigation
            filter="true"
            @changeRequestType="changeRequestType"
            @changeCity="changeCity"
            @changeSum="changeSum"
            @changeType="changeType"
        />
        <div class="profile__main__content">
          <div class="title">
            <page-title
                :title="name"
                weight="normal"
            />
            <search v-model="filterQuery.search_query" @click="getService('',true)"/>
          </div>
          <service-title
              size="sm"
              @change="sortBy"
          />
          <v-popup v-show="filter" :value="$t('Фильтр')" @closePopup="filter=false">
            <div class="mob_filter">
              <div v-if="mobileView" style="display: flex;width: 100%">
                <v-service-filter :mobile="true" selected="3" @changeRequestType="changeRequestType"
                                  @changeCity="changeCity" @changeSum="changeSum"
                                  @changeType="changeType"/>
              </div>
            </div>
          </v-popup>
          <div class="mobile_filter_btn">
            <v-button v-if="mobileView" fa-icon="sliders-h" size="success long" :value="$t('Фильтр')"
                      @click="filter=true"/>
          </div>
          <div v-if="type == 1 || !type || type == null">
            <service-item
                v-for="(service) in services"
                v-show="type == 1 || type == null"
                :key="service.id"
                :mobile="mobileView"
                :service="service"
            />
          </div>
          <div v-if="type == 2 && !checkup_id">
            <clinic-item
                v-for="(service) in services"
                v-show="type == 2"
                :key="service.id"
                :clinic="service"
                :mobile="mobileView"
            />
          </div>
          <div
              v-if="type == 2 && checkup_id"
          >
            <clinic-item-2
                v-for="(service) in services"
                v-show="type == 2"
                :key="service.id"
                :checkupid="checkup_id"
                :clinic="service"
                :mobile="mobileView"
            />
          </div>
          <div v-if="type == 3">
            <checkup-item
                v-for="(service) in services"
                v-show="type == 3"
                :key="service.id"
                :checkup="service"
                :mobile="mobileView"
                @selectClinic="selectClinic"
            />
          </div>
          <preloader v-show="load"/>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import VPopup from '@/components/modals/v-popup'
import vServiceFilter from '@/components/checkups/service-filter'
import MainLayout from '@/views/MainLayout'
import LeftNavigation from '@/components/user/left-navigation'
import ServiceItem from '@/components/services/service-item'
import ServiceTitle from '@/components/titles/service-title'
import PageTitle from '@/components/titles/page-title'
import Preloader from '@/components/preloader'
import CheckupItem from '@/components/checkups/checkup-item'
import ClinicItem from '@/components/clinic/clinic-item'
import ClinicItem2 from '@/components/clinic/clinic-item-2'
import {handleView} from '@/utils/functions'
import Search from "@/components/Search";
import VButton from '@/components/btn/v-button'

export default {
  name: 'Services',
  components: {
    VPopup,
    vServiceFilter,
    Search,
    ClinicItem2,
    ClinicItem,
    CheckupItem,
    Preloader,
    PageTitle,
    ServiceTitle,
    ServiceItem,
    LeftNavigation,
    MainLayout,
    VButton
  },
  data: function () {
    return {
      filter: false,
      services: [],
      checkup_id: undefined,
      RequestType: '3',
      name: this.$t('услуги'),
      service: '',
      data: {},
      mobileView: false,
      load: false,
      filterQuery: {
        search_query: ''
      },
      startTimer: false,
      type: 1
    }
  },
  async created() {
    await this.getService()
    this.mobileView = handleView()
  },
  methods: {
    changeRequestType(val) {
      if (val == 1) {
        this.RequestType = "3"
      } else if (val == 3) {
        this.RequestType = "1"
      } else if (val == 2) {
        this.RequestType = "2"
      }
      this.services = []
      this.getService()
    },
    selectClinic(val) {
      this.checkup_id = val
      this.services = []
      this.getService()
    },
    changeType(even) {
      this.checkup_id = null
      this.type = even
      this.services = []
      this.getService()
    },
    changeSum(min, max) {
      this.filterQuery.price_from = min
      this.filterQuery.price_to = max
      this.callAfterSort()
    },
    changeCity(even) {
      this.filterQuery.city_id = even
      this.callAfterSort()
    },
    sortBy(even) {
      this.filterQuery.sort_by = even
      this.services = []
      this.getService()
    },
    callAfterSort() {
      if (!this.startTimer) {
        this.startTimer = true
        setTimeout(() => {
          this.services = []
          this.getService()
          this.startTimer = false
        }, 3000)
      }
    },
    async getService(url = '',clear=false) {
      url = url.replace('http://', 'https://')
      // this.services = []
      var textType = 'services'
      // eslint-disable-next-line eqeqeq
      if (this.type == 1) {
        textType = 'services'
        this.filterQuery.requestType = this.RequestType
        // eslint-disable-next-line eqeqeq
      } else if (this.type == 2) {
        textType = 'clinics'
        // eslint-disable-next-line eqeqeq
      } else if (this.type == 3 || this.type == null) {
        textType = 'checkups'
        if (this.checkup_id) {
          textType = 'checkups/get-clinics'
          this.filterQuery.id_checkup = this.checkup_id
        }
      }
      this.filterQuery.locale=this.$i18n.locale
      this.load = true
      // this.filterQuery.search_query = this.search
      const result = await this.$http.post(
          url ? url : `${process.env.VUE_APP_API_URL}web/` + textType,
          this.filterQuery
      )
      this.data = result.data
      var data = null
      if(clear==true){
        this.services = []
      }

      if (this.checkup_id) {
        data = result.data.map(item => {
          this.services.push(item)
          this.type = 2
          return item
        })
      } else {
        data = result.data.data.map(item => {
          this.services.push(item)
          return item
        })
      }
      if (data) {
        this.load = false
      }
    },
    scroll() {
      window.onscroll = () => {
        var bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 200 > document.documentElement.offsetHeight
        if (bottomOfWindow && !this.load) {
          if (this.data.next_page_url) {
            this.getService(this.data.next_page_url)
          }
        }
      }
    }
  },
  mounted() {
    this.scroll()
  }
}
</script>

<style scoped>
.title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .title {
    flex-wrap: wrap;
  }
}

.service-title.divsm {
  width: auto !important;
}
</style>
