<template>
  <div class="profile">
    <vue-title :title="$t('личныеДанные')"/>
    <main-layout>
      <div class="profile__main">
        <left-navigation type="doctor"/>
        <div class="profile__main__content">
          <profile-title padding="p-2" :title="$t('личныеДанные')"/>
          <div class="profile__main__content__sub">
            <div class="profile__main__content__sub__img">
              <circle-img
                  :name=" $i18n.locale == 'kk' ? doctor.name_kz:$i18n.locale == 'ru' ? doctor.name : doctor.name_en"
                  :loader="changeImg" :pic="url?url:doctor.profile_image"/>
              <form enctype="multipart/form-data" method="post">
                <input v-show="false" ref="myFile" accept=".jpg, .jpeg, .png" name="filename" type="file"
                       @change="uploadAvatar">
                <input v-show="false" type="submit">
                <div class="profile__main__content__sub__img__edit" @click="fileUpload">{{ $t('Изменить') }} <img
                    src="../../assets/icons/ant-design_edit-filled.svg"/></div>
              </form>
            </div>
            <doctor-info/>
          </div>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import LeftNavigation from '@/components/user/left-navigation'
import ProfileTitle from '@/components/titles/profile-title'
import DoctorInfo from '@/components/doctor/doctor-info'
import CircleImg from '@/components/img/circle-img'
import axios from 'axios'
import {handleView} from '@/utils/functions'

export default {
  name: 'Profile',
  components: {
    CircleImg,
    DoctorInfo,
    ProfileTitle,
    LeftNavigation,
    MainLayout
  },
  data: function () {
    return {
      changeImg: '',
      mobileView: true,
      isTokenIsEmpty: null,
      showNav: false,
      doctor: JSON.parse(localStorage.user),
      url: ''
    }
  },
  async created() {
    this.mobileView = handleView()

  },
  methods: {
    async uploadAvatar(event) {
      this.changeImg = 'load'
      var file = event.target.files[0]
      this.url = URL.createObjectURL(file)
      var formData = new FormData()
      formData.append('avatar', file)
      await axios.post(`${process.env.VUE_APP_API_URL}doctor/profile/upload-avatar`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function () {
      })
          .catch(function () {
          })
      this.updateProfile()
      this.changeImg = ''
    },
    fileUpload() {
      this.$refs.myFile.click()
    },
    scroll() {
      const element = document.getElementById('feedback-partner')
      element.scrollIntoView({behavior: 'smooth'})
    },
    async updateProfile() {
      var urlProfile = `${process.env.VUE_APP_API_URL}user/profile`
      // eslint-disable-next-line eqeqeq
      var role = localStorage.getItem('role')
      // eslint-disable-next-line eqeqeq
      if (role == 'clinic') {
        urlProfile = 'api/clinic/profile'
      }
      // eslint-disable-next-line eqeqeq
      if (role == 'doctor') {
        urlProfile = 'api/doctor/profile'
      }
      const user = (await axios(urlProfile, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      })).data
      localStorage.setItem('user', JSON.stringify(user))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@import "../../assets/css/profile";
</style>
