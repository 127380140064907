<template>
  <div class="checkup-item">
    <v-popup v-show="showPopup" :title="$t('личныеДанные')" @closePopup="closeInfoPopup">
      <div v-show="!(role === 'user')" class="text-danger">
        {{ $t('дляЗаписиАвторизуйтесь') }}
      </div>
    </v-popup>
    <div class="checkup-item-logo">
      <circle-img :pic="checkup.image"
                  :name="$i18n.locale=='kz'?checkup.title_kz:$i18n.locale=='ru'?checkup.title:checkup.title_en"/>
    </div>
    <div class="checkup-item-cell">
      <div class="checkup-item-row">
        <div class="checkup-item-cell ">
          <div class="header__title h2">
            {{ $i18n.locale == 'kk' ? checkup.title_kz : $i18n.locale == 'ru' ? checkup.title : checkup.title_en }}
          </div>
          <div class="checkup-item-info plumb-kaz">
            {{
              $i18n.locale == 'kk' ? checkup.description_kz : $i18n.locale == 'ru' ? checkup.description : checkup.description_en
            }}
          </div>
        </div>
        <div v-if="mobile" class="service__info">
          <div class="checkup-item-cell">
            <div class="btn btn-secondary">
              <span class="bold primary plumb-kaz">{{ checkup.clinics_count }}</span> {{ $t('клиник') }}
            </div>
          </div>
          <div class="checkup-item-cell">
            <div class="btn btn-secondary">
              <span class="bold primary plumb-kaz">{{ checkup.services_count }}</span> {{ $t('обследований') }}
            </div>
          </div>
        </div>
        <div class="checkup-item-cell checkup-item-cell_center">
          <div class="price h2 bold primary ">{{ $t('от') }} {{ checkup.prices_from }} {{ $t('тенге') }}</div>
        </div>
      </div>
      <div class="checkup-item-row">
        <button class="btn btn-success" @click="selectClinic">{{ $t('подобратьКлинику') }}</button>
        <div v-if="!mobile" class="btn btn-secondary">
          <span class="bold primary plumb-kaz">{{ checkup.clinics_count }}</span> {{ $t('клиник') }}
        </div>
        <div v-if="!mobile" class="btn btn-secondary">
          <span class="bold primary plumb-kaz">{{ checkup.services_count }}</span> {{ $t('обследований') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vPopup from '../../components/modals/v-popup'
import CircleImg from '@/components/img/circle-img'

export default {
  name: 'checkup-item',
  components: {
    CircleImg,
    vPopup
  },
  props: ['mobile', 'checkup'],
  data: function () {
    return {
      role: localStorage.role ? localStorage.role : null,
      showPopup: false
    }
  },
  async created() {
  },
  methods: {
    selectClinic() {
      this.$emit('selectClinic', this.checkup.id)
    },
    closeInfoPopup() {
      this.showPopup = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .service__info .btn {
    padding: 2vw 1vw;
    width: 40vw;
    font-family: PlumbKaz, sans-serif !important;
    font-size: 4vw !important;
  }
  .service__info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .btn, .btn-success {
    font-size: 5vw !important;
    padding: 2vw;
  }
  .checkup-item {
    padding: 1.95vw;
    display: flex;
    text-align: left !important;
    flex-direction: column;
    margin-bottom: 5vw;
    background-color: $white;
    font-family: PlumbKaz, sans-serif;
    box-shadow: 0 4vw 6vw rgba(212, 217, 232, 0.2);
    border-radius: 2vw;
    justify-content: space-between;
  }
  .checkup-item-logo {
    display: flex;
    margin: 2.6vw;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }
  .checkup-item-row {
    display: flex;
    margin: 1vw 0;
    flex-direction: column;
    justify-content: space-between;
  }

  .checkup-item-cell {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1vw 0;
  }
  .checkup-item-cell_center,
  .service__content {
    width: 100% !important;
  }
  .price {
    padding: 2vw;
    text-align: center !important;
    border-radius: 2vw;
    background: $gray_60;
    border: 0.065vw solid $green;
  }
  .checkup-item-info {
    font-weight: 500;
    font-style: normal;
    font-size: 5vw;
    font-family: PlumbKaz, sans-serif;
    line-height: 6vw;
    padding: 4vw 0;
  }

}

@media only screen and (min-device-width: 451px) {
  .checkup-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.625vw;
    background-color: $white;
    font-family: PlumbKaz, sans-serif;
    box-shadow: 0px 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
    border-radius: 0.78vw;
    justify-content: space-between;
  }
  .btn, .btn-success {
    font-size: 1.3vw !important;
  }
  .checkup-item-cell_center {
    width: 40% !important;
  }
  .checkup-item-logo {
    display: flex;
    margin: 1.625vw;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: center;
  }
  .checkup-item-row {
    padding: 0.65vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .checkup-item.checkup-item-cell {
    margin: 1.3vw;
  }
  .checkup-item-cell {
    width: 100%;
    margin: 1.3vw 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0.52vw;
    margin-right: 1.625vw;
  }
  .checkup-item-info {
    font-weight: 500;
    font-style: normal;
    font-size: 1.105vw;
    font-family: PlumbKaz, sans-serif;
    line-height: 17.1px;
    padding-top: 7px;
  }

}

.checkup-item-row button {
  font-family: PlumbKaz, sans-serif !important;
}

.checkup-item-cell_center {
  display: flex;
  align-content: center;
  vertical-align: center;
  justify-content: center;
}
</style>
