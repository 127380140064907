<template>
  <div class="partners">
    <vue-title :title="nav_title"></vue-title>
    <main-layout>
      <div class="content">
        <v-navigation-history v-if="!mobileView" :tab="$t('какПодключиться')"/>
        <page-title :title="$t('какПодключиться')"></page-title>
        <p class="page-info">{{ $t('наTexme') }}</p>
        <div class="card danger flex-row-space">
          <v-col-info3 content="15 000" :footer="$t('ПрошлиЧекап')" :header="$t('Более')"/>
          <v-col-info3 content="12 500" :footer="$t('ПоставилиВысокуюОценку')" :header="$t('Более')"/>
          <v-col-info3 content="5 000" :footer="$t('ДваИболееЧекапа')" :header="$t('Более')"/>
          <v-col-info3 content="100" :footer="$t('клиникИлабораторий')" :header="$t('Более')"/>
        </div>
        <feedback-clinique id="feedback-partner"/>
      </div>
      <div style="height: 1.3vw"></div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import PageTitle from '@/components/titles/page-title'
import vColInfo3 from '@/components/col-info-3'
import vNavigationHistory from '@/components/navigation-history'
import FeedbackClinique from '@/components/feedbacks/feedback-clinique'
import {handleView} from '@/utils/functions'

export default {
  name: 'Partners',
  components: {
    FeedbackClinique,
    vColInfo3,
    PageTitle,
    MainLayout,
    vNavigationHistory
  },
  data: function () {
    return {
      nav_title: this.$t('какПодключиться'),
      mobileView: true,
      showNav: false
    }
  },
  methods: {
    scroll() {
      const element = document.getElementById('feedback-partner')
      element.scrollIntoView({behavior: 'smooth'})
    }
  },
  created() {
    this.mobileView = handleView()
  }
}
</script>
<style lang="scss" scoped>
</style>
