<template>
  <div class="profile__main__menu visibility">
    <ul v-show="role == 'user'">
      <li>
        <router-link :to="{'name': 'UserProfile'}"><img
            src="../../assets/icons/user_outline.svg">{{ $t('личныеДанные') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'UserBookingService'}"><img src="../../assets/icons/my-consultations.svg">
          {{ $t('моиКонсультации') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'UserBookingClinic'}"><img
            src="../../assets/icons/services.svg">{{ $t('записиВКлиники') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'UserBookingCheckup'}"><img
            src="../../assets/icons/checkup.svg">{{ $t('записиНаЧекапы') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'UserServices'}"><img src="../../assets/icons/doctors.svg">{{ $t('услуги') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'UserAnalysis'}"><img src="../../assets/icons/my-analises.svg">
          {{ $t('моиАнализы') }}
        </router-link>
      </li>
    </ul>
    <ul v-show="role == 'doctor'">
      <li>
        <router-link :to="{'name': 'DoctorProfile'}"><img src="../../assets/icons/user_outline.svg">
          {{ $t('личныеДанные') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'DoctorOrders'}"><img src="../../assets/icons/doctors.svg">{{ $t('бронирования') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'DoctorSchedule'}"><img src="../../assets/icons/schedule.svg">{{ $t('расписание') }}
        </router-link>
        <router-link :to="{'name': 'DoctorScheduleWork'}"><img
            src="../../assets/icons/schedule.svg">{{ $t('графикРаботы') }}
        </router-link>
      </li>
    </ul>
    <ul v-show="role == 'clinic'">
      <li>
        <router-link :to="{'name': 'ClinicProfile'}"><img src="../../assets/icons/user_outline.svg">
          {{ $t('личныеДанные') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'ClinicStaff'}"><img src="../../assets/icons/doctors.svg">{{ $t('сотрудники') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'ClinicOrders'}"><img src="../../assets/icons/orders.svg">{{ $t('бронирования') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'ClinicServices'}"><img src="../../assets/icons/services.svg">{{ $t('услуги') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'ClinicCheckups'}"><img src="../../assets/icons/checkup.svg">{{ $t('чекапы') }}
        </router-link>
      </li>

      <li>
        <router-link :to="{'name': 'ClinicServicesList'}"><img
            src="../../assets/icons/doctors.svg">{{ $t('услугиКлиники') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{'name': 'ClinicScheduleWork'}"><img
            src="../../assets/icons/schedule.svg">{{ $t('графикРаботы') }}
        </router-link>
      </li>
    </ul>

    <service-filter v-if="filter" @changeRequestType="changeRequestType" @changeCity="changeCity" @changeSum="changeSum"
                    @changeType="changeType" selected="1"/>
  </div>
</template>

<script>
import ServiceFilter from '@/components/checkups/service-filter'

export default {
  name: 'left-navigation',
  props: ['filter', 'type'],
  data: function () {
    return {
      showNav: false,
      user: localStorage.user ? JSON.parse(localStorage.user) : null,
      role: localStorage.role
    }
  },
  components: {ServiceFilter},
  methods: {
    changeSum(min, max) {
      this.$emit('changeSum', min, max)
    },
    changeType(val) {
      this.$emit('changeType', val)
    },
    changeCity(val) {
      this.$emit('changeCity', val)
    },
    changeRequestType(val) {
      this.$emit('changeRequestType', val)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .visibility {
    display: none;
  }
;
}

ul {
  margin-bottom: 1.3vw;
  //margin-left: -35px;
  padding: 0;
  width: 85%;
  list-style-type: none;
}

li {
  cursor: pointer;

  a {
    width: 90%;
    display: flex;
    padding: 0.52vw;
    line-height: 1.95vw;
    align-items: center;

    &.router-link-exact-active {
      color: black !important;
      border-radius: 0.78vw;
      background-color: $green_14 !important;
    }

    img {
      width: 1.495vw;
      height: 1.495vw;
      padding-right: 0.78vw;
    }
  }
}
</style>
