<template>
  <div v-if="!removed" class="order-item">
    <v-popup v-show="message" close-position="text-accent" @closePopup="closeInfoPopup">
      <div class="h2">
        <span class="center" v-html="message"></span>
        <div class="right">
          <v-button size="btn btn-success text-white m-1" value="OK" @click="postAction"/>
          <v-button size="text m-1" :value="$t('Отмена')" @click="closeInfoPopup"/>
        </div>
      </div>
    </v-popup>
    <div class="order-item-logo">
      <circle-img :name="order.name" pic=""/>
    </div>
    <div class="order-item-body">
      <div class="order-item-body-name h2">{{ order.name }} {{ order.surname }} {{ order.patronymic }}</div>
      <div>
        <p><a :href="'tel:+'+order.phone">{{ '+' + order.phone | phoneFormat }}</a></p>
        <p class="record_type">{{ $t('типЗаписи') }}:
          <span>
            <template v-if="order.record_type===1">{{ $t('онлайн') }}</template>
            <template v-else-if="order.record_type===2">{{ $t('оффлайн') }}</template>
        </span>
        </p>
        {{ $i18n.locale == 'kk' ? order.title_kz : $i18n.locale == 'ru' ? order.title : order.title_en }}
      </div>
      <div>{{ $t('дата') }}: {{ getDate(order.date) }}</div>
      <div>{{ $t('время') }}: {{ order.time.toString().slice(0, -3) }}</div>
      <p v-if="order.cancel_user" class="record_type">{{ $t('отменено') }}
        <span>
             <template v-if="order.cancel_user===1">{{ $t('отменаПользователь') }}</template>
            <template v-else-if="order.cancel_user===2">{{ $t('отменаВрачом') }}</template>
             <template v-else-if="order.cancel_user===3">{{ $t('отменаАдмином') }}</template>
        </span>
      </p>

    </div>
    <div class="order-item-action">
      <v-button v-if="type == 0" fa-icon="check" size="success long p-1" :value="$t('Подтвердить')"
                @click="approve(order.id)"/>
      <v-button v-if="type == 0 || type==1" fa-icon="trash" size="orange long p-1" :value="$t('Отменитьзапись')"
                @click="cancel(order.id)"/>

      <v-button v-if="type == 1" size="success long p-1" fa-icon="check" :value="$t('Завершить')"
                @click="confirm(order.id)"/>
      <v-button size="default long p-1" fa-icon="comments" :value="$t('ОткрытьЧат')"
                v-show="checkChatAvailability( order)"
                v-if=" order.record_type==1"
                @click="openChat(2)"/>
      <v-button size="info long p-1" fa-icon="phone" rotation="90" :value="$t('Позвонить')"
                v-show="checkCallAvailability( order)"
                v-if=" order.record_type==1"
                @click="openChat(1)"/>


    </div>
  </div>
</template>

<script>
import CircleImg from '@/components/img/circle-img'
import VButton from '@/components/btn/v-button'
import router from '@/router'
import DoctorConsultation from '@/views/doctor/Consultation'
import VPopup from '@/components/modals/v-popup'
import ClinicConsultation from '@/views/clinic/ClinicConsultation'
import {approveAlert, cancelAlert, confirmAlert} from '@/utils/functions'
import moment from "moment";

export default {
  components: {
    VPopup,
    VButton,
    CircleImg
  },
  data: function () {
    return {
      role: localStorage.role,
      mobileView: true,
      loader: false,
      o: this.order,
      isTokenIsEmpty: null,
      showNav: false,
      removed: false,
      message: '',
      id: 0,
      action: ''
    }
  },
  props: ['order', 'type'],
  name: 'order-item',
  methods: {
    checkChatAvailability(value) {
      let d = new Date(value.date)
      d.setDate(d.getDate() + 1)
      if (this.type == 1) {
        return 1
      }
      if (this.type == 2) {
        if (new Date() < d) {
          return 1
        } else {
          return 0
        }
      } else {
        return 0
      }

    },
    checkCallAvailability(value) {
      let d = new Date(value.date)
      d.setDate(d.getDate() + 1)
      if (this.type == 1) {
        return 1
      }
      if (this.type == 2) {
        if (new Date() < d) {
          return 1
        } else {
          return 0
        }
      } else {
        return 0
      }
    },


    getDate(date) {
      const months = {
        '01': this.$t('Января'),
        '02': this.$t('Февраля'),
        '03': this.$t('Марта'),
        '04': this.$t('Апреля'),
        '05': this.$t('Мая'),
        '06': this.$t('Июня'),
        '07': this.$t('Июля'),
        '08': this.$t('Августа'),
        '09': this.$t('Сентября'),
        '10': this.$t('Октября'),
        '11': this.$t('Ноября'),
        '12': this.$t('Декабря')
      }
      return date.slice(date.length - 2) + ' ' + months[date.slice(5, 7)] + ', ' + date.slice(0, 4)
    },
    closeInfoPopup() {
      this.message = ''
      this.id = ''
      this.action = ''
    },
    openChat(val) {
      // eslint-disable-next-line eqeqeq
      if (this.role == 'clinic') {
        router.push({
          path: `/clinic/orders/${this.order.id}`,
          component: ClinicConsultation,
          query: {
            type: val
          }
        })
      }
      // eslint-disable-next-line eqeqeq
      if (this.role == 'doctor') {
        router.push({
          path: `/doctor/orders/${this.order.id}`,
          component: DoctorConsultation,
          query: {
            type: val
          }
        })
      }
    },
    confirm(id) {
      this.message = approveAlert('запись')
      this.action = 'confirm'
      this.id = id
    },

    approve(id) {
      this.message = confirmAlert('запись')
      this.action = 'approve'
      this.id = id
    },
    cancel(id) {
      this.message = cancelAlert('запись')
      this.action = 'cancel'
      this.id = id
      // if (confirm('Вы точно хотите отменить запись?')) {
      //   this.postAction('cancel', id)
      // }
    },
    async postAction() {
      var url = `${process.env.VUE_APP_API_URL}${this.role}/bookings/` + this.action
      const result = await this.$http.post(
          url, {id: this.id}
      )
      if (result) {
        this.removed = true
        this.closeInfoPopup()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

.record_type {
  font-weight: 600;

  & span {
    color: red;
  }
}


@media only screen and (max-device-width: 450px) {
  .order-item {
    font-size: 1.17vw;
    padding: 2.08vw 3.25vw 3.25vw 2.08vw;
    display: flex;
    flex-wrap: wrap;
    text-align: left !important;
    flex-direction: row;
    margin-bottom: 1.625vw;
    background-color: $white;
    font-family: PlumbKaz, sans-serif;
    box-shadow: 0 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
    border-radius: 0.78vw;

    &-body {
      width: auto;
      flex-direction: column;
      font-size: 4vw;
      padding: 2vw;
      line-height: 6vw;

      &-name {
        margin-bottom: 1.3vw;
      }
    }

    &-action {
      width: 40%;
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      & {
        button {
          width: 100%;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row-reverse;
      justify-content: revert;
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .order-item {
    font-size: 1.17vw;
    padding: 2.08vw 3.25vw 3.25vw 2.08vw;
    display: flex;
    text-align: left !important;
    flex-direction: row;
    margin-bottom: 1.625vw;
    background-color: $white;
    font-family: PlumbKaz, sans-serif;
    box-shadow: 0 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
    border-radius: 0.78vw;

    &-body {
      margin-left: 3rem;
      flex-direction: column;
      font-size: 1.3vw;
      line-height: 1.755vw;

      &-name {
        margin-bottom: 1.3vw;
      }
    }

    &-action {
      margin-left: auto;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }

    .right {
      display: flex;
      flex-direction: row-reverse;
      justify-content: revert;
    }
  }
}
</style>
