<template>
  <div v-if="!removed" class="consultation__item">
    <v-popup v-show="message" close-position="text-accent" @closePopup="closeInfoPopup">
      <div class="h2">
        <span class="center" v-html="message"></span>
        <div class="right">
          <v-button size="btn btn-success text-white m-1" value="OK" :disabled="loader" @click="cancelConsultation"/>
          <v-button size="btn text m-1 " :value="$t('Отмена')" :disabled="loader" @click="closeInfoPopup"/>
        </div>
      </div>
    </v-popup>
    <div class="consultation__item__logo">
      <circle-img :pic="consultation.profile_image"
                  :name=" $i18n.locale == 'kk' ?consultation.name_kz: $i18n.locale == 'ru' ? consultation.name : consultation.name_en "/>
    </div>
    <div class="consultation__item__body">
      <p class="h2">{{
          $i18n.locale == 'kk' ? consultation.name_kz : $i18n.locale == 'ru' ? consultation.name : consultation.name_en
        }}</p>
      <p v-if="consultation.record_type" class="record_type">{{ $t('типЗаписи') }}:
        <span>
            <template v-if="consultation.record_type===1">{{ $t('онлайн') }}</template>
            <template v-else-if="consultation.record_type===2">{{ $t('оффлайн') }}</template>
        </span>
      </p>
      <div>{{ $t('дата') }}: {{ consultation.date }}</div>
      <div>{{ $t('время') }}: {{ consultation.time }}</div>
      <p v-if="consultation.record_type==2" :class="showHide ? '' : 'text-overflow-handle'"
         @click="showHide =! showHide">
        {{
          $i18n.locale == 'kk' ? consultation.bio_kz : $i18n.locale == 'ru' ? consultation.bio : consultation.bio_en
        }}</p>
      <p v-if="consultation.cancel_user" class="record_type">{{ $t('отменено') }}
        <span>
             <template v-if="consultation.cancel_user===1">{{ $t('отменаПользователь') }}</template>
            <template v-else-if="consultation.cancel_user===2">{{ $t('отменаВрачом') }}</template>
             <template v-else-if="consultation.cancel_user===3">{{ $t('отменаАдмином') }}</template>
        </span>
      </p>
    </div>
    <div class="consultation__item__action">
      <v-button
          v-if="consultation.record_type==1"
          v-show="checkChatAvailability(consultation)"
          fa-icon="comments"
          size="p-1 mb-1 default"
          :value="$t('ОткрытьЧат')"
          @click="openChat(2)"
      />
      <v-button
          v-if="consultation.record_type==1"
          v-show="checkCallAvailability(consultation)"
          fa-icon="phone"
          rotation="90"
          size="p-1 mb-1 success"
          :value="$t('Позвонить')"
          @click="openChat(1)"
      />
      <v-button
          v-if="consultation.is_approved==0"
          v-show="checkCancellationAvailability(consultation)"
          fa-icon="trash"
          size="p-1 mb-1 dk-danger text-white"
          :value="$t('Отменить')"
          @click="confirm"
      />
    </div>
  </div>
</template>

<script>
import VButton from '@/components/btn/v-button'
import CircleImg from '@/components/img/circle-img'
import router from '@/router'
import moment from "moment"
import VPopup from '@/components/modals/v-popup'
import {cancelAlert, cancelOrder} from '@/utils/functions'

export default {
  name: 'consultation-item',
  props: ['type', 'consultation'],
  components: {
    VPopup,
    CircleImg,
    VButton
  },
  data: function () {
    return {
      removed: false,
      showHide: false,
      message: '',
      loader: false,
    }
  },
  methods: {
    checkChatAvailability(value) {
      if (this.$route.name === 'UserBookingService' && (value.is_approved == 1 || (value.is_approved == 2 && moment(value.updated_at) >= moment().subtract(1, 'days')))) {
        return 1;
      } else {
        return 0;
      }
    },
    checkCallAvailability(value) {
      if (this.$route.name === 'UserBookingService' && (this.type != 0 && this.type != 2 && this.type != 3)) {
        return 1;
      } else {
        return 0;
      }
    },
    checkCancellationAvailability(value) {
      if (this.type != 2 && this.type != 3) {
        return 1;
      } else {
        return 0;
      }
    },

    confirm() {
      this.message = cancelAlert(this.$t('запись'))
    },
    closeInfoPopup() {
      this.message = ''
      this.id = ''
      this.action = ''
    },
    openChat(val) {
      if (this.$route.name === 'UserBookingService') {
        var routeName = 'UserConsultationService'
      } else if (this.$route.name === 'UserBookingClinic') {
        var routeName = 'UserConsultationClinic'
      } else if (this.$route.name === 'UserBookingCheckup') {
        var routeName = 'UserConsultationCheckup'
      }
      router.push({
        name: routeName,
        params: {
          id: this.consultation.id
        },
        query: {
          type: val
        }
      })
    },
    async cancelConsultation() {
      try {
        this.loader = true;
        let type = '';
        if (this.$route.name === 'UserBookingService') {
          type = 'service'
        } else if (this.$route.name === 'UserBookingClinic') {
          type = 'clinic'
        } else if (this.$route.name === 'UserBookingCheckup') {
          type = 'checkup'
        }
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}user/consultations/${type}/cancel`,
            {
              id: this.consultation.id
            }
        )
        if (result) {
          this.removed = true
          this.message = cancelOrder()
          location.reload()
        }
      } catch (e) {

      } finally {
        this.loader = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.record_type {
  font-weight: 600;

  & span {
    color: red;
  }
}

@media only screen and (max-device-width: 450px) {
  .text-overflow-handle {
    overflow: hidden;

    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    box-shadow: 0.3vw 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
  }

  .consultation__item {
    padding: 2.56vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: white;
    margin-bottom: 1.625vw;
    border-radius: 0.78vw;

    &__logo {
      padding-top: 1.95vw;
    }

    &__body {
      width: 100%;
      padding: 0 1.17vw;
      display: flex;
      flex-direction: column;
    }

    &__action {
      flex-basis: 100%;
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-items: center;
      padding-top: 1.95vw;
      justify-content: flex-start;

      & {
        a {
          width: 100%;

          & {
            .btn {
              width: 100% !important;
            }
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row-reverse;
      justify-content: revert;
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .text-overflow-handle {
    overflow: hidden;

    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-size: cover;
    box-shadow: 1.3vw 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
  }

  .consultation__item {
    padding: 1.56vw;
    display: flex;
    flex-direction: row;
    background-color: white;
    margin-bottom: 1.625vw;
    border-radius: 0.78vw;

    &__logo {
      padding-top: 1.95vw;
    }

    &__body {
      width: 60%;
      padding: 0 1.17vw;
      display: flex;
      flex-direction: column;
    }

    &__action {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-items: center;
      padding-top: 1.95vw;
      justify-content: flex-start;

      & {
        a {
          width: 100%;

          & {
            .btn {
              width: 100% !important;
            }
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row-reverse;
      justify-content: revert;
    }
  }
}
</style>
