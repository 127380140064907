<template>
  <div id="slider-container-transition" class="card-with-text">
    <div class="card-with-text-header">{{ header }}</div>
    <p>
      {{ content }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'card-with-text',
  props: ['header',
    'content']
}
</script>

<style>

@media only screen and (max-device-width: 450px) {
  .card-with-text {
    scroll-snap-align: start;
    min-width: 100%;
    scroll-behavior: smooth;
    padding: 2.08vw 10.51vw 0;
    flex-basis: 80%;
    text-align: center;
    flex-shrink: 0;
    text-align: center !important;
    background-color: white;
  }

  #slider-container-transition {
    transition: transform 0.7s ease-in-out;
  }
  .card-with-text-header {
    font-family: Gotham-Black, sans-serif;
    font-weight: bold;
    font-size: 25.24vw;
    color: red;
  }
  .card-with-text {
    width: 15%;
    padding-bottom: 2.82vw;
  }

  .card-with-text > p {
    font-size: 4vw;
    line-height: 5vw;
  }
}

@media only screen and (min-device-width: 451px) {
  .card-with-text {
    flex-basis: calc(25% - 6.5vw);
    padding-top: -2.925vw;
    flex-direction: column;
    text-align: left;
  }
  .card-with-text-header {
    font-family: Gotham-Black, sans-serif;
    font-weight: bold;
    font-size: 6.24vw;
    color: red;
  }
  .card-with-text {
    width: 15%;
    padding-bottom: 1.82vw;
  }

  .card-with-text > p {
    font-size: 1.17vw;
    line-height: 2vw;
  }
}

</style>
