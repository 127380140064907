<template>
  <div class="page-title t40" style="line-height: 35px!important;    font-size: 30px!important;" v-bind:class="weight">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'page-title',
  props: ['title', 'weight']
}

</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@media only screen and (max-width: 450px) {
  .page-title {
    padding-top: 1.95vw;
    padding-bottom: 2.08vw;
    font-family: "Gotham-Bold", serif;
    line-height: 8vm;
    font-size: 8vw;
    font-weight: 700;
    color: #4D4D4D;
  }

  .normal {
    font-family: Gotham-Medium, sans-serif !important;
    line-height: 6vw !important;
    font-size: 4vw !important;
    font-weight: 500 !important;
    font-style: normal;
  }
  .md {
    font-family: Gotham-Medium, sans-serif !important;
    line-height: 7vw !important;
    font-size: 5vw !important;
    font-weight: 500 !important;
    font-style: normal;
  }
}

@media only screen and (min-width: 451px) {
  .page-title {
    padding-top: 1.95vw;
    padding-bottom: 2.08vw;
    font-family: "Gotham-Bold", serif;
    line-height: 51.4px;
    font-weight: 700;
    color: #4D4D4D;
  }

  .normal {
    font-family: Gotham-Medium, sans-serif !important;
    line-height: 1.3vw !important;
    font-size: 1.95vw !important;
    font-weight: 500 !important;
    font-style: normal;
  }
  .md {
    font-family: Gotham-Medium, sans-serif !important;
    line-height: 1.3vw !important;
    font-size: 2.6vw !important;
    font-weight: 500 !important;
    font-style: normal;
  }
}
</style>
