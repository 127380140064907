<template>
  <button v-if="value.length < 30" :class="size" :disabled="disabled" :type="type?type:'button'" class="btn"
          @click="click">
    <font-awesome-icon v-if="faIcon" :icon="faIcon!=null?['fa', faIcon]:null" :rotation="rotation"
                       size="sm"/>
    <span class="value">{{ value }}</span>
  </button>
  <el-tooltip v-else :content="value"
              :open-delay="100"
              placement="top"
              popperClass="tooltip">
    <button :class="size" :disabled="disabled" :type="type?type:'button'" class="btn" @click="click">
      <font-awesome-icon v-if="faIcon" :icon="faIcon!=null?['fa', faIcon]:null" :rotation="rotation"
                         size="sm"/>
      <span class="value">{{ value.slice(0, 30) + '...' }}</span>
    </button>
  </el-tooltip>
</template>

<script>

export default {
  name: 'v-button',
  props: ['value', 'size', 'faIcon', 'rotation', 'type', 'disabled'],
  methods: {
    click () {
      this.$emit('click')
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

.warning {
  background-color: darkorange;
}

.isdark {
  padding: 0.52vw !important;
  border: 0.065vw solid gray !important;
}

span {
  text-align: center;
}

.yellow {
  color: white !important;
  background-color: #F2C94C !important;
}

.btn {
  display: flex;
  flex-direction: row;
  white-space: nowrap !important;
  align-items: center;
  width: fit-content;
  justify-content: center;

  & {
    svg {
      margin-right: 0.52vw;
    }

    div {
      display: flex;
      flex-direction: row;
      align-content: space-between;
      align-items: center;
    }
  }

  text-align: left;
}

@media only screen and (max-device-width: 450px) {
  .ptb1 {
    margin: 2vw 0!important;
  }
  .xlong {
    width: 100% !important;
  }

  .long {
    width: 100% !important;
  }

  .md {

  }

  .visibility {
    display: none;
  }
  .btn {
    font-family: Roboto, sans-serif;
    color: $black_secondary;
    width: auto;
    font-size: 4vw !important;
  }
  .sm {
    font-size: 3.5vw !important;
  }
  .p0,
  .p-1 {
    padding: 2vw !important;
    margin: 2vw!important;
  }

  .p-2 {
    padding: 1.04vw 2rem;
  }

  .m-1 {
    margin: 1.04vw;
  }

  .mb-1 {
    margin: 0.25rem 0;
  }

  .mr-1 {
    margin-right: 1.04vw;
  }
  .draw_share_atooltip {
    background: #515b62 !important;
    font-size: 1.04vw !important;
  }
  .tooltip{
    font-size: 4vw!important;
  }
}

@media only screen and (min-device-width: 451px) {
  .xlong {
    width: 100% !important;
  }

  .long {
    width: 100% !important;
  }

  .md {

  }

  .lg {
    width: 60% !important;
  }
  .visibility {
    visibility: visible;
  }
  .btn {
    font-family: Roboto, sans-serif;
    font-size: 1.3vw;
    color: $black_secondary;
    width: auto;
  }
  .sm {
    font-size: 0.975vw !important;
  }

  .p-1 {
    padding: 0.5rem 1.04vw;
  }

  .p-2 {
    padding: 1.04vw 2rem;
  }

  .m-1 {
    margin: 1.04vw;
  }

  .mb-1 {
    margin: 0.25rem 0;
  }

  .mr-1 {
    margin-right: 1.04vw;
  }
  .draw_share_atooltip {
    background: #515b62 !important;
    font-size: 1.04vw !important;
  }
}

.outline {
  outline: none;
  border: none;
}

.border-8 {
  border-radius: 0.52vw;
}

.border-60 {
  border-radius: 3.9vw;
}

.dark {
  color: white;
  background-color: $black_secondary;
}

.success {
  color: white;
  background-color: $green;
}

.default {
  color: $grey;
  border: 0.065vw solid $grey;
  background: transparent;
}

.orange {
  background-color: orange;
  color: white;
}
.red {
  background-color: red;
  color: white;
}
.green {
  background-color: green;
  color: white;
}
.bordered-danger {
  color: $black;
  border: 0.065vw solid $red;
  background: transparent;
}

.bordered-success {
  color: $green;
  border: 0.065vw solid $green;
  background: transparent;
}

.dk-danger {
  background: $red;
}

.w-100 {
  width: 100%;
}

.p0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0;
  margin-left: 0;
}

</style>
