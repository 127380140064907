<template>
  <div v-if="!deleted" class="analyzes-download">
    <div class="analyzes-download__item">
      <a v-if="url" target="_blank" v-bind:href="url">
        <font-awesome-icon icon="file-word"/>
        {{ name }}
      </a>
      <span v-if="!url" @click="fileUpload">
        <font-awesome-icon icon="file-word"/>
        {{ name }}
      </span>
    </div>
    <div class="analyzes-download__action">
      <form enctype="multipart/form-data" method="post">
        <input
            :value="id"
            name="doctor_requests_id"
            type="hidden"
        >
        <input
            v-show="false"
            ref="myFile"
            accept=".pdf, .txt, .doc, .docx"
            name="filename"
            type="file"
            @change="uploadAnalyze"
        >
        <input v-show="false" type="submit">
      </form>
      <div v-if="!userAnalyze">
        <v-button
            v-if="url && role != 'clinic'"
            fa-icon="trash"
            size="btn dk-danger text-white w-100 sm"
            value="Удалить"
            @click="deleteFile"
        />
      </div>
      <v-button
          class="uoload_more_btn"
          v-show="showUpload && role != 'clinic'"
          :value="'Загрузить' + (url ? ' еще' : '')"
          fa-icon="download"
          size="text-white dark w-100 sm"
          @click="fileUpload"
      />
    </div>
  </div>
</template>

<script>
import VButton from '@/components/btn/v-button'
import axios from 'axios'

export default {
  props: ['name', 'url', 'id', 'showUpload', 'type','userAnalyze'],
  name: 'analyzes-download',
  components: { VButton },
  data: function () {
    return {
      deleted: false,
      changeImg: '',
      fileUrl: '',
      role: localStorage.role
    }
  },
  methods: {
    async deleteFile () {
      var data = await axios.post(`${process.env.VUE_APP_API_URL}${this.role}/bookings/analyses/archive`, { file_id: this.id })
      if (data) {
        this.deleted = true
      }
    },
    async uploadAnalyze (event) {
      this.changeImg = 'load'
      var file = event.target.files[0]
      this.fileUrl = URL.createObjectURL(file)
      var formData = new FormData()

      formData.append('file', file)

      if (this.role === 'clinic') {
        formData.append('clinic_request_id', this.id)
      } else {
        formData.append('doctor_requests_id', this.id)
      }

      try {
        var result = await axios.post(`${process.env.VUE_APP_API_URL}${this.role}/bookings/analyses/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        await this.$emit('uploaded', result)
      } catch (e) {
      }
    },
    fileUpload () {
      this.$refs.myFile.click()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

.uoload_more_btn{
  margin-top: 8px;
}

@media only screen and (max-device-width: 450px) {
  .analyzes-download {
    margin: 2.52vw 0;
    display: flex;
    padding: 3.04vw 2.6vw;
    justify-content: space-between;
    font-family: Gotham-Medium, sans-serif;
    font-size: 4vw;
    font-weight: bold;
    border-radius: 2.78vw;
    border: 0.065vw solid $black_secondary;
    flex-direction: row;
    align-items: center;
  }
}

@media only screen and (min-device-width: 451px) {
  .analyzes-download {
    margin: 0.52vw 0;
    display: flex;
    padding: 1.04vw 2.6vw;
    justify-content: space-between;
    font-family: Gotham-Medium, sans-serif;
    font-size: 1.625vw;
    font-weight: bold;
    border-radius: 0.78vw;
    border: 0.065vw solid $black_secondary;
    flex-direction: row;
    align-items: center;
  }
}
</style>
