<template>
  <div class="profile__info">
    <v-popup v-show="showPopup" :title="$t('личныеДанные')" @closePopup="closeInfoPopup" heigh100>
      <div class="popup_body">
        <form v-on:submit.prevent="submitForm">
          <div class="form-group">
            {{ $t('Имя') }}
            <input ref="regname" :value="user.name" class="input" :placeholder="$t('введитеДанные')" required>
          </div>
          <div class="form-group">
            {{ $t('Фамилия') }}
            <input ref="regsurname" :value="user.surname" class="input" :placeholder="$t('введитеДанные')" required>
          </div>
          <div class="form-group">
            {{ $t('Отчество') }}
            <input ref="regpatronymic" :value="user.patronymic" class="input" :placeholder="$t('введитеДанные')"
                   required>
          </div>
          <div class="form-group">
            {{ $t('номерТелефона') }}

            <input
                id="rtel__number" ref="regnumber" :value="user.phoneNew" class="input tel__number"
                :placeholder="$t('введитеДанные')"
                type="tel">

          </div>
          <div class="form-group">
            E-mail
            <input disabled id="email" ref="regemail" :value="user.email" class="input"
                   :placeholder="$t('введитеДанные')"
                   required
                   type="email">
          </div>
          <div class="form-group">
            {{ $t('датаРождения') }}
            <input id="data-birth" ref="regbirth" :value="user.date_birthdate" class="input" max='2015-12-31'
                   min='1899-01-01' :placeholder="$t('введитеДанные')" required type="date">
          </div>
          <div class="form-group">
            {{ $t('Город') }}
            <select :value="user.city_id" ref="regCity" class=" input tel__number modalSelect" name="city"
                    style="width: 100%;margin: 0">
              <option v-for="(city) in cities" v-show="cities" :key="city.id+city.title"
                      :value="city.id">
                {{ $i18n.locale == 'kk' ? city.title_kz : $i18n.locale == 'ru' ? city.title : city.title_en }}
              </option>
            </select>
          </div>
          <div class="form-group">
            {{ $t('Пол') }}
            <select :value="user.sex" ref="regSex" class=" input tel__number modalSelect" style="width: 100%;margin: 0">
              <option :key="1"
                      :value="1"> {{ $t('Мужской') }}
              </option>
              <option :key="2"
                      :value="2"> {{ $t('Женский') }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <input :disabled="updated" class="btn btn-success" type="submit" :value="$t('Сохранить')">
          </div>
        </form>
      </div>
    </v-popup>
    <v-popup-min v-show="showPopupReset" :title="$t('парольОбновление')" @closePopup="closePopupReset" heigh100>
      <div class="popup_body">
        <form v-on:submit.prevent="updatePassword">
          <div class="form-group">
            {{ $t('старыйПароль') }}
            <input type="password" v-model="password" class="input" :placeholder="$t('введитеДанные')"
                   required>
          </div>
          <div class="form-group">
            {{ $t('новыйПароль') }}
            <input type="password" v-model="passwordNew" class="input" :placeholder="$t('введитеДанные')"
                   required>
          </div>
          <div class="form-group">
            {{ $t('повторитеПароль') }}
            <input type="password" v-model="passwordRepeat" class="input" :placeholder="$t('введитеДанные')"
                   required>
          </div>
          <div class="form-group">
            <input :disabled="updated" class="btn btn-success" type="submit" :value="$t('ОбновитьПароль')">
          </div>
        </form>
      </div>
    </v-popup-min>
    <vue-title :title="user.name+' '+user.surname+(user.patronymic?' '+user.patronymic:'')"/>
    <page-title :title="user.name+' '+user.surname+(user.patronymic?' '+user.patronymic:'')"/>
    <profile-info-block :label="$t('датаРождения')" :value="getDate(user.date_birthdate)"/>
    <profile-info-block :label="$t('Город')" :value="city"/>
    <profile-info-block :label="$t('Пол')" :value="user.sex==1?$t('Мужской'):$t('Женский')"/>
    <profile-info-block :label="$t('номерТелефона')" :value="'+' + user.phoneNew |phoneFormat"/>
    <profile-info-block :value="user.email" label="E-mail"/>
    <div class="edt" @click="selectClinic">{{ $t('редактировать') }}</div>
    <div style="margin-top: 1rem;margin-bottom: 1rem" class="edt " @click="chgPassword">{{ $t('ОбновитьПароль') }}</div>
    <div class="edt logout btn text-danger" @click="logout">{{ $t('Выход') }}</div>
  </div>
</template>

<script>
import ProfileInfoBlock from '@/components/user/profile-info-block'
import PageTitle from '@/components/titles/page-title'
import VPopup from '@/components/modals/v-big-popup'
import VPopupMin from '@/components/modals/v-popup'
import vSelect from '../select'
import Inputmask from 'inputmask'
import router from '@/router'
import axios from 'axios'
import {maska} from 'maska'

export default {
  name: 'profile-info',
  directives: {maska},
  components: {
    VPopupMin,
    vSelect,
    VPopup,
    ProfileInfoBlock,
    PageTitle
  },
  data: function () {
    return {
      password: '',
      passwordNew: '',
      passwordRepeat: '',
      showPopupReset: false,
      user: JSON.parse(localStorage.user),
      showPopup: false,
      updated: false,
      cities: []
    }
  },
  async created() {
    this.getCities();
  },
  mounted() {
    this.user = JSON.parse(localStorage.user)
    var im = new Inputmask('+9 (999) 999 99 99')
    im.mask(document.getElementById('rtel__number'))
    im.mask(this.$refs.regnumber)
    var birthData = new Inputmask('99-99-9999')
    birthData.mask(document.getElementById('data-birth'))

    this.user.phoneNew = this.user.phone

  },
  computed: {
    city() {
      if (this.$i18n.locale == 'ru') {
        return this.cities.find(el => el.id === this.user.city_id)?.title || ''
      } else {
        return this.cities.find(el => el.id === this.user.city_id)?.title_en || ''
      }
    }
  },
  methods: {
    async getCities() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}web/dictionaries/cities`, {});
        this.cities = result.data
      } catch (e) {
        throw e;
      }
    },
    getDate(date) {
      const months = {
        '01': this.$t('Января'),
        '02': this.$t('Февраля'),
        '03': this.$t('Марта'),
        '04': this.$t('Апреля'),
        '05': this.$t('Мая'),
        '06': this.$t('Июня'),
        '07': this.$t('Июля'),
        '08': this.$t('Августа'),
        '09': this.$t('Сентября'),
        '10': this.$t('Октября'),
        '11': this.$t('Ноября'),
        '12': this.$t('Декабря')
      }
      return date.slice(date.length - 2) + ' ' + months[date.slice(5, 7)] + ', ' + date.slice(0, 4)
    },
    async submitForm() {
      this.updated = true
      try {
        this.user.name = this.$refs.regname.value
        this.user.phone = this.$refs.regnumber.value
        this.user.date_birthdate = this.$refs.regbirth.value
        this.user.city_id = this.$refs.regCity.value
        await this.$store.dispatch('update', {
          sex: this.$refs.regSex.value,
          name: this.$refs.regname.value,
          surname: this.$refs.regsurname.value,
          patronymic: this.$refs.regpatronymic.value,
          phone: this.$refs.regnumber.value,
          date_birthdate: this.$refs.regbirth.value,
          city_id: this.$refs.regCity.value
        })
        this.updateProfile()
        window.location.reload()
        this.closeInfoPopup()
        document.querySelector('body').classList.remove('overflow-hidden')
        document.querySelector('#app').classList.remove('overflow-hidden')
        document.querySelector('.main-layout').classList.remove('overflow-hidden')
        document.querySelector('.content').classList.remove('overflow-hidden')
      } catch (e) {
        this.message = this.$t('неверныйЛогин')
      }
      this.showPopup = false
      this.updated = false
    },
    async updatePassword() {
      this.updated = true
      try {
        await this.$store.dispatch('reset', {
          password: this.password,
          passwordNew: this.passwordNew,
          passwordConfirm: this.passwordRepeat,
        })
        // this.updateProfile()
        // window.location.reload()
        this.closePopupReset()
        document.querySelector('body').classList.remove('overflow-hidden')
        document.querySelector('#app').classList.remove('overflow-hidden')
        document.querySelector('.main-layout').classList.remove('overflow-hidden')
        document.querySelector('.content').classList.remove('overflow-hidden')
      } catch (e) {

      }
      this.showPopup = false
      this.updated = false
    },
    async updateProfile() {
      var urlProfile = `${process.env.VUE_APP_API_URL}user/profile`
      // eslint-disable-next-line eqeqeq
      var role = localStorage.getItem('role')
      // eslint-disable-next-line eqeqeq
      // eslint-disable-next-line eqeqeq
      if (role == 'doctor') {
        urlProfile = 'api/doctor/profile'
      }
      // eslint-disable-next-line eqeqeq
      if (role == 'clinic') {
        urlProfile = 'api/clinic/profile'
      }
      const user = (await axios(urlProfile, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      })).data
      localStorage.setItem('user', JSON.stringify(user))
    },
    selectClinic() {
      this.showPopup = true
      document.querySelector('body').classList.add('overflow-hidden')
      document.querySelector('#app').classList.add('overflow-hidden')
      document.querySelector('.main-layout').classList.add('overflow-hidden')
    },
    chgPassword() {
      this.showPopupReset = true
      document.querySelector('body').classList.add('overflow-hidden')
      document.querySelector('#app').classList.add('overflow-hidden')
      document.querySelector('.main-layout').classList.add('overflow-hidden')
    },
    logout() {
      this.$store.dispatch('logout')
      router.replace('/')
    },
    closePopupReset() {
      document.querySelector('body').classList.remove('overflow-hidden')
      document.querySelector('#app').classList.remove('overflow-hidden')
      document.querySelector('.main-layout').classList.remove('overflow-hidden')
      this.showPopupReset = false
    },
    closeInfoPopup() {
      document.querySelector('body').classList.remove('overflow-hidden')
      document.querySelector('#app').classList.remove('overflow-hidden')
      document.querySelector('.main-layout').classList.remove('overflow-hidden')
      this.showPopup = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

@media only screen and (max-device-width: 450px) {
  .profile__info {
    width: 100%;
    margin-top: 2rem;
    border-top: gainsboro 0.065vw solid;
    font-family: Gotham-Medium, sans-serif;

    & {
      .edt {
        cursor: pointer;
        color: $green;
        width: auto !important;
        text-align: left !important;
        font-family: Gotham-Medium, sans-serif;
        font-weight: 500 !important;
        line-height: 8vw;
        font-size: 5vw;
      }
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .profile__info {
    width: 90%;
    border-top: gainsboro 0.065vw solid;
    font-family: Gotham-Medium, sans-serif;

    & {
      .edt {
        cursor: pointer;
        color: $green;
        width: auto !important;
        text-align: left !important;
        font-family: Gotham-Medium, sans-serif;
        font-weight: 500 !important;
        font-size: 22px;
      }
    }
  }
  .form-group {
    line-height: 1.2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1.3vw !important;
    padding: 0 !important;

    & {
      input[type='button'],
      input[type='submit'] {
        font-size: 1.3vw;
        border-radius: 8px !important;
        padding: 16px;
      }
    }
  }

  .form-group input {
    font-family: Gotham, sans-serif;
    margin: 0 !important;
  }
}

.logout {
  text-align: left !important;
}

.btn-success {
  background: $green !important;
  color: white !important;
}

button[disabled],
input[disabled] {
  color: gainsboro;
  background: gray !important;
}
</style>
