<template>
  <div :class="size" class="profile-info-block">
    <div class="label">
      {{ label }}
    </div>
    <div v-if="isArray==false" class="value">
      {{ value }}
    </div>
    <div v-else class="value" style="display: flex; justify-content: space-between; flex-wrap: wrap">
      <v-button
          v-for="item in value"
          :key="item"
          :disabled="load"
          :value="$i18n.locale == 'kk' ? item.title_kz:$i18n.locale == 'ru' ? item.title:item.title_en"
          style="margin: 0.5rem 0!important;"
      />
    </div>
  </div>
</template>

<script>
import vButton from '@/components/btn/v-button'

export default {
  name: 'profile-info-block',
  components: {
    vButton
  },
  props: ['value', 'label', 'size'],
  data: function () {
    return {
      isArray: false
    }
  },
  mounted() {
    if (Array.isArray(this.value)) {
      this.isArray = true
    }

  }
}
</script>

<style lang="scss" scoped>
@media only screen and (min-device-width: 451px) {
  .sm {
    padding-bottom: 1.3vw !important;
  }
  .profile-info-block {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.95vw;

    & {
      .label {
        font-family: Gotham, sans-serif;
        font-size: 1.3vw;
        padding-bottom: 0.65vw;
      }

      .value {
        font-family: Gotham-Medium, sans-serif;
        font-size: 22px;
      }
    }
  }
}

@media only screen and (max-device-width: 450px) {
  .sm {
    padding-bottom: 2.3vw !important;
  }
  .profile-info-block {
    display: flex;
    flex-direction: column;
    padding-bottom: 2.95vw;

    & {
      .label {
        font-family: Gotham, sans-serif;
        font-size: 4vw;
        padding-bottom: 1.65vw;
      }

      .value {
        font-family: Gotham-Medium, sans-serif;
        font-size: 5vw;
        line-height: 6vw;
      }
    }
  }
}
</style>
