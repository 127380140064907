<template>
  <div class="feedback card gr-success flex-col">
    <v-popup v-show="message" close-position="text-accent" @closePopup="closeAlertPopup">
      <div class="h2">
        <div class="center text-accent">
          <font-awesome-icon :icon="['fa','check-circle']" class="big-icon"/>
        </div>
        <span class="center" v-html="message"></span>
        <div class="center">
          <v-button size="text-accent p-1" value="OK" @click="sendEnded()"/>
        </div>
      </div>
    </v-popup>
    <form v-on:submit.prevent="submitForm">
      <div class="feedback__header h1 bold text-white text-45">
       {{$t('ВыберитеУслугу')}}
      </div>
      <div class="feedback__info page-info secondary-text bold">
       {{$t('оставьтеЗаявку')}}
      </div>
      <div class="feedback_input">
        <div class="feedback_input_col">
          <input ref="name" class="input" :placeholder="$t('Вашеимя')" required>
        </div>
        <div class="feedback_input_col">
          <input id="tel__numberc" ref="tel" class="input" :placeholder="$t('номерТелефона')" required
                 type="tel">
        </div>
        <div class="feedback_input_col">
          <input :disabled="send" class="input btn danger text-white" type="submit" :value="$t('отправитьЗаявку')">
        </div>
      </div>
      <div class="t20 feedback__checkup">
        <label class="container">
          <div class="feedback__checkup__flex">
            <div class="feedback__checkup__flex_col">
              <input required type="checkbox">
            </div>
            <div class="feedback__checkup__flex_col">
              <span class="checkmark"> {{$t('яПринимаю')}}</span>
            </div>
          </div>
        </label>
      </div>
    </form>
  </div>
</template>

<script>
import Inputmask from 'inputmask'
import VPopup from '@/components/modals/v-popup'
import VButton from '@/components/btn/v-button'
import { successFeedback } from '@/utils/functions'

export default {
  name: 'feedback',
  components: {
    VButton,
    VPopup
  },
  mounted () {
    var im = new Inputmask('+9 (999) 999 99 99')
    im.mask(document.getElementById('tel__numberc'))
  },
  data () {
    return {
      message: '',
      send: false
    }
  },
  methods: {
    sendEnded(){
      function scrollTo(to, duration = 700) {
        const
            element = document.scrollingElement || document.documentElement,
            start = element.scrollTop,
            change = to - start,
            startDate = +new Date(),
            // t = current time
            // b = start value
            // c = change in value
            // d = duration
            easeInOutQuad = function (t, b, c, d) {
              t /= d / 2;
              if (t < 1) return c / 2 * t * t + b;
              t--;
              return -c / 2 * (t * (t - 2) - 1) + b;
            },
            animateScroll = function () {
              const currentDate = +new Date();
              const currentTime = currentDate - startDate;
              element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
              if (currentTime < duration) {
                requestAnimationFrame(animateScroll);
              }
              else {
                element.scrollTop = to;
              }
            };
        animateScroll();
      }
      scrollTo(0, 0);

      this.$router.push('/')
    },
    closeAlertPopup () {
      this.message = ''
    },
    async submitForm () {
      this.send = true
      const result = await this.$http.post(
        `${process.env.VUE_APP_API_URL}web/request/customer`,
        {
          phone: `7${this.$refs.tel.value
            .replaceAll(' ', '')
            .replaceAll('(', '')
            .replaceAll(')', '')
            .replaceAll('+7', '')}`,
          name: this.$refs.name.value
        }
      )
      if (result) {
        this.$refs.tel.value = ''
        this.$refs.name.value = ''
        this.message = successFeedback()
        this.send = false
      }
    }
  }
}
</script>

<style scoped>
@media only screen and (max-device-width: 450px) {
  .feedback {
    display: flex;
    padding: 4vw 3vw;
  }

  .feedback_input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .feedback__checkup__flex {
    display: flex;
    font-size: 4vw;
  }

  .feedback__checkup__flex_col {
    display: table-cell;
  }

  .feedback__checkup {
    text-align: left !important;
    line-height: 6vw;
  }

  .feedback__header {
    font-size: 6vw !important;
    line-height: 8vw;
    text-align: center;
  }

  .feedback__info {
    line-height: 6vw;
    font-family: Gotham, sans-serif !important;
    font-weight: 400 !important;
  }

  .feedback_input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .feedback_input_col > .btn {
    border-radius: 1.105vw!important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 451px) {
  .feedback__checkup__flex {
    display: flex;
    flex-direction: row;
  }

  .feedback_input_col {
    width: 100%;
  }

  .feedback_input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .feedback_input_col > .btn {
    border-radius: 1.105vw !important;
  }

  .feedback__checkup__flex_col {
  }
}
</style>
