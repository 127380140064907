<template>
  <div class="profile">
    <vue-title :title="$t('данныеОрганизации')"/>
    <main-layout>
      <div class="profile__main">
        <left-navigation type="doctor"/>
        <div class="profile__main__content">
          <profile-title padding="p-2 web" :title="$t('данныеОрганизации')"/>
          <div class="profile__main__content__sub">
            <div class="profile__main__content__sub__img">
              <circle-img :name="$i18n.locale?doctor.name:doctor.name_en" :loader="changeImg" :pic="url?url:doctor.profile_photo"/>
              <form enctype="multipart/form-data" method="post">
                <input
                    v-show="false"
                    ref="myFile"
                    accept=".jpg, .jpeg, .png"
                    name="filename"
                    type="file"
                    @change="uploadAvatar"
                >
                <input v-show="false" type="submit">
                <div
                    class="profile__main__content__sub__img__edit web"
                    @click="fileUpload"
                >{{$t('Изменить')}} <img src="../../assets/icons/ant-design_edit-filled.svg"/></div>
              </form>
            </div>
            <clinic-info/>
          </div>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import LeftNavigation from '@/components/user/left-navigation'
import ProfileTitle from '@/components/titles/profile-title'
import CircleImg from '@/components/img/circle-img'
import axios from 'axios'
import ClinicInfo from '@/components/doctor/clinic-info'
import {handleView} from '@/utils/functions'

export default {
  name: 'Profile',
  components: {
    ClinicInfo,
    CircleImg,
    ProfileTitle,
    LeftNavigation,
    MainLayout
  },
  data: function () {
    return {
      changeImg: '',
      mobileView: true,
      isTokenIsEmpty: null,
      showNav: false,
      doctor: JSON.parse(localStorage.user),
      url: ''
    }
  },
  async created() {
    this.mobileView = handleView()
  },
  methods: {
    async uploadAvatar(event) {
      this.changeImg = 'load'
      var file = event.target.files[0]
      this.url = URL.createObjectURL(file)
      var formData = new FormData()
      formData.append('avatar', file)
      await axios.post(`${process.env.VUE_APP_API_URL}clinic/profile/upload-avatar`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function () {
      })
          .catch(function () {
          })
      this.updateProfile()
      this.changeImg = ''
    },
    fileUpload() {
      this.$refs.myFile.click()
    },
    scroll() {
      const element = document.getElementById('feedback-partner')
      element.scrollIntoView({behavior: 'smooth'})
    },
    async updateProfile() {
      var urlProfile = `${process.env.VUE_APP_API_URL}user/profile`
      // eslint-disable-next-line eqeqeq
      var role = localStorage.getItem('role')
      // eslint-disable-next-line eqeqeq
      if (role == 'clinic') {
        urlProfile = 'api/clinic/profile'
      }
      // eslint-disable-next-line eqeqeq
      if (role == 'doctor') {
        urlProfile = 'api/doctor/profile'
      }
      const user = (await axios(urlProfile, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      })).data
      localStorage.setItem('user', JSON.stringify(user))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";
@import "../../assets/css/profile";

@media only screen and (min-device-width: 451px) {
  .form-group {
    display: flex;
    flex-direction: column;
    line-height: 0 !important;
    padding: 0 !important;

    & {
      input[type='button'],
      input[type='submit'] {
        box-sizing: content-box
      }
    }
  }

  .form-group input {
    line-height: 0 !important;
    border-radius: 0 !important;
    font-family: Gotham, sans-serif;
    font-size: 5vw;
    margin: 0 !important;
  }
}
</style>
