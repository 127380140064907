<template>
  <div class="'col-info-3'">
    <div class="col-info-3-row">
      <div class="col-header">{{ header }}</div>
      <div class="col-content h2 ptb-2 text-white">{{ content }}</div>
      <div class="col-footer">{{ footer }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'col-info-3',
  props: ['header',
    'content',
    'footer']
}
</script>

<style lang="scss" scoped>
@media only screen and (max-device-width: 450px) {
  .col-info-3 {
    text-align: center !important;
    font-size: 4vw
  }
  .col-info-3-row {
    font-weight: 400;
    font-family: Gotham-Medium, sans-serif !important;
    width: 100%;
    padding: 6vw 0;
  }
  .col-content {
    font-size: 10vw;
  }

  .col-footer,
  .col-header {
    padding: 4vw 0;
    font-family: Gotham-Medium, sans-serif;
    font-size: 6vw;
    font-weight: 100;
  }
}

@media only screen and (min-device-width: 451px) {
  .col-info-3 {
    font-size: 1.105vw;
  }
  .col-info-3-row {
    width: 70%;
  }
  .col-content {
    font-size: 2.6vw;
  }

  .col-footer,
  .col-header {
    font-family: Gotham-Medium, sans-serif;
    font-size: 1.2vw;
    font-weight: 500;
  }
}

</style>
