function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile"},[_c('vue-title',{attrs:{"title":_vm.$t('расписание')}}),_c('main-layout',[(_vm.showPopup)?_c('RangeDatepickerModal',{attrs:{"clinicSchedule":true,"visible":_vm.showPopup},on:{"close":function($event){_vm.showPopup=false},"load-work":_vm.getSchedule}}):_vm._e(),_c('div',{staticClass:"profile__main"},[_c('left-navigation',{attrs:{"type":"clinic"}}),_c('div',{staticClass:"profile__main__content"},[_c('page-title',{attrs:{"title":"","weight":"normal"}}),_c('div',{staticClass:"profile_content"},[_c('v-button',{attrs:{"fa-icon":"calendar","size":"yellow w-20 p-1 m-1","value":_vm.$t('добавитьРасписание')},on:{"click":function($event){_vm.showPopup=true}}}),_c('calendar-view',{staticClass:"calendar_view theme-default",attrs:{"items":_vm.items,"show-date":_vm.showDate,"time-format-options":{ hour: 'numeric', minute: '2-digit' },"enable-drag-drop":true,"disable-past":_vm.disablePast,"disable-future":_vm.disableFuture,"show-times":_vm.showTimes,"display-period-uom":_vm.displayPeriodUom,"display-period-count":_vm.displayPeriodCount,"starting-day-of-week":_vm.startingDayOfWeek,"period-changed-callback":_vm.periodChanged,"current-period-label":_vm.useTodayIcons ? 'icons' : '',"displayWeekNumbers":_vm.displayWeekNumbers,"enable-date-selection":true,"selection-start":_vm.selectionStart,"selection-end":_vm.selectionEnd},on:{"date-selection-start":_vm.setSelection,"date-selection":_vm.setSelection,"date-selection-finish":_vm.finishSelection,"drop-on-date":_vm.onDrop,"click-date":_vm.onClickDay,"click-item":_vm.onClickItem,"contextmenu":function($event){return _vm.console.log('test')}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var value = ref.value;
var weekStartDate = ref.weekStartDate;
var top = ref.top;
var rest = objectWithoutProperties( ref, ["value", "weekStartDate", "top"] );
var item = rest;
return [( value.originalItem.requestType==1)?_c('div',{staticClass:"cv-item",class:value.classes,style:({top: top}),attrs:{"draggable":"true","title":" "},on:{"click":function($event){return _vm.onClickItem(value)}}},[_c('span',{staticClass:"startTime"},[_vm._v(_vm._s(value.originalItem.title_time))])]):_vm._e(),( value.originalItem.requestType==2)?_c('div',{staticClass:"cv-item cv-item-offline",class:value.classes,style:({top: top}),attrs:{"draggable":"true","title":" "},on:{"click":function($event){return _vm.onClickItem(value)}}},[_c('span',{staticClass:"startTime"},[_vm._v(_vm._s(value.originalItem.title_time))])]):_vm._e()]}},{key:"header",fn:function(ref){
var headerProps = ref.headerProps;
return [_c('calendar-view-header',{attrs:{"slot":"header","header-props":headerProps},on:{"input":_vm.setShowDate},slot:"header"})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }