<template>
  <div class="profile">
    <vue-title :title="$t('расписание')"/>
    <main-layout>
      <v-popup v-if="showPopup" @closePopup="showPopup =! showPopup">
        <date-picker :value="new Date()"/>
      </v-popup>
      <div class="profile__main">
        <left-navigation type="doctor"/>
        <div class="profile__main__content">
          <page-title title="" weight="normal"/>
          <div class="profile_content">
            <Calendar :events="events" :shedule="fcEvents"></Calendar>
            <div v-if="fcEvents.length>0" class="event__list">
              <p class="event__title bold">{{$t('предстоящиеСобытия')}}</p>
              <p class="event__date">{{ getFormattedDate(fcEvents[0].start) }} </p>
              <div class="event__cards">
                <div class="event__card">
                  <p class="client__name">{{ fcEvents[0].title }}</p>
                  <p class="client__time">{{ fcEvents[0].time.substring(0, fcEvents[0].time.lastIndexOf(':')) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/views/MainLayout'
import LeftNavigation from '@/components/user/left-navigation'
import PageTitle from '@/components/titles/page-title'
import VPopup from '@/components/modals/v-popup'
import Calendar from '@/components/Calendar.vue'
import * as dateUtil from '@/utils/dateUtil'
import {handleView} from '@/utils/functions'

export default {
  name: 'clinicSchedule',
  components: {
    VPopup,
    PageTitle,
    LeftNavigation,
    MainLayout,
    Calendar
  },
  data: function () {
    return {
      role: localStorage.role ? localStorage.role : null,
      name: this.$t('расписание'),
      mobileView: true,
      isTokenIsEmpty: null,
      showNav: false,
      showPopup: false,
      range: {
        start: new Date(2021, 2, 1),
        end: new Date(2021, 2, 3)
      },
      fcEvents: [],
      events: []
    }
  },
  async created() {
    this.mobileView = handleView()
    await this.getUpcoming()
    await this.getSchedule()
  },
  methods: {
    getFormattedDate(e) {
      var arr = []
      var arrs = e.split('-')
      arr.push(parseInt(arrs[0]))
      arr.push(parseInt(arrs[1]) - 1)
      arr.push(parseInt(arrs[2]))
      arr.push(0)
      arr.push(0)
      arr.push(0)
      arr.push(0)
      return dateUtil.formatDate(arr, 'MMMM D')
    },
    dayClick(event) {
    },
    async getSchedule() {
      this.loader = true
      var url = `${process.env.VUE_APP_API_URL}doctor/schedule/get`
      const result = await this.$http.post(url, {})
      result.data.map(item => {
        this.events.push(
            item.date
        )
        return item
      })
    },
    async getUpcoming() {
      this.loader = true
      var url = `${process.env.VUE_APP_API_URL}${this.role}/schedule/upcoming`
      const result = await this.$http.post(url, {})

      const sortedOfDate = result.data.sort((a, b) => {
        const Adate = `${a.date}T${a.time}`
        const Bdate = `${b.date}T${b.time}`
        return new Date(Adate) - new Date(Bdate)
      })
      sortedOfDate.map(item => {
        this.fcEvents.push({
          title: item.name,
          start: item.date,
          time: item.time
        })
        return item
      })
      // this.fcEvents.sort(function (a, b) {
      //   return a.start.localeCompare(b.start)
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";
@import "../../assets/css/profile";

@media only screen and (max-width: 450px) {
  .vc-weeks {
    //grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: auto auto 1fr 1fr 1fr auto auto;
    //grid-gap: 0.65vw;
    //height: calc(100vh - 0.65vw);
  }

  .vc-day > span,
  .vc-weekday {
    font-family: Gotham-Pro, sans-serif !important;
    //font-size: 1.6rem !important;
  }

  .profile_content {
  }

  .event {
    &__list {
      margin-top: 67px;
    }

    &__title {
      font-family: Gotham, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 6vw;
      color: #4D4D4D;
      margin-bottom: 0.65vw;
    }

    &__date {
      font-family: PlumbKaz;
      font-style: normal;
      font-weight: 500;
      font-size: 4vw;
      margin: 0;
      color: #9590A0;
    }

    &__cards {
      display: flex;
      flex-direction: column;
      margin-top: 1.95vw;
    }

    &__card {
      border-left: 10px solid #0b7d75;
      background: #FFFFFF;
      border-top: 0.5px solid #828282;
      border-right: 0.5px solid #828282;
      border-bottom: 0.5px solid #828282;
      box-sizing: border-box;
      border-radius: 0.65vw;
      position: relative;
      padding: 1.625vw 2.6vw;

      &::before {
        profile_content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0.52vw;
        height: 100%;
        background: #03887F;
        border-radius: 0.65vw 0px 0px 0.65vw;
      }

      &:not(:last-child) {
        margin-bottom: 0.975vw;
      }
    }
  }

  .client {
    &__name {
      margin: 0;
      font-family: Gotham, serif;
      font-weight: bold;
      font-size: 5vw;
      color: #828282;
    }

    &__time {
      margin: 0;
      font-family: PlumbKaz, serif;
      font-weight: 500;
      font-size: 4vw;
      color: #9590A0;
      margin-top: 0.65vw;
    }
  }
}

@media only screen and (min-width: 451px) {
  .vc-weeks {
    //grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: auto auto 1fr 1fr 1fr auto auto;
    //grid-gap: 0.65vw;
    //height: calc(100vh - 0.65vw);
  }

  .vc-day > span,
  .vc-weekday {
    font-family: Gotham-Pro, sans-serif !important;
    //font-size: 1.6rem !important;
  }

  .profile_content {
  }

  .event {
    &__list {
      margin-top: 67px;
    }

    &__title {
      font-family: Gotham, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.17vw;
      color: #4D4D4D;
      margin-bottom: 0.65vw;
    }

    &__date {
      font-family: PlumbKaz;
      font-style: normal;
      font-weight: 500;
      font-size: 1.105vw;
      margin: 0;
      color: #9590A0;
    }

    &__cards {
      display: flex;
      flex-direction: column;
      margin-top: 1.95vw;
    }

    &__card {
      border-left: 10px solid #0b7d75;
      background: #FFFFFF;
      border-top: 0.5px solid #828282;
      border-right: 0.5px solid #828282;
      border-bottom: 0.5px solid #828282;
      box-sizing: border-box;
      border-radius: 0.65vw;
      position: relative;
      padding: 1.625vw 2.6vw;

      &::before {
        profile_content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0.52vw;
        height: 100%;
        background: #03887F;
        border-radius: 0.65vw 0px 0px 0.65vw;
      }

      &:not(:last-child) {
        margin-bottom: 0.975vw;
      }
    }
  }

  .client {
    &__name {
      margin: 0;
      font-family: Gotham, serif;
      font-weight: bold;
      font-size: 1.3vw;
      color: #828282;
    }

    &__time {
      margin: 0;
      font-family: PlumbKaz, serif;
      font-weight: 500;
      font-size: 1.04vw;
      color: #9590A0;
      margin-top: 0.65vw;
    }
  }
}
</style>
