<template>
  <div class="empty">
    <font-awesome-icon icon="search" size="10x"/>
  </div>
</template>

<script>
export default {
  name: 'empty'
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.empty {
  padding: 1.3vw;
  color: $grey_70;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
</style>
