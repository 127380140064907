<template>
  <div class="answer">
    <div class="answer-header" v-on:click="(show=!show)">
      <div class="answer-header-text">{{ question }}</div>
      <div class="answer-arrow" v-bind:class="show?'up':'down'"></div>
    </div>
    <div v-show="show" class="answer-content">
      <span v-html="answer"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'answer',
  props: ['question',
    'answer'],
  data: function () {
    return {
      show: false
    }
  }

}
</script>

<style scoped>
.answer {
  display: flex;
  flex-direction: column;
}

.answer-header {
  display: flex;
  width: 1.95vw;
  height: 1.95vw;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (min-device-width: 451px) {
  .answer {
    margin-bottom: 1.04vw;
    padding: 2.08vw 43px;
    border-radius: 1.3vw;
    background-color: white;
    filter: drop-shadow(0px 1.95vw 2.6vw rgba(212, 217, 232, 0.2));
    text-align: left;
    flex-direction: column;
  }
  .answer-header {
    font-family: PlumbKaz-Bold, sans-serif;
    font-size: 1.95vw;
    width: 100%;
  }
  .answer-arrow {
    float: right;
    width: 1.95vw;
    height: 1.95vw;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .answer-content {
    font-size: 1.25vw;
    line-height: 1.5vw;
  }
}

@media only screen and (max-device-width: 450px) {
  .answer {
    margin-bottom: 3vw;
    padding: 5vw 5vw 5vw 8vw;
    border-radius: 1.3vw;
    background-color: white;
    filter: drop-shadow(0px 1.95vw 2.6vw rgba(212, 217, 232, 0.2));
    text-align: left;
    flex-direction: column;
  }

  .answer-header {
    font-family: PlumbKaz-Bold, serif;
    font-size: 5vw;
    width: 100%;
    height: auto;
  }

  .answer-arrow {
    float: right;
    width: 4vw;
    display: flex;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
  }

  .answer-content {
    font-size: 4vw;
    line-height: 6vw;
  }
}
.down {
  background-size: 100% 100% !important;
  background: url("../../assets/img/down-arrow.svg") no-repeat;
}

.up {
  background-size: 100% 100% !important;
  background: url("../../assets/img/up-arrow.svg") no-repeat;
}
</style>
