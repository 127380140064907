<template>
  <div class="service__item">
    <v-popup
        v-show="showPopup"
        @closePopup="closeInfoPopup"
        :is-visible="showPopup"
        :title="$t('записьнаконсультацию')"
    >
      <div class="service__item__popup">
        <div class="service__item__popup__body">
          <div class="service__item__popup__body__header">
            <div class="service__item__popup__body__header__logo">
              <circle-img
                  :name="  $i18n.locale == 'kk'?service.name_kz:$i18n.locale == 'ru' ? service.name : service.name_en"
                  :pic="service.profile_image"
                  size="sm"/>
            </div>
            <div class="service__item__popup__body__header__info">
              <div class="h2">
                {{ $i18n.locale == 'kk' ? service.name_kz : $i18n.locale == 'ru' ? service.name : service.name_en }}
              </div>
              <div class="service__item__popup__body__header__info__profession">
                <v-button
                    v-for="profession in service.professions"
                    :key="profession.id"
                    :value="$i18n.locale == 'kk'?profession.title_kz:$i18n.locale=='ru'?profession.title:profession.title_en"
                    size="p-1 mr-1 sm"
                />
              </div>
              <div class="service__item__popup__body__header__info__action">
                <!--                    :value="service.is_work_today == 1 ? 'Работает' : 'Выходной'"-->
                <v-button
                    style="margin: 0.5rem"
                    :value="$t('Работает')"
                    fa-icon="clock"
                    size="sm bordered-danger long lg"
                />
                <!--                <v-button-->
                <!--                    v-if="service.requestType=='1'"-->
                <!--                    style="margin: 0.5rem"-->
                <!--                    size="p-1 mr-1 sm green long  unvisibility" -->
                <!--                    :value="$t('онлайн')"-->
                <!--                />-->
                <!--                <v-button-->
                <!--                    v-if="service.requestType=='2'"-->
                <!--                    style="margin: 0.5rem"-->
                <!--                    size="p-1 mr-1 sm red long  unvisibility"-->
                <!--                    :value="$t('оффлайн')"-->
                <!--                />-->
                <!--                <v-button-->
                <!--                    v-if="service.requestType=='3'"-->
                <!--                    style="margin: 0.5rem"-->
                <!--                    size="p-1 mr-1 sm orange long  unvisibility"-->
                <!--                    :value="$t('онлайнИОфллайн')"-->
                <!--                />-->
                <v-button
                    v-if="service.requestType=='1'"
                    style="margin: 0.5rem"
                    size="p-1 mr-1 sm green long "
                    :value="$t('онлайн')"
                />
                <v-button
                    v-if="service.requestType=='2'"
                    style="margin: 0.5rem"
                    size="p-1 mr-1 sm red long "
                    :value="$t('оффлайн')"
                />
                <v-button
                    v-if="service.requestType=='3'"
                    style="margin: 0.5rem"
                    size="p-1 mr-1 sm orange long "
                    :value="$t('онлайнИОфллайн')"
                />
              </div>
            </div>
          </div>
          <div class="service__item__popup__body__body">
            <div v-show="!(role === 'user')" class="text-danger">
              {{ $t('авторизуйтесьДляЗаписи') }}
            </div>
            <div class="h2">{{ $t('услуги') }}</div>
            <div v-for="item in service.doctor_services" class="service__item__popup__body__body__info"
                 style="width: 100%">
              <span class="title-card">{{
                  $i18n.locale == 'kk' ? item.title_kz : $i18n.locale == 'ru' ? item.title : item.title_en
                }}</span>
              <span class="bold">{{ item.price }}  {{ $t('тенге') }}</span>
              <v-button
                  v-if="role == 'user'"
                  style="margin: 0.5rem"
                  fa-icon="calendar"
                  size="p-1 mr-1 sm orange "
                  :value="$t('Записаться')"
                  @click="signup(item.id);getSchedule()"
              />
              <v-button
                  v-else
                  style="margin: 0.5rem"
                  fa-icon="calendar"
                  size="p-1 mr-1 sm orange "
                  :value="$t('Записаться')"
                  @click="loginModal"
              />
            </div>
            <div class="service__item__popup__body__body__bio">
              {{ $i18n.locale == 'kk' ? service.bio_kz : $i18n.locale == 'ru' ? service.bio : service.bio_en }}
            </div>
          </div>
        </div>
      </div>

    </v-popup>
    <v-popup v-show="timeTake" close-position="right-close" @closePopup="closeTimeTake">
      <div class="service__item__popup">
        <div class="service__item__popup__body1">
          <form v-on:submit.prevent="submitOrderForm">
            <div class="service__item__popup__body1__left">
              <span class="service__item__popup__body1__left__header">{{ $t('записьнаконсультацию') }}</span>
              <input ref="d_id" :value="service.id" required type="hidden">
              <input v-if="service.doctor_services[0]" ref="d_s_id" :value="service.doctor_services[0].id" required
                     type="hidden">
              <input required type="hidden">
              <input v-model="this.dateTime" required type="hidden">
              <div class="form-group">
                <input
                    :value="$i18n.locale=='kk'?service.name_kz:$i18n.locale=='ru'?service.name:service.name_en"
                    class="input-sm bordered"
                    disabled="disabled"
                    readonly="readonly"
                    required
                    style="background-color: gainsboro!important; border-color: gainsboro"
                    type="text"
                    @change="changeCity"
                />
              </div>
              <div class="form-group">
                {{ $t('Вашеимя') }}
                <input
                    ref="username"
                    v-model="user.name"
                    class="input-sm bordered"
                    required
                    type="text"
                />
              </div>
              <div class="form-group">
                {{ $t('вашЭлектронныйАдрес') }}
                <input
                    ref="usercontact"
                    v-model="user.email"
                    class="input-sm bordered"
                    required
                    type="email"
                />
              </div>
              <div class="form-group">
                {{ $t('номерТелефона') }}
                <input
                    ref="usercontact"
                    class="input-sm bordered"
                    :value="user.phone"
                    required
                    type="text"
                    :id=service.id
                />
              </div>
              <div class="form-group">
                {{ $t('типЗаписи') }}
                <select disabled required v-model="record_type" class="input-sm bordered select_record">
                  <option value="">{{ $t('типЗаписи') }}</option>
                  <option value="1">
                    {{ $t('онлайн') }}
                  </option>
                  <option value="2">
                    {{ $t('оффлайн') }}
                  </option>
                </select>
              </div>
              <v-button
                  :disabled="load"
                  size="btn btn-success text-white offset"
                  type="submit"
                  :value="$t('перейтиКоплате')"
              />
            </div>
          </form>
          <div class="service__item__popup__body1__right">

            <date-picker
                v-model="dateTime"
                :open="true"
                :attributes="attrs"
                :is-required='true'
                :is24hr='true'
                :disabled-dates="disableDates"
                :available-dates='availableDates'
                :minute-increment="30"
                :timezone="timezone"
                mode="date"
                type="date"
                value="multiple"
                @update:from-page="changeMonth"
            />
            <el-select @change="selectCurentType" v-model="selectedTime" class="select_time vc-container"
                       :placeholder="$t('выберитеВремя')">
              <el-option
                  v-for="item in times"
                  :key="item.time_id"
                  :label="item.title_time"
                  :value="item.title_time"
                  :disabled="item.disabled"
              >

              </el-option>
            </el-select>
            <p v-if="errorMessage" class="center text-red-600 text-xs italic mt-1">
              {{ errorMessage }}
            </p>
          </div>
        </div>
      </div>
    </v-popup>
    <v-popup-normal v-show="messageError" @closePopup="messageError=null">
      <div class="popup__body h2">
        {{ messageError }}
      </div>
    </v-popup-normal>
    <v-popup-normal v-show="message" close-position="text-accent" @closePopup="closeAlertPopup">
      <div class="h2">
        <div class="center text-accent">
          <font-awesome-icon :icon="['fa','check-circle']" class="big-icon"/>
        </div>
        <span class="center" v-html="message"></span>
        <div class="center">
          <v-button size="text-accent p-1" value="OK" @click="closeAlertPopup"/>
        </div>
      </div>
    </v-popup-normal>
    <div class="service__item__img">
      <circle-img :name="   $i18n.locale == 'kk'?service.name_kz:$i18n.locale == 'ru' ? service.name : service.name_en "
                  :pic="service.profile_image"/>
      <div class="service__item__info__header visibility">
        <div class="flex">
          <div class="h2">
            {{ $i18n.locale == 'kk' ? service.name_kz : $i18n.locale == 'ru' ? service.name : service.name_en }}
          </div>
          <div class="service__item__info__header__price">
            <img src="../../assets/icons/coin.svg" width="17"> {{ $t('ценыОт') }} {{ service.prices_from }}
            {{ $t('тенге') }}
          </div>
        </div>
        <div class="service__item__info__footer visibility">
          <v-button
              v-for="profession in service.professions"
              :key="profession.id"
              :disabled="load"
              :value="$i18n.locale == 'kk'?profession.title_kz:$i18n.locale=='ru'?profession.title:profession.title_en"
              size="p-1 mr-1"
          />
        </div>
        <div class="service__item__info__header__action" @click="selectClinic">
          <v-button size="dark lg border-60 p-1" :value="$t('Подробно')"/>
        </div>
      </div>
    </div>
    <div class="service__item__info">
      <div class="service__item__info__header unvisibility">
        <div class="flex">
          <div class="h2">
            {{ $i18n.locale == 'kk' ? service.name_kz : $i18n.locale == 'ru' ? service.name : service.name_en }}
          </div>
          <div class="service__item__info__header__price">
            <img src="../../assets/icons/coin.svg" width="17"> {{ $t('ценыОт') }} {{ service.prices_from }}
            {{ $t('тенге') }}
          </div>
        </div>
        <div class="service__item__info__header__action" @click="selectClinic">
          <v-button size="dark lg border-60 p-1 " :value="$t('Подробно')"/>
        </div>
      </div>
      <div
          v-show="service.bio"

          class="service__item__info__body unvisibility"
          @click="showHide=!showHide"
      >
        {{ $i18n.locale == 'kk' ? service.bio_kz : $i18n.locale == 'ru' ? service.bio : service.bio_en }}
      </div>
      <div class="service__item__info__footer unvisibility">
        <v-button
            style="margin: 0.5rem;"
            v-for="profession in service.professions"
            :key="profession.id"
            :disabled="load"
            :value="$i18n.locale == 'kk'?profession.title_kz:$i18n.locale == 'ru' ? profession.title:profession.title_en"
            size="p-1 mr-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vButton from '@/components/btn/v-button'
import VPopup from '@/components/modals/v-big-popup'
import VPopupNormal from '@/components/modals/v-popup'
import CircleImg from '@/components/img/circle-img'
import {successOrder} from '@/utils/functions'
import moment from "moment";
import Inputmask from "inputmask";
// import DatePicker from "vue2-datepicker";
// import 'vue2-datepicker/index.css';

export default {
  name: 'service-item',
  components: {
    CircleImg,
    VPopup,
    VPopupNormal,
    vButton,
    // DatePicker
  },
  props: ['service', 'mobile', 'returnUrl'],
  created() {
    if (this.service.requestType == 1) {
      this.record_type = 1
    }
    if (this.service.requestType == 2) {
      this.record_type = 2
    }

    this.doctor_id = this.service.id
  },
  data: function () {
    return {
      timesShedule: [],
      currentType: 0,
      role: localStorage.role ? localStorage.role : null,
      record_type: '',
      showHide: false,
      showPopup: false,
      timeTake: false,
      timeServiceTake: false,
      timezone: '',
      user: localStorage.user ? JSON.parse(localStorage.user) : {name: '', email: '', phone: ''},
      dateTime: moment(),
      message: '',
      eMessage: undefined,
      messageError: '',
      load: false,
      doctor_id: null,
      selectedTime: '',
      dates: [],
      availableDates: [],
      disableDates: [],
      currentServiceID: '',
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          }
        }
      ]
    }
  },
  mounted() {
    var im = new Inputmask('+9 (999) 999 99 99')
    im.mask(document.getElementById(this.service.id))
  },
  // watch: {
  //   timeTake: function () {
  //     this.timeTake && this.getSchedule()
  //   }
  // },
  computed: {
    errorMessage: {
      get() {
        if (this.dateTime === '' || this.selectedTime === '') return this.$t('датаОбязательна')
        // var h = this.dateTime.getHours()
        // var m = this.dateTime.getMinutes()
        // // eslint-disable-next-line eqeqeq
        // if (h == new Date().getHours() && m == new Date().getMinutes()) {
        //   return 'Поле дата и время обязательны для заполнения'
        // }
        return this.eMessage
      },
      set() {
      }
    },
    times() {
      const date = moment(this.dateTime).format('YYYY-MM-DD')
      return this.dates[date]?.times
    }
  },
  // ToDo get doc work time

  // beforeUpdate() {
  //   this.timeTake && this.dates.length === 0 && this.getSchedule()
  // },
  watch: {
    dateTime: function () {
      this.selectedTime = ''
    }
  },
  methods: {
    selectCurentType() {
      let day = moment(this.dateTime).format('YYYY-MM-DD')
      let currentDay = this.timesShedule.find(time => time.date == day)
      let currentHour = currentDay.times.find(time => time.title_time == this.selectedTime)
      this.currentType = currentHour.requestType
      this.record_type = this.currentType
    },
    changeMonth(objOfDate, ...props) {
      const date = `${objOfDate.month}/01/${objOfDate.year}`
      this.dateTime = date
      this.timeTake && this.getSchedule()

    },
    async getSchedule() {
      try {
        let current = this.service.doctor_services.find(item => item.id === this.currentServiceID);
        console.log(current)
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}web/schedule`, {
              date: this.dateTime !== '' && this.dateTime != 'Invalid date' ? moment(this.dateTime).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
              doctor_info_id: this.doctor_id,
              service_id: this.currentServiceID,
              request_type: current.request_type
            }
        )
        for (let i = 0; i < result.data.disable.length; i++) {
          for (let x = 0; x < result.data.all.length; x++) {
            if (result.data.disable[i].date == result.data.all[x].date) {
              for (let time = 0; time < result.data.all[x].times.length; time++) {
                if (result.data.all[x].times[time].title_time.substr(0, 5) == result.data.disable[i].time.substr(0, 5)) {
                  result.data.all[x].times[time].disabled = true
                }
              }
            }
          }
        }

        var today = new Date();
        var time = new Date();

        let Hour = time.getHours();
        let Minutes = time.getMinutes();

        time = Hour + ':' + Minutes

        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;


        for (let y = 0; y < result.data.all.length; y++) {
          if (result.data.all[y].date == today) {
            for (let x = 0; x < result.data.all[y].times.length; x++) {
              console.log(time)
              console.log(result.data.all[y].times[x].title_time.split('-')[1])
              var a = time;
              var b = result.data.all[y].times[x].title_time.split('-')[1];
              var amin = a.split(':')[0] * 60 + a.split(':')[1] * 1;
              var bmin = b.split(':')[0] * 60 + b.split(':')[1] * 1;
              if (bmin + 60 * 12 < amin) {
                bmin += 60 * 24
              }
              var c = bmin - amin

              if (c <= 0) {
                result.data.all[y].times[x].disabled = true
              }


            }
          }
        }


        this.timesShedule = result.data.all
        this.dates = result.data.all.reduce((acc, item) => {
          acc[item.date] = item;
          return acc;
        }, {})
        this.availableDates = result.data.all.filter(item => {
          return moment(item.date).isSameOrAfter(moment().format('YYYY-MM-DD'))
        }).map(item => ({
          start: moment(item.date).toDate(),
          end: moment(item.date).toDate(),
        }))

        this.disableDates = this.availableDates.length === 0 ? [{start: new Date(1970)}] : []
        this.dateTime = ''
      } catch (e) {
      }
    },
    async submitOrderForm() {

      if (this.dateTime) {
        var h = this.dateTime.getHours()
        var m = this.dateTime.getMinutes()
        // eslint-disable-next-line eqeqeq
        const time = this.selectedTime.split('-')[0]
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}web/services/request-appointment`, {
              doctor_id: this.$refs.d_id.value,
              doctor_service_id: this.currentServiceID,
              date: moment(this.dateTime).format('YYYY-MM-DD'),
              time,
              record_type: this.record_type,
              name: this.user.name,
              email: this.user.email,
              phone: this.$refs.usercontact.value.replace(/\D/g, '')
            }
        ).catch(function (error) {
          if (localStorage.getItem('locale') == 'ru') {
            alert('На это время уже есть запись')
          } else if (localStorage.getItem('locale') == 'kk') {
            alert('Бұл жолғы рекорд қазірдің өзінде бар')
          } else {
            alert('There is already a record for this time')
          }
        })
        if (result) {
          this.user.name = ''
          this.user.email = ''
          this.user.phone = ''
          this.$refs.d_id.value = ''
          this.$refs.d_s_id.value = ''
          this.message = successOrder()
          setTimeout(() => {
            window.location.href = result.data.redirect_url
          }, 2000)
          this.closeInfoPopup()
          this.load = false
          this.closeTimeTake()
        }
      }
    },
    changeCity() {

    },
    signup(serviceId) {
      this.currentServiceID = serviceId
      this.closeInfoPopup()
      this.timeTake = true
    },
    selectClinic() {
      this.showPopup = true

    },
    loginModal() {
      this.showPopup = false
      document.getElementById('login').click()

    },
    closeInfoPopup() {
      this.showPopup = false

    },
    closeTimeTake() {
      this.timeTake = false

    },
    closeTimeServiceTake() {
      this.timeServiceTake = false

    },
    closeAlertPopup() {
      this.message = ''
    },
    pad(n) {
      return n < 10 ? '0' + n : n
    }
  }
}
</script>

<style lang="scss" scoped>
.is-disabled {
  background-color: red !important;
  color: white !important;
}

.is-disabled:hover {
  background-color: red !important;
  color: white !important;
}

.select_record {
  margin-bottom: 24px;
  height: 55px;
  width: 100%;
  font-size: initial !important;
  color: #4d4d4d;
  font-weight: normal;
  background: white;
  border-radius: 20px;
  border: 0.065vw solid #00938A;

  option {
    //height:35px;
  }
}

@media only screen and (max-device-width: 450px) {
  .select_record {
    height: 35px;

    option {
      height: 35px;
    }
  }
}

form {
  display: flex;
  height: 100%;
}

.offset {
  margin: 12px 0;
}

.select_time {
  width: 100%;
  max-width: 250px;
  margin-top: 20px;
}

@import "../../assets/css/colors";
.logo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: cover;
  box-shadow: 1.3vw 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
}

@media only screen and (max-device-width: 450px) {
  .title-card {
    width: auto;
    overflow: hidden;
    margin-right: 1rem;
    word-break: inherit;
    font-size: 3vw;
  }
  .circle {
    margin: 1vw 3vw;
    justify-content: left !important;
  }
  .vc-title,
  .btn {
    font-size: 4vw !important;
  }

  .service {

    &__item {
      flex-direction: column;
      width: 100%;
      box-shadow: 0 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
      background-color: white;
      margin: 5vw 0;

      &__popup {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__body {
          display: flex;
          flex-direction: column;
          margin: 0 2vw;

          &__header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__logo {
              margin: 0 1.95vw 1.3vw 0;
            }

            &__info {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;

              &__profession {
                display: flex;
                margin: 0.65vw 0;
                flex-direction: row;

                width: 100%;
                overflow-y: auto;
              }

              &__action {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 3vw 0;
              }
            }
          }

          &__body {
            line-height: 25px;

            &__info {
              width: 100%;
              border-top: 0.065vw solid gainsboro;
              flex-direction: row;
              display: flex;
              font-size: 4vw;
              font-family: Gotham-Pro, sans-serif;
              padding-top: 4vw;
              margin-bottom: 2vw;
              justify-content: space-between;
            }

            &__bio {
              font-family: PlumbKaz, sans-serif !important;
              font-size: 3vw !important;
              line-height: 6vw;
              margin: 3vw 0;
              display: block;
            }
          }
        }

        &__body1 {
          display: flex;
          flex-direction: column;
          max-width: 100vw;
          margin: 0 0.65vw;

          &__left {
            margin: 0 1.3vw;
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: space-around;

            &__header {
              font-family: Gotham-Pro, sans-serif;
              font-size: 4vw;
            }
          }

          &__right {
            padding: 1.04vw 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          &__header {
            display: flex;
            flex-direction: row;
            justify-content: left;
            text-align: left;

            &__logo {
              margin: 0 1.95vw 1.3vw 0;
            }

            &__info {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              width: 100%;

              &__profession {
                display: flex;
                margin: 2vw 0;
                flex-direction: row;
              }

              &__action {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              }
            }
          }

          &__body {
            line-height: 1.755vw;
            //button{
            //  padding:0 !important;
            //  margin:0 !important;
            //}

            &__info {
              width: 90%;
              border-top: 0.065vw solid gainsboro;
              flex-direction: row;
              display: flex;
              font-size: 1.04vw;
              font-family: Gotham-Pro, sans-serif;
              padding-top: 1.04vw;
              margin-bottom: 2.08vw;
              justify-content: space-between;
            }

            &__bio {
              font-family: PlumbKaz, sans-serif !important;
              overflow: hidden;
              font-size: 0.975vw !important;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      &__info {
        width: 100%;
        margin: 2.6vw 2.6vw 2.6vw 1.95vw;
      }
    }
  }

  .visibility {
    display: flex !important;
  }
  .unvisibility {
    display: none !important;
  }
  .h2 {
    font-size: 5vw !important;
  }
  .service {
    &__item {
      display: flex;
      border-radius: 2.78vw;
      flex-direction: column;
      text-align: left;

      &__img {
        margin: 1.5vw;
        //padding-right: 2vw;
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: auto;
      }

      &__info {
        width: 100%;
        flex-direction: row;

        &__header {
          margin: 1vw 0;
          display: flex;
          padding-left: 3vw;
          flex-direction: column;
          justify-content: left;
          width: 100%;
          box-sizing: border-box;

          &__price {
            margin: 1vw 0;
            font-size: 3vw;

            & img {
              width: 9px;
              margin-right: 0.52vw;
            }
          }

          & div {
            width: 100%;
            display: flex;
          }

          &__action {
            margin: 1vw 0;
            width: 100%;
            flex-direction: column !important;
            justify-content: left;

            .border-60 {
              border-radius: 2vw;
              margin-left: 0 !important;
            }
          }
        }

        &__body {
          font-family: Gotham-Pro, sans-serif;
          font-size: 3vw;
          line-height: 3.975vw;
          margin: 2vw;
        }

        &__footer {
          margin-top: 1.69vw;
          display: flex;
          flex-wrap: wrap;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;

          div {
            flex: 0 0 auto;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 451px) {
  .service {
    &__item {
      flex-direction: column;
      width: 100%;
      box-shadow: 0 1.95vw 2.6vw rgba(212, 217, 232, 0.2);
      background-color: white;
      margin-bottom: 1.625vw;

      &__popup {
        display: flex;
        flex-direction: column;

        &__body {
          display: flex;
          flex-direction: column;
          max-width: 500px;
          margin: 0 1.3vw;

          &__header {
            display: flex;
            flex-direction: row;

            &__logo {
              margin: 0 1.95vw 1.3vw 0;
            }

            &__info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
              max-width: 400px;

              &__profession {
                display: flex;
                margin: 0.65vw 0;
                flex-wrap: nowrap;
                flex-direction: row;
                width: 100%;
                overflow-x: auto;
                -webkit-overflow-scrolling: auto;
              }

              &__action {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
              }
            }
          }

          &__body {
            line-height: 1.755vw;

            &__info {
              width: 90%;
              border-top: 0.065vw solid gainsboro;
              flex-direction: row;
              display: flex;
              font-size: 1.04vw;
              font-family: Gotham-Pro, sans-serif;
              padding-top: 1.04vw;
              margin-bottom: 2.08vw;
              justify-content: space-between;
            }

            &__bio {
              display: block;
              font-family: PlumbKaz, sans-serif !important;
              font-size: 0.975vw !important;
            }
          }
        }

        &__body1 {
          display: flex;
          flex-direction: row;
          max-width: 700px;
          margin: 0 0.65vw;

          &__left {
            margin: 0 1.3vw;
            display: flex;
            flex-direction: column;
            width: 400px;
            justify-content: space-around;

            &__header {
              font-family: Gotham-Pro, sans-serif;
              font-size: 1.56vw;
            }
          }

          &__right {
            padding: 1.04vw 0;
          }

          &__header {
            display: flex;
            flex-direction: row;
            justify-content: left;
            text-align: left;

            &__logo {
              margin: 0 1.95vw 1.3vw 0;
            }

            &__info {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              width: 100%;

              &__profession {
                display: flex;
                margin: 0.65vw 0;
                flex-direction: row;
              }

              &__action {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              }
            }
          }

          &__body {
            line-height: 23px;

            &__info {
              width: 90%;
              border-top: 0.065vw solid gainsboro;
              flex-direction: row;
              display: flex;
              font-size: 1.04vw;
              font-family: Gotham-Pro, sans-serif;
              padding-top: 1.04vw;
              margin-bottom: 2.08vw;
              justify-content: space-between;
            }

            &__bio {
              font-family: PlumbKaz, sans-serif !important;
              overflow: hidden;
              font-size: 0.975vw !important;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      &__info {
        width: 100%;
        margin: 2.6vw 2.6vw 2.6vw 1.95vw;
      }
    }
  }

  .visibility {
    display: none !important;
  }
  .unvisibility {
  }
  .service {
    &__item {
      display: flex;
      border-radius: 0.78vw;
      flex-direction: row;

      &__img {
        padding: 2.6vw 0.65vw 0 2.6vw;
      }

      &__info {
        width: 100%;
        margin: 2.6vw 2.6vw 2.6vw 1.95vw;
        flex-direction: row;

        &__header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          &__price {
            margin: 1.04vw 0;

            & img {
              margin-right: 0.52vw;
            }
          }

          & div {
            width: 100%;
            display: flex;
          }

          &__action {
            width: 100%;
            flex-direction: column !important;
            justify-content: flex-start;
            padding-top: 1.04vw;
            justify-items: center;
            align-items: center;
          }
        }

        &__body {
          font-family: Gotham-Pro, sans-serif;
          font-size: 1.3vw;
          line-height: 23px;
        }

        &__footer {
          margin-top: 1.69vw;
          display: flex;
          flex-wrap: wrap;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;

          div {
            flex: 0 0 auto;
          }
        }
      }
    }
  }
  .bordered {
    border-radius: 0.52vw;
    border: 0.065vw $green solid;
  }
}
</style>
