<template class="template">
  <header>
    <div v-if="mobileView" id="mobile-nav">
      <div class="nav__icon">
        <img height="24" src="@/assets/img/menu-burger.svg" v-on:click="showNav =! showNav">
      </div>
      <div class="nav__logo">
        <div class="nav__logo">
          <img height="37" src="@/assets/img/logo.svg">
        </div>
      </div>
      <div class="nav__user">
        <img v-if="user" height="24" src="@/assets/img/out.svg" @click="logout">
        <img  v-if="!user" height="24" id="login" src="@/assets/img/user.svg" @click="closeLogin">
      </div>
    </div>
    <div v-if="showNav" class="mobile_nav_item">
      <a class="auth__nav__item change_lang" @click="changeLang()"><span>{{ $i18n.locale }}</span></a>
      <router-link v-show="user && role == 'user'" class="auth__nav__item" :to="{'name': 'UserProfile'}">
        <img src="../assets/icons/user_outline.svg"> {{ $t('личныеДанные') }}
      </router-link>
      <router-link v-show="user && role == 'user'" class="auth__nav__item" :to="{'name': 'UserServices'}">
        <img src="../assets/icons/doctors.svg"> {{ $t('услуги') }}
      </router-link>
      <router-link v-show="user && role == 'user'" class="auth__nav__item" :to="{'name': 'UserBookingService'}">
        <img src="../assets/icons/my-consultations.svg">{{ $t('моиКонсультации') }}
      </router-link>
      <router-link v-show="user && role == 'user'" class="auth__nav__item" :to="{'name': 'UserAnalysis'}">
        <img src="../assets/icons/my-analises.svg">{{ $t('моиАнализы') }}
      </router-link>

      <router-link v-show="user && role == 'user'" class="auth__nav__item" :to="{'name': 'UserBookingCheckup'}">
        <img src="../assets/icons/checkup.svg">{{ $t('записиНаЧекапы') }}
      </router-link>
      <router-link v-show="user && role == 'user'" class="auth__nav__item" :to="{'name': 'UserBookingClinic'}">
        <img src="../assets/icons/services.svg">{{ $t('записиВКлиники') }}
      </router-link>

      <router-link v-show="user && role == 'doctor'" class="auth__nav__item" :to="{'name': 'DoctorProfile'}">
        <img src="../assets/icons/user_outline.svg"> {{ $t('личныеДанные') }}
      </router-link>
      <router-link v-show="user && role == 'doctor'" class="auth__nav__item" :to="{'name': 'DoctorOrders'}">
        <img src="../assets/icons/doctors.svg">{{ $t('бронирования') }}
      </router-link>
      <router-link v-show="user && role == 'doctor'" class="auth__nav__item" :to="{'name': 'DoctorSchedule'}">
        <img src="../assets/icons/schedule.svg">{{ $t('расписание') }}
      </router-link>
      <router-link v-show="user && role == 'doctor'" class="auth__nav__item" :to="{'name': 'DoctorScheduleWork'}">
        <img src="../assets/icons/schedule.svg">{{ $t('графикРаботы') }}
      </router-link>
      <router-link v-show="user && role == 'clinic'" class="auth__nav__item" :to="{'name': 'ClinicProfile'}">
        <img src="../assets/icons/user_outline.svg"> {{ $t('личныеДанные') }}
      </router-link>
      <router-link v-show="user && role == 'clinic'" class="auth__nav__item" :to="{'name': 'ClinicStaff'}">
        <img src="../assets/icons/doctors.svg">{{ $t('сотрудники') }}
      </router-link>
      <router-link v-show="user && role == 'clinic'" class="auth__nav__item" :to="{'name': 'ClinicOrders'}">
        <img src="../assets/icons/orders.svg">{{ $t('бронирования') }}
      </router-link>
      <router-link v-show="user && role == 'clinic'" class="auth__nav__item" :to="{'name': 'ClinicServices'}">
        <img src="../assets/icons/services.svg">{{ $t('услуги') }}
      </router-link>
      <router-link v-show="user && role == 'clinic'" class="auth__nav__item" :to="{'name': 'ClinicCheckups'}">
        <img src="../assets/icons/checkup.svg">{{ $t('чекапы') }}
      </router-link>
      <!--      <router-link v-show="user && role == 'clinic'" class="auth__nav__item" :to="{'name': 'ClinicSchedule'}">-->
      <!--        <img src="../assets/icons/schedule.svg">{{ $t('расписание') }}-->
      <!--      </router-link>-->
      <router-link v-show="user && role == 'clinic'" class="auth__nav__item" :to="{'name': 'ClinicServicesList'}">
        <img src="../assets/icons/doctors.svg">{{ $t('услугиКлиники') }}
      </router-link>
      <router-link v-show="user && role == 'clinic'" class="auth__nav__item" :to="{'name': 'ClinicScheduleWork'}">
        <img src="../assets/icons/schedule.svg">{{ $t('графикРаботы') }}
      </router-link>
      <hr v-show="user">
      <router-link class="nav__item" to="/records">
        <span v-on:click="nav_title=$t('записи')"> {{ $t('записи') }}</span>
      </router-link>
      <router-link class="nav__item" to="/about">
        <span v-on:click="nav_title=$t('оНас')">{{ $t('оНас') }}</span>
      </router-link>
      <router-link class="nav__item" to="/partners">
        <span v-on:click="nav_title=$t('какПодключиться')">{{ $t('какПодключиться') }}</span>
      </router-link>
      <div class="phone-contact-wrapper phone-contact-wrapper-mobile">
        <a style="    padding-left: 3rem;" class="phone-link" href="tel:87470955433">8 747 095 54 33</a>
        <a style="    padding-left: 3rem;" target="_blank" class="whatsapp-link" href="https://wa.me/+77470955434">8 747
          095 54 34</a>
      </div>
    </div>
    <div v-if="!mobileView" id="nav">
      <div class="nav__logo">
        <router-link class="nav__item" to="/records">
          <img height="50" src="@/assets/img/logo.svg">
        </router-link>
      </div>
      <div class="nav__menu t20">
        <router-link class="nav__item" to="/records"><span v-on:click="nav_title=$t('записи')">  {{
            $t('записи')
          }}</span>
        </router-link>
        <router-link class="nav__item" to="/about"><span v-on:click="nav_title=$t('оНас')">{{
            $t('оНас')
          }}</span></router-link>
        <router-link class="nav__item" to="/partners">
          <span v-on:click="nav_title=$t('какПодключиться')"> {{ $t('какПодключиться') }}</span>
        </router-link>
        <div class="phone-contact-wrapper">
          <a class="phone-link" href="tel:87470955433">8 747 095 54 33</a>
          <a target="_blank" class="whatsapp-link" href="https://wa.me/+77470955434"> 8 747 095 54 34</a>
        </div>
        <a class="change_lang" @click="changeLang()"><span>{{ $i18n.locale }}</span></a>
        <div v-on:click="nav_title =  $t('личныйКабинет'); user && role ? null : closeLogin()">

          <router-link v-if="user && role == 'user'" :to="{'name': 'UserProfile'}"
                       class="nav__item active overflow-elipsis-head">{{
              user.name
            }}
          </router-link>
          <router-link v-else-if="user && role == 'doctor'" :to="{'name': 'DoctorProfile'}"
                       class="nav__item active overflow-elipsis-head">{{
              $i18n.locale == 'kk' ? user.name_kz : $i18n.locale == 'ru' ? user.name : user.name_en
            }}
          </router-link>
          <router-link v-else-if="user && role == 'clinic'" :to="{'name': 'ClinicProfile'}"
                       class="nav__item active overflow-elipsis-head">{{
              $i18n.locale == 'kk' ? user.name_kz : $i18n.locale == 'ru' ? user.name : user.name_en
            }}
          </router-link>
          <router-link id="login" v-else to="/" class="nav__item active"> {{
              $t('личныйКабинет')
            }}
          </router-link>
        </div>
      </div>
    </div>
    <v-modal v-show="this.$store.getters.islogInDialog" :isOpen="this.$store.getters.islogInDialog"></v-modal>
  </header>
</template>

<script>
import vModal from '@/components/modals/modal'
import router from '@/router'
import AuthModal from "./modals/AuthModal";


export default {
  name: 'navigation',
  props: ['mobileView'],
  components: {
    AuthModal,
    vModal,
  },
  data: function () {
    return {
      showNav: false,
      user: localStorage.user ? JSON.parse(localStorage.user) : null,
      role: localStorage.role
    }
  },
  methods: {
    changeLang() {
      if (this.$i18n.locale == 'ru') {
        this.$i18n.locale = 'kk'
        localStorage.setItem('locale', 'kk');
      } else if (this.$i18n.locale == 'kk') {
        this.$i18n.locale = 'en'
        localStorage.setItem('locale', 'en');
      } else if (this.$i18n.locale == 'en') {
        this.$i18n.locale = 'ru'
        localStorage.setItem('locale', 'ru');
      }

    },
    closeLogin() {
      this.$store.commit('openLogInDialog')
      document.body.classList.toggle('overflow-hidden')
      document.body.classList.toggle('overflow-ios')
    },
    logout() {
      this.$store.dispatch('logout')
      router.replace('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.overflow-elipsis-head {
  display: block;
  width: 254px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@import "../assets/css/colors";

.phone-contact-wrapper-mobile {
  width: 100% !important;
}

.phone-contact-wrapper {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 60px;
}


.phone-link {
  line-height: 30px;
  position: relative;
}

.phone-link:before {
  content: "";
  position: absolute;
  left: 10px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../assets/img/phone.svg');

}


.whatsapp-link {
  line-height: 30px;
  position: relative;
}

.whatsapp-link:before {
  content: "";
  position: absolute;
  left: 10px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../assets/img/whatsapp.svg');

}


@media only screen and (max-device-width: 450px) {
  .mobile {
    display: none;
  }
}

header {
  background-color: white !important;
}

#mobile-nav {
  padding: 1.69vw 4vw;
  vertical-align: middle;
}

.nav__user,
.nav__icon {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}

.mobile_nav_item {
  & {
    img {
      margin: 0 2vw 0 -2vw;
    }
  }

  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 2px;
  margin: 0 16px 2rem;
  background: white;
  padding-left: 1.04vw;

  & {
    .modal__view {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .auth__nav__item {
      display: flex;
      align-items: center;
      border: none;
    }
  }

  a {
    border: 0.065vw solid $green_14;
    font-family: Gotham-Medium, sans-serif;
    padding: 2vw 2vw;
    border-radius: 2vw;
    margin-bottom: 2vw;
    margin-right: 1.04vw;
    font-size: 5vw;
  }
}

.nav__icon > img {
}

#mobile-nav,
#nav {
  display: flex !important;
  justify-content: space-between;
}

#nav a {
  white-space: nowrap;
  padding-left: 1.3vw;
  padding-right: 1.3vw;
  font-weight: lighter;
  font-family: Gotham, sans-serif;
  color: $black;
  font-style: normal;
  border-radius: 0.78vw;
}

.nav__logo {
  padding-top: 1vw;
  margin-top: 0 !important;
  padding-left: 0.13vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav__menu {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}

#nav a.router-link-exact-active {
  background: none;
  color: black;
}

.change_lang {
  user-select: none;
  cursor: pointer;
  text-transform: uppercase;
}
</style>
